import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { mentorIcons } from './images/mentorIcons';

import css from './SectionBenefits.module.css';

const BenefitsContentForMentor = ({ currentUser, intl }) => {

  const cardsData = [
    {
      image: mentorIcons?.flexibility,
      title: 'Flexibility',
      text: intl.formatMessage({id: 'SectionBenefits.mentorText1'}),
    },
    {
      image: mentorIcons?.sideIncome,
      title: 'Side Income',
      text: intl.formatMessage({id: 'SectionBenefits.mentorText2'}),
    },
    {
      image: mentorIcons?.givingBack,
      title: 'Giving Back',
      text: intl.formatMessage({id: 'SectionBenefits.mentorText3'}),
    },
    {
      image: mentorIcons?.networking,
      title: 'Networking',
      text: intl.formatMessage({id: 'SectionBenefits.mentorText4'}),
    },
    {
      image: mentorIcons?.brilliantMentees,
      title: 'Brilliant Mentees',
      text: intl.formatMessage({id: 'SectionBenefits.mentorText5'}),
    },
  ];

  return (
    <div className={css.content}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionBenefits.titleMentor" />
      </h2>
      <p className={classNames(css.subTitle, css.subTitleForMentor)}>
        <FormattedMessage id="SectionBenefits.subTitleMentor" />
      </p>

      <ul className={css.cards}>
        {cardsData.map(item => {
          return (
            <li className={css.cardsItem} key={item.title}>
              {item.image}
              <h3 className={css.cardsItemTitle}>
                {item.title}
              </h3>
              <p className={css.cardsItemText}>
                {item.text}
              </p>
            </li>
          )
        })}
      </ul>

      {!currentUser && (
        <NamedLink
          name="SignupPage"
          to={{
            search: 'userType=mentor',
          }}
          className={classNames(css.button)}
        >
          <FormattedMessage id="SectionBenefits.startMentoringToday" />
        </NamedLink>
      )}

    </div>
  );
};

export default BenefitsContentForMentor;