import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_48HOURS,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_DECLINE,
  TRANSITION_ENTER_48HOURS_PRE_BOOKING,
  TRANSITION_ENTER_1HOUR_PRE_BOOKING,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER_48_HOURS,
  TRANSITION_CANCEL_BY_CUSTOMER_48_HOURS,
  TRANSITION_CANCEL_BY_PROVIDER_1_HOUR,
  TRANSITION_CANCEL_BY_CUSTOMER_1_HOUR,
  TRANSITION_PROVIDER_APPEAR_ON_MEETING,
  TRANSITION_CUSTOMER_APPEAR_ON_MEETING,
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  txIsAccepted,
  txIsEntered48HoursPreBooking,
  txIsEntered1HourPreBooking,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserNotifications } from '../../ducks/user.duck';
import {
  acceptSale,
  acceptSalePre48Hours,
  declineSale,
} from '../../containers/TransactionPage/TransactionPage.duck';

import * as log from '../../util/log';
import { updatedEntities, denormalisedEntities } from '../../util/data';

const RESULT_PAGE_SIZE = 100;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownListingEntities: updatedEntities({ ...state.ownListingEntities }, apiResponse),
  };
};

const isCurrentUserMentor = getState => {
  const { currentUser } = getState().user;
  let isMentor = currentUser && currentUser.attributes && currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userType === 'mentor';

  if( isMentor ){
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if ( cookies.userType ) {
      isMentor = cookies.userType === 'mentor';
    }
  }

  return isMentor;
};

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownListingEntities } = state.DashboardPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownListingEntities, resources, throwIfNotFound);
};

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_BOOKING_REQUESTS_REQUEST = 'app/DashboardPage/FETCH_BOOKING_REQUESTS_REQUEST';
export const FETCH_BOOKING_REQUESTS_SUCCESS = 'app/DashboardPage/FETCH_BOOKING_REQUESTS_SUCCESS';
export const FETCH_BOOKING_REQUESTS_ERROR = 'app/DashboardPage/FETCH_BOOKING_REQUESTS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/DashboardPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/DashboardPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/DashboardPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/DashboardPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/DashboardPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/DashboardPage/DECLINE_SALE_ERROR';

export const CANCEL_MEETING_REQUEST = 'app/DashboardPage/CANCEL_MEETING_REQUEST';
export const CANCEL_MEETING_SUCCESS = 'app/DashboardPage/CANCEL_MEETING_SUCCESS';
export const CANCEL_MEETING_ERROR = 'app/DashboardPage/CANCEL_MEETING_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/DashboardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/DashboardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/DashboardPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/DashboardPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const listingResultIds = data => data.data.map(l => l.id);

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  fetchBookingsInProgress: false,
  fetchBookingsError: null,
  pagination: null,
  bookingPagination: null,
  transactionRefs: [],
  bookingRefs: [],
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  listingPagination: null,
  queryListingsParams: null,
  queryListingsInProgress: false,
  queryListingsError: null,
  currentListingResultIds: [],
  ownListingEntities: {},
  cancelInProgress: false,
  cancelMeetingError: null,
};

export default function dashboardPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_BOOKING_REQUESTS_REQUEST:
      return { ...state, fetchBookingsInProgress: true, fetchBookingsError: null };
    case FETCH_BOOKING_REQUESTS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchBookingsInProgress: false,
        bookingRefs: entityRefs(transactions),
        bookingPagination: payload.data.meta,
      };
    }
    case FETCH_BOOKING_REQUESTS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchBookingsInProgress: false, fetchBookingsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case CANCEL_MEETING_REQUEST:
      return { ...state, cancelInProgress: true, cancelMeetingError: null };
    case CANCEL_MEETING_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_MEETING_ERROR:
      return { ...state, cancelInProgress: false, cancelMeetingError: payload };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsParams: payload.queryListingsParams,
        queryListingsInProgress: true,
        queryListingsError: null,
        currentListingResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentListingResultIds: listingResultIds(payload.data),
        listingPagination: payload.data.meta,
        queryListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryListingsInProgress: false, queryListingsError: payload };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.DashboardPage.acceptInProgress || state.DashboardPage.declineInProgress;
};

// ================ Selectors ================ //

export const cancelMeetingInProgress = state => {
  return state.DashboardPage.cancelInProgress;
};

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchBookingsRequest = () => ({ type: FETCH_BOOKING_REQUESTS_REQUEST });
const fetchBookingsSuccess = response => ({
  type: FETCH_BOOKING_REQUESTS_SUCCESS,
  payload: response,
});
const fetchBookingsError = e => ({
  type: FETCH_BOOKING_REQUESTS_ERROR,
  error: true,
  payload: e,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const cancelMeetingRequest = () => ({ type: CANCEL_MEETING_REQUEST });
const cancelMeetingSuccess = () => ({ type: CANCEL_MEETING_SUCCESS });
const cancelMeetingError = e => ({ type: CANCEL_MEETING_ERROR, error: true, payload: e });

export const queryListingsRequest = queryListingsParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryListingsParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const fetchOrdersOrSales = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: params.only,
    lastTransitions: [
      TRANSITION_ACCEPT, TRANSITION_ACCEPT_48HOURS,
      TRANSITION_ENTER_48HOURS_PRE_BOOKING, TRANSITION_ENTER_1HOUR_PRE_BOOKING,
      TRANSITION_PROVIDER_APPEAR_ON_MEETING, TRANSITION_CUSTOMER_APPEAR_ON_MEETING,
      TRANSITION_COMPLETE, TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_1_BY_CUSTOMER,
    ],
    include: [
      'provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking', 'listing'
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const fetchBookingRequests = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchBookingsRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: params.only,
    lastTransitions: [ TRANSITION_CONFIRM_PAYMENT ],
    include: [
      'provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking', 'listing'
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then( response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchBookingsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchBookingsError(storableError(e)));
      throw e;
    });
};

const MILLISECONDS_IN_48_HOURS = 48 * 60 * 60 * 1000;

export const acceptBooking = ( id, millisecondsTillBooking ) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  const over48Hours = millisecondsTillBooking > MILLISECONDS_IN_48_HOURS;

  return dispatch( over48Hours ? acceptSale( id ) : acceptSalePre48Hours( id ))
    .then(response => {
      dispatch(acceptSaleSuccess());

      const { currentUser } = getState().user;
      let isMentor = currentUser && currentUser.attributes && currentUser.attributes.profile &&
        currentUser.attributes.profile.publicData &&
        currentUser.attributes.profile.publicData.userType === 'mentor';

      if( isMentor ){
        const cookies = document.cookie.split('; ').reduce((acc, c) => {
          const [name, value] = c.split('=');
          return { ...acc, [name]: decodeURIComponent(value) };
        }, {});

        if ( cookies.userType ) {
          isMentor = cookies.userType === 'mentor';
        }
      }

      const fetchPromises = [
        dispatch( fetchOrdersOrSales({ only: isMentor ? 'sale' : 'order' })),
        dispatch( fetchBookingRequests({ only: isMentor ? 'sale' : 'order' }))
      ];

      return Promise.all( fetchPromises );
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineBooking = id => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  return dispatch( declineSale( id ) )
    .then(response => {
      dispatch(declineSaleSuccess());

      const { currentUser } = getState().user;
      let isMentor = currentUser && currentUser.attributes && currentUser.attributes.profile &&
        currentUser.attributes.profile.publicData &&
        currentUser.attributes.profile.publicData.userType === 'mentor';

      if( isMentor ){
        const cookies = document.cookie.split('; ').reduce((acc, c) => {
          const [name, value] = c.split('=');
          return { ...acc, [name]: decodeURIComponent(value) };
        }, {});

        if ( cookies.userType ) {
          isMentor = cookies.userType === 'mentor';
        }
      }

      const fetchPromises = [
        dispatch( fetchOrdersOrSales({ only: isMentor ? 'sale' : 'order' })),
        dispatch( fetchBookingRequests({ only: isMentor ? 'sale' : 'order' }))
      ];

      return Promise.all( fetchPromises );
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const cancelMeeting = tx => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const isMentor = isCurrentUserMentor( getState );

  if (cancelMeetingInProgress(getState())) {
    return Promise.reject(new Error('Meeting cancellation already in progress'));
  }

  if( !txIsAccepted( tx )){
    return Promise.reject(new Error('Meeting is not in accepted state - cancellation is impossible'));
  }

  dispatch( cancelMeetingRequest());

  let cancelTransition = null;

  if( txIsEntered1HourPreBooking( tx ))
    cancelTransition = tx.provider.id.uuid === currentUser.id.uuid ?
      TRANSITION_CANCEL_BY_PROVIDER_1_HOUR : TRANSITION_CANCEL_BY_CUSTOMER_1_HOUR;
  else if( txIsEntered48HoursPreBooking( tx ))
    cancelTransition = tx.provider.id.uuid === currentUser.id.uuid ?
      TRANSITION_CANCEL_BY_PROVIDER_48_HOURS : TRANSITION_CANCEL_BY_CUSTOMER_48_HOURS;
  else
    cancelTransition = tx.provider.id.uuid === currentUser.id.uuid ?
      TRANSITION_CANCEL_BY_PROVIDER : TRANSITION_CANCEL_BY_CUSTOMER;

  return sdk.transactions
    .transition({ id: tx.id, transition: cancelTransition, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelMeetingSuccess());
      dispatch(fetchCurrentUserNotifications());
      dispatch( fetchOrdersOrSales({ only: isMentor ? 'sale' : 'order' }));

      return response;
    })
    .catch(e => {
      dispatch(cancelMeetingError(storableError(e)));
      log.error(e, 'cancel-meeting-failed', {
        txId: tx.id,
        transition: cancelTransition,
      });
      throw e;
    });
};

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const params = { per_page: RESULT_PAGE_SIZE };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  return dispatch( fetchCurrentUser())
    .then(() => {
      const { currentUser } = getState().user;
      let isMentor = currentUser && currentUser.attributes && currentUser.attributes.profile &&
        currentUser.attributes.profile.publicData &&
        currentUser.attributes.profile.publicData.userType === 'mentor';

      if( isMentor ){
        const cookies = document.cookie.split('; ').reduce((acc, c) => {
          const [name, value] = c.split('=');
          return { ...acc, [name]: decodeURIComponent(value) };
        }, {});

        if ( cookies.userType ) {
          isMentor = cookies.userType === 'mentor';
        }
      }

      const fetchPromises = [
        dispatch( fetchOrdersOrSales({ only: isMentor ? 'sale' : 'order' })),
        dispatch( fetchBookingRequests({ only: isMentor ? 'sale' : 'order' })),
        dispatch( queryOwnListings({})),
      ];

      Promise.all( fetchPromises );
    });
};
