import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  fetchTransaction, fetchMessages
} from '../../containers/TransactionPage/TransactionPage.duck'

import * as log from '../../util/log';
import { updatedEntities, denormalisedEntities } from '../../util/data';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 100;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownListingEntities: updatedEntities({ ...state.ownListingEntities }, apiResponse),
  };
};

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownListingEntities } = state.ConversationPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));

  const throwIfNotFound = false;
  return denormalisedEntities(ownListingEntities, resources, throwIfNotFound);
};

// ================ Action types ================ //

export const FETCH_CONVERSATIONS_REQUEST = 'app/ConversationPage/FETCH_CONVERSATIONS_REQUEST';
export const FETCH_CONVERSATIONS_SUCCESS = 'app/ConversationPage/FETCH_CONVERSATIONS_SUCCESS';
export const FETCH_CONVERSATIONS_ERROR = 'app/ConversationPage/FETCH_CONVERSATIONS_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/ConversationPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ConversationPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ConversationPage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ConversationPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const listingResultIds = data => data.data.map(l => l.id);

const initialState = {
  fetchInProgress: false,
  fetchConversationsError: null,
  pagination: null,
  transactionRefs: [],
  listingPagination: null,
  queryListingsParams: null,
  queryListingsInProgress: false,
  queryListingsError: null,
  currentListingResultIds: [],
  ownListingEntities: {},
};

export default function conversationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONVERSATIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchConversationsError: null };
    case FETCH_CONVERSATIONS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONVERSATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsParams: payload.queryListingsParams,
        queryListingsInProgress: true,
        queryListingsError: null,
        currentListingResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentListingResultIds: listingResultIds(payload.data),
        listingPagination: payload.data.meta,
        queryListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryListingsInProgress: false, queryListingsError: payload };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.DashboardPage.acceptInProgress || state.DashboardPage.declineInProgress;
};

// ================ Action creators ================ //

const fetchConversationsRequest = () => ({ type: FETCH_CONVERSATIONS_REQUEST });
const fetchConversationsSuccess = response => ({
  type: FETCH_CONVERSATIONS_SUCCESS,
  payload: response,
});
const fetchConversationsError = e => ({
  type: FETCH_CONVERSATIONS_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryListingsParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryListingsParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const fetchConversations = params => (dispatch, getState, sdk) => {
  dispatch(fetchConversationsRequest());

  const page = 1;

  const apiQueryParams = {
    lastTransitions: [ TRANSITION_ENQUIRE ],
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchConversationsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchConversationsError(storableError(e)));
      throw e;
    });
};

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const params = { per_page: RESULT_PAGE_SIZE };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  const txId = params && params.id ? new UUID( params.id ) : null;

  const fetchPromises = [
    dispatch( fetchConversations({})),
    dispatch( queryOwnListings({}))
  ];

  if( txId ){
    fetchPromises.push( dispatch( fetchTransaction( txId, 'customer')));
    fetchPromises.push( dispatch( fetchMessages( txId, 1 )));
  }

  return Promise.all( fetchPromises );
};
