import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowCarousel.module.css';

const IconArrowCarousel = props => {
  const { className, rootClassName, direction } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (direction) {
    case "left":
      return (
        <svg
          className={classes}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          fill="none"
          viewBox="0 0 24 25"
        >
          <rect
            width="24"
            height="24"
            y="24.5"
            fill="#F39663"
            rx="12"
            transform="rotate(-90 0 24.5)"
          ></rect>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M14 7.5l-5 5 5 5"
          ></path>
        </svg>
      );
    case "right":
      return (
        <svg
          className={classes}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          fill="none"
          viewBox="0 0 24 25"
        >
          <rect
            width="24"
            height="24"
            x="24"
            y="0.5"
            fill="#F39663"
            rx="12"
            transform="rotate(90 24 .5)"
          ></rect>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M10 17.5l5-5-5-5"
          ></path>
        </svg>
      );

    default:
      return null
  }
};

IconArrowCarousel.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowCarousel.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowCarousel;
