import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionYourExperience.module.css';

import leftImage from './images/left-image.png';

const SectionYourExperience = props => {
  const { rootClassName, className, isLandingPage, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <img
              className={css.explanationPicture}
              src={leftImage}
            />
          </div>
        </div>

        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepTitle}>
            {isLandingPage ? (
              <FormattedMessage
                id="SectionYourExperience.title"
                values={{ lineBreak: <br /> }}
              />
            ) : (
              <FormattedMessage
                id="SectionYourExperience.titleMentee"
                values={{ lineBreak: <br /> }}
              />
            )}
          </h2>
          <div className={css.actionButtons}>
            {isLandingPage && !currentUser ? (
              <NamedLink
                name="SignupPage"
                to={{
                  search: 'userType=mentor',
                }}
                className={css.signupButton}
              >
                <FormattedMessage id="SectionYourExperience.button1" />
              </NamedLink>
            ) : (
              <NamedLink
                name="SearchPage"
                className={css.signupButton}
              >
                <FormattedMessage id="SectionYourExperience.button2" />
              </NamedLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionYourExperience.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionYourExperience.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionYourExperience;
