
export const itData = {
  mainTitle: "IT Career Mentor",
  accordionData: [
    {
      title: "About Job Specific IT Mentoring",
      description: [
        "In the Information Technology (IT) industry, innovation and evolution are constants. This industry includes a variety of job roles, from software development and cybersecurity to data analytics, AI and cloud computing. It plays an important role in shaping the future and driving global progress.",
        "The IT industry is all about agility and adaptability. Companies within the sector continue to push boundaries and strive to create more efficient systems, enhance connectivity, and revolutionise how businesses operate. The IT industry also influences communication, education, healthcare and commerce on a global scale.",
        "Moreover, the IT sector fosters a culture of continuous learning and collaboration. Professionals in this career are at the forefront of innovation, developing solutions to address emerging challenges. As technology becomes increasingly integrated into daily life, the IT industry's significance continues to grow.",
        "If you’re working in the IT sector, your growth opportunities are unparalleled. However, to navigate this complex career line, you’ll require more than just technical knowledge. Whether you’re looking to enter the IT industry or you want to climb the corporate ladder within the industry, you need strategic guidance and IT mentorship. ",
        "That’s where we come in! Our platform facilitates the connection between mentors and individuals like yourself who are hungry for insights and guidance. You can choose a mentor based on your current job role or the role you’re hoping to achieve.",
        "At Career Navig8r, our IT mentors have years of hands-on experience in this industry and can bring invaluable insights to the table. With their understanding of the challenges, trends and nuances of the IT industry, they can provide you with all the guidance you need. ",
        "This is your chance to gain expertise from those who have walked the same path that you’re on. Register with us and find your IT career mentor today! ",
      ]
    },
    {
      title: "Challenges Faced by Emerging IT Professionals",
      description: [
        "Starting a career in the IT industry comes with a range of challenges. One major obstacle is the rapid pace of technological advancements, which requires constant upskilling to stay in the game. The need for a broad skill set, covering coding languages, cybersecurity, and cloud computing, among others, also creates a stimulating yet overwhelming learning curve.",
        "Additionally, when you enter the IT workforce, you’ll need to face real-world scenarios that may not have been extensively covered in your academic settings. Navigating complex projects, collaborating in cross-functional teams, and maintaining tight deadlines are common challenges that will test the resilience and adaptability of your budding IT skills.",
        "Furthermore, the industry's competitive nature can be intense, with emerging professionals worldwide vying for job opportunities. Aside from technical expertise, getting a job in IT also requires effective communication skills and the ability to showcase your unique value in the industry.",
        "As you deal with these challenges, you could highly benefit from the guidance of a mentor in the IT industry. If you’re looking for someone who has been where you are and can teach you the ins and outs of this competitive industry, a job role-specific IT mentor is exactly what you need.",
        "Apply for our IT mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an IT Career Mentor?",
      description: [
        "If you’re working in the IT industry, regardless of your experience level, an IT career mentor’s advice and guidance would be invaluable for your growth. ",
        "If you’re a new professional, an IT mentorship can help you gain insights into the intricacies of the field and industry trends, and provide you with practical advice on skill development. Your mentors in IT will guide you through early career challenges and help you transition from your academic settings to the dynamic and demanding IT workplace. ",
        "Similarly, if you’re a mid-career professional, IT mentors can help you navigate complex scenarios, refine your skill sets, and assist you in strategising to move forward in your career. With technology evolving, an IT mentor can be your trusted advisor, helping you keep track of the industry changes and adapt proactively. ",
        "Even as a seasoned IT expert, you can benefit from an IT mentorship programme, especially when exploring new specialities, transitioning to leadership roles, or seeking guidance on broader career trajectories. You could run your ideas by your mentor in the IT industry, learn from their experiences, and improve your strategic thinking to tackle your advanced challenges. ",
        "Ultimately, if you want to thrive in the IT industry, an IT career mentor's guidance is just what you need. The relationship between you and your mentor will foster professional development and knowledge transfer, and help you build a supportive network that will contribute to a resilient and successful IT career journey. ",
      ]
    },
    {
      title: "What To Expect from an IT Mentorship?",
      description: [
        "An IT mentorship can be beneficial for both newbies in IT and experienced professionals. Firstly, as a mentee, you can expect personalised guidance tailored to your specific job role, career goals and challenges.",
        "Your IT mentor, who will often be a seasoned industry expert, will share practical knowledge, industry trends, and firsthand experiences with you.They’ll provide you with invaluable insights that go beyond what formal education may have taught you.",
        "In an IT mentorship programme, you’ll get constructive feedback for skill development and performance enhancement.Mentors can offer a fresh perspective to help you identify your strengths and areas for improvement.The mentorship dynamic will also encourage open communication, creating a safe space for you to ask questions, seek advice, and discuss your career aspirations.",
        "Furthermore, mentorship isn’t just about technical skills, it will also help improve your soft skills such as effective communication, leadership and interpersonal dynamics. Your mentors in IT will guide you through the nuances of workplace culture and help you navigate challenges within the IT industry.",
        "Lastly, a successful IT mentorship can also help you build a professional network. You’ll gain access to the mentor's industry connections, which would potentially open doors to new opportunities, collaborations and a broader support system.",
        "In essence, with a mentor in the IT industry, you won’t just gain knowledge; you’ll also be able to grow personally and professionally.",
      ]
    },
    {
      title: "Find an IT Career Mentor at Career Navig8r Today!",
      description: [
        "When choosing a career or growing within it, it’s not uncommon to feel lost and apprehensive.Moreover, if you’ve just begun your career in a new industry, the path to success can seem long- winded and overwhelming. ",
        "You may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring.This is where you could benefit from having a qualified and experienced career mentor.They will help you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now ",
      ]
    },
  ]
};

export const hrData = {
  mainTitle: "HR Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific HR Mentoring",
      description: [
        "The HR (Human Resources) industry is essential in modern organisations as they manage the workforce to drive productivity, engagement and organisational success. At its core, HR includes many and varied unique job roles and career paths including recruitment, talent management, employee relations, training and development, compensation and benefits, and HR technology.",
        "One of the key responsibilities of HR professionals is to attract and retain top talent by creating a positive work environment and offering competitive salary packages. They also handle the recruitment process, from sourcing candidates to conducting interviews and onboarding new hires.",
        "Moreover, HR professionals help create a culture of diversity, equality and inclusion within the workplace to ensure that all employees feel valued and respected.",
        "However, HR professionals often face the challenge of adapting to evolving workforce trends, such as remote work and the gig economy, while also navigating complex legal and regulatory requirements. This is where job-specific HR mentoring could help!",
        "At Career Navig8r, you can choose an HR mentor who will provide you with personalised guidance and support based on a particular aspect of your role or industry. This mentoring style focuses on the intricacies and nuances of specific job functions and can benefit you at any stage of your career in human resources.",
        "Your HR mentor will help you develop skills in a particular area, such as talent acquisition, performance management, employee relations, or HR technology implementation. The mentor in HR will use their own experience and expertise to offer insights, advice and practical strategies tailored to your unique needs and goals.",
        "This will not only accelerate your learning curve as an HR professional but also foster confidence and competence in handling job-specific responsibilities or navigating complex HR scenarios.",
        "If you’re an HR professional looking to enhance your skills, advance your career, and excel in your specialised areas of expertise, sign up with us to find your human resources mentor now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging HR Professionals",
      description: [
        "Whether you’re a budding HR professional or hoping to climb higher up the corporate ladder, you’re likely to encounter a range of challenges as you navigate your career and strive to establish yourself within the industry.",
        "Firstly, if you don’t have extensive hands-on experience, you may struggle to address complex HR issues, handle employee relations matters effectively, or make strategic decisions that impact the organisation.",
        "Additionally, the HR industry is constantly evolving, with new technologies, methods and legal requirements emerging regularly. Keeping up with these changes requires continuous learning and professional development, which can be challenging if you’re new to the field.",
        "Moreover, establishing your credibility and earning the trust of colleagues and senior leaders can be an uphill battle. You have to build relationships, demonstrate competence, and effectively communicate HR initiatives and strategies to gain respect within your organisation.",
        "Furthermore, as an emerging HR professional, you may often find yourself juggling various tasks, from recruitment and onboarding to performance management and employee engagement initiatives, all while managing time constraints and deadlines.",
        "However, you can overcome these obstacles with the help of a mentor in human resources. They will provide learning opportunities, help you build strong networks, and keep you adaptable in the face of change.",
        "To grow and thrive in your HR career, join our HR mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an HR Career Mentor?",
      description: [
        "If you’re just starting your career in human resources, you can greatly benefit from the guidance and support of a mentor. However, HR mentors aren’t only for newcomers to the field. Anyone pursuing a career in HR, regardless of their level of experience, can find value in having a human resources mentor.",
        "If you’re new to HR, you’ll need an HR mentor to navigate the complexities of the field, gain insights into industry best practices, and accelerate your learning curve. A mentor in human resources can provide invaluable advice on building foundational skills, understanding HR processes, and overcoming initial challenges.",
        "If you’re a mid-career HR professional, you can turn to HR mentors for guidance on advancing your career, transitioning into leadership roles, or specialising in specific areas such as talent management, organisational development, or HR technology. A mentor in HR can offer strategic career advice, share their own experiences, and help you find opportunities for growth and development.",
        "Even as a seasoned HR veteran, you could benefit from an HR mentorship programme, especially when you’re facing new challenges, transitioning to different industries or roles, or looking for fresh perspectives. A mentor in human resources can tell you about the challenges they have faced and the skills they have gained over the years, which would help you navigate complex HR issues with confidence.",
        "Overall, whether you’re just starting out or well-established in the field, you can benefit from the guidance, support and wisdom of a trusted mentor in HR. They have walked the same career path and have been exactly where you are. That makes them the ideal navigators to help you achieve your professional goals.",
      ]
    },
    {
      title: "What To Expect from an HR Mentorship Programme?",
      description: [
        "At Career Navig8r, our HR mentorship programme is designed to provide mentees with guidance, support and professional development opportunities within the field of human resources.",
        "When you sign up with us as a mentee, you can expect to receive personalised guidance from experienced HR professionals who are either one or multiple steps ahead of you in the HR industry. These human resources mentors will offer insights, advice and practical strategies tailored to your individual needs, career goals, and areas of interest within HR. That means you can choose a mentor based on the job role you’re hoping to achieve or excel in.",
        "An HR mentorship programme will give you the opportunity to engage in meaningful discussions, share experiences, and seek advice on navigating challenges commonly faced in HR roles. Your mentor in human resources will provide feedback on your work, help you develop new skills, and offer guidance on career advancement.",
        "Furthermore, through a mentorship program, you can connect with other professionals in the field, build relationships, and expand your professional networks. You’ll also gain exposure to different aspects of HR through shadowing experiences, project collaborations, or attending industry events.",
        "Sign up for our HR mentorship programme to receive all the support and guidance you need from experienced professionals in human resources.",
      ]
    },
    {
      title: "Find an HR Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!",
      ]
    },
  ]
};







export const financeData = {
  mainTitle: "Finance Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Finance Mentoring",
      description: [
        "The finance industry encompasses a variety of institutions, services and activities that are essential for the management of money, assets and investments within an economy. From banking and investment firms to insurance companies and stock exchanges, the finance sector plays a major role in facilitating economic growth and stability.",
        "Finance professionals are skilled in managing financial resources, analysing data, and making strategic decisions to maximise profitability and mitigate risks. They work in various sectors of the finance industry, including banking, investment management, insurance, and corporate finance.",
        "These professionals often hold specialised qualifications such as Chartered Financial Analyst (CFA), Certified Public Accountant (CPA), or Chartered Financial Planner (CFP), which demonstrate their expertise and commitment to professional standards.",
        "If you’re a finance professional who advises on investment opportunities, creates financial strategies, or ensures regulatory compliance, you’ll need strong analytical skills and the ability to interpret complex financial data to make informed decisions. Moreover, you have to keep up with market trends, economic developments, and regulatory changes to effectively navigate the dynamic finance industry.",
        "This is where finance mentoring can help you! At Career Navig8r, you can choose a finance mentor based on a specific job role. This could be a role that you already have and want to excel in or a role that you’re aiming to get.",
        "Your chosen mentor in the financial industry will offer insights, advice and practical strategies tailored to your unique needs and goals. They’ll help you develop your skills while giving you the confidence to manage your responsibilities and navigate any complex financial issues that come your way.",
        "If you’re a finance professional looking to benefit from the experience and expertise of the best financial mentors, sign up with us now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging Finance Professionals",
      description: [
        "As a budding finance professional, you’re likely to encounter various challenges as you navigate your career in the dynamic and competitive finance industry. One significant challenge is gaining practical experience and building a strong professional network.",
        "Today, even entry-level positions often require previous experience, which creates a rather tricky situation for recent graduates or career changers. Additionally, if you’re looking to break into certain specialised fields within finance, such as investment banking or hedge fund management, it can be particularly challenging due to the high competition and stringent hiring criteria.",
        "Moreover, you need to adapt to rapid technological advancements and evolving industry regulations. With various innovations and automation reshaping traditional finance practices, you have to continuously update your skills and knowledge to stay relevant.",
        "Another challenge is the pressure to perform in a high-stakes environment where financial decisions can have significant consequences. As an emerging professional, you may face intense workloads, tight deadlines, and demanding clients or supervisors, which can lead to stress and burnout.",
        "Furthermore, in the finance industry, long hours and unpredictable schedules are common. This can make it difficult to balance your professional ambitions with personal commitments while maintaining your physical and mental well-being.",
        "To navigate these obstacles, you’ll need resilience, adaptability, and a proactive approach to continuous learning and professional development. This can be achieved through finance mentoring.",
      ]
    },
    {
      title: "Who Needs a Finance Career Mentor?",
      description: [
        "Regardless of which stage you’re at in your finance career, you can benefit from having the best financial mentors to guide and support you.",
        "If you’re a recent graduate entering the finance industry, you can gain valuable insights and advice from finance mentors who have navigated similar career paths. These mentors can provide guidance on job search strategies, networking opportunities, and skill development to help you establish yourself in the field.",
        "If you’re a mid-career professional looking to advance or transition within the finance industry, you can also benefit from a finance mentorship programme. Mentors can offer guidance on career progression, leadership development, and navigating organisational dynamics. They can provide valuable perspectives and help you identify opportunities for growth and advancement.",
        "Even as an experienced finance professional, having a mentor can be helpful, especially if you’re facing new challenges or want to expand your expertise. A mentor in the financial industry can offer insights based on their extensive experience, provide strategic advice on complex financial matters, and help you navigate career transitions or leadership roles.",
        "Moreover, if you’re from an underrepresented or marginalised background in the finance industry, such as women or minorities, a finance mentor can provide support, advocacy and guidance to help you overcome barriers and achieve success.",
        "At Career Navig8r, our mentors from the financial industry have been exactly where you are and they’ve dealt with the same hurdles you’re experiencing. So, whether you’re seeking to grow, develop or excel in your finance career, the guidance, support and wisdom gained through finance mentoring can be invaluable for you.",
      ]
    },
    {
      title: "What To Expect from a Finance Mentorship Programme?",
      description: [
        "When you sign up for our finance mentorship programme, you’ll get to learn the ins and outs of your specific job role from experienced professionals within the finance industry.",
        "As a mentee, you can expect personalised guidance tailored to your career goals, interests and skill sets. Your mentor will provide individualised support, helping you navigate challenges and capitalise on opportunities specific to your career aspirations.",
        "This finance mentorship programme will also focus on skill development, allowing you to enhance your technical expertise, soft skills, and industry knowledge. Your mentor may provide training, recommend resources for self-study, and help you make better decisions in critical situations.",
        "Through mentorship, you’ll also get valuable networking opportunities, allowing you to connect with industry professionals, potential employers and peers. Your mentor may facilitate introductions, recommend networking events, or teach you how to build a professional network within the finance industry.",
        "Moreover, you can expect ongoing support as you advance in your career. Whether you’re aiming for promotions, job transitions, or skill development opportunities, your mentors will be right there to help you. They’ll offer advice on career planning, goal setting, and strategies for professional growth, and they’ll provide feedback to help you overcome challenges and achieve success in your finance career.",
        "Need help navigating the finance industry? Join our finance mentorship programme now!",
      ]
    },
    {
      title: "Find the Best Financial Mentors at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!"
      ]
    },
  ]
};
