import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import {
  removeListingFromFavorites,
} from '../../ducks/user.duck';

export const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/FavoritesPage/FETCH_FAVORITE_LISTINGS_ERROR';

export const REMOVE_FROM_FAVORITES_REQUEST = 'app/FavoritesPage/REMOVE_FROM_FAVORITES_REQUEST';
export const REMOVE_FROM_FAVORITES_SUCCESS = 'app/FavoritesPage/REMOVE_FROM_FAVORITES_SUCCESS';
export const REMOVE_FROM_FAVORITES_ERROR = 'app/FavoritesPage/REMOVE_FROM_FAVORITES_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchFavoritesInProgress: false,
  fetchFavoritesError: null,
  currentPageResultIds: [],
  removeFromFavoritesInProgress: false,
  removeFromFavoritesError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchFavoritesInProgress: true,
        fetchFavoritesError: null,
      };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        fetchFavoritesInProgress: false,
      };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchFavoritesInProgress: false, fetchFavoritesError: payload };

    case REMOVE_FROM_FAVORITES_REQUEST:
      return {
        ...state,
        removeFromFavoritesInProgress: true,
        removeFromFavoritesError: null,
      };
    case REMOVE_FROM_FAVORITES_SUCCESS:
      return {
        ...state,
        removeFromFavoritesInProgress: false,
      };
    case REMOVE_FROM_FAVORITES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, removeFromFavoritesInProgress: false, removeFromFavoritesError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchFavoriteListingsRequest = searchParams => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const fetchFavoriteListingsSuccess = response => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchFavoriteListingsError = e => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const removeFromFavoritesRequest = () => ({
  type: REMOVE_FROM_FAVORITES_REQUEST,
});

export const removeFromFavoritesSuccess = () => ({
  type: REMOVE_FROM_FAVORITES_SUCCESS,
});

export const removeFromFavoritesError = e => ({
  type: REMOVE_FROM_FAVORITES_ERROR,
  error: true,
  payload: e,
});

export const fetchFavoriteListings = listingIds => (dispatch, getState, sdk) => {
  dispatch( fetchFavoriteListingsRequest( listingIds ));

  const params = {
    ids: listingIds.join(','),
    per_page: 100,
    include: ['author', 'images', 'author', 'author.profileImage'],
    'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
    //'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.profileImage'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  if( listingIds && Array.isArray( listingIds ) && listingIds.length > 0 ) {
    return sdk.listings
      .query(params)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchFavoriteListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchFavoriteListingsError(storableError(e)));
        throw e;
      });
  } else {
    return Promise.resolve( null );
  }
};

export const removeFromFavorites = ( listingId ) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const { publicData = {}} = currentUser ? currentUser.attributes.profile : {};
  const { favoriteListingIds = []} = publicData;

  dispatch( removeFromFavoritesRequest());

  return dispatch( removeListingFromFavorites( listingId ))
    .then(() => {
      dispatch( fetchFavoriteListings( favoriteListingIds ));
      dispatch( removeFromFavoritesSuccess());
    })
    .catch(e => dispatch( removeFromFavoritesError( storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const { publicData = {}} = currentUser ? currentUser.attributes.profile : {};
  const { favoriteListingIds = []} = publicData;

  return dispatch( fetchFavoriteListings( favoriteListingIds ));
};
