import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './SignupSectionChooseUserRole.module.css';

const SignupSectionChooseUserRoleComponent = props => {
  const { rootClassName, className, intl, onChooseUserRole, onHoverUserRole } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div>
        <div className={css.formTitle}>
          <FormattedMessage id="SignupSectionChooseUserRole.title" values={{ br: <br/> }} />
        </div>
        <div
          className={css.roleBlock}
          onClick={() => onChooseUserRole('mentor')}
          onMouseOver={() => onHoverUserRole('mentor')}
        >
          <div className={css.roleSectionTitle}>
            <FormattedMessage id="SignupSectionChooseUserRole.mentorRoleTitle"/>
          </div>
          <div className={css.roleSectionText}>
            <FormattedMessage
              id="SignupSectionChooseUserRole.mentorRoleText"
              values={{ give: <b>give</b>}}
            />
          </div>
        </div>
        <div
          className={css.roleBlock}
          onClick={() => onChooseUserRole('mentee')}
          onMouseOver={() => onHoverUserRole('mentee')}
        >
          <div className={css.roleSectionTitle}>
            <FormattedMessage id="SignupSectionChooseUserRole.menteeRoleTitle"/>
          </div>
          <div className={css.roleSectionText}>
            <FormattedMessage
              id="SignupSectionChooseUserRole.menteeRoleText"
              values={{ get: <b>get</b>}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

SignupSectionChooseUserRoleComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupSectionChooseUserRoleComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupSectionChooseUserRole = compose(injectIntl)(SignupSectionChooseUserRoleComponent);
SignupSectionChooseUserRole.displayName = 'SignupSectionChooseUserRole';

export default SignupSectionChooseUserRole;
