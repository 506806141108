import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { convert } from 'html-to-text';
import truncate from 'lodash/truncate';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink } from '../../components';

import css from './SectionLocations.module.css';

import arrowLeftActiveImage from './images/arrow-left-active.png';
import arrowLeftInactiveImage from './images/arrow-left-inactive.png';
import arrowRightActiveImage from './images/arrow-right-active.png';
import arrowRightInactiveImage from './images/arrow-right-inactive.png';
import noPostImage from './images/no-post-image.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const POST_COLLAPSED_LENGTH = 150;

const locationLink = postRecord => {
  const nameText = <span className={css.locationName}>{postRecord.title}</span>;
  return (
    <ExternalLink href={postRecord.link} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            src={postRecord.thumbnail}
            alt={postRecord.title}
            className={css.locationImage}
          />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
      <div className={css.linkSubText}>
        {postRecord.content}
      </div>
    </ExternalLink>
  );
};

const SectionLocations = props => {
  const [ firstImageIndex, setFirstImageIndex ] = useState( 0 );

  const {
    rootClassName,
    className,
    viewport,
    wordpressPosts,
    fetchWordpressPostsError,
    fetchWordpressPostsInProgress
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const viewportExtraImages = viewport.width >= 768 ? 2 : 0;
  const arrowLeftImage = firstImageIndex > 0 ?
    arrowLeftActiveImage : arrowLeftInactiveImage;
  const arrowRightImage = firstImageIndex + viewportExtraImages < wordpressPosts.length - 1
    ? arrowRightActiveImage : arrowRightInactiveImage;

  const getFeaturedImage = postIndex => {
    if( !Array.isArray( wordpressPosts ) || postIndex >= wordpressPosts.length ){
      return { title: '', content: '', thumbnail: noPostImage };
    }

    const postRecord =  wordpressPosts[ postIndex ];

    return {
      title: postRecord.title && postRecord.title.rendered ?
        convert( postRecord.title.rendered ) : '',
      content: postRecord.content && postRecord.content.rendered ?
        truncate(convert( postRecord.content.rendered ), {
          length: POST_COLLAPSED_LENGTH,
          separator: /\s|,|\.|:|;/,
          omission: '…',
        }) : '',
      thumbnail: postRecord._embedded && Array.isArray( postRecord._embedded['wp:featuredmedia']) &&
        postRecord._embedded['wp:featuredmedia'].length > 0 &&
        postRecord._embedded['wp:featuredmedia'][0].source_url ?
          postRecord._embedded['wp:featuredmedia'][0].source_url : noPostImage,
      link: postRecord.link,
    };
  };

  const arrowBullets = (
    <div className={ viewport.width >= 768 ? css.arrowBullets : css.arrowBulletsMobile }>
      <img
        className={css.arrowImage}
        src={arrowLeftImage}
        onClick={() => {
          if( firstImageIndex > 0 )
            setFirstImageIndex( firstImageIndex - 1 )
        }}
      />
      <img
        className={css.arrowImage}
        src={arrowRightImage}
        onClick={() => {
          if( firstImageIndex + viewportExtraImages < wordpressPosts.length - 1 )
            setFirstImageIndex( firstImageIndex + 1 )
        }}
      />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.titleRow}>
        <div className={css.title}>
          <FormattedMessage id="SectionLocations.title" />
        </div>
        { viewport.width >= 768 ? arrowBullets : null }
      </div>
      <div className={css.locations}>
        {locationLink( getFeaturedImage( firstImageIndex ))}
        { viewportExtraImages > 0 ? (
          <>
            {locationLink( getFeaturedImage( firstImageIndex + 1 ))}
            {locationLink( getFeaturedImage( firstImageIndex + 2 ))}
          </>
        ) : null }
      </div>
      { viewport.width < 768 ? arrowBullets : null }
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose( withViewport )(SectionLocations);
