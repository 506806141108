import React, { useState, Component } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { InlineTextButton } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionFaqContent.module.css';

import plusSignImage from './images/plus-sign.png';
import minusSignImage from './images/minus-sign.png';

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }

  render() {
    const { expand } = this.state;
    const { question, answer } = this.props;

    const handleShowAnswerClick = () => {
      this.setState({ expand: !this.state.expand });
    };

    return (
      <div className={css.stepItemRow}>
        <div className={css.stepItemTextRow}>
          <div className={css.stepItemActiveTitle}>
            {question}
            <div className={css.stepItemPipeRow}>
              <div className={css.stepItemActiveFigure} onClick={handleShowAnswerClick}>
                <img src={expand ? minusSignImage : plusSignImage} />
              </div>
            </div>
          </div>
          {expand ? (
            <div className={css.stepItemActiveText}>
              {answer}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const SectionFaqContent = props => {
  const [selectedBlock, setSelectedBlock] = useState('general_frequently_questions');
  const { rootClassName, className, intl, withoutSidebar = false } = props;

  const classes = classNames(rootClassName || css.root, className, { [css.rootWithoutSidebar]: withoutSidebar });

  const frequentlyAskedQuestions = config.custom.frequentlyAskedQuestions;
  const selectedBlockQuestions = frequentlyAskedQuestions[selectedBlock].questions;

  const answersBlock = (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        {Object.keys(selectedBlockQuestions).map(blockKey => (
          <ExpandableText
            key={`${selectedBlock}-${blockKey}`}
            question={selectedBlockQuestions[blockKey].question}
            answer={selectedBlockQuestions[blockKey].answer}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.steps}>

        {!withoutSidebar && (
          <div className={css.stepQuestionWrapper}>
            <div className={css.stepQuestions}>
              {Object.keys(frequentlyAskedQuestions).map(blockKey => (
                <div
                  className={blockKey === selectedBlock ? css.activeQuestionBlock : css.questionBlock}
                  onClick={() => setSelectedBlock(blockKey)}
                >
                  {frequentlyAskedQuestions[blockKey].label}
                </div>
              ))}
            </div>
          </div>
        )}


        <div className={css.stepExplanationWrapper}>
          {answersBlock}
        </div>
      </div>
    </div>
  );
};

SectionFaqContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionFaqContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionFaqContent;
