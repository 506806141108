import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionBecomeMentorTop.module.css';

import inlineImage from './images/become-a-mentor-small.png';

const SectionBecomeMentorTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Become a Mentor
                </h2>

                <ul className={css.diskList}>
                  <li>
                    <p className={css.stepText}>
                      Always wanted to become a mentor but haven’t known where to start?
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Dabbled in mentoring before, but not been able to find mentees
                      who are a good match?
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      It’s time to become a Career Navig8r.
                    </p>
                  </li>
                </ul>

                <p className={css.stepText}>
                  No matter your career path, sector, or industry, getting onto and progressing up the career
                  ladder is more daunting than ever. Whether your professional journey has been a
                  hard fought challenge or comes relatively easily, you’ll have learned many lessons
                  that can help others expedite their careers. This is the role of the career mentor.
                </p>
                <p className={css.stepText}>
                  But becoming a career mentor isn’t just about guiding others, it can also have a
                  profound impact on your own self-discovery and leadership skills. As a career
                  mentor, you're doing so much more than just sharing knowledge, you’re building
                  a growth mindset, building resilience and nurturing the next generation in your given field.
                </p>
                <p className={css.stepText}>
                  At Career Navig8r, our mentorship platform helps shape careers and empower
                  individuals through their ideal professional paths. Our resources, tools
                  and Navigator program gives you the platform to make a meaningful difference
                  in the professional lives of others.
                </p>

                <p className={css.stepText}>
                  By becoming a mentor Navig8r, you’ll be given the opportunity to:
                </p>

                <ul className={css.diskList}>
                  <li>
                    <p className={css.stepText}>
                      Nurture talent for the next generation in your field
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Guide potential future leaders
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Inspire ambitious minds
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Build your own leadership skills
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Leave a lasting legacy within your space
                    </p>
                  </li>
                  <li>
                    <p className={css.stepText}>
                      Get paid for giving back
                    </p>
                  </li>
                </ul>

                <p className={css.stepText}>
                  If you’re interested in applying to be a mentor and want to join our
                  community of Navig8rs, sign up today!
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage1"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentor',
                    }}
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Become a mentor"
                  >
                    Become a mentor
                  </NamedLink>
                </div>


                <h2 className={css.stepSubSubTitle}>
                  What Does Career Mentorship Involve?
                </h2>

                <p className={css.stepText}>
                  If you’re searching for an alternate income and have the drive to share your wisdom
                  with other professionals, you’ve come to the right place. By signing up with Career Navig8r,
                  you have excellent opportunities to explore the world of career mentorship and bolster your
                  side hustle from home.
                </p>
                <p className={css.stepText}>
                  Career mentors are experienced professionals in their fields, offering guidance and
                  support to the next generation and those who are just behind them, following the same
                  career path. Providing career development opportunities, career mentors are trusted
                  advisors who offer valuable advice and constructive feedback to their mentees to
                  navigate their career paths effortlessly.
                </p>
                <p className={css.stepText}>
                  As one of the best online side hustles, career mentorship involves goal setting,
                  skill development and networking to help your mentees take that next jump in their
                  career, tackle specific problems or simply diversify their knowledge. Mentoring isn’t
                  just about helping industry starters. Regardless of where you are in your career path -
                  mentors deliver insight to those climbing up the professional ladder behind them, who
                  need support taking the next step. Mentors offer guidance to those who are already
                  working in a specific field and looking to take the next step in their career.
                </p>
                <p className={css.stepText}>
                  By nurturing their mentees, a mentor helps them fast-track their careers and overcome
                  professional hurdles. With active engagement and constant support through online coaching
                  sessions, career mentors add tangible value to their mentee’s professional path.
                </p>
                <p className={css.stepText}>
                  If you have experience within your field and a willingness to support others, you too
                  can become a mentor with Career Navig8r. All you’ll need is the drive to give back to
                  the next generation of your industry.
                </p>
                <p className={css.stepText}>
                  Sign up with Career Navig8r for the best side-hustle gigs and accelerate your career
                  path while earning an extra source of income.
                </p>



                <h2 className={css.stepSubSubTitle}>
                  Why Become A Career Mentor?
                </h2>
                <p className={css.stepText}>
                  Becoming a career mentor can be rewarding in many ways. It’s a chance to impart your
                  knowledge and experience, guide professionals in your field, and leave a mark on
                  the trajectory of their careers.
                </p>
                <p className={css.stepText}>
                  However, beyond giving back, online mentorship also offers you a platform for personal
                  growth, leadership enhancement, and the opportunity to shape the future of your
                  industry. You’ll be helping others excel in their professional lives whilst opening up a
                  lucrative side income opportunity.
                </p>


                <h3>
                  Empower the Next Generation of Professionals
                </h3>

                <p className={css.stepText}>
                  When you choose to become a career mentor, you are cementing your legacy
                  within your industry, helping those on their journey upwards. Whilst
                  many of our career experiences may feel mundane to us now, that hard-fought
                  wisdom will leave a memorable impact on your mentees.
                </p>
                <p className={css.stepText}>
                  You get to share your insights, nurture their strengths, and give them the
                  confidence needed to deal with career challenges. As a mentor, you can
                  inspire your mentees to better understand their potential and take bold steps.
                </p>
                <p className={css.stepText}>
                  The impact of this goes beyond individual growth—it pushes your industry,
                  and your sector forward and ensures a legacy of capable professionals who
                  can make a meaningful difference in the world.
                </p>


                <h3>
                  Share Your Expertise & Shape Careers
                </h3>

                <p className={css.stepText}>
                  Our online mentorship platform allows you to share your expertise and
                  leave a meaningful mark. The lessons from your experience and career-path-specific
                  insights are crucial to other professionals. By sharing your expertise,
                  you’ll provide invaluable guidance that enables your mentees to make
                  informed decisions and seize opportunities.
                </p>
                <p className={css.stepText}>
                  Through mentorship, you will sculpt their careers, influence their pivotal
                  choices, and foster growth in their professional lives. Your guidance doesn’t
                  just offer practical advice, it also inspires their confidence and broadens their perspectives.
                </p>
                <p className={css.stepText}>
                  As a mentor, the impact of your shared expertise will also help your mentees
                  in their future professional endeavours.
                </p>


                <h3>
                  Enhance Your Leadership Skills
                </h3>

                <p className={css.stepText}>
                  Online mentorship also offers a great way to refine and amplify your leadership skills.
                  As a mentor, you're not just guiding, you're leading by example. A successful mentor
                  will have a combination of leadership qualities like empathy, communication, adaptability
                  and strategic thinking.
                </p>
                <p className={css.stepText}>
                  By guiding others through their professional journeys, you can fine-tune your own ability
                  to inspire, motivate and influence positively. Mentoring also sharpens your capacity to
                  listen attentively, understand diverse perspectives, and offer the right guidance.
                </p>
                <p className={css.stepText}>
                  Moreover, the process of mentoring gives you real-time opportunities to practise your
                  decision-making, delegation and conflict resolution, all of which are fundamental
                  aspects of effective leadership. When you become a career mentor, you gain a deeper
                  understanding of interpersonal dynamics and team management, which will further help
                  you in your professional life.
                </p>


                <h3>
                  Become a Guide, a Navig8r in Your Industry / Sector
                </h3>

                <p className={css.stepText}>
                  As a mentor, your insights and guidance not only shape careers but also
                  influence the direction and innovation within your field. Becoming a
                  career mentor allows you to help professionals understand the complexities
                  of their chosen field.
                </p>
                <p className={css.stepText}>
                  With your experience, expertise and knowledge, you can provide your mentees
                  tailored strategies that newcomers might otherwise struggle with. Your guidance
                  might include personal success stories as well as industry nuances, trends and
                  the unwritten rules that you live day to day.
                </p>
                <p className={css.stepText}>
                  By sharing the intricacies and inner workings of your career, you’ll enable
                  your mentees to gain clarity, make informed decisions, and seek out innovative
                  approaches. Being a guide within your industry isn't just about giving information,
                  it's about shaping the future and preparing a new generation of knowledgeable,
                  well-equipped professionals ready to drive positive change.
                </p>

                <h3>
                  Boost Your Professional Development
                </h3>

                <p className={css.stepText}>
                  They say that teaching is learning twice. When you become a mentor,
                  it gives you a chance to deepen your knowledge, refine your communication
                  skills and remain in tune with the evolving landscape of your industry.
                </p>
                <p className={css.stepText}>
                  Mentoring also allows you to maintain a better understanding of what is
                  happening in your career space. This encourages continuous learning,
                  which enables you to provide relevant guidance to your mentees.
                  Moreover, when you explain concepts to mentees, it’s likely to
                  prompt fresh perspectives, challenging you to reassess and enhance
                  your own understanding.
                </p>
                <p className={css.stepText}>
                  Mentoring will also help you broaden your own network as you connect
                  with diverse talents, perspectives and ways of doing things that may
                  expand your own horizons. Additionally, guiding others can often lead
                  to self-reflection, which will enable you to refine your leadership style,
                  adapt to new challenges, and strengthen your problem-solving abilities.
                </p>
                <p className={css.stepText}>
                  Ultimately, mentoring is a great way to enhance your own personal growth.
                  It ensures that you remain agile, informed and continuously progressing
                  in your own professional journey.
                </p>


                <h3>
                  Develop Lasting Connections & Networks
                </h3>

                <p className={css.stepText}>
                  Mentorship allows you to create long-lasting connections and a more expansive network,
                  all whilst making a side income. When you become a mentor, you develop meaningful
                  relationships with mentees, built on trust, respect and shared aspirations.
                </p>
                <p className={css.stepText}>
                  These connections extend beyond mentorship; they have the potential to evolve into
                  mutually beneficial relationships that foster collaboration, partnership and
                  opportunity. Moreover, mentorship often introduces you to a more diverse network
                  of people within your industry, giving you a chance to grow your network. These
                  connections open doors to potential collaborations that can significantly impact
                  your professional journey.
                </p>
                <p className={css.stepText}>
                  The network that you create through mentorship is a valuable asset, offering support,
                  advice and opportunities for growth throughout your career.
                </p>

                <h3>
                  Monetise Your Expertise & Skills
                </h3>

                <p className={css.stepText}>
                  Beyond personal satisfaction, online mentorship can also be financially
                  rewarding. Career mentoring as a side hustle offers a fulfilling and
                  flexible way to leverage your expertise while supplementing your income.
                </p>
                <p className={css.stepText}>
                  It allows you to share your knowledge and guide others at your own pace,
                  fitting seamlessly into your schedule. Whether you’re mentoring for a
                  few hours a week or dedicating time during weekends, this flexible
                  engagement lets you shape careers while maintaining your primary
                  professional commitments.
                </p>
                <p className={css.stepText}>
                  As a side hustle, career mentoring is an opportunity to make a meaningful
                  impact while pursuing your passions. You can balance your career while
                  guiding aspiring professionals toward success within your industry.
                </p>


                <h2 className={css.stepSubSubTitle}>
                  How to Set Up Your Account
                </h2>

                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    <ul className={css.diskList}>
                      <li>
                        Click on “Be a Mentor”
                      </li>
                      <li>
                        Provide your name, email and password
                      </li>
                      <li>
                        Set up your profile with a picture and brief highlights of your career
                      </li>
                      <li>
                        Mention the job role or roles you’d like to be a Navig8r for
                      </li>
                      <li>
                        Provide details like job experience and specific skills
                      </li>
                      <li>
                        Define your availability, cost per hour and payment details
                      </li>
                      <li>
                        Let our platform manage the rest - emails, reminders, payment, online sessions
                      </li>
                    </ul>

                    <p className={css.stepText}>
                      If you’re ready to find your mentees, be a mentor now!
                    </p>

                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage} />
                  </p>
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage3"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentee',
                    }}
                    className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
                    title="Start mentoring"
                  >
                    Start mentoring
                  </NamedLink>
                </div>



                <h2 className={css.stepSubSubTitle}>
                  Apply to Be A Mentor at Career Navig8r Today!
                </h2>
                <p className={css.stepText}>
                  When choosing a career or growing within it, it’s not uncommon for people to 
                  feel lost and apprehensive. Picking a career path is a major life decision, 
                  which is why it requires careful consideration. Moreover, for those who have 
                  just begun their career in a new industry, the path to success can seem long and confusing.
                </p>
                <p className={css.stepText}>
                  Such individuals usually have numerous questions such as what to expect, which 
                  skills are needed, and what are employers looking for when hiring. This is where 
                  they need the help of a qualified and experienced mentor.
                </p>
                <p className={css.stepText}>
                  Whether you’re early on in your own career or a seasoned veteran, you have the 
                  opportunity to offer your knowledge and expertise to these individuals. You can 
                  help them understand what the job entails, which skills they need to work on and 
                  what are the challenges they may face.
                </p>
                <p className={css.stepText}>
                  Want to become a career mentor? Sign up with our mentorship platform and help shape 
                  the future of your industry.
                </p>











               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentorTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentorTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentorTop;
