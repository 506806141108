/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const securityLevels = [
  { key: 'all', label: 'All' },
  { key: 'chief', label: 'Director/Chief executive' },
  { key: 'senior', label: 'Senior/Area Manager' },
  { key: 'medium', label: 'Mid Level/Junior Manager' },
  { key: 'entry', label: 'Entry Level/Non-Management' },
];

export const languages = [
  { key: 'en', label: 'English', countryCode: 'gb'},
  { key: 'pt', label: 'Portuguese', countryCode: 'pt'},
  { key: 'ja', label: 'Japanese', countryCode: 'jp'},
  { key: 'hi', label: 'Hindi', countryCode: 'in'},
  { key: 'ms', label: 'Malay', countryCode: 'my'},
  { key: 'uk', label: 'Ukrainian', countryCode: 'ua'},
  { key: 'bn', label: 'Bengali', countryCode: 'bd'},
  { key: 'fr', label: 'French', countryCode: 'fr'},
  { key: 'es', label: 'Spanish', countryCode: 'es'},
  { key: 'de', label: 'German', countryCode: 'de'},
  { key: 'ar', label: 'Arabic', countryCode: 'sa'},
];

export const filters = [
  {
    id: 'category',
    label: 'Sectors',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accountancy_banking_and_finance', label: 'Accountancy, banking and finance'},
        { key: 'business_consulting_and_management', label: 'Business, consulting and management'},
        { key: 'charity_and_voluntary_work', label: 'Charity and voluntary work'},
        { key: 'creative_arts_and_design', label: 'Creative arts and design'},
        { key: 'energy_and_utilities', label: 'Energy and utilities'},
        { key: 'engineering_and_manufacturing', label: 'Engineering and manufacturing'},
        { key: 'environment_and_agriculture', label: 'Environment and agriculture'},
        { key: 'healthcare', label: 'Healthcare'},
        { key: 'hospitality_and_events_management', label: 'Hospitality and events management'},
        { key: 'information_technology', label: 'Information Technology'},
        { key: 'law', label: 'Law'},
        { key: 'law_enforcement_and_security', label: 'Law enforcement and security'},
        { key: 'leisure_sport_and_tourism', label: 'Leisure, sport and tourism'},
        { key: 'marketing_advertising_and_pr', label: 'Marketing, advertising and PR'},
        { key: 'media_and_internet', label: 'Media and internet'},
        { key: 'public_services_and_administration', label: 'Public services and administration'},
        { key: 'recruitment_and_hr', label: 'Recruitment and HR'},
        { key: 'retail', label: 'Retail'},
        { key: 'sales', label: 'Sales'},
        { key: 'science_and_pharmaceuticals', label: 'Science and pharmaceuticals'},
        { key: 'social_care', label: 'Social care'},
        { key: 'teacher_training_and_education', label: 'Teacher training and education'},
        { key: 'transport_and_logistics', label: 'Transport and logistics'},
      ],
    },
  },
  {
    id: 'securityLevels',
    label: 'Org. level',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_securityLevels'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: securityLevels,
    },
  },
  {
    id: 'languages',
    label: 'Languages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: languages,
    },
  },
  {
    id: 'price',
    label: 'Price / Hour',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'none',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'none',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'publicity',
    label: 'Publicity',
    type: 'SelectSingleFilter',
    group: 'none',
    queryParamNames: ['pub_publicity'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'public', label: 'Public Sector' },
        { key: 'private', label: 'Private Sector' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const jobRoles = require('./marketplace-job-roles').jobRoles;

export const frequentlyAskedQuestions = {
  general_frequently_questions: {
    label: "General frequently asked questions",
    questions: {
      what_is_a_mentor: {
        question: "What is a mentor?",
        answer: "At Career Navig8r, a Mentor (or Navig8r) is someone who has excelled at a job role and wants to share their experience with someone who either wants this role or wants to do it better.",
      },
      and_a_mentee: {
        question: "…and a mentee?",
        answer: "A mentee is someone who wants guidance on a specific job role from a Navig8r either because they want this job or they already have it and want to get better",
      },
      what_is_career_navigator: {
        question: "What is Career Navig8r?",
        answer: `
CareerNavig8r.com is a new mentoring platform that helps people looking for a mentor. 

Specifically, one who understands their exact challenges – to meet that mentor. 

Whether the need is:

- Being better at a new role.
- Preparing for a job interview.
- Looking to make some serious progression. 

We match mentees with Navig8rs (our fancy name for mentors) who have succeeded in their careers and are looking to help people shortcut their career progression.

“Their Experience = Your Success”
`,
      },
      what_does_career_navigator_do: {
        question: "What does Career Navig8r do?",
        answer: `
Two things.
1. If you’re looking to get a new job role, or you’re currently doing a job but want to get better at it, the platform enables you to find a mentor who is doing or has done your job.
And done it well.
They’ll enable you to either get that dream job or improve in your current role.

2. Career Navig8r provides the opportunity for you to become a Mentor.
Somewhere you can provide your skills, knowledge & experience in a specific role - to people
who want to get into or get better at, the same role you were (or still are) great at. A place where you get rewarded financially for sharing your skills & experiences.
`,
      },
      how_does_career_navigator_work: {
        question: "How does Career Navig8r work?",
        answer: `
We thought you’d ask. That’s why we wrote this section (for you inquisitive types): 

${process.env.REACT_APP_CANONICAL_ROOT_URL}/how-it-works

It’s an internal link. Won’t go anywhere weird.
`,
      },
      why_should_i_use_career_navigator: {
        question: "Why should I use Career Navig8r?",
        answer: `
For Mentees

Imagine the power of having a Mentor who has already succeeded in your dream job...being on hand with guidance and advice when you want to take that step.
A step they’ve already taken.
Someone happy to share with you the challenges & potential pitfalls, plus, all the skills and knowledge that made them successful. Think of the very job-specific advice & guidance they could provide when it comes to securing or preparing you for the interview.
Maybe you love your current job and want to be better at it through ongoing specialised and situation-specific help & guidance?
CareerNavig8r.com gives you all of that job-specific, role relevant direction!

For Mentors

Imagine you knew what you know now when you first started your career.
How powerful would that have been? That knowledge is exactly what you’ve got to offer. The knowledge & skills you’ve accrued throughout your career are valuable. Not just to the people looking to follow in your footsteps - but also to you.
The people excited to hear and be guided by you are also willing to pay you for your time & expertise.
As a Mentor, you can be full-time or part-time, or you can enrich your retirement.
Why shouldn’t you have a career encore?

CareerNavig8r.com lets you get paid for sharing what you know!
`,
      },

      do_i_need_to_create_an_account: {
        question: "Do I need to create an account?",
        answer: `
As a mentee, you can browse mentors (Navig8rs) without creating an account. To message a Navig8r or request a booking you’ll need to sign up for an account.
To list yourself as a mentor you need to sign up & provide payment information. (Assuming you want to get paid of course)
When you go to perform any of these things you will be prompted to create a user account where necessary.

NB: All user accounts require email verification.
`,
      },

      how_will_my_mentor_mentee_and_i_communicate: {
        question: "How will my mentor/mentee and I communicate?",
        answer: `
You’ll communicate through our inbuilt video conferencing capability.
There’s no need to worry about things like Microsoft Team, Zoom or Google Meet.
We’ve taken care of that for you.
`,
      },
      what_countries_do_you_support: {
        question: "What countries do you support?",
        answer: `
Career Navig8r is worldwide. We are wherever the internet is. You can be a mentee or Navig8r from anywhere in the world. Mentors can show all the languages they can mentor in.

Our only limitation is that set by Stripe which is the payment platform we use. Stripe allows nearly all payment cards, but for mentors - it only supports certain countries' bank accounts.
As a result, if you want to mentor, you will need to create or have a bank account in a country that Stripe works with. Currently (June 2022) Stripe covers 47 countries.
You can find the list here: 
`,
      },
      who_started_career_navig8r: {
        question: "Who started Career Navig8r?",
        answer: `
Career Navig8r was started by Kenneth D. Glynn (founder). It is now a part of his overarching company, the Beacon HRM Group, which is in turn an international Talent Consultancy. Beacon supplies Training, Executive Coaching, eLearning, and Talent Management services across the globe.
`,
      },
      why_does_my_inbox_have_two_sections: {
        question: "Why does my Inbox have two sections – “From Mentees” and “From Mentors”",
        answer: `
The short answer is: Because you can be both mentee and mentor at the same time. 
If someone you’re mentoring messages you their message goes to “From mentees” 
If someone is mentoring you their message goes to “From mentors”

If you’re only one or the other. The other side of your inbox will always be empty. 
A bit lonely even.
But it’s only an inbox.



Don’t feel sad about it.
`,
      },

    }
  },

  mentor_specific_faqs: {
    label: "Mentor Specific - FAQs",
    questions: {
      is_it_free_to_sign_up_as_a_mentor: {
        question: "Is it free to sign up as a Mentor? (In short - Yes)",
        answer: `
There is no cost for listing yourself as a Mentor.
Career Navig8r receives a set percentage (10%) of the revenue you receive each time a booking is made and that’s the only cost for access to the mentor role.
You only pay when you earn.
`,
      },
      can_i_have_the_mentee_book_and_pay_me_directly: {
        question: "Once I have started mentoring, can I have the mentee book and pay me directly?",
        answer: `
Nope. This is against the rules of the site.
It’s a little bit unethical for starters. You’ll also NOT be covered by our arbitration policy and have no recourse should anything go awry with your sessions and bookings.
You also lose access to the privacy and security of using our payment system. When you book everything through CareerNavig8r.com all of your communications, bookings and payments are private, protected and secure.
`,
      },
      why_do_i_need_to_be_over_eighteen_to_use_career_navig8r: {
        question: "Why do I need to be over eighteen to use Career Navig8r?",
        answer: `
Career Navig8r is a professional platform for people who are looking to either improve their career, or someone else’s. Conversations could be very adult. Money will be changing hands for services provided so the age limit is strictly set to a minimum of 18.
`,
      },
      how_do_i_set_myself_up_as_a_mentor: {
        question: "How do I set myself up as a Mentor?",
        answer: `
Easy & Straightforward:

Click “Be a Mentor” (link) in the top righthand corner of the main page.
Fill in your Name, E-mail address, password & age confirmation (minimum 18 years of age). Verify your email address.
You’ve now begun to create your Mentor Profile.
Add a picture to the information above. People like to know who they are dealing with and it will help mentees to choose you if they can see you.

Next, Fill in your work history. This is how mentees will understand that you’ve succeeded in the roles they are looking to either get into or get better at.
Start with the most recent and work backwards. Highlight the roles you’re comfortable mentoring in.

Now, that you’ve completed your profile; the option “Roles I can Mentor” is available. Here you get to define the job role, sectors and seniority levels you can mentor at.
You can add languages here and the cost you charge per hour for your mentoring service.

Don’t worry - you can edit your profile at any time.

Please note that all listings are subject to review by our team to ensure the safety of our community.
If you need to expedite this process or need help with your listing, please email us at admin@CareerNavig8r.com.
`,
      },
      what_should_i_put_in_my_listing: {
        question: "What should I put in my listing?",
        answer: `
We interviewed some mentees for you - to give you the exact info they want.

Here’s what was important to them:

    1. Has the Mentor performed the job role I’m interested in?

    2. Have they performed it in my sector?

    3. Have they performed it at the necessary level in terms of seniority?

    4. For who, and for how long did they do it?

    6. Is their experience & knowledge real & proven?

    7. Do they clearly define how their experience can help me? (Can they tell me not just that they had this role, but also show me that they were particularly good at doing it?)

    8. Do they come across as someone professional and approachable?

If your listing answers these questions, you’re in a good place as far as your audience is concerned.

Question 6 can be answered by having a link to your LinkedIn page (if you have one) Question 7 you can answer by providing a line or two in the role profile section.
Some people also find a video clip describing them and their experience, and the way they can help is beneficial.
`,
      },
      what_type_of_photograph_should_i_use: {
        question: "What type of photograph should I use?",
        answer: `
Professional, friendly and pleasant work best. You’re offering a professional service to other professionals. Bear that in mind when choosing your picture.

Smiling helps and - wear clothes, please.
`,
      },
      why_have_you_asked_for_a_linkedin_address: {
        question: "Why have you asked for a LinkedIn address (Please note: this is optional)?",
        answer: `
This adds credibility to your profile and is a simple and easy way for mentees to see that your experience matches up to your profile
`,
      },
      how_do_i_find_my_linkedin_url: {
        question: "How do I find my LinkedIn URL? (optional)",
        answer: `
If you’ve already got a LinkedIn profile:
Click ‘Home’ then click your profile picture. On the top right, you should see Edit public profile & URL. Click that, then copy & paste your profile link.
If you don’t have a LinkedIn profile:
Just go to LinkedIn and set one up.
Then follow the steps above. If you get stuck simply Google ‘create a LinkedIn profile’
`,
      },
      why_should_i_make_a_video_for_my_career_navig8r_mentor_profile: {
        question: "Why should I make a video for my Career Navig8r mentor profile? (optional)",
        answer: `
A picture tells a thousand words, but a video many, many more. By creating a short video (around 2 minutes) discussing how your experience can help a potential mentee with a job role, they will see how approachable, positive, and effective you will be. It makes their decision to choose you as their Navig8r easier.
`,
      },
      how_do_i_make_a_youTube_video_n_place_it_on_career_navig8r: {
        question: "How do I make a YouTube video & place it on Career Navig8r? (optional)",
        answer: `
You make your video with your mobile phone (or a camera if you have one). Plan what you will say, practice, dress well and have great lighting.

Next, sign-up for a free YouTube account. The account allows you to post videos to be seen by people you choose. If you’re unsure how to do this, just google “how to set up a YouTube channel” and watch one of the many videos explaining it.

Upload your video onto your YouTube channel and then copy the link to it. Here is a YouTube video explaining how (Link: https://www.youtube.com/watch?v=zWh3CShX_do). With the link copied, return to CareerNavig8r.com and go to the Role Profile (not Mentor Profile) that the video relates to. To find the relevant Role Profile click on the circle with your initials or photo on Career Navig8r to reveal a link to your Role Profile. Then paste the link into the section asking for your YouTube video Address.

If using the same video for each of the roles you can mentor (you made your video general in nature), then paste the link into each of the Role Profiles you’ve created.
`,
      },
      how_do_i_accept_a_booking_request: {
        question: "How do I accept a booking request?",
        answer: `
The system will send a message to your e-mail address and to your Career Navig8r inbox to notify you a booking request from a mentee has been made.
In the e-mail message will be:

    • The date and time of the booking
    • The fee agreed and the final amount you will receive after the Career Navig8r fee (10%)
    • The time you have available to accept the booking by (Please note: if you do not accept the request within this time but then proceed to have this meeting, you will not get paid (the system will have automatically refunded the Mentee))
    • A link to Accept or Decline the booking

Please click on the link. When you do this the Career Navig8r website will launch in your browser. You will be taken to a page where you can click on one of two buttons – to accept the booking or to decline it (it will always be your choice).
You can also send a message to the person requesting your time and either - thank them for the booking, or explain why you’re not available. You can suggest alternative times.

If you see a booking request message in your Career Navig8r inbox before you see the e-mail, opening the message will take you to the same place mentioned above.

If you decline or accept a booking request, an automatic e-mail is sent to the mentee telling them your decision (if you accepted the booking).
`,
      },
      do_i_have_to_accept_a_booking_request: {
        question: "",
        answer: `
You are NOT required to accept a request and may decline any request. If you take no action to accept or deny a request, the booking request will automatically expire in 24 hours. If you missed the request by accident, you can message the potential mentee directly from their expired request and ask if they want to make another request.
`,
      },
      mentor_how_long_do_i_have_to_respond_to_booking_requests: {
        question: "Mentor: How long do I have to respond to booking requests?",
        answer: `
You have seven calendar days to either accept or deny a booking request. After this period, the booking request will automatically expire, and the mentee will receive an automatic refund.
Important Please note:
If you proceed with the meeting without accepting the booking request you will not be paid.
`,
      },
      can_my_mentee_book_this_time_so_i_can_get_paid_for_it: {
        question: "I just had a great meeting with my mentee during which we decided to extend our time together. Can my mentee, even though the time has passed, book this time so I can get paid for it?",
        answer: `
NO is the simple answer. The system doesn’t allow additional time after the originally agreed time. Of course, we want you to continue working together if it’s proving productive so just keep an eye on the time and, if it’s getting close to the end, you can ask the mentee to go back to Career Navig8r and request a new meeting.
You’ll also need to ‘Accept Request’ for you to get paid, and, for the time not to be automatically refunded to the mentee.
To be clear: You will get paid for the original meeting. You will get paid for any additional meetings that are BOTH requested & accepted.
Allowing a meeting to run on, unless you’re happy to, will not allow you to get paid.
`,
      },
      how_can_i_modify_a_booking_request: {
        question: "How can I modify a booking request?",
        answer: `
Once a booking request is sent, the dates, amount, and details of the booking request cannot be modified.
`,
      },
      do_you_charge_transaction_fees_for_bookings: {
        question: "Do you charge transaction fees for bookings?",
        answer: `
We charge 10% of the booking fee.
`,
      },
      mentor_what_fee_should_i_charge_per_hour: {
        question: "Mentor: What fee should I charge per hour?",
        answer: `
Entirely up to you. How much do you think your time is worth?
How much will a mentee be willing to pay for your skills knowledge, expertise & guidance? (You can charge as low as 1 euro if you like). Take into account local rates, taxes, Career Navig8r fees, when listing your hourly rate.
Ultimately - what are you happy with, and what will people pay for your time?
`,
      },
      i_accepted_a_booking_request_how_do_i_collect_the_payment: {
        question: "I accepted a booking request. How do I collect the payment?",
        answer: `
Career Navig8r uses Stripe for its delayed payment feature. Once the session is booked, Stripe collects the money & deposits it into your account 14 days after the session.
`,
      },
      how_do_i_charge_for_additional_services: {
        question: "How do I charge for additional services?",
        answer: `
If you want to do additional work outside of a mentoring session. For example - if you wanted to record yourself doing a task that would benefit from a hands-on tutorial via a video or screen recording.
Either ask the mentee to book from your available time or, you can add additional time for them to book in the Role Profile.

NB - Please make sure you ask the mentee to specifically state the purpose of the booking.
If you don’t do this the mentee could request a refund.
`,
      },
      can_i_cancel_a_booked_mentoring_session: {
        question: "Can I cancel a booked mentoring session?",
        answer: `
Yes. The money will be refunded to the mentee.
If you do have to cancel, please let your mentee know why you’re cancelling and also ask them to rebook at another time. Just be nice.
`,
      },
      what_is_the_cancellation_policy: {
        question: "What is the Cancellation Policy?",
        answer: `
A Mentee can cancel their session with you at no charge up to 48-hours before the session. All monies are automatically refunded if the request is made within this 48-hour period.
If the cancellation occurs less than 48 hours before a meeting the mentee is charged for the full cost of the meeting.

If you want more detail on cancellations you can visit our dedicated cancellations & refunds section by following this link to that section
`,
      },
      what_information_is_shared_about_me: {
        question: "What information is shared about me on Career Navig8r?",
        answer: `
A general search on Career Navig8r could provide the following Information:

- Name
- Career / Job History
- Your LinkedIn page (if provided)
- Your YouTube videos discussing how you can help (if you made them)
- Your review scores and the comments

Once a Mentee has booked your time and you have confirmed said booking, they will receive:

-	Your Skype address (If applicable)

NB: None of our mentees, nor Career Navig8r (or any of its staff) will ever have any access to your financial information.
`,
      },
      is_my_financial_information_safe: {
        question: "Is my financial information safe?",
        answer: `
Career Navig8r have no access to your payment information.
We use Stripe as a payment provider that is globally recognised as a leader in international payments and is trusted by brands you’ve heard of like Google, Amazon, Shopify and Deliveroo. You’re in good (and safe) hands.
If you really want to learn more about how Stripe handles security, you can head here
`,
      },
      do_i_have_to_report_and_pay_taxes_for_mentoring_income: {
        question: "Do I have to report and pay taxes for mentoring income?",
        answer: `
You must abide by all legal requirements for tax in your country. Career Navig8r will not be held responsible for you not declaring income. As mentioned previously. We have no access at all to your financial information.
`,
      },
      what_are_the_minimum_requirements_for_equipment: {
        question: "What are the minimum requirements for equipment?",
        answer: `
We’ll cover this in a handy list:
1. Displays: laptop, desktop monitor, or tablet. (Ideally 2GB of RAM)
2. Microphones and cameras: built-in microphones and webcams, USB microphones and webcams or Bluetooth microphones.
3. Speakers: built-in computer speaker, external speaker.
4. Internet Connection: WiFi or ethernet (ideally over 1 MBps)
`,
      },
      i_have_the_experience_but_i_am_not_sure_how_to_develop_my_approach: {
        question: "I have the experience, but I am not sure how to develop my approach to mentoring, is there any help? (In short: Yes)",
        answer: `
Once you’ve created your Mentor Account you’ll have access to “Mentor Supports”.
In there you'll find an ever-growing list of resources to help you be the best Mentor you can be.
`,
      },
      do_i_have_to_communicate_on_career_navig8r: {
        question: "Do I have to communicate on Career Navig8r?",
        answer: `
Yes. All communication must occur on Career Navig8r.
In fact, over & above your name, there’s no need to share any personal contact detail.
`,
      },
      how_can_i_be_safe_while_using_career_navig8r: {
        question: "How can I be safe while using Career Navig8r?",
        answer: `
Career Navig8r is a safe platform due to the nature of the site.
No unnecessary personal details should be exchanged. (Please don’t ask) You should not meet face to face.
All communication, including payment and booking, should be done through Career Navig8r. If you see anything that you believe is unsafe or contrary to our community guidelines [link], please report using the admin@careernavig8r.com email address.
`,
      },
    }
  },
  mentee_specific_faqs: {
    label: "Mentee Specific - FAQs",
    questions: {
      is_it_free_to_sign_up_as_a_mentee: {
        question: "Is it free to sign up as a mentee? (In short - Yes)",
        answer: `
There is no cost for signing up as a mentee.
The only cost occurs when you book time with the mentor you choose. Only from the second meeting as your first meeting is a free 20 minute ‘getting to know you’ meeting. The hourly rate
your chosen mentor charges is publicly available BEFORE you send them a meeting request. Absolutely NO hidden costs.
`,
      },
      i_ve_started_working_with_my_mentor_can_I_stop_using_career_navig8r: {
        question: "I’ve started working with my mentor, can I stop using Career Navig8r and instead book and pay them directly?",
        answer: `
Nope. This is against the rules of the site.
It’s a little bit unethical. You’ll also NOT be covered by our arbitration policy and have no recourse should anything go awry with your sessions and bookings.

You also lose access to the privacy and security of using our payment system. When you book everything through CareerNavig8r.com all of your communications, bookings and payments are private, protected and secure.
`,
      },
      what_if_they_make_this_suggestion_to_me: {
        question: "What if they make this suggestion to me?",
        answer: `
Remind them that it’s against the rules.
If they ask again, please get in touch with us using the admin@careernavig8r.com email
`,
      },
      how_do_I_request_to_book_a_mentor: {
        question: "How do I request to book a mentor?",
        answer: `
Nice & easy. Once you’ve signed up as a mentee by entering your name and email address. Ticked the box to confirm you’re over 18 and then confirmed your email address. Just head to the big button marked “Find a mentor” Use the drop-down to choose the job role you want mentoring in and…click search
`,
      },
      what_information_is_shared_about_me_on_career_navig8r: {
        question: "What information is shared about me on Career Navig8r or used for other purposes?",
        answer: `
None: All Career Navig8r collects is your name, confirmation you are over eighteen, phone number (optional) and e-mail address. None of this is shared with any other party and is used by Career Navig8r for administration purposes only.
`,
      },
      why_do_i_need_to_be_over_18_to_use_career_navig8r: {
        question: "Why do I need to be over 18 to use Career Navig8r?",
        answer: `
Career Navig8r is a professional platform for people who are looking to either improve their career, or someone else’s. Conversations could be very adult in nature. Money will be changing hands for services provided so the age limit is strictly set to a minimum of 18.
`,
      },
      can_i_cancel_a_mentoring_session_I_booked: {
        question: "Can I cancel a mentoring session I booked?",
        answer: `
Yes, you can.

There are two scenarios in which this doesn’t cost you anything and you can get a full refund: If you cancel the session 48-hours before the session.
Or, if you cancel within 15 minutes of making the booking (if within the 48 hour period before the session) then all of your money gets refunded

If the cancellation occurs less than 48 hours before a meeting you will be charged for the full cost of the meeting.
If you want more detail on cancellations you can visit our dedicated cancellations & refunds section by following this link to that section
`,
      },
      how_do_i_cancel_a_mentoring_session_i_booked: {
        question: "How do I cancel a Mentoring Session I booked?",
        answer: `
Before you cancel, make sure you know the cancellation policy detailed above.
Once you are happy to cancel, you just need to go to your Career Navig8r inbox. Find the meeting confirmation and click the ‘cancel’ button.
You’ll then get a notification that your cancellation has succeeded
`,
      },
      can_i_have_my_company_organisation_pay_for_my_mentoring: {
        question: "Can I have my company/organisation pay for my mentoring?",
        answer: `
Yes. Just arrange for a person in your organisation who has the authority to make this decision to send an e-mail to admin@CareerNavig8r.com.
We will arrange for a business account to be set up.
`,
      },
      is_a_booking_request_binding: {
        question: "Is a booking request binding?",
        answer: `
As the mentee can cancel a session with your Mentor at no charge up to 48-hours before the session. Or, as referenced earlier, if a booking is made & subsequently cancelled within 15 minutes.
After this, the fee is non-refundable and as such seen as binding. All monies are automatically refunded by the Stripe system.
`,
      },
      how_many_booking_requests_can_i_send: {
        question: "How many booking requests can I send?",
        answer: `
As many as you like, but remember it is only a request at this point. The Mentor must accept each session/meeting you are requesting individually.
You will receive an individual booking confirmation for each meeting you request (even if you book them all at the same time).
If you don’t receive a booking confirmation (or a message that the booking was refused) for each meeting; look in your Career Navig8r inbox, or check your spam folder
`,
      },
      how_long_will_it_take_for_a_mentor_to_respond_to_my_request: {
        question: "How long will it take for a Mentor to respond to my request?",
        answer: `
Mentors are typically very responsive and reply as soon as they receive a booking request. If you do not hear back from a Mentor regarding your request, you can also send them a message to gently remind them to respond.
The mentors have seven calendar days to respond before the booking is automatically cancelled.
`,
      },
      is_there_a_minimum_or_maximum_booking_duration: {
        question: "Is there a minimum or maximum booking duration?",
        answer: `
The minimum is one hour, but there is no maximum. We recommend that you do not go over three hours. Meetings like this can require a lot of energy. So, our advice is to do a series of shorter meetings rather than one long meeting.
`,
      },
      can_i_modify_a_booking_once_it_has_been_sent: {
        question: "Can I modify a booking once it has been sent as a booking request?",
        answer: `
No. The system doesn’t currently allow for the modification of a booking request.
However, you can cancel and rebook a meeting at no cost as long as it’s more than 48 hours before the meeting.
`,
      },
      when_is_a_booking_confirmed: {
        question: "When is a booking confirmed?",
        answer: `
A booking is confirmed once marked as completed by the mentor.
You will receive a confirmation message (email & or SMS)for each of the meetings you have booked.
Look for these in your Career Navig8r Inbox, just in case these messages end up in your e-mail’s spam folder.
`,
      },
      how_can_I_be_safe_and_cautious_when_using_career_navig8r: {
        question: "How can I be safe and cautious when using Career Navig8r?",
        answer: `
Career Navig8r is a safe platform due to the nature of the site.
No unnecessary personal details should be exchanged. (Please don’t ask) You should not meet face to face.
All communication, including payment and booking, should be done through Career Navig8r. If you see anything that you believe is unsafe or contrary to our community guidelines [link], please report using the admin@careernavig8r.com email address.
`,
      },
      do_i_have_to_communicate_on_career_navig8r: {
        question: "Do I have to communicate on Career Navig8r? (Short Answer: Yes)",
        answer: `
Yes. All communication must occur on Career Navig8r.
In fact, over & above your name, there’s no need to share any personal contact detail.
`,
      },
      what_information_is_shared_with_the_mentor: {
        question: "What information is shared with the mentor (In short: your Name)",
        answer: `
Any of the other (limited) information we ask you for as a mentee never gets shared with your mentor. They just get your name.
`,
      },
      what_type_of_photograph_should_i_use: {
        question: "What type of photograph should I use? (In short: optional)",
        answer: `
As a mentee, there is no requirement to upload a photo.
If you do, just choose one where you look happy or one you’re happy with. And where you’re wearing clothes.
`,
      },
    }
  },

  technical_faqs: {
    label: "Technical – FAQs",
    questions: {
      how_do_i_create_a_mentee_account: {
        question: "How do I create a Mentee account?",
        answer: `
You can easily create a user account by:
Clicking on “Become a mentee” in the top right corner of the homepage. Enter your email address and create a secure password.
We’ll then send you an email to confirm your email address.
That’s it. You now have an account
`,
      },
      how_do_i_log_into_my_account: {
        question: "How do I log into my account?",
        answer: `
Click “Log in” in the top right corner of the homepage then enter your email address and secure password.
`,
      },
      how_can_i_delete_my_account: {
        question: "How can I delete my account?",
        answer: `
If you would like to permanently delete your mentor or mentee account, please email us at admin@CareerNavig8r.com or just use the “Delete my account” option in your account.
`,
      },
      how_do_i_report_suspicious_account_activity: {
        question: "How do I report suspicious account activity?",
        answer: `
Please report any suspicious activity immediately by sending us an email at admin@CareerNavig8r.com
`,
      },
      how_do_i_report_other_users_for_acting_against_policies: {
        question: "How do I report other users for acting against “Career Navig8r” policies?",
        answer: `
Please report any suspicious activity or users immediately by sending us an email at admin@CareerNavig8r.com
`,
      },
    }
  },

  financial_and_payments_faqs: {
    label: "Financial and Payments - FAQs",
    questions: {
      how_do_i_know_my_payment_and_financial_details_are_safe: {
        question: "How do I know my payment and financial details are safe?",
        answer: `
Navig8r have no access to your payment information.
We use Stripe as a payment provider and they are globally recognised as a leader in international payment provision and are trusted by brands you’ve heard of like Google, Amazon, Shopify and Deliveroo. You’re in good (and safe) hands.
If you really want to learn more about how Stripe handles security, you can head here.
`,
      },
      what_if_i_m_dissatisfied_can_i_get_my_money_back: {
        question: "What if I’m dissatisfied, can I get my money back?",
        answer: `
Yes, we always want people to be happy with their mentoring and, if you have genuine reasons for your dissatisfaction, we are more than happy to understand why, and see whether we can get the money refunded.
It’s probably worth you familiarising yourself with the Arbitration & Refund policy which you can find -here]
`,
      },
      what_is_the_cancellation_policy: {
        question: "What is the Cancellation Policy?",
        answer: `
A mentee can cancel a session with their mentor up to 48-hours before a session without penalty. After this, the fee is non-refundable.
All monies to be refunded are done automatically by Stripe.

If a mentee books an appointment within 48 hours of the appointment date then they will be charged unless such appointment is cancelled within 15 minutes of the booking (the “Cancellation Period”). If a mentee does not cancel a confirmed appointment within the applicable Cancellation Period, 100% of the cost of the services will be charged as a cancellation fee or in the case of a subscription the session credit will be deemed “used”.
If you want more information about our cancellation policy click here [link to cancellation policy]
`,
      },
      what_if_the_mentee_wants_a_refund_after_our_session_together: {
        question: "What if the mentee wants a refund after our session together? (Career Navig8r’s Arbitration Service)",
        answer: `
[Link to arbitration policy in support section]
`,
      },
    }
  },
};

