import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionCanIBeAMentor.module.css';

const SectionCanIBeAMentor = props => {
  const { rootClassName, className, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>

        <div className={css.contentItem}>
          <h2 className={css.title}>
            <FormattedMessage
              id="SectionCanIBeAMentor.title1"
              values={{ lineBreak: <br /> }}
            />
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage
              id="SectionCanIBeAMentor.subTitle1"
              values={{ lineBreak: <br /> }}
            />
          </p>
        </div>
        <div className={css.contentItem}>
          <h2 className={css.title}>
            <FormattedMessage
              id="SectionCanIBeAMentor.title2"
              values={{ lineBreak: <br /> }}
            />
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage
              id="SectionCanIBeAMentor.subTitle2"
              values={{ lineBreak: <br /> }}
            />
          </p>
        </div>

        {!currentUser && (
          <NamedLink
            name="SignupPage"
            to={{
              search: 'userType=mentor',
            }}
            className={css.button}
          >
            <FormattedMessage id="SectionCanIBeAMentor.button" />
          </NamedLink>
        )}

      </div>
    </div>
  );
};

SectionCanIBeAMentor.defaultProps = { rootClassName: null, className: null };

SectionCanIBeAMentor.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionCanIBeAMentor);
