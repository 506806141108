import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { Tooltip } from 'react-tooltip';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, InfoIcon, ExternalLink } from '../../components';
import CustomJobRoleSelectField from './CustomJobRoleSelectField';

import css from './EditListingJobRoleForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingJobRoleFormComponent = props => {
  const [ numberOfProjects, setNumberOfProjects ] =
    useState( props.numberOfProjects ? props.numberOfProjects : 1 );

  const copyValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  const setValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  return (
    <FinalForm
      {...props}
      mutators={{ copyValue, setValue }}
      render={formRenderProps => {
        const {
          jobRoles,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          form,
          values,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const onJobRoleChange = selectedOption => {
          form.mutators.setValue("jobRoleName", selectedOption.value );
        };

        const addProject = () => {
          form.mutators.setValue("numberOfProjects", numberOfProjects + 1 );
          setNumberOfProjects(numberOfProjects + 1);
        };

        const removeProject = projInd => {
          if( projInd < numberOfProjects ){
            for(let curInd = projInd; curInd < numberOfProjects; curInd++){
              form.mutators.copyValue(
                `name${curInd}`,
                values[`name${curInd + 1}`]
              );
              form.mutators.copyValue(
                `details${curInd}`,
                values[`details${curInd + 1}`]
              );
            }
          }

          form.mutators.copyValue(`name${numberOfProjects}`, null );
          form.mutators.copyValue(`details${numberOfProjects}`, null );

          form.mutators.setValue("numberOfProjects", numberOfProjects - 1 );
          setNumberOfProjects( numberOfProjects - 1 );
        };

        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingJobRoleForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.descriptionRequired',
        });

        const skillsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.skillsPlaceholder',
        });
        const skillsRequiredMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.skillsRequired',
        });

        const projectNamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.projectNamePlaceholder',
        });
        const projectNameRequiredMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.projectNameRequired',
        });

        const projectDetailsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.projectDetailsPlaceholder',
        });
        const projectDetailsRequiredMessage = intl.formatMessage({
          id: 'EditListingJobRoleForm.projectDetailsRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingJobRoleForm.updateFailed"/>
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingJobRoleForm.createListingDraftError"/>
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingJobRoleForm.showListingFailed"/>
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <CustomJobRoleSelectField
              id="jobRoleName"
              name="jobRoleName"
              className={css.jobRoleName}
              jobRoles={jobRoles}
              selectedValue={values["jobRoleName"]}
              onChange={onJobRoleChange}
              intl={intl}
            />

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            />

            <div className={css.descriptionWrapper}>
              { typeof window === 'object' ? (
                <Tooltip
                  anchorId="descriptionTooltipIcon"
                  className={css.tooltipText}
                  clickable={true}
                >
                  <div className={css.descriptionTooltip}>
                    <FormattedMessage
                      id="EditListingJobRoleForm.descriptionTooltip"
                      values={{
                        br: <br/>,
                        descriptionTooltipLink: (
                          <ExternalLink
                            key="descriptionTooltipLink"
                            href="https://careernavig8r.com/blog"
                            sameTab={true}
                          >
                            <FormattedMessage id="EditListingJobRoleForm.descriptionTooltipLink" />
                          </ExternalLink>
                        )
                      }}
                    />
                  </div>
                </Tooltip>
              ) : null }
              <InfoIcon id="descriptionTooltipIcon" className={css.infoIcon} />
              <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                rootClassName={css.descriptionRoot}
                inputRootClass={css.descriptionInput}
                type="textarea"
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
            </div>

            <FieldTextInput
              id="jobRoleSkills"
              name="jobRoleSkills"
              className={css.skills}
              type="text"
              placeholder={skillsPlaceholderMessage}
              validate={required(skillsRequiredMessage)}
            />

            <h1 className={css.projectsTitle}>
              <FormattedMessage id="EditListingJobRoleForm.projectsTitle"/>
            </h1>

            { Array.from(Array( numberOfProjects ).keys()).map( projInd => {
              return (
                <div key={`projRecord${projInd}`}>
                  <FieldTextInput
                    id={`name${projInd + 1}`}
                    name={`name${projInd + 1}`}
                    className={css.name}
                    type="text"
                    placeholder={projectNamePlaceholderMessage}
                    maxLength={TITLE_MAX_LENGTH}
                    validate={composeValidators(required(projectNameRequiredMessage), maxLength60Message)}
                  />

                  <FieldTextInput
                    id={`details${projInd + 1}`}
                    name={`details${projInd + 1}`}
                    className={css.details}
                    rootClassName={css.detailsRoot}
                    type="textarea"
                    placeholder={projectDetailsPlaceholderMessage}
                    validate={required(projectDetailsRequiredMessage)}
                  />

                  { numberOfProjects > 1 ? (
                    <Button
                      className={css.removeProjectButton}
                      type="button"
                      onClick={() => removeProject( projInd + 1 )}
                    >
                      <FormattedMessage id="EditListingJobRoleForm.removeJobButton"/>
                    </Button>
                  ) : null }
                </div>
              );
            })}

            <Button
              className={css.addProjectButton}
              type="button"
              onClick={() => addProject()}
            >
              <FormattedMessage id="EditListingJobRoleForm.addAnotherButton"/>
            </Button>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
};

EditListingJobRoleFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingJobRoleFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingJobRoleFormComponent);
