import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionRefundAndArbitrationContent.module.css';

const SectionCommunityThrivelinesContent = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <p className={css.contentText}>
            Please note: this policy does not cover refunds due to cancellation. For information
            relating to this, please see Career Navig8r’s Cancellation Policy..<br/>
            Instead, this policy covers how issues relating to Mentor quality and conduct and
            Mentee conduct are dealt with when it comes to requesting a refund. This is done via
            the Arbitration Process laid out below.<br/>
            Once again - if you’re looking for a refund due to non-attendance go &nbsp;
            <NamedLink name="CancellationPolicyPage">
              here
            </NamedLink><br/>
            If you're looking for a refund due to the quality of service, you’re in the right place
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Arbitration Process:</h2>
          <p className={css.contentText}>
            Career Navig8r does not provide Mentoring or Mentors. Career Navig8r provides the
            means for Mentors and Mentees to find, book and pay for Mentoring Services. The
            service provider for Mentoring is the Mentor. As such, if this service is not
            satisfactory, or the conduct of those involved is not appropriate, this is initially an
            issue for the Mentor and Mentee to solve independently of Career Navig8r.<br/>
            However, if an agreement can't be reached and because we want Career Navig8r to
            be a safe place to deliver and receive Mentoring, We have created an Arbitration
            Service so that refunds are possible under some clear conditions. By using Career
            Navig8r you are agreeing to the process by which this arbitration operates and the
            outcome of the arbitration.<br/>
            The conditions referred to above are driven by Stripe. Stripe was chosen due to its
            security and because it offers a delayed payment system. It will collect money from a
            Mentee and then deposit these funds into the Mentor’s bank account after a period of
            time (Career Navig8r has no access to these funds at any time).<br/>
            What Career Navig8r can do, while these funds are being held by Stripe is arbitrate
            between Mentor and Mentee if a dispute occurs, and then depending on the outcome
            of the arbitration (which we will decide) direct Stripe to pay either the Mentor or
            refund the Mentee. Stripe has strict time limits on how long it will delay a payment
            which directly impacts the rules of the arbitration.<br/>
            As such:<br/>
            1. Directly after a Mentoring meeting is conducted, Stripe will hold the funds for
            payment of this meeting for 14 days. Once this fourteen-day period is concluded,
            Stripe will automatically deposit the funds due into the Mentor's bank account, at
            which point Career Navig8r will no longer be able to arbitrate. As a result of this,
            the following is the structure of the Arbitration process:<br/>
            1. The complaint/request for refund must be e-mailed to
            admin@CareerNavig8r.com within five calendar days of the Mentoring
            Session. If you raise a complaint, please remember that only the written
            evidence held in the Career Navig8r platform is valid. Communicating
            through the internal messaging system is part of the terms & conditions of
            using Career Navig8r. All communication that did not occur in the
            internal system won’t count towards your case.<br/>
            The only exception to this rule is that (if possible) verbal communication
            within mentoring meetings can be used as evidence.<br/>
            2. If a request for a refund is made after the five calendar days, Career
            Navig8r will no longer be in the position to Arbitrate, and the Mentor and
            Mentee will need to solve the issue independent of Career Navig8r (Career
            Navig8r will no longer be involved and will play no part in any refund)<br/>
            3. If the Mentee attempts to provide additional evidence after the initial five
            days, it will not be reviewed. The reason is the overarching time limit. To
            be fair to the Mentor, we need to allow the Mentor adequate time to see the
            evidence presented against them and then time to respond.<br/>
            4. Once Career Navig8r has received the complaint and the evidence
            from the Mentee, it will then be sent unedited to the Mentor.<br/>
            5. The Mentor will now have a right to reply and is given three calendar
            days to prepare and send their defence. Similarly, all the evidence
            presented by the Mentor must be communication that was carried out
            through the internal Career Navig8r messaging system. No evidence
            presented by the Mentor after this three-day period will be reviewed.<br/>
            6. If there is no response from the Mentor within the three-day period, for
            whatever reason, then the arbitration will only consider the evidence
            presented by the Mentee. The timescales are restricted by the length of
            time Stripe will hold the funds. The timescales herein allow time for both
            parties to gather and present evidence, and for Career Navig8r to review
            the evidence before the 14-day time limit is reached.<br/>
            7. With (hopefully) both cases made within the required timeframe Career
            Navig8r will impartially adjudicate the issue and come to a binding decision
            before the fourteen
            days deadline has been reached. The findings of Career Navig8r are binding
            (a stipulation of using this site for all users)<br/>
            8. Career Navig8r will communicate its decision to both parties.
            Please note: The communication will not contain the reasons for the decision
            but simply what has been decided.<br/>
            2. The second impact that the Stripe system has on the arbitration process is the fact
            that. Stripe will only hold monies for a maximum of 90 calendar days from the
            point of collection . In this case, the point of collection is when a mentoring
            meeting/session has been booked.<br/>
            The fourteen-day rule is present from the point after the meeting
            occurs, but the ninety-day rule is from the point of booking.<br/>
            This means that if a Mentee requests a refund due to a complaint, and it is made
            within five days of the meeting as outlined previously, but the meeting took place
            within fifteen days of the ninety-day period concluding, Career Navig8r is unable
            to arbitrate.<br/>
            As such, the refund request will be an issue for the Mentor and Mentee to
            solve independent of Career Navig8r<br/>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Refunds due to technical issues</h2>
          <p className={css.contentText}>
            Please note: Refunds will not be offered as a result of technical issues that occur while
            using the Career Navig8r platform. Further, it is the responsibility of both Mentor &
            Mentee to ensure that their internet quality is at the standard required to have
            effective video conferencing meetings using the Career Navig8r video-conferencing
            application.<br/>
            If technical issues occur for the Mentee that prevents a meeting that has started (both
            Mentor and Mentee have joined the meeting) from continuing (or continuing
            effectively) it will be at the Mentor’s discretion as to whether a refund is offered.
            If technical issues occur for the Mentor that prevents a meeting that has started (both
            Mentor and Mentee have joined the meeting) from continuing (or continuing
            effectively), the Mentor should offer a refund and instruct Career Navig8r to ensure this
            happens. If the Mentor does not do this  or refuses to act on the request by the Mentee
            to do so, then the Mentee can have this issue arbitrated.<br/>
            We hope of course, that this never needs to happen, and that everything and
            everyone can resolve any issues amicably and sensibly.<br/>
            If not, the arbitration will be as per the details laid out in this document.
          </p>
        </div>
      </div>
    </div>
  );
};

SectionCommunityThrivelinesContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCommunityThrivelinesContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCommunityThrivelinesContent;
