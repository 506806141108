import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import css from './ListingPage.module.css';

import unrollIconImage from './images/unwrap-section-icon.png';
import rollIconImage from './images/wrap-section-icon.png';

const SectionLanguages = props => {
  const [ isUnrolled, setUnrolled ] = useState( false );

  const { languages, anotherLanguage } = props;

  const languageOptions = config.custom.languages;

  const findLanguageOption = languageKey =>
    languageOptions.find( languageOption => languageOption.key === languageKey) || {};

  const languagesList = (
    <>
      { languages.map(( languageKey, index ) => {
        const languageOption = findLanguageOption(languageKey);
        return (
          <div className={css.languageName} key={`language-${index}-`}>
            <span className={`fi fi-${languageOption.countryCode}`}></span>
            &nbsp;{languageOption.label}
          </div>
        );
      })}
    </>
  );

  return languages ? (
    <div className={css.sectionDescription}>
      <img
        src={isUnrolled ? rollIconImage : unrollIconImage}
        className={css.rollUnrollIcon}
        onClick={() => setUnrolled( !isUnrolled )}
      />
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.languagesTitle" />
      </h2>
      <div className={isUnrolled ? css.unrolledLanguages : css.languages}>
        <div className={css.languagesList}>
          {languagesList}
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionLanguages;
