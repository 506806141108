import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        If your country of residence or establishment is within the European Economic
        Area (“EEA”), Switzerland or the United Kingdom, the Terms of Service for
        European Users apply to you.
      </p>

      <p>
        If your country of residence or establishment is outside of the EEA, Switzerland,
        and the United Kingdom, the Terms of Service for Non-European Users apply to you.
      </p>

      <h2>Terms of Service for European Users</h2>

      <p>
        As a consumer you can access the European Commission’s online dispute resolution
        platform here: https://ec.europa.eu/consumers/odr. Please note that Career
        Navig8r is not committed nor obliged to use an alternative dispute resolution
        entity to resolve disputes with consumers.
      </p>

      <p>
        Section 24 of these Terms contains an arbitration agreement and class action
        waiver that applies to all claims brought against Career Navig8r in the United
        States. Please read them carefully.
      </p>

      <p className={css.lastUpdated}>Last Updated: October 2021</p>

      <p>
        Thank you for using Career Navig8r!
      </p>

      <p>
        These Terms of Service for European Users (“Terms”) are a binding legal
        agreement between you and Career Navig8r that govern your use of the websites,
        applications, and other oﬀerings from Career Navig8r (collectively, the
        “Career Navig8r Platform”). When used in these Terms, “Career Navig8r,”
        “we,” “us,” or “our” refers to the Career Navig8r entity set out on Schedule 1
        with whom you are contracting.
      </p>

      <p>
        The Career Navig8r Platform oﬀers an online venue that enables users (“Members”)
        to publish, oﬀer, search for, and book services. Members who publish and
        oﬀer services are “Mentors” (Navig8rs) and Members who search for, book,
        or use services are “Mentees.” Mentors oﬀer “Mentor Services”. You must
        register an account to access and use many features of the Career Navig8r
        Platform, and must keep your account information accurate. As the provider
        of the Career Navig8r Platform, Career Navig8r does not own, control, oﬀer
        or manage any Listings or Mentor Services (a listing is made when you create
        a description of a Job Role you wish to oﬀer Mentoring for, that can be
        accessed by potential Mentees on the Career Navig8r platform, and oﬀer that
        service at a specific price). Career Navig8r is not a party to the contracts
        concluded directly between Mentors and Mentees, nor is Career Navig8r an insurer
        or an organiser or retailer of mentoring services. Career Navig8r is not
        acting as an agent in any capacity for any Member, except as specified in
        the Payments Terms of Service “Payment Terms”). To learn more about Career
        Navig8r’s role see Section 16.
      </p>

      <h2>Table of Contents</h2>
      <p>
        Mentee Terms<br/>
        <ol>
          <li>Our Mission</li>
          <li>Searching and Booking on Career Navig8r</li>
          <li>Cancellations, Refunds and Booking Modifications</li>
          <li>Your Responsibilities</li>
          <li>Mentor TermsMentoring on Career Navig8r</li>
          <li>Managing Your Listing</li>
          <li>Booking Modifications</li>
          <li>Taxes</li>
          <li>General TermsReviews</li>
          <li>Content</li>
          <li>Fees</li>
          <li>Career Navig8r Platform Rules</li>
          <li>Termination, Suspension and Other Measures</li>
          <li>Modification of these Terms</li>
          <li>Resolving Complaints and Damage Claims</li>
          <li>Career Navig8r’s Role</li>
          <li>Member Accounts</li>
          <li>Disclaimer</li>
          <li>Indemnification</li>
          <li>Contracting Entities</li>
          <li>Applicable Law and Jurisdiction</li>
          <li>Miscellaneous</li>
          <li>United States Dispute Resolution and Arbitration Agreement</li>
          <li>Additional Terms Specific to Business Mentors Business Users</li>
          <li>Termination, Suspension, and other Measures</li>
          <li>Complaints Handling and Mediation</li>
          <li>Access to Data</li>
          <li>Additional Distribution Channels</li>
        </ol>
      </p>

      <h2>Schedule 1 Contracting Entities</h2>

      <p>Mentee Terms</p>

      <h3>1. Our Mission.</h3>

      <p>
        Our mission is to create a communication platform/online meeting place that
        enables Mentors to oﬀer their Mentoring Services to Mentees. A place where
        you can browse through hundreds of listings to find the ones that fit your
        needs. Learn more about a listing by reviewing the description and photos,
        the Mentor profile, and past Mentee reviews. If you have questions, just
        message the Mentor.
      </p>

      <p>
        Career Navig8r provides the Career Navig8r Platform to Members for the purpose
        of assisting Mentees in securing the services of a Mentor who can credibly
        guide them on how to get and succeed in their desired Job Role. Or, if you
        are currently in a role, provide independent support. The Career Navig8r
        technology platform allows you to search for a potential Mentor by roles
        they have had or currently hold, and then to further specify your requirement
        by defining the sector they have expertise in, the organisational level
        they held the role at, and the Mentor cost per hour. This search will help
        you find a Mentor capable of supporting you to define a successful path
        to a desired Job Role, to successfully interview for this Job Role, and
        then to further excel as you begin it.
      </p>

      <h3>2. Searching and Booking on Career Navig8r.</h3>

      <p>
        2.1 Searching. You search for Mentor Services by first using the criteria
        of job role and then further filter these results by Sector, Organisational
        Level, Language, and price. Search results are based on their relevance
        to your search and other criteria. Relevance considers factors like price,
        availability, reviews, customer service and cancellation history, popularity,
        previous mentor sessions and saved Listings, Mentor requirements and more.
      </p>

      <p>
        2.2 Booking. When you book a Mentor, you are agreeing to pay all charges
        for your booking including the Listing price, applicable fees like Career
        Navig8r’s service fee, oﬄine fees, fee of our Stripe and equivalent (independent
        payment system used by the Career Navig8r system) and any other items identified
        during checkout (collectively, “Total Price”).
      </p>

      <p>
        There are two types of Bookings on Career Navig8r.<br/>
        <ol>
          <li>
            A Discovery Meeting, which is a twenty-minute meeting where the Mentor
            and Mentee decide if they can achieve the Mentees goals through future
            “Full Meetings”.
          </li>
          <li>
            A Full Meeting, which is booked in blocks of sixty minutes
          </li>
        </ol>
      </p>

      <p>
        A Mentee through the Career Navig8r platform sends a booking request for
        either meeting type to their chosen Mentor. The Mentor can in turn accept
        or decline this booking request. By accepting such a request, the Mentor
        has accepted a contract to oﬀer Mentor Services (sometimes called a booking
        in these Terms) which is formed directly between Mentee and the Mentor.
        Simultaneously to this acceptance, the Mentee receives a booking confirmation,
        for the meeting type they requested, and this in turn sees them now receiving
        a contract for Mentor Services (sometimes called a booking in these Terms).
        This is formed directly between Mentee and the Mentor. The cancellation policy
        and any other rules, standards, policies, or requirements identified in
        the Listing, during checkout, and as part of the terms of service form part
        of your contract with the Mentor.
      </p>

      <p>
        For the Full Meeting booking type, Career Navig8r reserves the right to
        immediately deduct the booking fee from the Mentee’s credit card / payment
        method on submission of the booking request for the amount specified. This
        amount can only be held for a maximum of 90 days in advance of a meeting
        due to our payment platforms rules (Stripe). Within this 90-day period
        the meeting as such needs to occur. When it does, the monies are stored
        for fourteen days before they are then transferred to the Mentor’s Bank
        Account unless this period extends beyond the original 90-day period. For
        example, if the meeting occurred 80 days after the booking request, and
        said meeting occurs, these funds would be paid to the Mentor 10 days later
        as per the operating rules of Stripe.
      </p>

      <p>
        This payment can be refunded through an Arbitration Process set out in point
        15 of the Terms of Service which in turn reﬂect the rules for storing monies
        imposed by Stripe.
      </p>

      <p>
        In the event that the Mentor declines that meeting then the Career Navig8r
        booking system will initiate a refund to the Mentee as per the conditions
        laid out in the Career Navig8r Cancellation Policy. It is the Mentee’s
        responsibility to provide the correct credit card details and other necessary
        information to Career Navig8r in order to receive the refund. It is the Mentor’s
        responsibility to provide the correct Bank Account details to ensure any monies
        due are paid.<br/>
        Career Navig8r is not responsible or liable for any incorrect or out of
        date information provided by either the Mentor or Mentee that result in
        the refund or payment not being deposited correctly.
      </p>

      <p>
        <b>2.2.1 Length of Meetings to reflect the potential of Meetings not starting on time</b>
        <br/><br/>
        What follows is the explanation as to why “Full Meetings” may not be a full
        60 minutes (Full Meeting). This relates to circumstances of the Mentor or
        Mentee being late to their meeting type, rather than a meeting being shortened
        for any other reason. By using the Career Navig8r platform you are agreeing
        to these time reductions occurring and also agreeing that no refund will
        be made as a result of the meeting times being shortened. What follows is
        the reason why meetings could be shortened by the system and by what amount
        they would be shortened. Again, what follows is designed to oﬀset the impact
        of the Mentor or Mentee being late to a meeting type and preventing this
        lateness having an impact of seeing consecutive meetings that happen not
        being cancelled.
      </p>

      <p>
        “Full Meeting” Time Parameters<br/>
        <ul className={css.diskList}>
          <li>
            Five minutes before this meeting starts both Mentor and Mentee receive
            an email with a link to join their meeting. This link is also found
            in both the Mentor’s and Mentee’s Career Navig8r inbox.
          </li>
          <li>
            For the Link to function correctly, the Mentor and Mentee must be signed
            into their Career Navig8r account
          </li>
          <li>
            This link will take both the Mentor and Mentee to the booking request
            page in Career Navig8r
          </li>
          <li>
            As the Meeting time arrives, a button that up to this point allows the Mentor
            or Mentee to cancel the meeting, instead asks them to access the meeting.
            By pressing this button on their respective pages, both the Mentor and
            Mentee will enter the meeting’s virtual meeting room. A message will
            tell both participants if the other person has also arrived. When both
            parties have arrived, the Mentor’s Join Meeting button will become active
            and by pressing it, the Video Conferencing will launch. Once this button
            is pressed by the Mentor, the Mentee’s join now button becomes active
            and they too can join, at which point the meeting begins.
          </li>
        </ul>
      </p>

      <p>
        The Mentee is late – What now?<br/>
        <ul className={css.diskList}>
          <li>
            If a Mentee arrives late, they should still enter the waiting room first
            (to allow them to adjust their settings if required)
          </li>
          <li>
            If the Mentor is on time, the “join now” button becomes active, and
            they should join the full meeting (they are told once joined that the Mentee
            has not arrived yet). The countdown clock will appear and start counting
            down from when the Mentor enters the meeting (not from when both people
            are in the meeting) from 60 minutes.
          </li>
        </ul>
      </p>

      <p>
        The Mentor is Late – What Now?<br/>
        <ul className={css.diskList}>
          <li>
            As stated, the Mentee cannot enter the meeting unless the Mentor has
            joined ahead of them
          </li>
          <li>
            This means that the Join Now button only appears for the Mentee once
            the Mentor is in the meeting
          </li>
          <li>
            If the Mentor arrives into the waiting room late, they will see the clock
            counting down from the meeting’s start down, with it having started
            at fifteen minutes
          </li>
          <li>
            The Mentor can launch the meeting up to 14 minutes 59 seconds have elapsed
            (as measured by the countdown clock)
          </li>
          <li>
            Once they enter the meeting, the clock will begin a 55 minutes countdown
            (or if it is a two-hour meeting – 1 hour and 55 minutes etc.)
          </li>
          <li>
            Why is the clock starting at 55 minutes rather than the normal 60 minutes?<br/>
            <ul className={css.circleList}>
              <li>
                By starting at 55 minutes, if the Mentor has another meeting with
                someone else in the next hour, they will arrive with roughly five
                minutes to spare so that meeting is not automatically cancelled
              </li>
              <li>
                Then that meeting will be 55 minutes and so on until they come to
                their last meeting
              </li>
              <li>
                Fortunately, the following happens with this chain reaction approach
                <ul className={css.squareList}>
                  <li>
                    Meeting One: Mentee One: Start Time 2pm. Mentor late (2.14.59):
                    Meeting starts at 2.15 for 55 minutes
                  </li>
                  <li>
                    Meeting Two: Mentee Two: Start Time 3pm: Mentor arrives due
                    to the above at 3.10 for 55 minutes
                  </li>
                  <li>
                    Meeting Three: Mentee Three: Start Time 4pm: Mentor arrives
                    at 4.05 for 55 minutes
                  </li>
                  <li>
                    So, the process recorrects by the Mentor ‘s Meeting four,
                    (the Mentor will be on-time)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p>
        By using the Career Navig8r system, you are agreeing to the above shortened
        meetings (five minute loss) with no refund applicable. This said, if the Mentor
        and Mentee both arrive on time, the meeting will be sixty minutes in length.
      </p>

      <p>Mentor or Mentee Technical or Skill Faults</p>

      <p>
        Career Navig8r has no control over the quality of equipment or software,
        or the eﬀectiveness of the internet connection that a Mentor or Mentee has
        access to. As a result, if a meeting cannot be attended by a Mentor or Mentee
        or is disrupted due to a technical fault Career Navig8r accepts no responsibility
        for this. It is hoped that in such circumstances, the Mentor will request
        a full refund (Career Navig8r is not in the position to oﬀer partial refunds)
        on behalf of the Mentee.<br/>
        Alternatively, if the fault was that of the Mentor, but they will not oﬀer
        a refund, the Mentee can ask for Arbitration to be put in place as described
        in point 15 of this document / the Terms of Service.
      </p>

      <p>
        PROMOTIONS: Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered
        to be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <p>
        <b>2.2.1 Length of Meetings to reflect the potential of Meetings not starting on time</b>
        <br/><br/>
        What follows is the explanation as to why “Full Meetings” may not be a full
        60 minutes (Full Meeting). This relates to circumstances of the Mentor or
        Mentee being late to their meeting type, rather than a meeting being shortened
        for any other reason. By using the Career Navig8r platform you are agreeing
        to these time reductions occurring and also agreeing that no refund will
        be made as a result of the meeting times being shortened. What follows is
        the reason why meetings could be shortened by the system and by what amount
        they would be shortened. Again, what follows is designed to oﬀset the impact
        of the Mentor or Mentee being late to a meeting type and preventing this
        lateness having an impact of seeing consecutive meetings that happen not
        being cancelled.
      </p>

      <p>
        2.4 Bookings for Mentor Services. A Mentor Service booking entitles you
        to participate in, attend, or use that Mentor Service. You are responsible
        for confirming that you meet minimum age, proficiency, or other requirements.
        You are responsible for informing the Mentor of any medical or physical
        conditions, or other circumstances that may impact your ability to participate,
        attend or use the Mentor Service. Except where expressly authorised by Career
        Navig8r, you may not allow any person to join a Mentor Service.
      </p>

      <h3>Cancellations and Booking Modifications.</h3>

      <p>
        3.1 Cancellations. In general, if you cancel a booking, the amount
        refunded to you is determined by the cancellation policy that applies to
        that booking. But, in certain situations, other policies take precedence
        and determine what amount is refunded to you. If something outside your
        control requires you to cancel a booking, you may be entitled to a partial
        or full refund as outlined in this document. If the Mentor cancels, (as
        defined in our Cancellation Policy), you may be entitled to a full refund
        as outlined within this Policy. You may appeal a decision by Career Navig8r
        by contacting our customer service, (admin@careernavig8r.com).
      </p>

      <p>
        3.2 Booking Modifications Mentors and Mentees are responsible for any booking
        modifications they agree to make via the Career Navig8r Platform or direct
        Career Navig8r customer service to make on their behalf ("Booking Modifications"),
        and agree to pay any additional amounts or fees associated with any Booking
        Modification.
      </p>

      <h3>4. Your Responsibilities and Assumption of Risk.</h3>

      <p>
        4.1 Your Responsibilities. You are responsible and liable for your own acts
        and omissions. For example, this means: you must act with integrity, treat
        others with respect, and comply with applicable laws at all times.
      </p>

      <p>
        4.2 Your Responsibilities. You are responsible and liable for your own acts
        and omissions. For example, this means: you must act with integrity, treat
        others with respect, and comply with applicable laws at all times.
      </p>

      <h2>Mentor Terms</h2>

      <h3>5. Mentoring on Career Navig8r.</h3>

      <p>
        5.1 Mentor. As a Mentor, Career Navig8r oﬀers you the opportunity to share
        your Mentor Service with our vibrant community of Mentees - and earn money
        doing it. It is easy to create a Listing for your Role Profiles/Job Roles
        and you are in control of how you Mentor, set your price, availability, and
        rules for each Listing ().
      </p>

      <p>
        5.2 Contracting with Mentees. When you accept a booking request or receive
        a booking confirmation through the Career Navig8r Platform, you are entering
        into a contract directly with the Mentee, and are responsible for delivering
        the Mentor Service under the terms and at the price specified in your Listing.
        You are also agreeing to pay applicable fees like Career Navig8r’s service
        fee for each booking. Career Navig8r Payments will deduct amounts you owe
        from your pay-out unless we and you agree to a diﬀerent method. Any terms
        or conditions that you include in any supplement contract with Mentees must:
        (i) be consistent with these Terms, our Policies, and the information provided
        in your Listing, and (ii) be prominently disclosed in your Listing description.
      </p>

      <p>
        5.3 Independence of Mentors.Your relationship with Career Navig8r is that
        of an independent individual or entity and not an employee, agent, joint
        venturer or partner of Career Navig8r, except that Career Navig8r Payments
        acts as a payment collection agent as described in the Payments Terms. Career
        Navig8r does not direct or control your Mentor Service and you understand
        that you have complete discretion whether and when to provide Mentor Services
        and at what price and on what terms to oﬀer them.
      </p>

      <p>
        PROMOTIONS: Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered to
        be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <h3>6. Managing Your Listing.</h3>

      <p>
        6.1 Creating and Managing Your Role Profiles/Job Roles Mentor Services Listing.
        The Career Navig8r Platform provides tools that make it easy for you to set
        up and manage a Listing. Your Listing must include complete and accurate
        information about your role profiles/job roles Mentor Service and your price.
        You are responsible for your acts and omissions as well as for keeping your
        Listing information (including calendar availability) and content (like photos)
        up-to-date and accurate at all times. We recommend that you obtain appropriate
        insurance for your Mentor Services and suggest you carefully review policy
        terms and conditions like coverage details and exclusions.
      </p>

      <p>
        6.2 Know Your Legal Obligations. You are responsible for understanding and
        complying with any laws, rules, regulations, and contracts with third parties
        that apply to your Mentor Service Listing or Mentor Services. Information
        we provide regarding legal requirements is for informational purposes only
        and you should independently confirm your obligations. You are responsible
        for handling and using personal data of Mentees and others in compliance
        with applicable privacy laws and these Terms, including our Mentor Privacy
        Standards. You should also seek permission from previous and current employers
        before sharing any information relating to them, the work you did for them,
        or any intellectual property which they own or owned by others. It is also
        advisable to seek legal advice on these matters as well.
      </p>

      <p>
        6.3 Search Ranking. The ranking of Listings in search results on the Career
        Navig8r Platform depends on a variety of factors, including these main parameters:<br/>
        <ul className={css.diskList}>
          <li>
            Mentee search parameters (e.g., number of Mentees, time and duration
            of the session, price range),
          </li>
          <li>
            Listing characteristics (e.g., price, calendar availability, number
            and quality of images, Reviews, type of Mentor Service, Mentor status,
            age of the Listing, average Mentee popularity),
          </li>
          <li>
            Mentee booking experience (e.g., customer service and cancellation history
            of the Mentor, ease of booking),
          </li>
          <li>
            Mentor requirements (e.g., minimum, or maximum sessions, booking cut-oﬀ time), and
          </li>
          <li>
            Mentee preferences (e.g., previous sessions, saved Listings, location
            from where the Mentee is searching).
          </li>
        </ul>
      </p>

      <p>
        Search results may appear diﬀerent on our mobile application than they appear
        on our website. Career Navig8r may allow Mentors to promote their Listings
        in search or elsewhere on the Career Navig8r Platform by paying a fee. More
        information about the factors that determine how your Listing appears in
        search results, our current promotional programs (if any) and how we identify
        promoted Content can be found in our Help Centre.
      </p>

      <p>
        6.4 Your Responsibilities. You are responsible for your own acts and omissions
        in providing your Mentor Services. You are responsible for setting your
        price and establishing rules and requirements for your Listing. You must
        describe any and all fees and charges in your Listing description and may
        not collect any additional fees or charges outside the Career Navig8r. Do
        not encourage Mentees to create third-party accounts, submit reviews, provide
        their contact information, or take other actions outside the Career Navig8r
        Platform in violation of our Terms of Service Policy.
      </p>

      <p>
        6.5 Mentoring as a Team or Organization.If you work with a co-Mentor or
        Mentor as part of a team, business or other organisation, the entity and
        each individual who participates in providing Mentor Services, is responsible
        and liable as a Mentor under these Terms. If you accept terms or enter into
        contracts, you represent and warrant that you are authorised to enter into
        contracts for and bind your team, business or other organisation, and that
        each entity you use is in good standing under the laws of the place where
        it is established. If you perform other functions, you represent and warrant
        that you are authorised to perform those functions. If you instruct Career
        Navig8r to transfer a portion of your pay-out to a co-Mentor or other Mentors,
        or send payments to someone else, you must be authorised to do so, and are
        responsible and liable for the payment amounts and accuracy of any pay-out
        information you provide.
      </p>

      <p>
        6.6 Your Assumption of Risk. You acknowledge that Mentoring carries inherent
        risks and agree that you assume the entire risk arising out of your access
        to and use of the Career Navig8r Platform, oﬀering Mentor Services, or any
        interaction you have with other Members whether in person or online. You
        agree that you have had the opportunity to investigate the Career Navig8r
        Platform and any laws, rules, regulations, or obligations that may be applicable
        to your Listings or Mentor Services and that you are not relying upon any
        statement of law made by Career Navig8r.
      </p>

      <h3>7. Cancellations, Issues, and Booking Modifications.</h3>

      <p>
        7.1 Cancellations and Issues.In general, if a Mentee cancels a booking,
        the amount paid to you is determined by the cancellation policy that applies
        to that booking. As a Mentor, you should not cancel on a Mentee without
        a valid or applicable reason. If you cancel on a Mentee without such a valid
        reason, we may impose a cancellation fee and other consequences If: (i) a Mentee
        or Mentor experiences an Issue (issues will be defined and ruled upon through
        Career Navig8r’s Arbitration process as outlined in Career Navig8r’s refund
        and arbitration policy), (ii) an Extenuating Circumstance arises(the determination
        of if an extenuating circumstance has occurred will be defined and ruled
        upon through Career Navig8r’s Arbitration process as outlined in Career
        Navig8r’s refund and arbitration policy), or (iii) a booking is cancelled
        under Section 13 of these Terms, the amount you are paid will be reduced
        by the amount we refund or otherwise provide to the Mentee, and by any other
        reasonable costs we incur as a result of the cancellation. If a Mentee receives
        a refund after you have already been paid, or the amount of the refund and
        other costs incurred by Career Navig8r exceeds your pay-out, Career Navig8r
        (via Career Navig8r Payments) may recover that amount from you, including
        by oﬀsetting the refund against your future pay-outs. You agree that Career
        Navig8r’s Cancellation Policy, and these Terms pre-empt the cancellation
        policy you set in situations where they allow for the cancellation of a booking
        and/or the issuance of refunds to Mentees. If we reasonably expect to provide
        a refund to a Mentee under one of these policies, we may delay release of
        any pay-out for that booking until a refund decision is made. You may appeal
        a decision by Career Navig8r by contacting our customer service.
      </p>

      <p>
        7.2 Booking Modifications.Mentors and Mentees are responsible for any Booking
        Modifications they agree to make via the Career Navig8r Platform or direct
        Career Navig8r customer service to make on their behalf, and agree to pay
        any additional amounts, fees or taxes associated with a Booking Modification.
      </p>

      <h3>8. Taxes.</h3>

      <p>
        8.1 Mentor Taxes. As a Mentor, you are responsible for determining and
        fulfilling your obligations under applicable laws to report, collect, remit
        or include in your price any applicable VAT or other indirect taxes, income
        or other taxes (“Taxes”)
      </p>

      <p>General Terms</p>

      <h3>9. Reviews.</h3>

      <p>
        After each Mentor Service, Mentees and Mentors will have an opportunity
        to review each other. Your Review must be accurate and may not contain any
        discriminatory, oﬀensive, defamatory, or other language that violates our
        Community Guidelines. Reviews are not verified by Career Navig8r for accuracy
        and may be incorrect or misleading.
      </p>

      <h3>10. Content.</h3>

      <p>
        Parts of the Career Navig8r Platform enable you to provide feedback, text,
        photos, audio, video, information and other content (“Content”). By providing
        Content, in whatever form and through whatever means, you grant Career Navig8r
        a non-exclusive, worldwide, royalty-free, sub-licensable and transferable
        licence, for the term of the protection of the rights so licensed, to access,
        use, store, copy, modify, prepare derivative works of, distribute, publish,
        transmit, stream, broadcast, and otherwise exploit in any manner such Content
        to provide and/or promote the Career Navig8r Platform, in any media or platform,
        known or unknown to date and in particular on Internet and social networks.
        If Content includes personal information, such Content will only be used
        for these purposes if such use complies with applicable data protection
        laws in accordance with our Privacy / GDPR Policy.
      </p>

      <p>
        Where Career Navig8r pays for the creation of Content or facilitates its
        creation, Career Navig8r may own that Content, in which case supplemental
        terms or disclosures will say that. You are solely responsible for all Content
        that you provide and warrant that you either own it or are authorised to
        grant Career Navig8r the rights described in these Terms. You are responsible
        and liable if any of your Content violates or infringes the intellectual
        property or privacy rights of any third party. Content must comply with
        our Community Guidelines, which prohibit, among other things, discriminatory,
        obscene, harassing, deceptive, violent, and illegal content. You agree that
        Career Navig8r may make available services or automated tools to translate
        Content and that your Content may be translated using such services or tools.
        Career Navig8r does not guarantee the accuracy or quality of translations
        and Members are responsible for confirming the accuracy of such translations.
      </p>

      <p>
        You should access the Career Navig8r platform only by using a personal computer
        or device on which virus protection software has been installed, to update
        virus signature files promptly when updates become available from the manufacturer
        of the virus protection software, and to immediately install updates to browser
        software as such updates become available from the manufacturer.
      </p>

      <p>
        We encourage you to routinely scan your PC using your virus protection product
        to detect and remove any viruses found. Undetected or unrepaired, a virus
        may corrupt and destroy your programs, files, or software. You agree that
        we are not responsible for and shall have no liability for any electronic
        virus that you may encounter using our online platform. Career Navig8r is
        not responsible and therefore holds no liability for any virus incurred
        as a result of opening or receiving a file through the Career Navig8r chat
        function or platform. You agree that you open any file on the Career Navig8r
        platform entirely at your own risk.
      </p>

      <h3>11. Fees and Promotions</h3>

      <p>
        Career Navig8r may charge fees to Mentors for use of the Career Navig8r
        Platform. More information about when service fees apply and how they are
        calculated can be found on our Service Fees page. Except as otherwise provided
        on the Career Navig8r Platform, service fees are non-refundable. Career
        Navig8r reserves the right to change the service fees at any time and will
        provide Members notice of any fee changes before they become eﬀective. Fee
        changes will not aﬀect bookings made prior to the eﬀective date of the fee
        change. If you disagree with a fee change you may terminate this agreement
        at any time pursuant to Section 13.2.
      </p>

      <p>
        PROMOTIONS Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered to
        be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <h3>12. Career Navig8r Platform Rules.</h3>

      <p>
        12.1 Rules. You must follow these rules and must not help or induce others
        to break or circumvent these rules.<br/>
        <ul className={css.diskList}>
          <li>
            Act with integrity and treat others with respect.<br/>
            <ul className={css.circleList}>
              <li>
                Do not lie, misrepresent something or someone, or pretend to be
                someone else.
              </li>
              <li>
                Be polite and respectful when you communicate or interact with others.
              </li>
              <li>
                Follow our Community Guidelines and do not discriminate against
                or harass others.
              </li>
            </ul>
          </li>
          <li>
            Do not scrape, hack, reverse engineer, compromise or impair the Career
            Navig8r Platform<br/>
            <ul className={css.circleList}>
              <li>
                Do not use bots, crawlers, scrapers or other automated means to access
                or collect data or other content from or otherwise interact with
                the Career Navig8r Platform.
              </li>
              <li>
                Do not hack, avoid, remove, impair, or otherwise attempt to circumvent
                any security or technological measure used to protect the Career
                Navig8r Platform or Content.
              </li>
              <li>
                Do not decipher, decompile, disassemble or reverse engineer any
                of the software or hardware used to provide the Career Navig8r Platform.
              </li>
              <li>
                Do not take any action that could damage or adversely aﬀect the performance
                or proper functioning of the Career Navig8r Platform.
              </li>
            </ul>
          </li>
          <li>
            Only use the Career Navig8r Platform as authorised by these Terms or
            another agreement with us.<br/>
            <ul className={css.circleList}>
              <li>
                You may only use another Member’s personal information as necessary
                to facilitate a transaction using the Career Navig8r Platform as
                authorised by these Terms.
              </li>
              <li>
                Do not use the Career Navig8r Platform, our messaging tools, or
                Members’ personal information to send commercial messages without
                their express consent.
              </li>
              <li>
                You may use Content made available through the Career Navig8r Platform
                solely as necessary to enable your use of the Career Navig8r Platform
                as a Mentee or Mentor.
              </li>
              <li>
                Do not use Content unless you have permission from the Content owner,
                or the use is authorised by us in these Terms or another agreement
                you have with us.
              </li>
              <li>
                Do not request, make or accept a booking or any payment outside
                of the Career Navig8r Platform to avoid paying fees, taxes or for
                any other reason.
              </li>
              <li>
                Do not require or encourage Mentees to open an account, leave a review,
                or otherwise interact with a third-party website, application or
                service before, during or after a booking, unless authorised by Career Navig8r.
              </li>
              <li>
                Do not engage in any practices that are intended to manipulate our
                search algorithm.
              </li>
              <li>
                Do not book Mentor Services unless you are actually using the Mentor Services.
              </li>
              <li>
                Do not use, copy, display, mirror or frame the Career Navig8r Platform,
                any Content, any Career Navig8r branding, or any page layout or
                design without our consent.
              </li>
            </ul>
          </li>
          <li>
            Honour your legal obligations.<br/>
            <ul className={css.circleList}>
              <li>
                Understand and follow the laws that apply to you, including privacy,
                data protection laws and intellectual policy laws.
              </li>
              <li>
                If you provide us with someone else’s personal information, you:
                (i) must do so in compliance with applicable law, (ii) must be authorised
                to do so, and (iii) authorise us to process that information under
                our Privacy / GDPR Policy.
              </li>
              <li>
                Read and follow our Terms, Policies and Standards.
              </li>
              <li>
                Do not use the name, logo, branding, or trademarks of Career Navig8r
                or others without permission.
              </li>
              <li>
                Do not use or register any domain name, social media handle, trade
                name, trademark, branding, logo or other source identifier that
                may be confused with Career Navig8r branding.
              </li>
              <li>
                Do not oﬀer Mentor Services that violate the laws or agreements
                that apply to you.
              </li>
              <li>
                Do not oﬀer or solicit prostitution or participate in or facilitate
                human traffcking.
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p>
        12.2 Reporting Violations. If you believe that a Member, Listing or Content
        poses an imminent risk of harm to a person, you should immediately contact
        local authorities before contacting Career Navig8r. In addition, if you
        believe that a Member, Listing or Content has violated our Standards, you
        should report your concerns to Career Navig8r. If you reported an issue
        to local authorities, Career Navig8r may request a copy of that report.
        Except as required by law, we are not obligated to take action in response
        to any report.
      </p>

      <p>
        12.3 Copyright Notifications. If you believe that Content on the Career
        Navig8r Platform infringes copyrights, please notify us.
      </p>

      <h3>13. Termination, Suspension and other Measures.</h3>

      <p>
        13.1 Term. The agreement between you and Career Navig8r reﬂected by these
        Terms remains in eﬀect until either you or we terminate the agreement in
        accordance with these Terms.
      </p>

      <p>
        13.2 Termination. You may terminate this agreement at any time by sending
        us an email admin@careernavig8r.com or by deleting your account. Career
        Navig8r may terminate this agreement for any reason by giving you 30 days’
        notice via email or using any other contact information you have provided
        for your account. Career Navig8r may also terminate this agreement immediately
        and without prior notice and stop providing access to the Career Navig8r
        Platform if (i) you materially breach these Terms or our Policies, (ii) you
        violate applicable laws, or (iii) such action is necessary to protect the personal
        safety or property of Career Navig8r, its Members, or third parties (for
        example in the case of fraudulent behaviour of a Member), or (iv) your account
        has been inactive for more than two years.
      </p>

      <p>
        13.3 Member Violations. If (i) you breach these Terms, our Policies, or
        our Standards, (ii) you violate applicable laws, regulations or third party
        rights, (iii) you have repeatedly received poor Reviews or Career Navig8r
        otherwise becomes aware of or has received complaints about your performance
        or conduct, (vi) you have repeatedly cancelled confirmed bookings or failed
        to respond to booking requests without a valid reason, or (vii) such action
        is necessary to protect the personal safety or property of Career Navig8r,
        its Members, or third parties, Career Navig8r may:<br/>
        <ul className={css.diskList}>
          <li>
            suspend or limit your access to or use of the Career Navig8r Platform
            and/or your account;
          </li>
          <li>suspend or remove Listings, Reviews, or other Content;</li>
          <li>cancel pending or confirmed bookings; or</li>
          <li>suspend or revoke any special status associated with your account.</li>
          <br/>
          In case of non-material violations or where otherwise appropriate, you
          will be given notice of any intended measure by Career Navig8r and an opportunity
          to resolve the issue, unless such notification would (i) prevent or impede
          the detection or prevention of fraud or other illegal activities, (ii) harm
          the legitimate interests of other Members or third parties, or (iii) contravene
          applicable laws.
        </ul>
      </p>

      <p>
        13.4 Legal Mandates. Career Navig8r may take any action it determines is
        reasonably necessary to comply with applicable law, or the order or request
        of a court, law enforcement or other administrative agency or governmental
        body, including the measures described above in Section 13.3.
      </p>

      <p>
        13.5 Eﬀect of Termination. If you are a Mentor and terminate your Career
        Navig8r account, any confirmed booking(s) will be automatically cancelled,
        and your Mentees will receive a full refund. If you terminate your account
        as a Mentee, any confirmed booking(s) will be automatically cancelled, and
        any refund will depend upon the terms on the conditions for said cancellations
        as defined in Career Navig8r’s cancellation policy. When this agreement has
        been terminated, you are not entitled to a restoration of your account or
        any of your Content. If your access to or use of the Career Navig8r Platform
        has been limited, or your Career Navig8r account has been suspended, or
        this agreement has been terminated by us, you may not register a new account
        or access or use the Career Navig8r Platform through an account of another Member.
      </p>

      <p>
        13.6 Appeal. If Career Navig8r takes any of the measures described in this
        Section 13 you may appeal such a decision by contacting our customer service.
      </p>

      <h3>14. Modification of these Terms.</h3>

      <p>
        Career Navig8r may modify these Terms at any time. When we make changes to
        these Terms, we will post the revised Terms on the Career Navig8r Platform
        and update the “Last Updated” date at the top of these Terms. We will also
        provide you with notice of the proposed changes by email at least thirty
        (30) days before the date they become eﬀective. If you disagree with the
        revised Terms, you may terminate this agreement immediately as provided in
        these Terms. We will inform you about your right to terminate the Agreement
        in the notification email. If you do not terminate your agreement before
        the date the revised Terms become eﬀective, your continued access to or use
        of the Career Navig8r Platform will constitute acceptance of the revised Terms.
      </p>

      <h3>15. Resolving Complaints</h3>

      <p>
        Arbitration Process:
      </p>

      <p>
        Career Navig8r does not provide Mentoring or Mentors. Career Navig8r’s service
        is to provide the means for Mentors and Mentees to find, book and pay for
        the Mentoring Services oﬀered by Mentors. The service provider for Mentoring
        is the Mentor. As such, if this service is not satisfactory, or the conduct
        of those involved is not appropriate, this is an issue for the Mentor and
        Mentee to solve independent of Career Navig8r.
      </p>

      <p>
        This said, so that Career Navig8r is a safe place to deliver and receive
        Mentoring, Career Navig8r has created an Arbitration Service so that refunds
        are possible under some clear conditions/stipulations. By using Career Navig8r
        you are agreeing to the process by which this arbitration operates and
        the outcome of the arbitration.
      </p>

      <p>
        These stipulations referred to above are driven primarily by Stripe (Stripe
        is a completely independent technology platform that Career Navig8r uses
        to allow you to make and receive payments). Stripe was chosen due to its
        security and because it oﬀers a delayed payment system. It will collect money
        from a Mentee and then deposit these funds into the Mentor’s bank account
        after a period of time (Career Navig8r has no access to these funds at any time).
      </p>

      <p>
        What Career Navig8r can do, is while these funds are being held by Stripe
        (or future similar payment platforms), to arbitrate between Mentor and Mentee
        if a dispute occurs, and then depending on the outcome of the arbitration
        (which we will decide) to direct Stripe to pay either the Mentor or refund
        the Mentee. But Stripe has strict time limits on how long it will delay
        a payment which directly impact the rules of the arbitration. As such:<br/>
        <ul className={css.diskList}>
          <li>
            Directly after a Mentoring meeting is conducted, Stripe will hold the funds
            for payment of this meeting for fourteen calendar days. Once this fourteen-day
            period is concluded, Stripe will automatically deposit the funds due
            to the Mentor into the Mentor’s bank account, at which point Career Navig8r
            will no longer be able to arbitrate and make a refund. As a result of
            this, the following is the structure of the Arbitration process:<br/>
            <ol>
              <li>
                The complaint / request for refund must be e-mailed to admin@careernavig8r.com
                within five calendar days of the Mentoring Session. The complaint
                must contain all the information that the Mentee has that illustrates
                that a refund should be made. It is important to note that any written
                evidence submitted, has to be information that was communicated through
                Career Navig8r communication system as it is a condition of using
                Career Navig8r that all written communication between a Mentor and
                Mentee must occur through the internal communication system. The only
                exception to this is the verbal communication that occurs in the Mentoring
                meetings itself.
              </li>
              <li>
                If a request for a refund is made after the five calendar days,
                Career Navig8r will no longer be in the position to Arbitrate, and
                the Mentor and Mentee will need to solve the issue independent of
                Career Navig8r (Career Navig8r will no longer be involved and will
                play no part in any refund)
              </li>
              <li>
                If the Mentee attempts to provide additional evidence after the initial
                five days, it will not be reviewed. The reason is the overarching
                time limit. To be fair to the Mentor, we need to allow the Mentor
                adequate time to see the evidence presented against them and then
                time to respond.
              </li>
              <li>
                Once Career Navig8r has received the complaint and the evidence
                from the Mentee, it will then be sent unedited to the Mentor.
              </li>
              <li>
                The Mentor will now have a right to reply and is given three calendar
                days to prepare and send their defence. Again, any written evidence
                presented must be that found on Career Navig8r’s communication system
                (see reason above) and no evidence presented by the Mentor after
                this three-day period will be reviewed.
              </li>
              <li>
                If there is no response from the Mentor within this three-day period,
                for whatever reason, then unfortunately the arbitration will only
                consider the evidence presented by the Mentee. The only reason for
                this, is that the Stripe system will only hold the funds for
                the fourteen days, so this drives these strict time limits.
              </li>
              <li>
                With the Mentees case made and hopefully the case of the Mentor
                presented to Career Navig8r within the stipulated time frames, Career
                Navig8r will impartially adjudicate the issue and come to a binding
                decision before the fourteen days deadline has been reached. The findings
                of Career Navig8r are binding (a stipulation of using this site for all users)
              </li>
              <li>
                Career Navig8r will communicate its decision to both Mentor and
                Mentee, but this communication will not contain the reasons for
                the decision but simply what has been decided.
              </li>
            </ol>
          </li>
          <li>
            The second impact that the Stripe system has on the arbitration process
            relates to the following rule that it has in place. Strip will only
            hold monies for a maximum of 90 calendar days from the point of collection.
            In this case, the point of collection is when a Mentoring Meeting / Session
            has been booked. The fourteen-day rule is present from the point after
            the meeting occurs, but the ninety-day rule is from the point of booking.
            This means that if a Mentees requests a refund due to a complaint, and
            although it is made within five days of the meeting as outlined previously,
            if the meeting took place within fifteen days of the ninety-day period
            concluding, then Career Navig8r is unable to arbitrate. As such, the refund
            request will be an issue for the Mentor and Mentee to solve independent
            of Career Navig8r.
          </li>
        </ul>
      </p>

      <h3>16. Career Navig8r’s Role.</h3>

      <p>
        We oﬀer a platform that enables Members to publish, oﬀer, search for, and
        book Mentor Services. When Members make or accept a booking, they are entering
        into a contract directly with each other. Career Navig8r is not and does
        not become a party to or other participant in any contractual relationship
        between Members. Career Navig8r is not acting as an agent for any Member
        except for where Career Navig8r Payments acts as a collection agent as provided
        in the Payments Terms. While we work hard to ensure our Members have great
        experiences using Career Navig8r, we do not and cannot control the conduct
        or performance of Mentees and Mentors and do not guarantee (i) the existence,
        quality, safety, suitability, or legality of any Listings or Mentor Services
        or (ii) the truth or accuracy of any Listing descriptions, Reviews, or other
        Content provided by Members. You acknowledge that Career Navig8r has no general
        obligation to monitor the use of the Career Navig8r Platform and verify information
        provided by our Members, but has the right to review, disable access to,
        remove, or edit Content to: (i) operate, secure and improve the Career Navig8r
        Platform (including for fraud prevention, risk assessment, investigation
        and customer support purposes); (ii) ensure Members’ compliance with these
        Terms; (iii) comply with applicable law or the order or requirement of a court,
        law enforcement or other administrative agency or governmental body;
        (iv) address Member Content that we determine is harmful or objectionable;
        (v) take actions set out in these Terms; and (vi) maintain and enforce any
        quality or eligibility criteria, including by removing Listings that don’t
        meet quality and eligibility criteria. Where we remove or disable Content,
        we will notify a Member and provide the reasons for such a measure, unless
        such notification would (i) prevent or impede the detection or prevention
        of fraud or other illegal activities, (ii) harm the legitimate interests
        of other Members or third parties, or (iii) contravene applicable laws.
        You may appeal such a decision by contacting our customer service. Members
        agree to cooperate with and assist Career Navig8r in good faith, and to provide
        Career Navig8r with such information and take such actions as may be reasonably
        requested by Career Navig8r with respect to any investigation undertaken by
        Career Navig8r regarding the use or abuse of the Career Navig8r Platform.
      </p>

      <h3>17. Member Accounts.</h3>

      <p>
        You must register an account to access and use many features of the Career
        Navig8r Platform. Registration is only permitted for legal entities, partnerships
        and natural persons who are 18 years or older. You represent and warrant
        that you are not a person or entity barred from using the Career Navig8r
        Platform under the laws of the United States, your place of residence, or
        any other applicable jurisdiction. You must provide accurate, current, and
        complete information during registration and keep your account information
        up-to-date. You may not register more than one account or transfer your
        account to someone else. You are responsible for maintaining the confidentiality
        and security of your account credentials and may not disclose your credentials
        to any third party. You must immediately notify Career Navig8r if you suspect
        that your credentials have been lost, stolen, or your account is otherwise
        compromised. You are responsible and liable for activities conducted through
        your Career Navig8r Account, unless such activities are not authorised by
        you and you are not otherwise negligent (such as failing to report the unauthorised
        use or loss of your credentials). If and as permitted by applicable law,
        we may, but have no obligation to (i) ask you to provide identification
        or other information, (ii) undertake checks designed to help verify your
        identity or background, (iii) screen you against third-party databases or
        other sources and request reports from service providers, and (iv) obtain
        reports from public records of criminal convictions or sex oﬀender registrations
        or their local equivalents.
      </p>

      <h3>18. Disclaimer.</h3>

      <p>We do not endorse or warrant the existence, conduct, performance, safety,
        quality, legality or suitability of any Mentee, Mentor, Mentor Service,
        Listing or third party and we do not warrant that verification, identity
        or background checks conducted on Members (if any) will identify past misconduct
        or prevent future misconduct. Any references to a member being "verified"
        (or similar language) indicate only that the Member or Career Navig8r has
        completed a relevant verification or identification process and nothing
        else. We are not responsible for outages or disruptions of the Internet
        and telecommunications equipment which are beyond our control and can lead
        to interruptions in the availability of the Career Navig8r Platform. Career
        Navig8r may, temporarily and under consideration of the Members’ legitimate
        interests (e.g., by providing prior notice), restrict the availability of
        the Career Navig8r Platform or certain features thereof, if this is necessary
        in view of capacity limits, the security or integrity of our servers, or
        to carry out maintenance measures that ensure the proper or improved functioning
        of the Career Navig8r Platform.
      </p>

      <h3>19. Indemnification.</h3>

      <p>
        To the maximum extent permitted by applicable law, you agree to release,
        defend (at Career Navig8r’s option), indemnify, and hold Career Navig8r
        (including Career Navig8r Payments, other affliates, and their personnel)
        harmless from and against any claims, liabilities, damages, losses, and
        expenses, including, without limitation, reasonable legal and accounting
        fees, arising out of or in any way connected with: (i) your breach of these
        Terms (including any supplemental or additional terms that apply to a product
        or feature) or our Policies or Standards, (ii) your improper use of the Career
        Navig8r Platform, (iii) your interaction with any Member, participation in
        Mentor Service, including without limitation any injuries, losses or damages
        (whether compensatory, direct, incidental, consequential or otherwise) of
        any kind arising in connection with or as a result of such interaction, stay,
        participation or use, (iv) your failure, or our failure at your direction,
        to accurately report, collect or remit Taxes, or (v) your breach of any laws,
        regulations or third party rights such as intellectual property or privacy
        rights. The indemnification obligation only applies if and to the extent
        that the claims, liabilities, damages, losses, and expenses have been
        adequately caused by your culpable breach of a contractual obligation.
      </p>

      <h3>20. Contracting Entities.</h3>

      <p>
        Based on your country of residence or establishment and what you are doing
        on the Career Navig8r Platform, Schedule 1 below sets out the Career Navig8r
        entity with whom you are contracting. If we identify through the Career
        Navig8r Platform, a Career Navig8r entity other than the one set out on
        Schedule 1 as being responsible for a product, feature or transaction,
        the Career Navig8r entity so identified is your contracting entity with
        respect to that product, feature or transaction. If you change your country
        of residence or establishment to a country outside of the EEA, Switzerland
        or the United Kingdom, the Career Navig8r company you contract with and
        the applicable version of the Terms of Service will be determined by your
        new country of residence or establishment, from the date on which your
        country of residence or establishment changes.
      </p>

      <h3>21. Applicable law and Jurisdiction.</h3>

      <p>
        These Terms are governed by and construed in accordance with Irish law.
        If you are acting as a consumer and if mandatory statutory consumer protection
        regulations in your country of residence contain provisions that are more
        beneficial for you, such provisions shall apply irrespective of the choice
        of Irish law. As a consumer, you may bring any judicial proceedings relating
        to these Terms before the competent court of your place of residence or
        the competent court of Career Navig8r's place of business in Ireland. If
        Career Navig8r wishes to enforce any of its rights against you as a consumer,
        we may do so only in the courts of the jurisdiction in which you are a resident.
        If you are acting as a business, you agree to submit to the exclusive
        jurisdiction of the Irish courts.
      </p>

      <h3>22. Miscellaneous.</h3>

      <p>
        22.1 Other Terms Incorporated by Reference. Our Mentor Terms
        G Arbitration Policy, Community Guidelines, Cancellation Policy, Policies,
        Standards and other supplemental policies and terms linked to in these Terms
        apply to your use of the Career Navig8r Platform, are incorporated by reference,
        and form part of your agreement with Career Navig8r.
      </p>

      <p>
        22.2 Interpreting these Terms. Except as they may be supplemented by additional
        terms, conditions, policies, guidelines, standards, and in-product disclosures,
        these Terms constitute the entire agreement between Career Navig8r and you
        pertaining to your access to or use of the Career Navig8r Platform and supersede
        any and all prior oral or written understandings or agreements between Career
        Navig8r and you. These Terms do not and are not intended to confer any rights
        or remedies upon anyone other than you and Career Navig8r. If any provision
        of these Terms is held to be invalid or unenforceable, except as otherwise
        indicated in Section 23.10 below, such provision will be struck and will
        not aﬀect the validity and enforceability of the remaining provisions.
      </p>

      <p>
        22.3 No Waiver. Career Navig8r’s failure to enforce any right or provision
        in these Terms will not constitute a waiver of such right or provision unless
        acknowledged and agreed to by us in writing. Except as expressly set forth
        in these Terms, the exercise by either party of any of its remedies under
        these Terms will be without prejudice to its other remedies under these
        Terms or otherwise permitted under law.
      </p>

      <p>
        22.4 Assignment. You may not assign, transfer or delegate this agreement
        or your rights and obligations hereunder without Career Navig8r's prior
        written consent. Career Navig8r may without restriction assign, transfer
        or delegate this agreement and any rights and obligations hereunder, at
        its sole discretion, with 30 days’ prior notice. Your right to terminate
        this agreement at any time pursuant to Section 13.2 remains unaﬀected.
      </p>

      <p>
        22.5 Notice. Unless specified otherwise, any notices or other communications
        to Members permitted or required under this agreement, will be provided
        electronically and given by Career Navig8r, Career Navig8r Platform notification,
        or any other contact method we enable you to provide.
      </p>

      <p>
        22.6 Third-Party Services. The Career Navig8r Platform may contain links
        to third-party websites, applications, services or resources (“Third-Party Services”)
        that are subject to diﬀerent terms and privacy practices. Career Navig8r
        is not responsible or liable for any aspect of such Third-Party Services
        and links to such Third-Party Services are not an endorsement.
      </p>

      <p>
        22.7 Google Terms. Some translations on the Career Navig8r Platform are powered
        by Google. Google disclaims all warranties related to the translations,
        express or implied, including any warranties of accuracy, reliability, and
        any implied warranties for merchantability, fitness for a particular purpose
        and non-infringement. Some areas of the Career Navig8r Platform implement
        Google Maps/Earth mapping services, including Google Maps API(s). Your use
        of Google Maps/Earth is subject to the Google Maps/Google Earth Additional
        Terms of Service.
      </p>

      <p>
        22.8 Apple Terms. If you access or download our application from the Apple
        App Store, you agree to Apple’s Licensed Application End User Licence Agreement.
      </p>

      <p>
        22.9 Career Navig8r Platform Content. Content made available through the Career
        Navig8r Platform may be protected by copyright, trademark, and/or other laws
        of the United States and other countries. You acknowledge that all intellectual
        property rights for that Content are the exclusive property of Career Navig8r
        and/or its licensors and agree that you will not remove, alter or obscure
        any copyright, trademark, service mark or other proprietary rights notices.
        You may not use, copy, adapt, modify, prepare derivative works of, distribute,
        licence, sell, transfer, publicly display, publicly perform, transmit, broadcast
        or otherwise exploit any Content accessed through the Career Navig8r Platform
        except to the extent you are the legal owner of that Content or as expressly
        permitted in these Terms. Subject to your compliance with these Terms, Career
        Navig8r grants you a limited, non-exclusive, non-sublicensable, revocable,
        non-transferable licence to (i) download and use the Application on your
        personal device(s); and (ii) access and view the Content made available
        on or through the Career Navig8r Platform and accessible to you, solely
        for your personal and non-commercial use.
      </p>

      <p>
        22.10 Force Majeure. Career Navig8r shall not be liable for any delay or
        failure to perform resulting from causes outside its reasonable control,
        including, but not limited to, acts of God, war, terrorism, riots, embargoes,
        acts of civil or military authorities, fire, ﬂoods, accidents, epidemics
        or disease, strikes or shortages of transportation facilities, fuel, energy,
        labour or materials.
      </p>

      <p>
        22.11 Emails and SMS. You will receive administrative communications from
        us using the email address or other contact information you provide for
        your Career Navig8r account. Enrolment in additional email subscription
        programs will not aﬀect the frequency of these administrative emails, though
        you should expect to receive additional emails specific to the program(s)
        to which you have subscribed. You may also receive promotional emails from
        us. No fee is charged for these promotional emails, but third-party data
        rates could apply. In the U.S. if you consent to receive SMS (text messages)
        from us, you will be subject to our SMS Terms.
      </p>

      <p>
        22.12 Contact Us. If you have any questions about these Terms, please email
        us atadmin@careernavig8r.com.
      </p>

      <h3>23. United States Dispute Resolution and Arbitration Agreement.</h3>

      <p>
        23.1 Application. This Arbitration Agreement only applies to you if your
        country of residence or establishment is the United States. If your country
        of residence or establishment is not the United States, and you nevertheless
        attempt to bring any legal claim against Career Navig8r in the United States,
        this Arbitration Agreement will apply for determination of the threshold
        issue of whether this Section 24 applies to you, and all other threshold
        determinations, including residency, arbitrability, venue, and applicable law.
      </p>

      <p>
        23.2 Overview of Dispute Resolution Process. Career Navig8r is committed
        to participating in a consumer-friendly dispute resolution process. To that
        end, these Terms provide for a two-part process for individuals to whom this
        Section 24 applies: (1) an informal negotiation directly with Career Navig8r’s
        customer service team (described in paragraph 24.3, below), and if necessary
        (2) a binding arbitration administered by the American Arbitration Association
        (“AAA”). You and Career Navig8r each retain the right to seek relief in
        small claims court as an alternative to arbitration.
      </p>

      <p>
        23.3 Mandatory Pre-Arbitration Dispute Resolution and Notification. At least
        30 days prior to initiating an arbitration, you and Career Navig8r each
        agree to notify the other party of the dispute in writing and attempt in
        good faith to negotiate an informal resolution. You must send your notice
        of dispute to Career Navig8r by mailing it to Career Navig8r’s agent for
        service: admin@careernavig8r.com. Career Navig8r will send its notice of
        dispute to the email address associated with your Career Navig8r account.
        A notice of dispute must include: the party’s name and preferred contact
        information, a brief description of the dispute, and the relief sought.
        If the parties are unable to resolve the dispute within the 30-day period,
        only then may either party commence arbitration by filing a written Demand
        for Arbitration (available at www.adr.org) with the AAA and providing a copy
        to the other party as specified in the AAA Rules (available at www.adr.org).
      </p>

      <p>
        23.4 Agreement to Arbitrate. You and Career Navig8r mutually agree that
        any dispute, claim or controversy arising out of or relating to these Terms
        or the applicability, breach, termination, validity, enforcement or interpretation
        thereof, or any use of the Career Navig8r Platform, Mentor Services, or
        any Content (collectively, “Disputes”) will be settled by binding individual
        arbitration (the “Arbitration Agreement”). If there is a dispute about whether
        this Arbitration Agreement can be enforced or applies to our Dispute, you
        and Career Navig8r agree that the arbitrator will decide that issue.
      </p>

      <p>
        23.5 Exceptions to Arbitration Agreement. You and Career Navig8r each agree
        that the following causes of action and/or claims for relief are exceptions
        to the Arbitration Agreement and will be brought in a judicial proceeding
        in a court of competent jurisdiction (as defined by Section 22 of the Terms
        of Service for Non-European Users): (i) any claim or cause of action alleging
        actual or threatened infringement, misappropriation or violation of a party’s
        copyrights, trademarks, trade secrets, patents, or other intellectual property
        rights; (ii) any claim or cause of action seeking emergency injunctive relief
        based on exigent circumstances (e.g., imminent danger or commission of a crime,
        hacking, cyber-attack); or (iii) a request for the remedy of public injunctive
        relief. You and Career Navig8r agree that the remedy of public injunctive relief
        will proceed after the arbitration of all arbitrable claims, remedies, or
        causes of action, and will be stayed pending the outcome of the arbitration
        pursuant to section 3 of the Federal Arbitration Act.
      </p>

      <p>
        23.6 Arbitration Rules and Governing Law. This Arbitration Agreement evidences
        a transaction in interstate commerce and the Federal Arbitration Act governs
        all substantive and procedural interpretation and enforcement of this provision.
        The arbitration will be administered by AAA in accordance with the Consumer
        Arbitration Rules and/or other AAA arbitration rules determined to be applicable
        by the AAA (the “AAA Rules “) then in eﬀect, except as modified here. The
        AAA Rules are available at www.adr.org. In order to initiate arbitration,
        a completed written demand (available at www.adr.org) must be filed with
        the AAA and provided to the other party, as specified
      </p>

      <p>
        23.7 Modification of AAA Rules - Attorney’s Fees and Costs. Your arbitration
        fees and your share of arbitrator compensation shall be governed by the
        AAA Rules and, where appropriate, limited by the AAA Consumer Rules. Either
        party may make a request that the arbitrator award attorneys’ fees and costs
        upon proving that the other party has asserted a claim, cross-claim or defence
        that is groundless in fact or law, brought in bad faith or for the purpose
        of harassment, or is otherwise frivolous, as allowed by applicable law and the AAA Rules.
      </p>

      <p>
        23.8 Arbitrator’s Decision. The arbitrator’s decision will include the essential
        findings and conclusions upon which the arbitrator based the award. Judgement
        on the arbitration award may be entered in any court with proper jurisdiction.
        The arbitrator may award any relief allowed by law or the AAA Rules, but
        declaratory or injunctive relief may be awarded only on an individual basis
        and only to the extent necessary to provide relief warranted by the
        claimant’s individual claim.
      </p>

      <p>
        23.9 Jury Trial Waiver. You and Career Navig8r acknowledge and agree that
        we are each waiving the right to a trial by jury as to all arbitrable Disputes.
      </p>

      <p>
        23.10 No Class Actions or Representative Proceedings. You and Career Navig8r
        acknowledge and agree that, to the fullest extent permitted by law, we are
        each waiving the right to participate as a plaintiﬀ or class member in any
        purported class action lawsuit, class-wide arbitration, private attorney
        general action, or any other representative or consolidated proceeding.
        Unless we agree in writing, the arbitrator may not consolidate more than
        one party’s claims and may not otherwise preside over any form of any class
        or representative proceeding. If there is a final judicial determination
        that applicable law precludes enforcement of the waiver contained in this
        paragraph as to any claim, cause of action or requested remedy, then that
        claim, cause of action or requested remedy, and only that claim, cause of
        action or requested remedy, will be severed from this agreement to arbitrate
        and will be brought in a court of competent jurisdiction. In the event that
        a claim, cause of action or requested remedy is severed pursuant to this
        paragraph, then you and we agree that the claims, causes of action or requested
        remedies that are not subject to arbitration will be stayed until all arbitrable
        claims, causes of action and requested remedies are resolved by the arbitrator.
      </p>

      <p>
        23.11 Severability. Except as provided in Section 24.11, in the event that
        any portion of this Arbitration Agreement is deemed illegal or unenforceable,
        such provision will be severed and the remainder of the Arbitration Agreement
        will be given full force and eﬀect.
      </p>

      <p>
        23.12 Changes to Agreement to Arbitrate. If Career Navig8r changes this
        Section 24 after the date you last accepted these Terms (or accepted any
        subsequent changes to these Terms), you may reject that change by sending
        us written notice (including by email) within 30 days of the date the change
        is eﬀective. Rejecting a new change, however, does not revoke or alter your
        prior consent to any earlier agreements to arbitrate any Dispute between
        you and Career Navig8r (or your prior consent to any subsequent changes
        thereto), which will remain in eﬀect and enforceable as to any Dispute
        between you and Career Navig8r.
      </p>

      <p>
        23.13 Survival. Except as provided in Section 23.12, and subject to Section
        13.6 of the Terms of Service for Non-European Users this Section 24 will
        survive any termination of these Terms and will continue to apply even if
        you stop using the Career Navig8r Platform or terminate your Career Navig8r Account.
      </p>

      <p>
        Additional Terms Applicable to Business Users
      </p>

      <p>
        If you are a Business User as defined in Section 25 of these Terms, the following
        additional terms will apply to you:
      </p>

      <h3>24. Business Users.</h3>

      <p>
        You are a “Business User” for the purposes of these Terms if (i) your place
        of residence or establishment is within the EEA or the United Kingdom,
        (ii) you fully meet the definition of a ‘business user’ outlined in Article
        2 (1) of Regulation (EU) 2019/1150 on promoting fairness and transparency
        for business users of online intermediation services (“Platform to Business
        Regulation”), and (iii) you have notified Career Navig8r that you are a Business
        User by adding your business details to your Career Navig8r account. You
        are responsible for keeping your business details accurate and up to date.
      </p>

      <h3>25. Termination, Suspension and other Measures.</h3>

      <p>
        In case we take any of the measures according to Section 13.2 and 13.3,
        you will be given the opportunity to clarify the facts and circumstances
        leading to such a measure in the framework of our internal complaint-handling
        process as described in Section 27.
      </p>

      <h3>26. Complaints Handling and Mediation.</h3>

      <p>
        We want to be transparent about how we handle complaints and aim to treat
        all Business Users fairly. Our Help Centre explains how you can access our
        internal complaint-handling system and what you can expect as a Business
        User when you make a complaint in relation to issues falling under the remit
        of Article 11 (1) of the Platform to Business Regulation. It also sets out
        the details of the mediation service that you can use in the event that
        such a complaint is not resolved.
      </p>

      <h3>27. Access to Data.</h3>

      <p>
        Business Users have access to personal and other data in their Career Navig8r
        account and Mentor dashboard which is provided by the Business User, their
        Mentees or generated through the use of the Career Navig8r Platform and
        which is necessary for the performance of their Mentor Services as well
        as aggregated information about searches, bookings and the performance of
        their listings. Our Privacy / GDPR Policy sets out the categories of personal
        data and other data we collect, how we use, process, disclose and retain
        it, and how you can access it and exercise your data rights.
      </p>

      <h3>28. Additional Distribution Channels.</h3>

      <p>
        Listings may appear in advertisements for Career Navig8r published on third
        party websites from time to time.
      </p>

      <p>
        Schedule 1 - Contracting Entities
      </p>

      <div className={css.contactingEntitiesTable}>
        <div className={css.contactingEntitiesRow}>
          <div className={css.contactingEntitiesCell}>
            <b>YOUR PLACE OF RESIDENCE OR ESTABLISHMENT:</b>
          </div>
          <div className={css.contactingEntitiesCell}>
            <b>YOUR ACTIVITY ON THE Career Navig8r PLATFORM:</b>
          </div>
          <div className={css.contactingEntitiesCell}>
            <b>Career Navig8r CONTRACTING ENTITY:</b>
          </div>
          <div className={css.contactingEntitiesLastCell}>
            <b>CONTACT INFORMATION:</b>
          </div>
        </div>
        <div className={css.contactingEntitiesRow}>
          <div className={css.contactingEntitiesCell}>
            European Economic Area, Switzerland or the United Kingdom
          </div>
          <div className={css.contactingEntitiesCell}>
            Booking or oﬀering certain Mentor Services, where Career Navig8r is
            identified in the checkout or listing process.
          </div>
          <div className={css.contactingEntitiesCell}>
            Career Navig8r
          </div>
          <div className={css.contactingEntitiesLastCell}>
            admin@ careernavig8r .com
          </div>
        </div>
        <div className={css.contactingEntitiesRow}>
          <div className={css.contactingEntitiesCell}>
            &nbsp;
          </div>
          <div className={css.contactingEntitiesCell}>
            Booking or oﬀering Mentor Services located in the United States …….<br/><br/>
            Career Navig8r is identified in the checkout or listing process.
          </div>
          <div className={css.contactingEntitiesCell}>
            &nbsp;
          </div>
          <div className={css.contactingEntitiesLastCell}>
            admin@ careernavig8r .com
          </div>
        </div>
        <div className={css.contactingEntitiesLastRow}>
          <div className={css.contactingEntitiesCell}>
            &nbsp;
          </div>
          <div className={css.contactingEntitiesCell}>
            All other activities.
          </div>
          <div className={css.contactingEntitiesCell}>
            Career Navig8r
          </div>
          <div className={css.contactingEntitiesLastCell}>
            admin@ careernavig8r .com
          </div>
        </div>
      </div>

      <h2>Terms of Service for Non-European Users</h2>

      <p>
        Section 23 of these Terms contains an arbitration agreement and class action
        waiver that apply to all claims brought against Career Navig8r in the United
        States. Please read them carefully.
      </p>

      <p className={css.lastUpdated}>Last Updated: October 2021</p>

      <p>
        Thank you for using Career Navig8r!
      </p>

      <p>
        These Terms of Service (“Terms”)are a binding legal agreement between you
        and Career Navig8r that govern your use of the websites, applications, and
        other oﬀerings from Career Navig8r (collectively, the “Career Navig8r Platform”).
        When used in these Terms, “Career Navig8r,” “we,” “us,” or “our” refers to
        the Career Navig8r entity set out on Schedule 1 with whom you are contracting.
      </p>

      <p>
        The Career Navig8r Platform oﬀers an online venue that enables users (“Members”)
        to publish, oﬀer, search for, and book services. Members who publish and
        oﬀer services are “Mentors” and Members who search for, book, or use services
        are “Mentees.” Mentors oﬀer “MentorServices”. You must register an account
        to access and use many features of the Career Navig8r Platform, and must
        keep your account information accurate. As the provider of the Career Navig8r
        Platform, Career Navig8r does not own, control, oﬀer or manage any Listings
        or Mentor Services (a listing is made when you create a description of
        a Job Role you wish to oﬀer Mentoring for, that can be accessed by potential
        Mentees on the Career Navig8r platform, and oﬀer that service at a specific
        price). Career Navig8r is not a party to the contracts concluded directly
        between Mentors and Mentees, nor is Career Navig8r an insurer or an organiser
        or retailer of mentoring services.<br/>
        Career Navig8r is not acting as an agent in any capacity for any Member,
        except as specified in the Payments Terms of Service “Payment Terms”).
        To learn more about Career Navig8r’s role see Section 16.
      </p>

      <p>
        We maintain other terms and policies that supplement these Terms like our
        Privacy / GDPR Policy, which describes our collection and use of personal
        data, and our Payments Terms, which govern any payment services provided
        to Members by the Career Navig8r payment entities ("Career Navig8r Payments").
      </p>

      <p>
        If you are a mentor, you are responsible for understanding and complying
        with all laws, rules, regulations, and contracts with third parties that
        apply to your Mentor Services.
      </p>

      <h2>Table of Contents</h2>

      <p>
        Mentee Terms<br/>
        <ol>
          <li>Our Mission</li>
          <li>Searching and Booking on Career Navig8r</li>
          <li>Cancellations, Issues and Booking Modifications.</li>
          <li>Your Responsibilities and Assumption of Risk.</li>
          <li>Mentor TermsMentoring on Career Navig8r</li>
          <li>Managing Your Listing</li>
          <li>Cancellations, Issues, and Booking Modifications.</li>
          <li>Taxes</li>
          <li>General TermsReviews</li>
          <li>Content</li>
          <li>Fees</li>
          <li>Career Navig8r Platform Rules</li>
          <li>Termination, Suspension and Other Measures</li>
          <li>Modification</li>
          <li>Resolving Complaints and Damage Claims</li>
          <li>Career Navig8r’s Role</li>
          <li>Member Accounts</li>
          <li>Disclaimer of Warranties</li>
          <li>Limitations on Liability</li>
          <li>Indemnification</li>
          <li>Contracting Entities</li>
          <li>United States Governing Law and Venue</li>
          <li>United States Dispute Resolution and Arbitration Agreement</li>
          <li>China Governing Law and Dispute Resolution</li>
          <li>Rest of World Dispute Resolution, Venue and Forum, and Governing Law</li>
          <li>Miscellaneous</li>
        </ol>
      </p>

      <h2>Schedule 1 - Contracting Entities</h2>

      <h3>1. Our Mission.</h3>

      <p>
        Our mission is to create a communication platform/online meeting place which
        enables Mentors to oﬀer their Mentoring Services to Mentees. A place where
        you can browse through millions of Listings to find the ones that fit your
        needs. Learn more about a Listing by reviewing the description and photos,
        the Mentor profile, and past Mentee reviews. If you have questions, just
        message the Mentor.
      </p>

      <p>
        Career Navig8r provides the Career Navig8r Platform to Members for the purpose
        of assisting Members in securing the services of a Mentor who can credibly
        guide you on how to get and succeed in your desired Job Role. Or, if you
        are currently in a role, provide independent support. The Career Navig8r
        technology platform allows you to search for a potential Mentor by Job Roles
        they have had or currently hold, and then to further specify your requirement
        through defining the sector they have expertise in, the organisational level
        they held the Job Role at, and the Mentor cost per hour. This search will
        aid you to find a Mentor capable of supporting you to define a successful
        path to a desired Job Role, to successfully interview for this Job Role,
        and then to further excel as you begin it.
      </p>

      <h3>2. Searching and Booking on Career Navig8r.</h3>

      <p>
        2.1 Searching. You search for Mentor Services by first using the criteria
        of job role and then further filter these results by Sector, Organisational
        Level, Language, and price. Search results are based on their relevance
        to your search and other criteria. Relevance considers factors like price,
        availability, reviews, customer service and cancellation history, popularity,
        previous mentor sessions and saved Listings, Mentor requirements and more.
      </p>

      <p>
        2.2 Booking. When you book a Mentor, you are agreeing to pay all charges
        for your booking including the Listing price, applicable fees like Career
        Navig8r’s service fee, oﬄine fees, fee of our Stripe and equivalent (independent
        payment system used by the Career Navig8r system) and any other items identified
        during checkout (collectively, “Total Price”).
      </p>

      <p>
        There are two types of Bookings on Career Navig8r.<br/>
        <ul className={css.diskList}>
          <li>
            A Trial Meeting, which is a twenty-minute meeting in which the Mentor
            and Mentee decide if they can achieve the Mentees goals in future “Full Meetings”.
          </li>
          <li>A Full Meeting, which is booked in blocks of sixty minutes</li>
        </ul>
      </p>

      <p>
        A Mentee through the Career Navig8r platform sends a booking request for
        either meeting type to their chosen Mentor. The Mentor can in turn accept
        or decline this booking request. By accepting such a request, the Mentor
        has accepted a contract to oﬀer Mentor Services (sometimes called a booking
        in these Terms) which is formed directly between Mentee and the Mentor.
        Simultaneously to this acceptance, the Mentee receives a booking confirmation,
        for the meeting type they requested, and this in turn sees them now receiving
        a contract for Mentor Services (sometimes called a booking in these Terms).
        This is formed directly between Mentee and the Mentor. The cancellation
        policy and any other rules, standards, policies, or requirements identified
        in the Listing, during checkout, and as part of the terms of service form
        part of your contract with the Mentor.
      </p>

      <p>
        For the Full Meeting booking type, Career Navig8r reserves the right to
        immediately deduct the booking fee from the Mentee’s credit card / payment
        method on submission of the booking request for the amount specified. This
        amount can only be held for a maximum period of 90 days in advance of a meeting
        due to our payment platforms rules (Stripe). Within this 90-day period
        the meeting as such needs to occur. When it does, the monies are stored
        for fourteen days before they are then transferred to the Mentor’s Bank
        Account unless this period extends beyond the original 90-day period. For
        example, if the meeting occurred 80 days after the booking request, and
        said meeting occurs, these funds would be paid to the Mentor 10 days later
        as per the operating rules of Stripe.
      </p>

      <p>
        This payment can be refunded through an Arbitration Process set out in point
        15 of the Terms of Service which in turn reﬂect the rules for storing monies
        that Stripe impose.
      </p>

      <p>
        In the event that the Mentor declines that meeting then the Career Navig8r
        booking system will initiate a refund to the Mentee as well as a result
        of the conditions laid out in the Career Navig8r Cancellation Policy. It
        is the Mentee’s responsibility to provide the correct credit card details
        and other necessary information to Career Navig8r in order to receive such
        a refund. It is the Mentor’s responsibility to provide the correct Bank
        Account details to ensure any monies due are paid.<br/>
        Career Navig8r is not responsible or liable for any incorrect or out of
        date information provided by either the Mentor or Mentee to result in
        the refund or payment not being deposited correctly.
      </p>

      <p>
        For the Trial Meeting booking type, Career Navig8r reserves the right to
        immediately pre-authorise the Mentee’s Credit Card for the booking request
        for the amount specified. This pre-authorization can only be in place for
        six days, and hence bookings for Trial Meetings must be made within a window
        of six days. As detailed in the Career Navig8r Cancellation Policy, if
        the Mentee cancels this meeting up to 24 hours before the meeting start
        time, or attends this meeting, or the Mentor does not attend this meeting
        then this payment is not taken from the Mentee’s credit card.
      </p>

      <p>
        In the event that the Mentor declines this meeting then the Career Navig8r
        booking system will not claim any funds from the Mentee’s Credit Card. Again,
        it is the Mentor’s responsibility to provide the correct Bank Account details
        to ensure any monies due are paid. Career Navig8r is not responsible or
        liable for any incorrect or out of date information provided by either the Mentor
        or Mentee to result in the refund or payment not being deposited correctly.
      </p>

      <p>
        <b>2.2.1 Length of Meetings to reflect the potential of Meetings not starting on time</b>
        <br/>
        <ul className={css.diskList}>
          <li>
            Five minutes before this meeting starts both Mentor and Mentee receive
            an email with a link to join their meeting. This link is also found
            in both the Mentor’s and Mentee’s Career Navig8r inbox.
          </li>
          <li>
            A Full Meeting, which is booked in blocks of sixty minutes
          </li>
          <li>
            For the Link to function correctly, the Mentor and Mentee must be signed
            into their Career Navig8r account
          </li>
          <li>
            This link will take both the Mentor and Mentee to the booking request
            page in Career Navig8r
          </li>
          <li>
            As the Meeting time arrives, a button that up to this point allows
            the Mentor or Mentee to cancel the meeting, instead now asks them to access
            the meeting. By pressing this button on their respective pages, both
            the Mentor and Mentee will enter the meeting’s virtual meeting room.
            A message will tell both participants if the other person has also arrived.
            When both parties have arrived, the Mentor’s Join Meeting button will
            become active and by pressing it, the Video Conferencing will launch.
            Once this button is pressed by the Mentor, the Mentee’s join now button
            becomes active and they too can join, at which point the meeting begins.
          </li>
        </ul>
      </p>

      <p>
        The Mentee is late – What now?<br/>
        <ul className={css.diskList}>
          <li>
            If a Mentee arrives late, they should still enter the waiting room first
            (to allow them to adjust their settings if required)
          </li>
          <li>
            If the Mentor is on time, the “join now” button becomes active, and
            they should join the full meeting (they are told once joined that
            the Mentee has not arrived yet). The countdown clock will appear and
            start counting down from when the Mentor enters the meeting (not from
            when both people are in the meeting) from 60 minutes.
          </li>
        </ul>
      </p>

      <p>
        The Mentor is Late – What Now?<br/>
        <ul className={css.diskList}>
          <li>
            As stated, the Mentee cannot enter the meeting unless the Mentor has
            joined ahead of them
          </li>
          <li>
            This means that the Join Now button only appears for the Mentee once
            the Mentor is in the meeting
          </li>
          <li>
            If the Mentor arrives into the waiting room late, they will see the clock
            counting down from the meeting’s start down, with it having started
            at fifteen minutes
          </li>
          <li>
            The Mentor can launch the meeting up to 14 minutes 59 seconds have
            elapsed (as measured by the countdown clock)
          </li>
          <li>
            Once they enter the meeting, the clock will begin a 55 minutes countdown
            (or if it is a two-hour meeting – 1 hour and 55 minutes etc.)
          </li>
          <li>
            Why is the clock starting at 55 minutes rather than the normal 60 minutes?<br/>
            <ul className={css.circleList}>
              <li>
                By starting at 55 minutes, if the Mentor have another meeting with
                someone else in the next hour, they will arrive with roughly five
                minutes to spare so that meeting is not automatically cancelled
              </li>
              <li>
                Then that meeting will be 55 minutes and so on until they come
                to their last meeting
              </li>
              <li>
                Fortunately, the following happens with this chain reaction approach<br/>
                <ul className={css.squareList}>
                  <li>
                    Meeting One: Mentee One: Start Time 2pm. Mentor late (2.14.59):
                    Meeting starts at 2.15 for 55 minutes
                  </li>
                  <li>
                    Meeting Two: Mentee Two: Start Time 3pm: Mentor arrives due
                    to the above at 3.10 for 55 minutes
                  </li>
                  <li>
                    Meeting Three: Mentee Three: Start Time 4pm: Mentor arrives
                    at 4.05 for 55 minutes
                  </li>
                  <li>
                    So, the process recorrects by the Mentor ‘s Meeting four,
                    (the Mentor will be on-time)
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p>
        By using the Career Navig8r system, you are agreeing to the above shortened
        meetings (five minute loss) with no refund applicable. This said, if the Mentor
        and Mentee both arrive on time, the meeting will be sixty minutes in length.
      </p>

      <p>
        Mentor or Mentee Technical or Skill Faults
      </p>

      <p>
        Career Navig8r has no control over the quality of equipment or software,
        or the eﬀectiveness of the internet connection that a Mentor or Mentee has
        access to. As a result, if a meeting cannot be attended by a Mentor or Mentee
        or is disrupted due to a technical fault Career Navig8r accepts no responsibility
        for this. It is hoped that in such circumstances, the Mentor will request
        a full refund (Career Navig8r is not in the position to oﬀer partial refunds)
        on behalf of the Mentee.<br/>
        Alternatively, if the fault was that of the Mentor, but they will not oﬀer
        a refund, the Mentee can ask for Arbitration to be put in place as described
        in point 15 of this document / the Terms of Service.
      </p>

      <p>
        PROMOTIONS: Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered to
        be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <p>
        <b>2.2.1 Length of Meetings to reflect the potential of Meetings not starting on time</b>
        <br/>
        What follows is the explanation as to why “Full Meetings” may not be a full
        60 minutes (Full Meeting). This relates to circumstances of the Mentor or
        Mentee being late to their meeting type, rather than a meeting being shortened
        for any other reason. By using the Career Navig8r platform you are agreeing
        to these time reductions occurring and also agreeing that no refund will
        be made as a result of the meeting times being shortened. What follows is
        the reason why meetings could be shortened by the system and by what amount
        they would be shortened. Again, what follows is designed to oﬀset the impact
        of the Mentor or Mentee being late to a meeting type and preventing this
        lateness having an impact of seeing consecutive meetings that happen not
        being cancelled.
      </p>

      <p>
        2.4 Bookings for Mentor Services. A Mentor Service booking entitles you
        to participate in, attend, or use that Mentor Service. You are responsible
        for confirming that you meet minimum age, proficiency, or other requirements.
        You are responsible for informing the Mentor of any medical or physical
        conditions, or other circumstances that may impact your ability to participate,
        attend or use the Mentor Service. Except where expressly authorised by
        Career Navig8r, you may not allow any person to join a Mentor Service.
      </p>

      <h3>3. Cancellations and Booking Modifications.</h3>

      <p>
        3.1 Cancellations. In general, if you cancel a booking, the amount
        refunded to you is determined by the cancellation policy that applies to
        that booking. But, in certain situations, other policies take precedence
        and determine what amount is refunded to you. If something outside your
        control requires you to cancel a booking, you may be entitled to a partial
        or full refund as outlined in this document. If the Mentor cancels, (as
        defined in our Cancellation Policy), you may be entitled to a full refund
        as outlined within this Policy. You may appeal a decision by Career Navig8r
        by contacting our customer service, (admin@careernavig8r.com).
      </p>

      <p>
        3.2 Booking Modifications Mentors and Mentees are responsible for any booking
        modifications they agree to make via the Career Navig8r Platform or direct
        Career Navig8r customer service to make on their behalf ("Booking Modifications"),
        and agree to pay any additional amounts or fees associated with any Booking Modification.
      </p>

      <h3>4. Your Responsibilities and Assumption of Risk.</h3>

      <p>
        4.1 Your Responsibilities. You are responsible and liable for your own acts
        and omissions. For example, this means: you must act with integrity, treat
        others with respect, and comply with applicable laws at all times.
      </p>

      <p>
        4.2 Your Assumption of Risk. You acknowledge that many activities carry
        inherent risks and agree that, to the maximum extent permitted by applicable
        law, you assume the entire risk arising out of your access to and use of
        the Career Navig8r Platform and any Content (as defined in Section 10),
        use of any other Mentor Service, or any other interaction you have with
        other Members whether in person or online. This means it is your responsibility
        to investigate a Mentor Service to determine whether it is suitable for you.
        You freely and wilfully assume those risks by choosing to participate in
        those Mentor Services.
      </p>

      <h3>Mentor Terms</h3>

      <p>5. Mentoring on Career Navig8r.</p>

      <p>
        5.1 Mentor. As a Mentor, Career Navig8r oﬀers you the opportunity to share
        your Mentor Service with our vibrant community of Mentees - and earn money
        doing it. It is easy to create a Listing for your Role Profiles/Job Roles
        and you are in control of how you Mentor, set your price, availability,
        and rules for each Listing ().
      </p>

      <p>
        5.2 Contracting with Mentees. When you accept a booking request or receive
        a booking confirmation through the Career Navig8r Platform, you are entering
        into a contract directly with the Mentee, and are responsible for delivering
        the Mentor Service under the terms and at the price specified in your Listing.
        You are also agreeing to pay applicable fees like Career Navig8r’s service
        fee for each booking. Career Navig8r Payments will deduct amounts you owe
        from your pay-out unless we and you agree to a diﬀerent method. Any terms
        or conditions that you include in any supplement contract with Mentees must:
        (i) be consistent with these Terms, our Policies, and the information provided
        in your Listing, and (ii) be prominently disclosed in your Listing description.
      </p>

      <p>
        5.3 Independence of Mentors.Your relationship with Career Navig8r is that
        of an independent individual or entity and not an employee, agent, joint
        venturer or partner of Career Navig8r, except that Career Navig8r Payments
        acts as a payment collection agent as described in the Payments Terms. Career
        Navig8r does not direct or control your Mentor Service and you understand
        that you have complete discretion whether and when to provide Mentor Services
        and at what price and on what terms to oﬀer them.
      </p>

      <p>
        PROMOTIONS: Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered to
        be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <h3>6. Managing Your Listing.</h3>

      <p>
        6.1 Creating and Managing Your Role Profiles/Job Roles Mentor Services Listing.
        The Career Navig8r Platform provides tools that make it easy for you to set up
        and manage a Listing. Your Listing must include complete and accurate information
        about your role profiles/job roles Mentor Service and your price. You are
        responsible for your acts and omissions as well as for keeping your Listing
        information (including calendar availability) and content (like photos)
        up-to-date and accurate at all times. We recommend that you obtain appropriate
        insurance for your Mentor Services and suggest you carefully review policy
        terms and conditions like coverage details and exclusions.
      </p>

      <p>
        6.2 Know Your Legal Obligations.You are responsible for understanding and
        complying with any laws, rules, regulations, and contracts with third
        parties that apply to your Mentor Service Listing or Mentor Services.
        Information we provide regarding legal requirements is for informational
        purposes only and you should independently confirm your obligations. You
        are responsible for handling and using personal data of Mentees and others
        in compliance with applicable privacy laws and these Terms, including our
        Mentor Privacy Standards. You should also seek permission from previous
        and current employers before sharing any information relating to them,
        the work you did for them, or any intellectual property which they own or
        owned by others. It is also advisable to seek legal advice on these matters as well.
      </p>

      <p>
        6.3 Search Ranking. The ranking of Listings in search results on the Career
        Navig8r Platform depends on a variety of factors, including these main parameters:
        <br/>
        <ul className={css.diskList}>
          <li>
            Mentee search parameters (e.g., number of Mentees, time and duration
            of the session, price range),
          </li>
          <li>
            Listing characteristics (e.g., price, calendar availability, number
            and quality of images, Reviews, type of Mentor Service, Mentor status,
            age of the Listing, average Mentee popularity),
          </li>
          <li>
            Mentee booking experience (e.g., customer service and cancellation
            history of the Mentor, ease of booking),
          </li>
          <li>
            Mentor requirements (e.g., minimum, or maximum sessions, booking
            cut-oﬀ time), and
          </li>
          <li>
            Mentee preferences (e.g., previous sessions, saved Listings, location
            from where the Mentee is searching).
          </li>
        </ul>
        <br/>
        Search results may appear diﬀerent on our mobile application than they appear
        on our website. Career Navig8r may allow Mentors to promote their Listings
        in search or elsewhere on the Career Navig8r Platform by paying an additional
        fee. More information about the factors that determine how your Listing
        appears in search results, our current promotional programs (if any) and
        how we identify promoted Content can be found in our Help Centre.
      </p>

      <p>
        6.4 Your Responsibilities.You are responsible for your own acts and omissions
        in providing your Mentor Services. You are responsible for setting your
        price and establishing rules and requirements for your Listing. You must
        describe any and all fees and charges in your Listing description and may
        not collect any additional fees or charges outside the Career Navig8r.
        Do not encourage Mentees to create third-party accounts, submit reviews,
        provide their contact information, or take other actions outside the Career
        Navig8r Platform in violation of our Terms of Service Policy.
      </p>

      <p>
        6.5 Mentoring as a Team or Organization.If you work with a co-Mentor or
        Mentor as part of a team, business or other organisation, the entity and
        each individual who participates in providing Mentor Services, is responsible
        and liable as a Mentor under these Terms. If you accept terms or enter into
        contracts, you represent and warrant that you are authorised to enter into
        contracts for and bind your team, business or other organisation, and that
        each entity you use is in good standing under the laws of the place where
        it is established. If you perform other functions, you represent and warrant
        that you are authorised to perform those functions. If you instruct Career
        Navig8r to transfer a portion of your pay-out to a co-Mentor or other Mentors,
        or send payments to someone else, you must be authorised to do so, and
        are responsible and liable for the payment amounts and accuracy of any
        pay-out information you provide.
      </p>

      <p>
        6.6 Your Assumption of Risk.You acknowledge that Mentoring carries inherent
        risks and agree that you assume the entire risk arising out of your access
        to and use of the Career Navig8r Platform, oﬀering Mentor Services, or any
        interaction you have with other Members whether in person or online. You
        agree that you have had the opportunity to investigate the Career Navig8r
        Platform and any laws, rules, regulations, or obligations that may be applicable
        to your Listings or Mentor Services and that you are not relying upon any
        statement of law made by Career Navig8r.
      </p>

      <h3>7. Cancellations, Issues, and Booking Modifications.</h3>

      <p>
        7.1 Cancellations and Issues.In general, if a Mentee cancels a booking,
        the amount paid to you is determined by the cancellation policy that applies
        to that booking. As a Mentor, you should not cancel on a Mentee without
        a valid or applicable reason. If you cancel on a Mentee without such a valid
        reason, we may impose a cancellation fee and other consequences If: (i)
        a Mentee or Mentor experiences an Issue (issues will be defined and ruled
        upon through Career Navig8r’s Arbitration process as outlined in Career
        Navig8r’s refund and arbitration policy), (ii) an Extenuating Circumstance
        arises(the determination of if an extenuating circumstance has occurred will
        be defined and ruled upon through Career Navig8r’s Arbitration process as
        outlined in Career Navig8r’s refund and arbitration policy), or (iii) a booking
        is cancelled under Section 13 of these Terms, the amount you are paid will
        be reduced by the amount we refund or otherwise provide to the Mentee,
        and by any other reasonable costs we incur as a result of the cancellation.
        If a Mentee receives a refund after you have already been paid, or the amount
        of the refund and other costs incurred by Career Navig8r exceeds your pay-out,
        Career Navig8r (via Career Navig8r Payments) may recover that amount from
        you, including by oﬀsetting the refund against your future pay-outs. You
        agree that Career Navig8r’s Cancellation Policy, and these Terms pre-empt
        the cancellation policy you set in situations where they allow for the cancellation
        of a booking and/or the issuance of refunds to Mentees. If we reasonably
        expect to provide a refund to a Mentee under one of these policies, we may
        delay release of any pay-out for that booking until a refund decision is made.
        You may appeal a decision by Career Navig8r by contacting our customer service.
      </p>

      <p>
        7.2 Booking Modifications.Mentors and Mentees are responsible for any Booking
        Modifications they agree to make via the Career Navig8r Platform or direct
        Career Navig8r customer service to make on their behalf, and agree to pay
        any additional amounts, fees or taxes associated with a Booking Modification.
      </p>

      <h3>8. Taxes.</h3>

      <p>
        8.1 Mentor Taxes. As a Mentor, you are responsible for determining and
        fulfilling your obligations under applicable laws to report, collect, remit
        or include in your price any applicable VAT or other indirect taxes, income
        or other taxes (“Taxes”)
      </p>

      <p>General Terms</p>

      <h3>9. Reviews.</h3>

      <p>
        After each Mentor Service, Mentees and Mentors will have an opportunity
        to review each other. Your Review must be accurate and may not contain any
        discriminatory, oﬀensive, defamatory, or other language that violates our
        Community Guidelines. Reviews are not verified by Career Navig8r for accuracy
        and may be incorrect or misleading.
      </p>

      <h3>10. Content.</h3>

      <p>
        Parts of the Career Navig8r Platform enable you to provide feedback, text,
        photos, audio, video, information and other content (“Content”). By providing
        Content, in whatever form and through whatever means, you grant Career Navig8r
        a non-exclusive, worldwide, royalty-free, sub-licensable and transferable
        licence, for the term of the protection of the rights so licensed, to access,
        use, store, copy, modify, prepare derivative works of, distribute, publish,
        transmit, stream, broadcast, and otherwise exploit in any manner such Content
        to provide and/or promote the Career Navig8r Platform, in any media or platform,
        known or unknown to date and in particular on Internet and social networks.
        If Content includes personal information, such Content will only be used
        for these purposes if such use complies with applicable data protection
        laws in accordance with our Privacy / GDPR Policy.
      </p>

      <p>
        Where Career Navig8r pays for the creation of Content or facilitates its
        creation, Career Navig8r may own that Content, in which case supplemental
        terms or disclosures will say that. You are solely responsible for all Content
        that you provide and warrant that you either own it or are authorised to grant
        Career Navig8r the rights described in these Terms. You are responsible
        and liable if any of your Content violates or infringes the intellectual
        property or privacy rights of any third party. Content must comply with
        our Community Guidelines, which prohibit, among other things, discriminatory,
        obscene, harassing, deceptive, violent, and illegal content. You agree that
        Career Navig8r may make available services or automated tools to translate
        Content and that your Content may be translated using such services or tools.
        Career Navig8r does not guarantee the accuracy or quality of translations
        and Members are responsible for confirming the accuracy of such translations.
      </p>

      <p>
        You should access the Career Navig8r platform only by using a personal computer
        or device on which virus protection software has been installed, to update
        virus signature files promptly when updates become available from the manufacturer
        of the virus protection software, and to immediately install updates to browser
        software as such updates become available from the manufacturer.
      </p>

      <p>
        We encourage you to routinely scan your PC using your virus protection product
        to detect and remove any viruses found. Undetected or unrepaired, a virus
        may corrupt and destroy your programs, files, or software. You agree that
        we are not responsible for and shall have no liability for any electronic
        virus that you may encounter using our online platform. Career Navig8r is
        not responsible and therefore holds no liability for any virus incurred as
        a result of opening or receiving a file through the Career Navig8r chat
        function or platform. You agree that you open any file on the Career Navig8r
        platform entirely at your own risk.
      </p>

      <h3>11. Fees and Promotions</h3>

      <p>
        Career Navig8r may charge fees to Mentors for use of the Career Navig8r
        Platform. More information about when service fees apply and how they are
        calculated can be found on our Service Fees page. Except as otherwise provided
        on the Career Navig8r Platform, service fees are non-refundable. Career
        Navig8r reserves the right to change the service fees at any time and will
        provide Members notice of any fee changes before they become eﬀective. Fee
        changes will not aﬀect bookings made prior to the eﬀective date of the fee
        change. If you disagree with a fee change you may terminate this agreement
        at any time pursuant to Section 13.2.
      </p>

      <p>
        PROMOTIONS Career Navig8r reserves the right to oﬀer discount promotions
        from time to time up to a maximum of 20% discount. The discount oﬀered to
        be shared 50/50 by Career Navig8r and the Mentor. However, these discounted
        rates may carry special restrictions and conditions, for example they may
        be non-cancellable and non-refundable.
      </p>

      <h3>12. Career Navig8r Platform Rules.</h3>

      <p>
        12.1 Rules. You must follow these rules and must not help or induce others
        to break or circumvent these rules.<br/>
        <ul className={css.diskList}>
          <li>
            Act with integrity and treat others with respect.<br/>
            <ul className={css.circleList}>
              <li>
                Do not lie, misrepresent something or someone, or pretend to be
                someone else.
              </li>
              <li>
                Be polite and respectful when you communicate or interact with others.
              </li>
              <li>
                Follow our Community Guidelines and do not discriminate against
                or harass others.
              </li>
            </ul>
          </li>
          <li>
            Do not scrape, hack, reverse engineer, compromise or impair the Career
            Navig8r Platform<br/>
            <ul className={css.circleList}>
              <li>
                Do not use bots, crawlers, scrapers or other automated means to access
                or collect data or other content from or otherwise interact with
                the Career Navig8r Platform.
              </li>
              <li>
                Do not hack, avoid, remove, impair, or otherwise attempt to circumvent
                any security or technological measure used to protect the Career
                Navig8r Platform or Content.
              </li>
              <li>
                Do not decipher, decompile, disassemble or reverse engineer any
                of the software or hardware used to provide the Career Navig8r Platform.
              </li>
              <li>
                Do not take any action that could damage or adversely aﬀect the performance
                or proper functioning of the Career Navig8r Platform.
              </li>
            </ul>
          </li>
          <li>
            Only use the Career Navig8r Platform as authorised by these Terms or
            another agreement with us.<br/>
            <ul className={css.circleList}>
              <li>
                You may only use another Member’s personal information as necessary
                to facilitate a transaction using the Career Navig8r Platform as
                authorised by these Terms.
              </li>
              <li>
                Do not use the Career Navig8r Platform, our messaging tools, or
                Members’ personal information to send commercial messages without
                their express consent.
              </li>
              <li>
                You may use Content made available through the Career Navig8r Platform
                solely as necessary to enable your use of the Career Navig8r Platform
                as a Mentee or Mentor.
              </li>
              <li>
                Do not use Content unless you have permission from the Content owner,
                or the use is authorised by us in these Terms or another agreement
                you have with us.
              </li>
              <li>
                Do not request, make or accept a booking or any payment outside
                of the Career Navig8r Platform to avoid paying fees, taxes or for
                any other reason.
              </li>
              <li>
                Do not require or encourage Mentees to open an account, leave a review,
                or otherwise interact with a third-party website, application or
                service before, during or after a booking, unless authorised by
                Career Navig8r.
              </li>
              <li>
                Do not engage in any practices that are intended to manipulate
                our search algorithm.
              </li>
              <li>
                Do not book Mentor Services unless you are actually using
                the Mentor Services.
              </li>
              <li>
                Do not use, copy, display, mirror or frame the Career Navig8r Platform,
                any Content, any Career Navig8r branding, or any page layout or
                design without our consent.
              </li>
            </ul>
          </li>
          <li>
            Honour your legal obligations.<br/>
            <ul className={css.circleList}>
              <li>
                Understand and follow the laws that apply to you, including privacy,
                data protection laws and intellectual policy laws.
              </li>
              <li>
                If you provide us with someone else’s personal information, you:
                (i) must do so in compliance with applicable law, (ii) must be authorised
                to do so, and (iii) authorise us to process that information under
                our Privacy / GDPR Policy.
              </li>
              <li>
                Read and follow our Terms, Policies and Standards.
              </li>
              <li>
                Do not use the name, logo, branding, or trademarks of Career Navig8r
                or others without permission.
              </li>
              <li>
                Do not use or register any domain name, social media handle, trade
                name, trademark, branding, logo or other source identifier that
                may be confused with Career Navig8r branding.
              </li>
              <li>
                Do not oﬀer Mentor Services that violate the laws or agreements
                that apply to you.
              </li>
              <li>
                Do not oﬀer or solicit prostitution or participate in or facilitate
                human traffcking.
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p>
        12.2 Reporting Violations. If you believe that a Member, Listing or Content
        poses an imminent risk of harm to a person, you should immediately contact
        local authorities before contacting Career Navig8r. In addition, if you
        believe that a Member, Listing or Content has violated our Standards, you
        should report your concerns to Career Navig8r. If you reported an issue
        to local authorities, Career Navig8r may request a copy of that report.
        Except as required by law, we are not obligated to take action in response
        to any report.
      </p>

      <p>
        12.3 Copyright Notifications. If you believe that Content on the Career
        Navig8r Platform infringes copyrights, please notify us.
      </p>

      <h3>13. Termination, Suspension and other Measures.</h3>

      <p>
        13.1 Term. The agreement between you and Career Navig8r reﬂected by these
        Terms remains in eﬀect until either you or we terminate the agreement in
        accordance with these Terms.
      </p>

      <p>
        13.2 Termination. You may terminate this agreement at any time by sending
        us an email admin@careernavig8r.com or by deleting your account. Career
        Navig8r may terminate this agreement for any reason by giving you 30 days’
        notice via email or using any other contact information you have provided
        for your account. Career Navig8r may also terminate this agreement immediately
        and without prior notice and stop providing access to the Career Navig8r
        Platform if (i) you materially breach these Terms or our Policies, (ii)
        you violate applicable laws, or (iii) such action is necessary to protect
        the personal safety or property of Career Navig8r, its Members, or third
        parties (for example in the case of fraudulent behaviour of a Member), or
        (iv) your account has been inactive for more than two years.
      </p>

      <p>
        13.3 Member Violations. If (i) you breach these Terms, our Policies, or
        our Standards, (ii) you violate applicable laws, regulations or third party
        rights, (iii) you have repeatedly received poor Reviews or Career Navig8r
        otherwise becomes aware of or has received complaints about your performance
        or conduct, (vi) you have repeatedly cancelled confirmed bookings or failed
        to respond to booking requests without a valid reason, or (vii) such action
        is necessary to protect the personal safety or property of Career Navig8r,
        its Members, or third parties, Career Navig8r may:<br/>
        <ul className={css.diskList}>
          <li>
            suspend or limit your access to or use of the Career Navig8r Platform
            and/or your account;
          </li>
          <li>
            suspend or remove Listings, Reviews, or other Content;
          </li>
          <li>
            cancel pending or confirmed bookings; or
          </li>
          <li>
            suspend or revoke any special status associated with your account.
          </li>
        </ul>
      </p>

      <p>
        In case of non-material violations or where otherwise appropriate, you will
        be given notice of any intended measure by Career Navig8r and an opportunity
        to resolve the issue, unless such notification would (i) prevent or impede
        the detection or prevention of fraud or other illegal activities, (ii) harm
        the legitimate interests of other Members or third parties, or (iii) contravene
        applicable laws.
      </p>

      <p>
        13.4 Legal Mandates. Career Navig8r may take any action it determines is
        reasonably necessary to comply with applicable law, or the order or request
        of a court, law enforcement or other administrative agency or governmental
        body, including the measures described above in Section 13.3.
      </p>

      <p>
        13.5 Eﬀect of Termination. If you are a Mentor and terminate your Career
        Navig8r account, any confirmed booking(s) will be automatically cancelled,
        and your Mentees will receive a full refund. If you terminate your account
        as a Mentee, any confirmed booking(s) will be automatically cancelled, and
        any refund will depend upon the terms on the conditions for said cancellations
        as defined in Career Navig8r’s cancellation policy. When this agreement
        has been terminated, you are not entitled to a restoration of your account
        or any of your Content. If your access to or use of the Career Navig8r Platform
        has been limited, or your Career Navig8r account has been suspended, or this
        agreement has been terminated by us, you may not register a new account
        or access or use the Career Navig8r Platform through an account of another Member.
      </p>

      <p>
        13.6 Survival. Parts of these Terms that by their nature survive termination,
        will survive termination of this agreement, including Sections 2 through 26.
      </p>

      <h3>14. Modification</h3>

      <p>
        Career Navig8r may modify these Terms at any time. When we make changes to
        these Terms, we will post the revised Terms on the Career Navig8r Platform
        and update the “Last Updated” date at the top of these Terms. We will also
        provide you with notice of the proposed changes by email at least thirty
        (30) days before the date they become eﬀective. If you disagree with the revised
        Terms, you may terminate this agreement immediately as provided in these
        Terms. We will inform you about your right to terminate the Agreement in
        the notification email. If you do not terminate your agreement before
        the date the revised Terms become eﬀective, your continued access to or
        use of the Career Navig8r Platform will constitute acceptance of the revised
        Terms.
      </p>

      <h3>15. Resolving Complaints</h3>

      <p>
        Arbitration Process:
      </p>

      <p>
        Career Navig8r does not provide Mentoring or Mentors. Career Navig8r’s service
        is to provide the means for Mentors and Mentees to find, book and pay for
        the Mentoring Services oﬀered by Mentors. The service provider for Mentoring
        is the Mentor. As such, if this service is not satisfactory, or the conduct
        of those involved is not appropriate, this is an issue for the Mentor and
        Mentee to solve independent of Career Navig8r.
      </p>

      <p>
        This said, so that Career Navig8r is a safe place to deliver and receive
        Mentoring, Career Navig8r has created an Arbitration Service so that refunds
        are possible under some clear conditions /stipulations. By using Career
        Navig8r you are agreeing to the process by which this arbitration operates
        and the outcome of the arbitration.
      </p>

      <p>
        This said, so that Career Navig8r is a safe place to deliver and receive
        Mentoring, Career Navig8r has created an Arbitration Service so that refunds
        are possible under some clear conditions /stipulations. By using Career
        Navig8r you are agreeing to the process by which this arbitration operates
        and the outcome of the arbitration.
      </p>

      <p>
        What Career Navig8r can do, is while these funds are being held by Stripe
        (or future similar payment platforms), to arbitrate between Mentor and Mentee
        if a dispute occurs, and then depending on the outcome of the arbitration
        (which we will decide) to direct Stripe to pay either the Mentor or refund
        the Mentee. But Stripe has strict time limits on how long it will delay
        a payment which directly impact the rules of the arbitration. As such:<br/>
        <ul className={css.diskList}>
          <li>
            Directly after a Mentoring meeting is conducted, Stripe will hold the funds
            for payment of this meeting for fourteen calendar days. Once this fourteen-day
            period is concluded, Stripe will automatically deposit the funds due
            to the Mentor into the Mentor’s bank account, at which point Career Navig8r
            will no longer be able to arbitrate and make a refund. As a result of
            this, the following is the structure of the Arbitration process:<br/>
            <ol>
              <li>
                The complaint / request for refund must be e-mailed to admin@careernavig8r.com
                within five calendar days of the Mentoring Session. The complaint must
                contain all the information that the Mentee has that illustrates that
                a refund should be made. It is important to note that any written evidence
                submitted, has to be information that was communicated through Career
                Navig8r communication system as it is a condition of using Career Navig8r
                that all written communication between a Mentor and Mentee must occur
                through the internal communication system. The only exception to this
                is the verbal communication that occurs in the Mentoring meetings itself.
              </li>
              <li>
                If a request for a refund is made after the five calendar days,
                Career Navig8r will no longer be in the position to Arbitrate, and
                the Mentor and Mentee will need to solve the issue independent of
                Career Navig8r (Career Navig8r will no longer be involved and will
                play no part in any refund)
              </li>
              <li>
                If the Mentee attempts to provide additional evidence after the initial
                five days, it will not be reviewed. The reason is the overarching
                time limit. To be fair to the Mentor, we need to allow the Mentor
                adequate time to see the evidence presented against them and then
                time to respond.
              </li>
              <li>
                Once Career Navig8r has received the complaint and the evidence
                from the Mentee, it will then be sent unedited to the Mentor.
              </li>
              <li>
                The Mentor will now have a right to reply and is given three calendar
                days to prepare and send their defence. Again, any written evidence
                presented must be that found on Career Navig8r’s communication system
                (see reason above) and no evidence presented by the Mentor after
                this three-day period will be reviewed.
              </li>
              <li>
                If there is no response from the Mentor within this three-day period,
                for whatever reason, then unfortunately the arbitration will only
                consider the evidence presented by the Mentee. The only reason for
                this, is that the Stripe system will only hold the funds for the fourteen
                days, so this drives these strict time limits.
              </li>
              <li>
                With the Mentees case made and hopefully the case of the Mentor
                presented to Career Navig8r within the stipulated time frames, Career
                Navig8r will impartially adjudicate the issue and come to a binding
                decision before the fourteen days deadline has been reached. The findings
                of Career Navig8r are binding (a stipulation of using this site
                for all users)
              </li>
              <li>
                Career Navig8r will communicate its decision to both Mentor and
                Mentee, but this communication will not contain the reasons for
                the decision but simply what has been decided.
              </li>
            </ol>
          </li>
          <li>
            The second impact that the Stripe system has on the arbitration process
            relates to the following rule that it has in place. Strip will only
            hold monies for a maximum of 90 calendar days from the point of collection.
            In this case, the point of collection is when a Mentoring Meeting / Session
            has been booked. The fourteen-day rule is present from the point after
            the meeting occurs, but the ninety-day rule is from the point of booking.
            This means that if a Mentees requests a refund due to a complaint, and
            although it is made within five days of the meeting as outlined previously,
            if the meeting took place within fifteen days of the ninety-day period
            concluding, then Career Navig8r is unable to arbitrate. As such, the refund
            request will be an issue for the Mentor and Mentee to solve independent
            of Career Navig8r.
          </li>
        </ul>
      </p>

      <h3>16. Career Navig8r’s Role.</h3>

      <p>
        We oﬀer a platform that enables Members to publish, oﬀer, search for, and
        book Mentor Services. When Members make or accept a booking, they are entering
        into a contract directly with each other. Career Navig8r is not and does
        not become a party to or other participant in any contractual relationship
        between Members. Career Navig8r is not acting as an agent for any Member
        except for where Career Navig8r Payments acts as a collection agent as provided
        in the Payments Terms. While we work hard to ensure our Members have great
        experiences using Career Navig8r, we do not and cannot control the conduct
        or performance of Mentees and Mentors and do not guarantee (i) the existence,
        quality, safety, suitability, or legality of any Listings or Mentor Services
        or (ii) the truth or accuracy of any Listing descriptions, Reviews, or other
        Content provided by Members. You acknowledge that Career Navig8r has no general
        obligation to monitor the use of the Career Navig8r Platform and verify
        information provided by our Members, but has the right to review, disable
        access to, remove, or edit Content to: (i) operate, secure and improve
        the Career Navig8r Platform (including for fraud prevention, risk assessment,
        investigation and customer support purposes); (ii) ensure Members’ compliance
        with these Terms; (iii) comply with applicable law or the order or requirement
        of a court, law enforcement or other administrative agency or governmental
        body; (iv) address Member Content that we determine is harmful or objectionable;
        (v) take actions set out in these Terms; and (vi) maintain and enforce any
        quality or eligibility criteria, including by removing Listings that don’t
        meet quality and eligibility criteria. Where we remove or disable Content,
        we will notify a Member and provide the reasons for such a measure, unless
        such notification would (i) prevent or impede the detection or prevention
        of fraud or other illegal activities, (ii) harm the legitimate interests
        of other Members or third parties, or (iii) contravene applicable laws.
        You may appeal such a decision by contacting our customer service. Members
        agree to cooperate with and assist Career Navig8r in good faith, and to
        provide Career Navig8r with such information and take such actions as may
        be reasonably requested by Career Navig8r with respect to any investigation
        undertaken by Career Navig8r regarding the use or abuse of the Career
        Navig8r Platform.
      </p>

      <h3>17. Member Accounts.</h3>

      <p>
        You must register an account to access and use many features of the Career
        Navig8r Platform. Registration is only permitted for legal entities, partnerships
        and natural persons who are 18 years or older. You represent and warrant
        that you are not a person or entity barred from using the Career Navig8r
        Platform under the laws of the United States, your place of residence, or
        any other applicable jurisdiction. You must provide accurate, current, and
        complete information during registration and keep your account information
        up-to-date. You may not register more than one account or transfer your
        account to someone else. You are responsible for maintaining the confidentiality
        and security of your account credentials and may not disclose your credentials
        to any third party. You must immediately notify Career Navig8r if you suspect
        that your credentials have been lost, stolen, or your account is otherwise
        compromised. You are responsible and liable for activities conducted through
        your Career Navig8r Account, unless such activities are not authorised by
        you and you are not otherwise negligent (such as failing to report the unauthorised
        use or loss of your credentials). If and as permitted by applicable law,
        we may, but have no obligation to (i) ask you to provide identification
        or other information, (ii) undertake checks designed to help verify your
        identity or background, (iii) screen you against third-party databases or
        other sources and request reports from service providers, and (iv) obtain
        reports from public records of criminal convictions or sex oﬀender registrations
        or their local equivalents.
      </p>

      <h3>18. Disclaimer of Warranties.</h3>

      <p>
        We provide the Career Navig8r Platform and all Content “as is” without warranty
        of any kind and we disclaim all warranties, whether express or implied.
        For example: (i) we do not endorse or warrant the existence, conduct, performance,
        safety, quality, legality or suitability of any Mentee, Mentor, Mentor Service,
        Listing or third party; (ii) we do not warrant the performance or non-interruption
        of the Career Navig8r Platform; and (iii) we do not warrant that verification,
        identity or background checks conducted on Listings or Members (if any) will
        identify past misconduct or prevent future misconduct. Any references to
        a Member or Listing being "verified" (or similar language) indicate only
        that the Member or Listing or Career Navig8r has completed a relevant verification
        or identification process and nothing else. The disclaimers in these Terms
        apply to the maximum extent permitted by law. If you have statutory rights
        or warranties we cannot disclaim, the duration of any such statutorily required
        rights or warranties, will be limited to the maximum extent permitted by law.
      </p>

      <h3>19. Limitations on Liability.</h3>

      <p>
        Neither Career Navig8r (including its affliates and personnel) nor any other
        party involved in creating, producing, or delivering the Career Navig8r
        Platform or any Content will be liable for any incidental, special, exemplary
        or consequential damages, including lost profits, loss of data or loss of
        goodwill, service interruption, computer damage or system failure or the cost
        of substitute products or services, or for any damages for personal or bodily
        injury or emotional distress arising out of or in connection with (i) these
        Terms, (ii) the use of or inability to use the Career Navig8r Platform or
        any Content, (iii) any communications, interactions or meetings you may
        have with someone you interact or meet with through, or as a result of,
        your use of the Career Navig8r Platform, or (iv) publishing or booking of
        a Listing, including the provision or use of Mentor Services, whether based
        on warranty, contract, tort (including negligence), product liability or
        any other legal theory, and whether or not Career Navig8r has been informed
        of the possibility of such damage, even if a limited remedy set out in these
        Terms is found to have failed of its essential purpose.
      </p>

      <p>
        Except for our obligation to transmit payments to Mentors under these Terms,
        or make payments under the Career Navig8r Mentor Guarantee, in no event
        will Career Navig8r’s aggregate liability for any claim or dispute arising
        out of or in connection with these Terms, your interaction with any Members,
        or your use of or inability to use the Career Navig8r Platform, any Content,
        or any Mentor Service, exceed: (A) to Mentees, the amount you paid as a Mentee
        during the 12-month period prior to the event giving rise to the liability,
        (B) to Mentors, the amount paid to you as a Mentor in the 12-month period
        prior to the event giving rise to the liability, or (C) to anyone else, one
        hundred U.S. dollars (US$100).
      </p>

      <p>
        These limitations of liability and damages are fundamental elements of
        the agreement between you and Career Navig8r. If applicable law does not
        allow the limitations of liability set out in these Terms, the above limitations
        may not apply to you.
      </p>

      <h3>20. Indemnification.</h3>

      <p>
        To the maximum extent permitted by applicable law, you agree to release,
        defend (at Career Navig8r’s option), indemnify, and hold Career Navig8r
        (including Career Navig8r Payments, other affliates, and their personnel)
        harmless from and against any claims, liabilities, damages, losses, and
        expenses, including, without limitation, reasonable legal and accounting
        fees, arising out of or in any way connected with: (i) your breach of these
        Terms (including any supplemental or additional terms that apply to a product
        or feature) or our Policies or Standards, (ii) your improper use of the Career
        Navig8r Platform, (iii) your interaction with any Member, participation
        in an Experience or other Mentor Service, including without limitation any
        injuries, losses or damages (whether compensatory, direct, incidental,
        consequential or otherwise) of any kind arising in connection with or as
        a result of such interaction, stay, participation or use, (iv) your failure,
        or our failure at your direction, to accurately report, collect or remit
        Taxes, or (v) your breach of any laws, regulations or third party rights
        such as intellectual property or privacy rights.
      </p>

      <h3>21. Contracting Entities.</h3>

      <p>
        Based on your country of residence or establishment and what you are doing
        on the Career Navig8r Platform, Schedule 1 below sets out the Career Navig8r
        entity with whom you are contracting. If we identify through the Career Navig8r
        Platform, a Career Navig8r entity other than the one set out on Schedule
        1 as being responsible for a product, feature or transaction, the Career
        Navig8r entity so identified is your contracting entity with respect to
        that product, feature or transaction. If you change your country of residence
        or establishment to a country outside of the EEA, Switzerland or the United
        Kingdom, the Career Navig8r company you contract with and the applicable
        version of the Terms of Service will be determined by your new country of
        residence or establishment, from the date on which your country of residence
        or establishment changes.
      </p>

      <h3>22. United States Governing Law and Venue.</h3>

      <p>
        If you reside or have your place of establishment in the United States,
        these Terms will be interpreted in accordance with the laws of the State
        of California and the United States of America, without regard to conﬂict-of-law
        provisions. Judicial proceedings (other than small claims actions) that
        are excluded from the arbitration agreement in Section 23 must be brought
        in state or federal court in San Francisco, California, unless we both
        agree to some other location. You and we both consent to venue and personal
        jurisdiction in San Francisco, California.
      </p>

      <h3>23. United States Dispute Resolution and Arbitration Agreement.</h3>

      <p>
        23.1 Application. This Arbitration Agreement only applies to you if your
        country of residence or establishment is the United States. If your country
        of residence or establishment is not the United States, and you nevertheless
        attempt to bring any legal claim against Career Navig8r in the United States,
        this Arbitration Agreement will apply for determination of the threshold
        issue of whether this Section 24 applies to you, and all other threshold
        determinations, including residency, arbitrability, venue, and applicable law.
      </p>

      <p>
        23.2 Overview of Dispute Resolution Process. Career Navig8r is committed
        to participating in a consumer-friendly dispute resolution process. To
        that end, these Terms provide for a two-part process for individuals to
        whom this Section 24 applies: (1) an informal negotiation directly with
        Career Navig8r’s customer service team (described in paragraph 24.3, below),
        and if necessary (2) a binding arbitration administered by the American
        Arbitration Association (“AAA”). You and Career Navig8r each retain the right
        to seek relief in small claims court as an alternative to arbitration.
      </p>

      <p>
        23.3 Mandatory Pre-Arbitration Dispute Resolution and Notification. At least
        30 days prior to initiating an arbitration, you and Career Navig8r each
        agree to notify the other party of the dispute in writing and attempt in
        good faith to negotiate an informal resolution. You must send your notice
        of dispute to Career Navig8r by mailing it to Career Navig8r’s agent for
        service: admin@careernavig8r.com. Career Navig8r will send its notice of
        dispute to the email address associated with your Career Navig8r account.
        A notice of dispute must include: the party’s name and preferred contact
        information, a brief description of the dispute, and the relief sought.
        If the parties are unable to resolve the dispute within the 30-day period,
        only then may either party commence arbitration by filing a written Demand
        for Arbitration (available at www.adr.org) with the AAA and providing a copy
        to the other party as specified in the AAA Rules (available at www.adr.org).
      </p>

      <p>
        23.4 Agreement to Arbitrate. You and Career Navig8r mutually agree that
        any dispute, claim or controversy arising out of or relating to these Terms
        or the applicability, breach, termination, validity, enforcement or interpretation
        thereof, or any use of the Career Navig8r Platform, Mentor Services, or
        any Content (collectively, “Disputes”) will be settled by binding individual
        arbitration (the “Arbitration Agreement”). If there is a dispute about whether
        this Arbitration Agreement can be enforced or applies to our Dispute, you
        and Career Navig8r agree that the arbitrator will decide that issue.
      </p>

      <p>
        23.5 Exceptions to Arbitration Agreement. You and Career Navig8r each agree
        that the following causes of action and/or claims for relief are exceptions
        to the Arbitration Agreement and will be brought in a judicial proceeding
        in a court of competent jurisdiction (as defined by Section 22 of the Terms
        of Service for Non-European Users): (i) any claim or cause of action alleging
        actual or threatened infringement, misappropriation or violation of a party’s
        copyrights, trademarks, trade secrets, patents, or other intellectual property
        rights; (ii) any claim or cause of action seeking emergency injunctive relief
        based on exigent circumstances (e.g., imminent danger or commission of a crime,
        hacking, cyber-attack); or (iii) a request for the remedy of public injunctive
        relief. You and Career Navig8r agree that the remedy of public injunctive
        relief will proceed after the arbitration of all arbitrable claims, remedies,
        or causes of action, and will be stayed pending the outcome of the arbitration
        pursuant to section 3 of the Federal Arbitration Act.
      </p>

      <p>
        23.6 Arbitration Rules and Governing Law. This Arbitration Agreement evidences
        a transaction in interstate commerce and the Federal Arbitration Act governs
        all substantive and procedural interpretation and enforcement of this provision.
        The arbitration will be administered by AAA in accordance with the Consumer
        Arbitration Rules and/or other AAA arbitration rules determined to be applicable
        by the AAA (the “AAA Rules “) then in eﬀect, except as modified here. The
        AAA Rules are available at www.adr.org. In order to initiate arbitration,
        a completed written demand (available at www.adr.org) must be filed with
        the AAA and provided to the other party, as specified
      </p>

      <p>
        23.7 Modification of AAA Rules - Attorney’s Fees and Costs. Your arbitration
        fees and your share of arbitrator compensation shall be governed by the
        AAA Rules and, where appropriate, limited by the AAA Consumer Rules. Either
        party may make a request that the arbitrator award attorneys’ fees and costs
        upon proving that the other party has asserted a claim, cross-claim or defence
        that is groundless in fact or law, brought in bad faith or for the purpose
        of harassment, or is otherwise frivolous, as allowed by applicable law and
        the AAA Rules.
      </p>

      <p>
        23.8 Arbitrator’s Decision. The arbitrator’s decision will include the essential
        findings and conclusions upon which the arbitrator based the award. Judgement
        on the arbitration award may be entered in any court with proper jurisdiction.
        The arbitrator may award any relief allowed by law or the AAA Rules, but
        declaratory or injunctive relief may be awarded only on an individual basis
        and only to the extent necessary to provide relief warranted by the claimant’s
        individual claim.
      </p>

      <p>
        23.9 Jury Trial Waiver. You and Career Navig8r acknowledge and agree that
        we are each waiving the right to a trial by jury as to all arbitrable Disputes.
      </p>

      <p>
        23.10 No Class Actions or Representative Proceedings. You and Career Navig8r acknowledge
        and agree that, to the fullest extent permitted by law, we are each waiving
        the right to participate as a plaintiﬀ or class member in any purported
        class action lawsuit, class-wide arbitration, private attorney general action,
        or any other representative or consolidated proceeding. Unless we agree in
        writing, the arbitrator may not consolidate more than one party’s claims
        and may not otherwise preside over any form of any class or representative
        proceeding. If there is a final judicial determination that applicable law
        precludes enforcement of the waiver contained in this paragraph as to any
        claim, cause of action or requested remedy, then that claim, cause of action
        or requested remedy, and only that claim, cause of action or requested remedy,
        will be severed from this agreement to arbitrate and will be brought in
        a court of competent jurisdiction. In the event that a claim, cause of action
        or requested remedy is severed pursuant to this paragraph, then you and
        we agree that the claims, causes of action or requested remedies that are
        not subject to arbitration will be stayed until all arbitrable claims, causes
        of action and requested remedies are resolved by the arbitrator.
      </p>

      <p>
        23.11 Severability. Except as provided in Section 24.11, in the event that
        any portion of this Arbitration Agreement is deemed illegal or unenforceable,
        such provision will be severed and the remainder of the Arbitration Agreement
        will be given full force and eﬀect.
      </p>

      <p>
        23.12 Changes to Agreement to Arbitrate. If Career Navig8r changes this
        Section 24 after the date you last accepted these Terms (or accepted any
        subsequent changes to these Terms), you may reject that change by sending
        us written notice (including by email) within 30 days of the date the change
        is eﬀective. Rejecting a new change, however, does not revoke or alter your
        prior consent to any earlier agreements to arbitrate any Dispute between
        you and Career Navig8r (or your prior consent to any subsequent changes
        thereto), which will remain in eﬀect and enforceable as to any Dispute
        between you and Career Navig8r.
      </p>

      <p>
        23.13 Survival. Except as provided in Section 23.12, and subject to Section
        13.6 of the Terms of Service for Non-European Users this Section 24 will
        survive any termination of these Terms and will continue to apply even if
        you stop using the Career Navig8r Platform or terminate your Career Navig8r
        Account.
      </p>

      <h3>24. China Governing Law and Dispute Resolution.</h3>

      <p>
        24.1 China Domestic Transactions. If you reside or have your place of establishment
        in China, and are contracting with Career Navig8r China, these Terms and
        this Section 24.1 are governed by the laws of the People’s Republic of China.
        In this situation, any dispute arising from or in connection with these Terms
        or use of the Career Navig8r Platform shall be submitted to the China International
        Economic and Trade Arbitration Commission (“CIETAC”) for arbitration which
        shall be conducted in accordance with the Commission’s arbitration rules
        in eﬀect at the time of applying for arbitration. The arbitral award is
        final and binding upon both parties. The tribunal shall consist of three
        (3) arbitrators. The seat of the arbitration shall be Beijing. The language
        of the arbitration shall be English.
      </p>

      <p>
        24.2 Cross-border Transactions. If you reside or have your place of establishment
        in China, and are contracting with Career Navig8r, Inc., Career Navig8r
        Travel, LLC, Career Navig8r Ireland UC, or any other non-China entity, these
        Terms and this Section 24.2 are governed by the laws of Singapore. In this
        situation, any dispute arising out of or in connection with these Terms or
        use of the Career Navig8r Platform, including any question regarding the existence,
        validity or termination of these Terms, shall be referred to and finally
        resolved by arbitration administered by the Singapore International Arbitration
        Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore
        International Arbitration Centre (“SIAC Rules”) for the time being in force,
        which rules are deemed to be incorporated by reference in this clause.
        The seat of the arbitration shall be Singapore. The Tribunal shall consist
        of three (3) arbitrators. The language of the arbitration shall be English.
      </p>

      <p>
        24.3 Without Limitation Provisions. The above Sections 24.1 and 24.2 are
        expressed to be without regard to conﬂict of law provisions and shall not
        be construed to limit any rights which Career Navig8r may have to apply
        to any court of competent jurisdiction for any order requiring you to perform
        or be prohibited from performing certain acts and other provisional relief
        permitted under the laws of Singapore, the People’s Republic of China, or
        any other laws that may apply to you.
      </p>

      <p>
        25. Rest of World Dispute Resolution, Venue and Forum, and Governing Law.
        If you reside or have your place of establishment outside of the United
        States and China, this Section applies to you and these Terms will be interpreted
        in accordance with Irish law. The application of the United Nations Convention
        on Contracts for the International Sale of Goods (CISG) is excluded. The choice
        of law does not impact your rights as a consumer according to the consumer
        protection regulations of your country of residence. If you are acting
        as a consumer, you agree to submit to the non-exclusive jurisdiction of
        the Irish courts. Legal proceedings that you are able to bring against us
        arising from or in connection with these Terms may only be brought in a court
        located in Ireland or a court with jurisdiction in your place of residence.
        If Career Navig8r wishes to enforce any of its rights against you as a consumer,
        we may do so only in the courts of the jurisdiction in which you are a resident.
        If you are acting as a business, you agree to submit to the exclusive jurisdiction
        of the Irish courts.
      </p>

      <p>
        24.1 China Domestic Transactions. If you reside or have your place of establishment
        in China, and are contracting with Career Navig8r China, these Terms and
        this Section 24.1 are governed by the laws of the People’s Republic of China.
        In this situation, any dispute arising from or in connection with these
        Terms or use of the Career Navig8r Platform shall be submitted to the China
        International Economic and Trade Arbitration Commission (“CIETAC”) for arbitration
        which shall be conducted in accordance with the Commission’s arbitration
        rules in eﬀect at the time of applying for arbitration. The arbitral award
        is final and binding upon both parties. The tribunal shall consist of three
        (3) arbitrators. The seat of the arbitration shall be Beijing. The language
        of the arbitration shall be English.
      </p>

      <h3>26. Miscellaneous.</h3>

      <p>
        26.1 Other Terms Incorporated by Reference. Our Mentor Terms, Arbitration
        Policy, Community Guidelines, Cancellation Policy, Policies, Standards and
        other supplemental policies and terms linked to in these Terms apply to your
        use of the Career Navig8r Platform, are incorporated by reference, and form
        part of your agreement with Career Navig8r.
      </p>

      <p>
        26.2 Interpreting these Terms. Except as they may be supplemented by additional
        terms, conditions, policies, guidelines, standards, and in-product disclosures,
        these Terms (including those items incorporated by reference) constitute
        the entire agreement between Career Navig8r and you pertaining to your access
        to or use of the Career Navig8r Platform and supersede any and all prior
        oral or written understandings or agreements between Career Navig8r and
        you. These Terms do not and are not intended to confer any rights or remedies
        upon anyone other than you and Career Navig8r. If any provision of these
        Terms is held to be invalid or unenforceable, except as otherwise indicated
        in Section 23.11 above, such provision will be struck and will not aﬀect
        the validity and enforceability of the remaining provisions. Where the word
        “will” is used in these Terms it connotes an obligation with the same
        meaning as “shall.”
      </p>

      <p>
        26.3 No Waiver. Career Navig8r’s failure to enforce any right or provision
        in these Terms will not constitute a waiver of such right or provision unless
        acknowledged and agreed to by us in writing. Except as expressly set forth
        in these Terms, the exercise by either party of any of its remedies under
        these Terms will be without prejudice to its other remedies under these
        Terms or otherwise permitted under law.
      </p>

      <p>
        26.4 Assignment. You may not assign, transfer or delegate this agreement
        or your rights and obligations hereunder without Career Navig8r's prior
        written consent. Career Navig8r may without restriction assign, transfer
        or delegate this agreement and any rights and obligations hereunder, at
        its sole discretion, with 30 days’ prior notice.
      </p>

      <p>
        26.5 Notice. Unless specified otherwise, any notices or other communications
        to Members permitted or required under this agreement, will be provided
        electronically and given by Career Navig8r via email, Career Navig8r Platform
        notification, messaging service (including SMS and WeChat), or any other
        contact method we enable and you provide
      </p>

      <p>
        26.6 Third-Party Services. The Career Navig8r Platform may contain links
        to third-party websites, applications, services or resources (“Third-Party
        Services”) that are subject to diﬀerent terms and privacy practices. Career
        Navig8r is not responsible or liable for any aspect of such Third-Party
        Services and links to such Third-Party Services are not an endorsement.
      </p>

      <p>
        26.7 Google Terms. Some translations on the Career Navig8r Platform are
        powered by Google. Google disclaims all warranties related to the translations,
        express or implied, including any warranties of accuracy, reliability, and
        any implied warranties for merchantability, fitness for a particular purpose
        and non-infringement. Some areas of the Career Navig8r Platform implement
        Google Maps/Earth mapping services, including Google Maps API(s). Your use
        of Google Maps/Earth is subject to the Google Maps/Google Earth Additional
        Terms of Service.
      </p>

      <p>
        26.8 Apple Terms. If you access or download our application from the Apple
        App Store, you agree to Apple’s Licensed Application End User Licence Agreement.
      </p>

      <p>
        26.9 Career Navig8r Platform Content. Content made available through the Career
        Navig8r Platform may be protected by copyright, trademark, and/or other laws
        of the United States and other countries. You acknowledge that all intellectual
        property rights for that Content are the exclusive property of Career Navig8r
        and/or its licensors and agree that you will not remove, alter or obscure
        any copyright, trademark, service mark or other proprietary rights notices.
        You may not use, copy, adapt, modify, prepare derivative works of, distribute,
        licence, sell, transfer, publicly display, publicly perform, transmit, broadcast
        or otherwise exploit any Content accessed through the Career Navig8r Platform
        except to the extent you are the legal owner of that Content or as expressly
        permitted in these Terms. Subject to your compliance with these Terms, Career
        Navig8r grants you a limited, non-exclusive, non-sublicensable, revocable,
        non-transferable licence to (i) download and use the Application on your
        personal device(s); and (ii) access and view the Content made available on
        or through the Career Navig8r Platform and accessible to you, solely for
        your personal and non-commercial use.
      </p>

      <p>
        26.10 Force Majeure. Career Navig8r shall not be liable for any delay or
        failure to perform resulting from causes outside its reasonable control,
        including, but not limited to, acts of God, war, terrorism, riots, embargoes,
        acts of civil or military authorities, fire, ﬂoods, accidents, epidemics
        or disease, strikes or shortages of transportation facilities, fuel, energy,
        labour or materials.
      </p>

      <p>
        26.11 Emails and SMS. You will receive administrative communications from
        us using the email address or other contact information you provide for
        your Career Navig8r account. Enrolment in additional email subscription
        programs will not aﬀect the frequency of these administrative emails, though
        you should expect to receive additional emails specific to the program(s)
        to which you have subscribed. You may also receive promotional emails from
        us. No fee is charged for these promotional emails, but third-party data
        rates could apply. In the U.S. if you consent to receive SMS (text messages)
        from us, you will be subject to our SMS Terms.
      </p>

      <p>
        Contact Us. If you have any questions about these Terms, please email us
        at admin@careernavig8r.com.
      </p>

      <p>
        Schedule 1 - Contracting Entities
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
