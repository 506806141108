import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionAboutMission.module.css';

import rightImage from './images/right-image.png';

const SectionAboutMission = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionAboutMission.title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionAboutMission.text" />
          </p>
        </div>

        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <NamedLink name="AboutPage" to={{ hash: '#sectionAboutMission' }}>
              <img
                className={css.explanationPicture}
                src={rightImage}
              />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionAboutMission.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionAboutMission.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionAboutMission;
