import React, { Component } from 'react';
import { compose } from 'redux';
import { string, func } from 'prop-types';
import truncate from 'lodash/truncate';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, ExternalLink } from '../../components';

import css from './TransactionCard.module.css';

import linkedinIconImage from './images/ion_logo-linkedin.png';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MAX_DESCRIPTION_LENGTH = 300;
const DESCRIPTION_OMISSION_SUFFIX = '...';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'TransactionCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'TransactionCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getCurrentPosition = workExperience => {
  let currentPosition = null;

  if( Array.isArray( workExperience )){
    workExperience.forEach( experienceRecord => {
      const isCurPos = experienceRecord.isCurrentPosition;

      if( Array.isArray( isCurPos ) && isCurPos.length === 1 && isCurPos[ 0 ] === "current"){
        currentPosition = {
          organisation: experienceRecord.organisation,
          category: experienceRecord.category
        };
      }
    });
  }

  return currentPosition;
};

export const TransactionCardComponent = props => {
  const {
    className, rootClassName, intl, viewport, listing, renderSizes, setActiveListing
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description, price, publicData = {}} = currentListing.attributes;
  const {
    type: listingType, numberOfReviews = 0, averageRating = 0, workExperience = []
  } = publicData;
  const currentPosition = getCurrentPosition( workExperience );
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const authorImage = author.profileImage ? author.profileImage : null;
  const authorPublicData = author.attributes.profile.publicData;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'TransactionCard.perNight'
    : isDaily
    ? 'TransactionCard.perDay'
    : 'TransactionCard.perUnit';

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters );
  const currentPositionCategory = currentPosition ?
    categoryOptions.find( categoryOption => currentPosition.category === categoryOption.key ) :
    null;
  const currentPositionCategoryLabel =
    currentPositionCategory ? currentPositionCategory.label : null;

  const numberOfVisibleSectors = typeof window === 'object' && window.innerWidth >= 768 ? 2 : 1;
  const sectors = workExperience.map( workRecord => {
    const option =
      categoryOptions.find(categoryOption => workRecord.category === categoryOption.key);

    return option ? option.label : null;
  });

  const sectorsList = (
    <>
      { sectors.map(( sector, index ) =>
        <div className={css.sectorName} key={`visibleSector-${index}-`}>{sector}</div>
      )}
    </>
  );

  const linkedinLink = authorPublicData.linkedinLink ? (
    <ExternalLink href={authorPublicData.linkedinLink}>
      <img src={linkedinIconImage}/>
    </ExternalLink>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.threeToTwoWrapper} >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={authorName}
          image={authorImage}
          variants={['landscape-crop', 'landscape-crop2x']}
          sizes={renderSizes}
        />
      </div>
      <div className={css.info}>
        <div className={css.nameInfo}>
          <div className={css.authorName}>
            {authorName}
          </div>
          {linkedinLink}
        </div>
        { currentPosition ? (
          <div className={css.currentPosition}>
            {currentPositionCategoryLabel} @ {currentPosition.organisation}
          </div>
        ) : null }
      </div>
    </div>
  );
};

TransactionCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

TransactionCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default compose( withViewport, injectIntl )(TransactionCardComponent);
