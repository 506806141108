import React from 'react';
import { required } from '../../util/validators';
import { FieldSelectEdit } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CustomJobRoleSelectField = props => {
  const { name, id, selectedValue, jobRoles, onChange, intl } = props;

  const jobRolePlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.titlePlaceholder',
  });
  const jobRoleRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.titleRequired',
    })
  );

  const selectOptions = jobRoles.map( jobRole => {
    return {
      value: jobRole.key,
      label: jobRole.label,
    };
  });

  return jobRoles ? (
    <FieldSelectEdit
      className={css.title}
      name={name}
      id={id}
      selectedValue={selectedValue}
      placeholder={jobRolePlaceholder}
      validate={required(jobRoleRequired)}
      onChange={onChange}
      options={selectOptions}
    />
  ) : null;
};

export default CustomJobRoleSelectField;
