import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2292_11003)">
        <path d="M8.00001 -0.0117188C12.4247 -0.0117188 16.0113 3.57489 16.0113 7.99961C16.0113 12.4243 12.4247 16.0109 8.00001 16.0109C3.57529 16.0109 -0.011322 12.4243 -0.011322 7.99961C-0.011322 3.57489 3.57529 -0.0117188 8.00001 -0.0117188ZM8.00001 1.59048C4.46044 1.59048 1.59074 4.45997 1.59074 7.99975C1.59074 11.5395 4.46023 14.409 8.00001 14.409C11.5398 14.409 14.4093 11.5395 14.4093 7.99975C14.4093 4.45997 11.5398 1.59048 8.00001 1.59048ZM8.00001 6.39735C8.41082 6.39735 8.74947 6.57774 8.79575 6.81015L8.80111 6.86474V11.538C8.80111 11.7962 8.44249 12.0054 8.00002 12.0054C7.5892 12.0054 7.25056 11.8251 7.20427 11.5926L7.19891 11.538V6.86475C7.19891 6.60656 7.55754 6.39735 8.00001 6.39735ZM8.00001 3.99391C8.44248 3.99391 8.80111 4.35268 8.80111 4.79515C8.80111 5.23762 8.44248 5.59625 8.00001 5.59625C7.55754 5.59625 7.19891 5.23762 7.19891 4.79515C7.19891 4.35268 7.55754 3.99391 8.00001 3.99391Z" fill="#9C9C9C"/>
      </g>
      <defs>
        <clipPath id="clip0_2292_11003">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

InfoIcon.defaultProps = {
  className: null,
};

InfoIcon.propTypes = {
  className: string,
};

export default InfoIcon;
