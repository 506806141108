import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { NamedLink, SectionFaqContent } from '../../components';
import classNames from 'classnames';

import css from './SectionFAQ.module.css';

const SectionFAQComponent = props => {
  const { rootClassName, className, intl, isLandingPage } = props;


  const classes = classNames(rootClassName || css.root, className, {[css.rootHomepage]: isLandingPage});

  return (
    <div className={classes}>
      <div className={css.content}>

        <h2 className={css.title}>
          <FormattedMessage id="SectionFAQ.title" />
        </h2>

        <SectionFaqContent
          intl={intl}
          withoutSidebar
        />
      </div>
    </div>
  );
};

SectionFAQComponent.defaultProps = { rootClassName: null, className: null };

SectionFAQComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionFAQ = compose(
  injectIntl
)(SectionFAQComponent);

export default SectionFAQ;
