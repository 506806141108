import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionAboutContent.module.css';

import illustrationImage from './images/illustration.png';

const SectionAboutContent = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepSubtitle}>
            The why of Career Navig8r
          </h2>
          <p className={css.stepText}>
            Unfulfilled potential and wasted experience lie at the heart of our mission.<br/>
            They’re the reason we exist.
          </p>
          <p className={css.stepText}>
            The founder of Career Navig8r Ken watched his sister
            “the most competent & able person I know” become frustrated at her
            inability to find an outlet for all of her competence, experience & knowledge.<br/>
            And his father who, once he’d retired, struggled to find an outlet
            for the vast amount of skills, talent & knowledge he had.
          </p>
          <p className={css.stepText}>
            Both became frustrated, and Ken wanted to find a way for his sister,
            father and virtually anybody else.<br/>
            Anywhere in the world.<br/>
            To be able to mentor people who need it.<br/>
          </p>
          <p className={css.stepText}>
            Ken wanted to harness all of the knowledge - not just from his family.<br/>
            From everyone - anywhere in the world.
          </p>
          <p className={css.stepText}>
            The desire was there. We all know the need for mentors (or Navig8rs) exists.
          </p>
          <p className={css.stepText}>
            Finally–the technology is here too.<br/>
            The Career Navig8r platform is the first of its kind that allows people
            looking <b>for a mentor</b>, to talk to people looking to <b>be a mentor</b>.
          </p>
          <p className={css.stepText}>
            The platform allows you to create a mentor profile, or, browse
            mentor profiles, and find the person who has excelled in a job role.<br/>
            That’s what makes CareerNavig8r different.
          </p>
          <p className={css.stepText}>
            There are plenty of places you can find a mentor or trainer.
          </p>
          <p className={css.stepText}>
            But only one where you can find a mentor, or be a mentor, for a specific job role.<br/>
            We offer specific - defined - development.
          </p>
          <p className={css.stepText}>
            This leads you nicely to our purpose, vision and values…
          </p>

          <div className={css.stepIllustration}>
            <img src={illustrationImage} className={css.illustration} />
          </div>

          <h2 className={css.stepSubtitle2}>
            Vision & values - what’s our purpose?
          </h2>
          <p className={css.stepText}>
            Simply - We believe that mentoring is most effective when your mentor&nbsp;
            <b>has already succeeded in the job you’re being mentored for.</b>
          </p>
          <div className={css.middleTextBlock}>
            <p className={css.stepText}>
              <i><b>“We aim to be the world leader in connecting professionals to mentors”</b></i>
            </p>
            <p className={css.stepText}>
              There are a few things we believe that shape our vision.<br/>
              <b>We Believe:</b><br/>
              + Anyone can mentor anyone who is on the same career path<br/>
              + The exchange of knowledge and expertise should be easily accessible<br/>
              + Mentoring should be an ongoing process in everyone’s career development<br/>
              + Mentoring is at its most effective when your mentor has already succeeded in your job
            </p>
            <p className={css.stepText}>
              So what does all that lead to?<br/>
              We’re going to improve the mentoring space<br/>
              We’re going to unleash people’s true potential. Both mentees and Navig8rs.
            </p>
            <p className={css.stepText}>
              And how will we do that?<br/>
              <b>Do great work</b><br/>
              We achieve success by working as one to improve the space of mentoring.<br/>
              <b>Value our Mentors and Mentees equally</b><br/>
              We thrive on transparency, mutual respect, and constructive feedback.<br/>
              <b>Be open-minded</b><br/>
              Try new things, test, fail, tweak and learn.<br/>
              <b>Promote growth and happiness</b><br/>
              Our success depends on the collective growth and happiness of our team, our Mentors, and our Mentees.
            </p>
          </div>
          <p className={css.stepText}>
            Our unique platform enables anyone to become a mentor. It also helps
            mentees find their own career navig8r who will help them improve or
            advance their career.<br/><br/>
            Mentees will find, connect, and work with mentors (they choose) who
            have already excelled at their dream job.
          </p>
          <p className={css.stepText}>
            &nbsp;
          </p>
          <p className={css.stepText}>
            <div className={css.stepTldrSubtitle}>TL;dr</div>
            <i>
              Our brand new platform is the only one of its kind that matches people
              looking to advance their career–with people who have already been
              successful in that role.<br/>
              Anyone can sign up as a mentee OR as a mentor.<br/>
              We give equal value to both.<br/>
            </i>
          </p>
        </div>
      </div>
    </div>
  );
};

SectionAboutContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionAboutContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionAboutContent;
