import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '../../components';
import { icons } from './images/icons';

import css from './SectionYouCan.module.css';

const SectionYouCanComponent = props => {
  const { rootClassName, className, isLandingPage, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const cardsData = [
    {
      image: icons?.icon1,
      text: intl.formatMessage({ id: 'SectionYouCan.cardItem1' }),
    },
    {
      image: icons?.icon2,
      text: intl.formatMessage({ id: 'SectionYouCan.cardItem2' }),
    },
    {
      image: icons?.icon3,
      text: intl.formatMessage({ id: 'SectionYouCan.cardItem3' }),
    },
    {
      image: icons?.icon4,
      text: intl.formatMessage({ id: 'SectionYouCan.cardItem4' }),
    },
  ];

  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionYouCan.title" />
        </h2>
        <ul className={css.cards}>
          {cardsData.map((item, i) => {
            return (
              <li className={css.cardsItem} key={`item-${i}`}>
                {item.image}
                <p className={css.cardsItemText}>
                  {item.text}
                </p>
              </li>
            )
          })}
        </ul>

        <p className={css.subTitleMobile}>
          <FormattedMessage id="SectionYouCan.subTitle" />
        </p>

        <NamedLink
          name="SearchPage"
          className={classNames(css.button)}
        >
          <FormattedMessage id="SectionYouCan.button" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionYouCanComponent.defaultProps = { rootClassName: null, className: null };

SectionYouCanComponent.propTypes = {
  rootClassName: string,
  className: string,
};


const SectionYouCan = injectIntl(SectionYouCanComponent);

export default SectionYouCan;
