import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import plusSignImage from './images/plus-sign.png';
import minusSignImage from './images/minus-sign.png';
import { itData, hrData, financeData } from './accordionData.js'

import css from './Accordion.module.css';

const AccordionIT = props => {
  const { rootClassName, className, urlQueryParams } = props;

  const isIT = urlQueryParams?.pub_category === 'information_technology';
  const isHR = urlQueryParams?.pub_category === 'recruitment_and_hr';
  const isFinance = urlQueryParams?.pub_category === 'accountancy_banking_and_finance';

  if(!isIT && !isHR && !isFinance) return null

  const [accordionIndex, setAccordionIndex] = useState(0);

  const onAccordionClick = (index) => {
    if (index === accordionIndex) setAccordionIndex(-1)
    else setAccordionIndex(index)
  }

  let data

  if(isIT) data = itData
  else if (isHR) data = hrData
  else if (isFinance) data = financeData

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <h2 className={css.title}>
        {data?.mainTitle}
      </h2>

      <div className={css.accordion}>
        {data?.accordionData?.map((data, i) => {
          return (
            <div className={classNames(css.accordionItem, { [css.accordionItemActive]: accordionIndex === i })}>
              <h3 className={css.accordionTitle} onClick={() => onAccordionClick(i)}>
                {data.title}
                <img src={accordionIndex === i ? minusSignImage : plusSignImage} />
              </h3>
              {data.description.map(p => {
                return <p className={css.accordionDescription}>{p}</p>
              })}
            </div>
          )
        })}
      </div>
    </div>
  );
};

AccordionIT.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

AccordionIT.propTypes = {
  rootClassName: string,
  className: string,
};

export default AccordionIT;
