import React, { Component } from 'react';
import { compose } from 'redux';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import config from '../../config';
import { NamedLink, ExternalLink, ResponsiveImage } from '../../components';

import css from './MenteeProfileCard.module.css';

import linkedinIconImage from './images/ion_logo-linkedin.png';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MAX_DESCRIPTION_LENGTH = 90;
const DESCRIPTION_OMISSION_SUFFIX = '...';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getCurrentPosition = workExperience => {
  let currentPosition = null;

  if( Array.isArray( workExperience )){
    workExperience.forEach( experienceRecord => {
      const isCurPos = experienceRecord.isCurrentPosition;

      if( Array.isArray( isCurPos ) && isCurPos.length === 1 && isCurPos[ 0 ] === "current"){
        currentPosition = {
          organisation: experienceRecord.organisation,
          category: experienceRecord.category
        };
      }
    });
  }

  return currentPosition;
};

export const MenteeProfileCardComponent = props => {
  const {
    className, rootClassName, intl, user, renderSizes,
    currentUser, isOwnListing, currentAuthor,
    setActiveListing, openEnquiryDialog
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const { publicData = {}} = currentAuthor.attributes.profile;
  const {
    type: listingType, numberOfReviews = 0, averageRating = 0, workExperience = []
  } = publicData;
  const author = ensureUser( user );
  const authorName = author.attributes.profile.displayName;
  const authorPublicData = author.attributes.profile.publicData || {};
  const authorImage = author.profileImage ? author.profileImage : null;

  const linkedinLink = authorPublicData.linkedinLink ? (
    <ExternalLink href={authorPublicData.linkedinLink}>
      <img src={linkedinIconImage}/>
    </ExternalLink>
  ) : null;

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(null)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={authorName}
            image={authorImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.mainInfo}>
        <div className={css.authorName}>
          {authorName}
          {linkedinLink}
        </div>
      </div>
      { user && currentUser && user.id.uuid === currentUser.id.uuid ? (
        <div className={css.ownListingMessage}>
          <FormattedMessage id="MenteeProfileCard.ownListingMessage" />
        </div>
      ) : (
        <>
          <div
            className={css.askMeButton}
            onClick={openEnquiryDialog}
          >
            <FormattedMessage id="MenteeProfileCard.askMeButton" />
          </div>
        </>
      )}
    </div>
  );
};

Component.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

MenteeProfileCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default compose( withViewport, injectIntl )( MenteeProfileCardComponent);
