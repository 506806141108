import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './ConfirmSignupForm.module.css';

import checkboxTickedImage from './checkbox_ticked.png';
import checkboxUntickedImage from './checkbox_unticked.png';

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => {
  const [ conditionsTicked, setConditionsTicked ] = useState( false );

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenPrivacyPolicy,
          authInfo,
          idp,
        } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // LinkedIn link
        const linkedinPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.linkedinPlaceholder',
        });

        // Mobile phone number
        const mobileNumberPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.mobileNumberPlaceholder',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !conditionsTicked;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="ConfirmSignupForm.termsOfServiceLinkText" />
          </span>
        );

        const handlePrivacyKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenPrivacyPolicy();
          }
        };
        const privacyLink = (
          <span
            className={css.termsLink}
            onClick={onOpenPrivacyPolicy}
            role="button"
            tabIndex="1"
            onKeyUp={handlePrivacyKeyUp}
          >
            <FormattedMessage id="ConfirmSignupForm.privacyPolicyLinkText" />
          </span>
        );

        // If authInfo is not available we should not show the ConfirmForm
        if (!authInfo) {
          return;
        }

        // Initial values from idp provider
        const { email, firstName, lastName } = authInfo;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                className={css.linkedin}
                type="linkedinLink"
                id={formId ? `${formId}.linkedinLink` : 'linkedinLink'}
                name="linkedinLink"
                autoComplete="text"
                placeholder={linkedinPlaceholder}
              />
              <FieldTextInput
                className={css.mobileNumber}
                type="mobileNumber"
                id={formId ? `${formId}.mobileNumber` : 'mobileNumber'}
                name="mobileNumber"
                autoComplete="text"
                placeholder={mobileNumberPlaceholder}
              />
              <FieldTextInput
                type="hidden"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                placeholder={emailPlaceholder}
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.firstNameRoot}
                type="hidden"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="hidden"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <img
                  className={css.checkboxImage}
                  src={ conditionsTicked ? checkboxTickedImage : checkboxUntickedImage }
                  onClick={() => setConditionsTicked( !conditionsTicked )}
                />
                <span className={css.termsText}>
                    <FormattedMessage
                      id="ConfirmSignupForm.termsAndConditionsAcceptText"
                      values={{ termsOfService: termsLink, privacyPolicy: privacyLink }}
                    />
                </span>
              </p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
};

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
