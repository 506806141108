import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SectionSimpleSignup.module.css';

const NUMBER_OF_POINTS = 3;

const SectionSimpleSignup = props => {
  const { rootClassName, className, currentUser } = props;

  const [selectedPoint, setSelectedPoint] = useState(1);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setSelectedPoint(preIndex => preIndex < NUMBER_OF_POINTS ? preIndex + 1 : 1);
    },
      1500);

    return () => clearInterval(intervalID);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>

        <div className={css.contentItem}>
          <h2 className={css.title}>
            <FormattedMessage
              id="SectionSimpleSignup.title"
              values={{ lineBreak: <br /> }}
            />
          </h2>

          <div className={css.explanationStepBlocks}>
            <div className={css.explanationItemBlock}>
              <div className={css.stepItemRow}>
                <div className={css.stepItemActiveFigure}>
                  1
                </div>
                <div className={css.stepItemActiveText}>
                  <FormattedMessage id="SectionSimpleSignup.step1" />
                </div>
              </div>
              <div className={css.stepItemPipe}>&nbsp;</div>
              <div className={css.stepItemRow}>
                <div className={selectedPoint >= 2 ? css.stepItemActiveFigure : css.stepItemFigure}>
                  2
                </div>
                <div className={selectedPoint >= 2 ? css.stepItemActiveText : css.stepItemText}>
                  <FormattedMessage id="SectionSimpleSignup.step2" />
                </div>
              </div>
              <div className={css.stepItemPipe}>&nbsp;</div>
              <div className={css.stepItemRow}>
                <div className={selectedPoint >= 3 ? css.stepItemActiveFigure : css.stepItemFigure}>
                  3
                </div>
                <div className={selectedPoint >= 3 ? css.stepItemActiveText : css.stepItemText}>
                  <FormattedMessage id="SectionSimpleSignup.step3" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={css.contentItem}>
          <div className={css.videoWrapper}>
            <iframe
              key="qfkhbnqc0og"
              width="526"
              height="296"
              src={"https://www.youtube.com/embed/qfkhbnqc0og"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>

        {!currentUser && (
          <NamedLink
            name="SignupPage"
            to={{
              search: 'userType=mentor',
            }}
            className={classNames(css.button)}
          >
            <FormattedMessage id="SectionSimpleSignup.button" />
          </NamedLink>
        )}

      </div>
    </div>
  );
};

SectionSimpleSignup.defaultProps = { rootClassName: null, className: null };

SectionSimpleSignup.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionSimpleSignup);
