import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

import unrollIconImage from './images/unwrap-section-icon.png';
import rollIconImage from './images/wrap-section-icon.png';

const SectionExperience = props => {
  const [isUnrolled, setUnrolled] = useState(false);

  const { workExperience } = props;

  const jobRoleOptions = config.custom.jobRoles;

  const findJobRoleOption = jobRoleKey =>
    jobRoleOptions.find(jobRoleOption => jobRoleOption.key === jobRoleKey) ||
    { key: jobRoleKey, label: '' };


  return workExperience ? (
    <div className={css.sectionDescription}>
      <img
        src={isUnrolled ? rollIconImage : unrollIconImage}
        className={css.rollUnrollIcon}
        onClick={() => setUnrolled(!isUnrolled)}
      />
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.experienceTitle" />
      </h2>
      <div className={isUnrolled ? css.unrolledExperienceRecords : css.experienceRecords}>
        {workExperience.map(experienceRecord => (
          <>
            <div className={css.workRecord}>
              <div className={css.jobRoleLabel}>
                {findJobRoleOption(experienceRecord.title).label}
              </div>
              <div className={css.organisationName}>
                &nbsp;in {experienceRecord.organisation}
              </div>
            </div>

            {!experienceRecord.endDate || isNaN(experienceRecord.endDate) ? (
              <div className={css.organizationTimeline}>
                From {(new Date(experienceRecord.startDate)).toLocaleDateString("en-UK", { year: 'numeric', month: 'long' })}
                &nbsp;to <FormattedMessage id="ListingPage.experiencePresent" />
              </div>
            ) : (
              <div className={css.organizationTimeline}>
                From {(new Date(experienceRecord.startDate)).toLocaleDateString("en-UK", { year: 'numeric', month: 'long' })}
                &nbsp;to {(new Date(experienceRecord.endDate)).toLocaleDateString("en-UK", { year: 'numeric', month: 'long' })}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  ) : null;
};

export default SectionExperience;
