import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  FieldCheckbox,
  JobRoleSuggestionModal,
  InfoIcon,
} from '../../components';
import { JobRoleSuggestionForm } from '../../forms';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import CustomJobRoleSelectField from './CustomJobRoleSelectField';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => {
  const [ numberOfJobs, setNumberOfJobs ] = useState( props.numberOfJobs ? props.numberOfJobs : 1 );
  const [ enquiryModalOpen, setEnquiryModalOpen ] = useState( false );
  const [categoryStamp, setCategoryStamp] = useState(Date.now());

  const copyValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  const setValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  return (
    <FinalForm
      {...props}
      mutators={{ copyValue, setValue, ...arrayMutators }}
      render={formRenderProps => {
        const {
          categories,
          publicity,
          jobRoles,
          className,
          isAuthenticated,
          currentUser,
          form,
          formId,
          values,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          onManageDisableScrolling,
          onSendJobRoleSuggestionsToAdmin,
          sendJobRoleSuggestionInProgress,
          jobRoleSuggestionIsSent,
          sendJobRoleSuggestionError,
        } = formRenderProps;

        const onJobRoleChange = selectedOption => {
          form.mutators.setValue(`title${selectedOption.jobRoleIndex + 1}`, selectedOption.value );
          // values[`title${selectedOption.jobRoleIndex + 1}`] = selectedOption.value;
          // setCategoryStamp(Date.now());
        };

        const onSubmitJobRoleSuggestions = values => {
          const { message, email } = values;

          onSendJobRoleSuggestionsToAdmin(
            currentUser.attributes.profile.firstName,
            email,
            message.trim(),
          );
        };

        const addJob = () => {
          form.mutators.setValue("numberOfJobs", numberOfJobs + 1 );
          setNumberOfJobs(numberOfJobs + 1);
        };

        const removeJob = jobInd => {
          if( jobInd < numberOfJobs ){
            for(let curInd = jobInd; curInd < numberOfJobs; curInd++){
              form.mutators.copyValue(
                `title${curInd}`,
                values[`title${curInd + 1}`]
              );
              form.mutators.copyValue(
                `category${curInd}`,
                values[`category${curInd + 1}`]
              );
              form.mutators.copyValue(
                `organisation${curInd}`,
                values[`organisation${curInd + 1}`]
              );
              form.mutators.copyValue(
                `publicity${curInd}`,
                values[`publicity${curInd + 1}`]
              );
              form.mutators.copyValue(
                `startDate${curInd}`,
                values[`startDate${curInd + 1}`]
              );
              form.mutators.copyValue(
                `endDate${curInd}`,
                values[`endDate${curInd + 1}`]
              );
              form.mutators.copyValue(
                `isCurrentPosition${curInd}`,
                values[`isCurrentPosition${curInd + 1}`]
              );
            }
          }

          form.mutators.copyValue(`title${numberOfJobs}`, null );
          form.mutators.copyValue(`category${numberOfJobs}`, null );
          form.mutators.copyValue(`organisation${numberOfJobs}`, null );
          form.mutators.copyValue(`publicity${numberOfJobs}`, null );
          form.mutators.copyValue(`startDate${numberOfJobs}`, null );
          form.mutators.copyValue(`endDate${numberOfJobs}`, null );
          form.mutators.copyValue(`isCurrentPosition${numberOfJobs}`, null );

          form.mutators.setValue("numberOfJobs", numberOfJobs - 1 );
          setNumberOfJobs( numberOfJobs - 1 );
        };

        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const organisationPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.organisationPlaceholder',
        });
        const organisationRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.organisationRequired',
        });

        const publicityRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.publicityRequired',
        });

        const startDatePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.startDatePlaceholder',
        });
        const startDateRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.startDateRequired',
        });

        const endDatePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.endDatePlaceholder',
        });
        const endDateRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.endDateRequired',
        });

        const currentPositionLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.currentPositionLabel',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed"/>
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError"/>
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed"/>
          </p>
        ) : null;

        const jobNotFoundTooltip = (
          <div className={css.jobNotFoundTooltip}>
            Please try searching for other/similar titles -<br/>
            this could be an internationally<br/>
            recognised role rather than your regional term for it<br/><br/>
            <a onClick={() => { e.preventDefault(); console.log('setEnquiryModalOpen'); setEnquiryModalOpen( true );}}>
              I still can't find my job role
            </a>
          </div>
        );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <>
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              { Array.from(Array( numberOfJobs ).keys()).map( jobInd => {
                return (
                  <div key={`jobRecord${jobInd}`}>
                    <div className={css.fieldRow}>
                      <CustomJobRoleSelectField
                        id={`title${jobInd + 1}`}
                        name={`title${jobInd + 1}`}
                        className={css.title}
                        jobRoles={jobRoles}
                        selectedValue={values[`title${jobInd + 1}`]}
                        onChange={ selectedOption =>
                          onJobRoleChange({ ...selectedOption, jobRoleIndex: jobInd })
                        }
                        intl={intl}
                      />

                      <CustomCategorySelectFieldMaybe
                        id={`category${jobInd + 1}`}
                        name={`category${jobInd + 1}`}
                        categories={categories}
                        intl={intl}
                      />
                    </div>

                    { typeof window === 'object' ? (
                        <Tooltip
                          anchorId={`cantFindJobLink${jobInd + 1}`}
                          className={css.tooltipText}
                          clickable={true}
                        >
                          <div className={css.jobNotFoundTooltip}>
                            <FormattedMessage
                              id="EditListingDescriptionForm.jobNotFoundTooltip"
                              values={{
                                br: <br/>,
                                jobNotFoundTooltipLink: (
                                  <a onClick={ e => { e.preventDefault(); setEnquiryModalOpen( true );}}>
                                    <FormattedMessage id="EditListingDescriptionForm.jobNotFoundTooltipLink" />
                                  </a>
                                )
                              }}
                            />
                          </div>
                        </Tooltip>
                    ) : null }
                    <div
                      id={`cantFindJobLink${jobInd + 1}`}
                      className={css.cantFindJobLink}
                      onClick={() => setEnquiryModalOpen( true )}
                    >
                      <FormattedMessage id="EditListingDescriptionForm.jobRoleNotFound"/>
                    </div>

                    <FieldTextInput
                      id={`organisation${jobInd + 1}`}
                      name={`organisation${jobInd + 1}`}
                      className={css.organisation}
                      type="text"
                      placeholder={organisationPlaceholderMessage}
                      maxLength={TITLE_MAX_LENGTH}
                      validate={composeValidators(required(organisationRequiredMessage), maxLength60Message)}
                    />

                    <FieldCheckboxGroup
                      className={css.publicity}
                      id={`publicity${jobInd + 1}`}
                      name={`publicity${jobInd + 1}`}
                      options={publicity}
                      twoColumns={true}
                      validate={required(publicityRequiredMessage)}
                    />
                    <OnChange name={`publicity${jobInd + 1}`}>
                      {(value, previous) => {
                        const selectedPublicity = value || [];
                        const previousPublicity = previous || [];

                        if( selectedPublicity.length === 0 ){
                          form.mutators.setValue(`publicity${jobInd + 1}`, previousPublicity );
                        } else if( selectedPublicity.length === 2 ){
                          const newSelected =
                            selectedPublicity.filter( publicity =>
                              !previousPublicity.includes( publicity )
                            );

                          form.mutators.setValue(`publicity${jobInd + 1}`, newSelected );
                        }
                      }}
                    </OnChange>

                    <div className={css.fieldRow}>
                      <FieldTextInput
                        id={`startDate${jobInd + 1}`}
                        name={`startDate${jobInd + 1}`}
                        className={css.startDate}
                        type="date"
                        placeholder={startDatePlaceholderMessage}
                        validate={required(startDateRequiredMessage)}
                      />

                      { !Array.isArray( values[`isCurrentPosition${jobInd + 1}`]) ||
                        values[`isCurrentPosition${jobInd + 1}`].length === 0
                        ? (
                          <FieldTextInput
                            id={`endDate${jobInd + 1}`}
                            name={`endDate${jobInd + 1}`}
                            className={css.endDate}
                            type="date"
                            placeholder={endDatePlaceholderMessage}
                            validate={required(endDateRequiredMessage)}
                          />
                        ) : null }
                    </div>

                    <FieldCheckbox
                      className={css.currentJob}
                      id={`isCurrentPosition${jobInd + 1}`}
                      name={`isCurrentPosition${jobInd + 1}`}
                      value="current"
                      label={currentPositionLabel}
                    />

                    { numberOfJobs > 1 ? (
                      <Button
                        className={css.removeJobButton}
                        type="button"
                        onClick={() => removeJob( jobInd + 1 )}
                      >
                        <FormattedMessage id="EditListingDescriptionForm.removeJobButton"/>
                      </Button>
                    ) : null }

                    <hr className={css.jobDivider} />
                  </div>
                );
              })}

              <FieldTextInput
                id="numberOfJobs"
                name="numberOfJobs"
                type="hidden"
              />

              <Button
                className={css.addJobButton}
                type="button"
                onClick={() => addJob()}
              >
                <FormattedMessage id="EditListingDescriptionForm.addAnotherButton"/>
              </Button>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
            <JobRoleSuggestionModal
              id="EditListingPage.jobRoleSuggestion"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && enquiryModalOpen}
              onClose={() => setEnquiryModalOpen( false )}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <JobRoleSuggestionForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                categories={categories}
                sendEnquiryError={sendJobRoleSuggestionError}
                onSubmit={onSubmitJobRoleSuggestions}
                onCancel={() => setEnquiryModalOpen( false )}
                inProgress={sendJobRoleSuggestionInProgress}
              />
          </JobRoleSuggestionModal>
        </>
      );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
