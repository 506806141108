import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import "/node_modules/flag-icons/css/flag-icons.min.css";

import css from './ListingPage.module.css';

import unrollIconImage from './images/unwrap-section-icon.png';
import rollIconImage from './images/wrap-section-icon.png';

const SectionSkills = props => {
  const [ isUnrolled, setUnrolled ] = useState( false );

  const { jobRoleSkills } = props;

  return jobRoleSkills ? (
    <div className={css.sectionDescription}>
      <img
        src={isUnrolled ? rollIconImage : unrollIconImage}
        className={css.rollUnrollIcon}
        onClick={() => setUnrolled( !isUnrolled )}
      />
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.skillsTitle" />
      </h2>
      <div className={isUnrolled ? css.unrolledSkills : css.skills}>
        <div className={css.skillsList}>
          <div className={css.skillName}>
            {jobRoleSkills}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionSkills;
