import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './SectionSubscription.module.css';

const SectionSubscriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        form,
        values
      } = fieldRenderProps;

      const onHandleSubmit = (e) => {
        handleSubmit();
        e.preventDefault();
        form.change('email', null);
        form.blur('email');
      }

      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={css.form} onSubmit={(e) => onHandleSubmit(e)}>
          <FieldTextInput
            className={css.input}
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            placeholder={intl.formatMessage({
              id: 'SectionSubscription.placeholder',
            })}
          />
          <PrimaryButton
            className={css.button}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            <FormattedMessage id="SectionSubscription.button" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

SectionSubscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

SectionSubscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const SectionSubscriptionForm = compose(injectIntl)(SectionSubscriptionFormComponent);
SectionSubscriptionForm.displayName = 'SectionSubscriptionForm';

export default SectionSubscriptionForm;
