import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { PrimaryButton, FieldTextInput, NamedLink } from '../../components';

import css from './SigninSectionChooseUserRole.module.css';

const SigninSectionChooseUserRoleComponent = props => {
  const { rootClassName, className, intl, onChooseUserRole } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div>
        <div className={css.formTitle}>
          <FormattedMessage id="SigninSectionChooseUserRole.title" values={{ br: <br/> }} />
        </div>
        <div className={css.roleBlock} onClick={() => onChooseUserRole('mentor')}>
          <div className={css.roleSectionTitle}>
            <FormattedMessage id="SigninSectionChooseUserRole.mentorRoleTitle"/>
          </div>
        </div>
        <div className={css.roleBlock} onClick={() => onChooseUserRole('mentee')}>
          <div className={css.roleSectionTitle}>
            <FormattedMessage id="SigninSectionChooseUserRole.menteeRoleTitle"/>
          </div>
        </div>
      </div>
    </>
  );
};

SigninSectionChooseUserRoleComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SigninSectionChooseUserRoleComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SigninSectionChooseUserRole = compose(injectIntl)(SigninSectionChooseUserRoleComponent);
SigninSectionChooseUserRole.displayName = 'SigninSectionChooseUserRole';

export default SigninSectionChooseUserRole;
