import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';

import VideoTopContentForMentor from './VideoTopContentForMentor';
import VideoTopContentForMentee from './VideoTopContentForMentee';

import css from './SectionWithVideoTop.module.css';

const SectionWithVideoTop = props => {
  const { rootClassName, className, isLandingPage, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {isLandingPage ? <VideoTopContentForMentor currentUser={currentUser} /> : <VideoTopContentForMentee currentUser={currentUser} />}
    </div>
  );
};

SectionWithVideoTop.defaultProps = { rootClassName: null, className: null };

SectionWithVideoTop.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionWithVideoTop);
