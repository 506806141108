import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingJobRoleForm } from '../../forms';

import css from './EditListingJobRolePanel.module.css';
import moment from 'moment/moment';
import config from '../../config';

const EditListingJobRolePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData = {}} = currentListing.attributes;
  const { jobRoleName, jobRoleSkills, keyProjects = [], workExperience = []} = publicData;
  const selectedJobRoles = workExperience.map( wer => wer.title );
  const jobRoleOptions = config.custom.jobRoles.filter( jr => selectedJobRoles.includes( jr.key ));

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingJobRolePanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingJobRolePanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingJobRolePanel.createListingTitle" />
  );
  const panelSubtitle = (
    <FormattedMessage
      id="EditListingJobRolePanel.subtitle"
      values={{ br: <br/>, pleaseNote: <b>Please note:</b>}}
    />
  );

  const getProjectFields = projectRecords => {
    const recordFields = {};

    projectRecords.forEach(( expRecord, index ) => {
      recordFields[`name${index + 1}`] = expRecord.name;
      recordFields[`details${index + 1}`] = expRecord.details;
    });

    return recordFields;
  };

  const buildProjectsArray = projectFields => {
    const numberOfRecords =
      projectFields.numberOfProjects ? Number.parseInt( projectFields.numberOfProjects ) : 1;
    const projectRecords = [];

    for( let fInd = 1; fInd <= numberOfRecords; fInd++ ){
      projectRecords[ fInd - 1 ] = {
        name: projectFields[`name${fInd}`],
        details: projectFields[`details${fInd}`]
      };
    }

    return projectRecords;
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <div className={css.subtitle}>{panelSubtitle}</div>
      <EditListingJobRoleForm
        className={css.form}
        numberOfProjects={ keyProjects.length }
        jobRoles={jobRoleOptions}
        initialValues={{
          title: title.trim(),
          description: description.trim(),
          jobRoleName,
          jobRoleSkills,
          numberOfProjects: keyProjects.length,
          ...getProjectFields( keyProjects )
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, jobRoleName, jobRoleSkills } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: { jobRoleName, jobRoleSkills, keyProjects: buildProjectsArray( values )},
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingJobRolePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingJobRolePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingJobRolePanel;
