import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionJobInterviewCoachingTop.module.css';

import inlineImage from './images/job-interview-coaching-small.png';

const SectionBecomeMentor = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Job Interview Coaching
                </h2>

                <p className={css.stepText}>
                  Job interview coaching gives you an edge over other candidates -
                  it’s an investment in your future that’s undoubtedly worth making.
                  Work with an interview coach to boost your confidence, navigate
                  your next career move like a boss and make sure you’re planted
                  firmly in hirers’ minds for all the right reasons.
                </p>
                <p className={css.stepText}>
                  Our Career Navig8rs can provide 1-2-1 coaching for interviews
                  that’ll leave you ready to take on the world.
                </p>

                <p className={css.stepSubSubTitle}>
                  What Is An Interview Coach?
                </p>
                <p className={css.stepText}>
                  An interview coach is someone who’ll help you get interview-ready.
                  They’ll advise you how to banish your nerves, show you how to introduce
                  yourself and practise likely interview questions with you..
                  Effective job interview coaching will leave you feeling like
                  your best, most confident self.
                </p>
                <p className={css.stepText}>
                  Your interview coach will take the time to understand your biggest
                  interview worries, your sweaty palm triggers and they’ll answer
                  every question you have. From what to wear, to whose hand to shake,
                  to whether you should bring up your niche hobby, an interview
                  coach will help guide you through the whole process so you can
                  say goodbye to clammy hands for good.
                </p>

                <p className={css.stepSubSubTitle}>
                  Interview Coaching From Both Sides Of The Table
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    An interview coach could be someone who regularly conducts lots
                    of interviews and has well and truly seen it all; it might be
                    someone who’s got a 100% success rate from every interview they’ve
                    ever had. Our Career Navig8rs have interview experience, whether
                    in a boardroom, on a Teams call, or even as part of a group interview
                    (*shudders*) and they’re ready to pass on their wisdom.
                    <br/><br/>
                    Get unique access to the minds of hirers and learn tips and tricks
                    from those who’ve been successful candidates. Find out what not
                    to say in an interview, how to present yourself in the very best
                    light and how much time to spend on requested tasks. Interview
                    coaching will leave you prepared for every eventuality, including
                    any left field questions that might be thrown your way.
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  The Benefits Of Interview Coaching
                </p>
                <p className={css.stepText}>
                  If you struggle with interview anxiety or it’s been years (or maybe
                  even decades) since your last application process experience,
                  working with an interview coach could transform your fortunes.
                  Here are just some of the benefits of job interview coaching:
                  <ul className={css.diskList}>
                    <li>
                      Improve your interview confidence
                    </li>
                    <li>
                      Learn interview tips that’ll stay with you forever
                    </li>
                    <li>
                      Get answers to all of your questions to put your mind at rest
                    </li>
                    <li>
                      Learn how to tailor your interview approach to different
                      roles/employers/job specs
                    </li>
                    <li>
                      Flexibility - the beauty of interview coaching online is that
                      it fits around your other commitments. Prepping for an interview
                      on top of your current job can be a lot, but our platform makes it easy
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  Find An Interview Coach
                </p>
                <p className={css.stepText}>
                  Show your nerves who’s boss and go after the opportunities you
                  deserve with the support of an interview coach. Our Career Navig8rs
                  have unique candidate experience and hiring insights, and they’re
                  ready to pass on their wisdom to help you ace your next interview.
                </p>
                <p className={css.stepText}>
                  Whether you’ve got an ‘informal chat’, a proper executive interview
                  or a final-stage presentation booked in,&nbsp;
                  <NamedLink
                    key="linkToSearchPage"
                    name="SearchPage"
                    title="find a mentor"
                  >
                    find a mentor
                  </NamedLink>
                  &nbsp;who’ll coach you through the process, every step of the way.
                </p>
                <p className={css.stepText}>
                  Simply search for mentors who offer interview coaching, check
                  their hourly rate and contact them directly. Brush up on your
                  interview technique and conquer your next application process
                  with support from an interview coach.
                </p>
                <p className={css.stepText}>
                  <i>Bossed every interview you’ve ever had? Thrive off being asked
                    to list your weaknesses/recall a time when you faced a challenge
                    at work/describe where you’ll be in 5 years’ time? We’d love
                    to have you on board as an interview coach. Sign up to become
                    a Career Navig8r today. </i>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
