import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import SlotCounter from 'react-slot-counter';

import SlotTextWithTimeout from './SlotTextWithTimeout';

import heroImageDesktop from './images/hero-mentee-desktop.jpg';
import heroImageMobile from './images/hero-mentee-mobile.jpg';

import css from './SectionHeroNew.module.css';

const isFirstLetterVowel = (word) => {
  const vowels = ['A', 'E', 'I', 'O', 'U', 'a', 'e', 'i', 'o', 'u'];
  const firstLetter = word?.charAt(0);
  return vowels.includes(firstLetter);
}

const HeroContentForMentee = ({ userRolesData, currentUser }) => {
  const [roleIndex, setRoleIndex] = useState(Math.floor(Math.random() * userRolesData.length));
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true)
    const interval = setInterval(() => {
      setRoleIndex(prevIndex => (prevIndex + 1) % userRolesData.length);
      setRoleKey('wrongRole1');
    }, 14000);
    // }, 10000000); 

    return () => clearInterval(interval);
  }, [userRolesData]);

  const role = userRolesData[roleIndex];
  const [roleKey, setRoleKey] = useState('wrongRole1');

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setRoleKey('wrongRole2');
    }, 4000);
    // }, 4000000);

    const timeout2 = setTimeout(() => {
      setRoleKey('mainRole');
    }, 8000);
    // }, 8000000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [roleIndex]);

  const mainRoleText = (
    <SlotCounter
      key={`${role.mainRole}-${roleIndex}`}
      value={role.mainRole}
      dummyCharacters={'----------'}
      duration={0.25}
      charClassName='slot-counter-char'
      separatorClassName='slot-counter-separator'
    />
  );

  const secondaryRoleText = (
    <SlotCounter
      key={`${roleKey}`}
      value={role[roleKey]}
      dummyCharacters={'----------'}
      duration={0.5}
      charClassName='slot-counter-char'
      separatorClassName='slot-counter-separator'
    />
  );

  return (
    <div className={css.heroContent}>
      <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
        <span className={css.staticText}>
          If you want to be {isFirstLetterVowel(role.mainRole) ? 'an' : 'a'}
        </span>
        <span className={classNames(css.blueText, css.blueTextMain)}>{mainRoleText}</span>
        <br />
        <span className={css.staticText}>
          would you accept {isFirstLetterVowel(role[roleKey]) ? 'an' : 'a'}
        </span>
        <span className={classNames(css.blueText, { [css.mintText]: roleKey === 'wrongRole1' || roleKey === 'wrongRole2' })}>
          {secondaryRoleText}
        </span>
        <br />
        <span className={css.staticText}> 
          {roleKey === 'wrongRole1' && <>as your Mentor? <SlotTextWithTimeout text='No!' id='wrongRole1' /></>}
          {roleKey === 'wrongRole2' && <>as your Mentor? <SlotTextWithTimeout text='No!' id='wrongRole2' /></>}
          {roleKey === 'mainRole' && <>as your Mentor? <SlotTextWithTimeout text='Yes!' id='mainRole' /></>}
        </span>
      </h1>

      <h2 className={classNames(css.heroSubTitle, css.heroSubTitleMentee, { [css.heroSubTitleFEDelay]: mounted })}>
        <FormattedMessage id="SectionHeroNew.subTitleMentee" />
      </h2>

      <picture
        className={classNames(css.heroImg, { [css.heroImgFEDelay]: mounted })}
        alt='Hero image'
      >
        <source media="(max-width: 767px)" srcSet={heroImageMobile} />
        <source media="(min-width: 768px)" srcSet={heroImageDesktop} />
        <img src={heroImageDesktop} alt="Fallback Image" />
      </picture>

      <div className={css.buttonsWrapper}>
        <NamedLink
          key="linkToLandingPage1"
          name="SearchPage"
          className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
          title="Find a mentor"
        >
          <FormattedMessage id="SectionHeroNew.findAMentor" />
        </NamedLink>
      </div>
    </div>
  );
};


export default HeroContentForMentee;
