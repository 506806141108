import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionCareerDevelopmentMentoringTop.module.css';

import inlineImage from './images/career-development-mentoring-small.png';

const SectionCareerDevelopmentMentoringTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Career Development Mentoring
                </h2>

                <p className={css.stepText}>
                  Unsure how to take the next step in your career? Big ambitions
                  but unsure how to make them a reality? A career development mentor
                  could be the guiding light you’re looking for.
                </p>
                <p className={css.stepText}>
                  We all feel a little lost from time to time but if that feeling’s
                  lingering, investing in career development advice will allow you
                  to realign with your goals and set the wheels in motion to take
                  your career to the next level.
                </p>

                <p className={css.stepSubSubTitle}>
                  What Is A Career Development Mentor?
                </p>
                <p className={css.stepText}>
                  A career development mentor is someone who’ll work with you to develop
                  your professional skills to further your career. They’ll start
                  by identifying your career development goals, helping you put these
                  into an actionable plan to turn your ambitions into reality.
                </p>
                <p className={css.stepText}>
                  Your career development mentor will help you unlock the tools
                  to access the next step on your professional journey by encouraging
                  you to expand your network, study for a specific qualification
                  or improve a particular skill, like public speaking or delivering
                  constructive feedback. A good career development mentor will check
                  on your progress along the way, helping to keep you accountable
                  and keep you moving forward.
                </p>
                <p className={css.stepText}>
                  Think of your career development mentor as being like a compass
                  and a cheerleader rolled into one.
                </p>

                <p className={css.stepSubSubTitle}>
                  Get Help with Your Career Development Goals
                </p>
                <p className={css.stepText}>
                  Perhaps you’ve always wanted to be a CEO, to have your boss’s
                  job or to earn enough to buy your dream house. Our big ultimate
                  goals need breaking down into smaller, bite-sized chunks, and
                  a career development mentor can help you do exactly that.
                </p>
                <p className={css.stepText}>
                  Our Career Navig8rs will help you craft SMART (specific, measurable,
                  achievable, relevant, time-bound) career development goals to keep
                  you focused as you level up your skills. They’ll help you realise
                  exactly what you want from your career and work with you to create
                  a roadmap together to get you there.
                </p>

                <p className={css.stepSubSubTitle}>
                  The Benefits Of Investing In Career Development Advice
                </p>
                <p className={css.stepText}>
                  If you’re feeling unfulfilled, stuck in a rut or just a little
                  lost on your professional journey, investing in career development
                  advice could provide the motivation you need to get back on track.
                  Successful career development mentoring should feel like finally
                  seeing the light at the end of the tunnel.
                </p>

                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    Benefits of career development support include:
                    <ul className={css.diskList}>
                      <li>
                        Boosting your confidence at work
                      </li>
                      <li>
                        Expanding your professional network
                      </li>
                      <li>
                        Identifying your strengths and weaknesses
                      </li>
                      <li>
                        Creating a roadmap to success that’s unique to you
                      </li>
                      <li>
                        Developing your workplace skills, including soft skills
                      </li>
                      <li>
                        Getting help with creating (and achieving!) SMART goals
                      </li>
                      <li>
                        Accessing resources that will help you grow professionally
                      </li>
                      <li>
                        Recognising the professional opportunities available to you
                      </li>
                      <li>
                        Getting a fresh perspective on your career from someone objective
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  Find A Career Development Mentor
                </p>
                <p className={css.stepText}>
                  Ready to take your professional success to the next level? Find
                  a career development mentor who can support you every step of
                  the way. Whether you’re going after a promotion, embarking on
                  a career change or simply want to improve your soft skills, career
                  development mentoring will transform your future.
                </p>
                <p className={css.stepText}>
                  The Career Navig8r platform lets you&nbsp;
                  <NamedLink
                    key="linkToSearchPage"
                    name="SearchPage"
                    title="search our mentor database"
                  >
                    search our mentor database
                  </NamedLink>
                  &nbsp;to find a Career Navig8r whose skill set and hourly rate
                  is right for you.
                </p>
                <p className={css.stepText}>
                  <i>Want to help other people with their professional development?
                    You’re a hero! Register to become a career development mentor. </i>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionCareerDevelopmentMentoringTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCareerDevelopmentMentoringTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCareerDevelopmentMentoringTop;
