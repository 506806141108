import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './SectionCancellationPolicyContent.module.css';

const SectionCancellationPolicyContent = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <p className={css.contentText}>
            This cancellation policy has been created using scenario-based explanations.
            Just to make things a little easier to understand.<br/>
            (Don’t worry, we understand these things are a bit boring but necessary)
            <br/><br/>
            There are two types of mentoring meetings you can book. The first is
            the discovery meeting - when the two of you get to know each other and
            make sure you can work together.<br/>
            It’s important that both mentee & mentor are happy with the partnership.
            <br/><br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                The Discovery Meeting. The 'getting to know you' meeting. We have good
                news, there's no money involved, it's a free exchange of thoughts & ideas
                to make sure you’re compatible. No need for a cancellation policy here
              </li>
              <li className={css.ctli}>
                The Full Meeting is the second type of meeting. These take place
                when you’ve had the other meeting and agreed you can work together.
                They are bought/booked in 1-hour blocks. This is where money changes
                hands (or at least gets sent over the internet). Clearly, these need
                a cancellation policy.
              </li>
            </ul>
            (and we’ve handily laid one just below for you)
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Full Meeting Rules of Cancellation</h2>

          <p className={css.contentText}>
            Please note: Payment for a full meeting is taken at the point of booking.
            <br/><br/>
            Full Meeting - Rule 1: Mentee decides to cancel.<br/>
            A Mentee can cancel a session with their Navig8r at no charge up to 48-hours
            before the meeting is scheduled. All monies are automatically refunded by Stripe.<br/>
            NB: Within 48 hours - the fee is non-refundable.
            <br/><br/>
            Full Meeting - Rule 2: Navig8r decides to cancel.
            <br/><br/>
            A Mentor can cancel at any point and a full refund made to the Mentee.
            All monies are automatically refunded by Stripe.
            <br/><br/>
            Full Meeting - Rule 3: Navig8r does not attend the Full Meeting<br/>
            If the Navig8r (mentor) does not attend the Full Meeting, a full refund
            is made but there is a strict set of conditions that determine whether
            a Navig8r has not attended.<br/>
            The conditions are based on how and when the Mentee attends AND leaves
            the meeting. See below for the conditions:<br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                The Mentee should go to their Career Navig8r Inbox / Bookings just
                before the full meeting is scheduled to start
              </li>
              <li className={css.ctli}>
                Here they will find the booking message for the meeting.
              </li>
            </ul>
            Shortly before the meeting is to begin a ‘Start Meeting’ button will
            be present (refreshing the page definitely helps here if you can’t see it initially)
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Clicking this button takes the mentee to the waiting room. A clock
                will appear which is counting down to the meeting start time in minutes.
              </li>
            </ul>
            There's an inbuilt 15-minute leeway (because life happens) so the countdown
            clock will show those 15 minutes PLUS any additional minutes before
            the official meeting start time.<br/>
            If the Mentee entered the room 4 minutes before the official start time.
            The countdown clock will be counting down from 19 minutes
            (15 minutes + 4 minutes)<br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                A message will state that the Mentor has not yet joined the meeting
              </li>
              <li className={css.ctli}>
                The Mentee needs to wait in the waiting room until this clock runs
                down to zero, at which point the system will cancel the meeting,
                and will note the Mentor did not attend. This will see a full refund occurring.
              </li>
              <li className={css.ctli}>
                It's important the Mentee doesn't leave the waiting room until the clock
                reaches zero! If they leave before this point, and the mentor does
                arrive at a point between the official start time and the 15-minute
                leeway allowance, the system will see the mentee as being the one
                who has not attended.
              </li>
            </ul>
            A full refund for the meeting will be made
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Where applicable. Stripe handles all refunds
              </li>
            </ul>
            <br/><br/>
            TL;dr: If you’re the mentee, and you don’t give the Navig8r the full
            allotted 15 minutes AFTER the official start time. YOU will be seen as
            the person who didn’t attend. You’ll be charged the agreed rate for the meeting
            <br/><br/>
            Full Meeting - Rule Four: The Mentee does not attend the Trial Meeting<br/>
            If the Mentee does not attend the full meeting - and they are outside
            of the 48-hour free cancellation time period - full payment for the meeting
            will be taken.<br/>
            There is a strict set of conditions that determine if a Mentee has not attended.<br/>
            The conditions are based on how and when the Navig8r attends AND leaves
            the meeting. See below for the conditions:
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                The Navig8r should go to their Career Navig8r Inbox / Bookings just
                before the Full Meeting is scheduled to start
              </li>
              <li className={css.ctli}>
                Here they will find the booking message for the meeting.
              </li>
            </ul>
            Shortly before the meeting is to begin a 'Start Meeting' button will
            be present (refreshing the page definitely helps here if you can't see it initially)
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Clicking this button takes the Navig8r to the waiting room. A clock
                will appear which is counting down to the meeting start time in minutes.
              </li>
            </ul>
            There's an inbuilt 15-minute leeway (because life happens) so the countdown
            clock will show those 15 minutes PLUS any additional minutes before
            the official meeting start time.<br/>
            If the Mentee entered the room 4 minutes before the official start time.
            The countdown clock will be counting down from 19 minutes (15 minutes + 4 minutes)
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                A message will state that the Mentee has not yet joined the meeting
              </li>
              <li className={css.ctli}>
                The Navig8r needs to wait in the waiting room until this clock runs
                down to zero, at which point the system will cancel the meeting,
                and will note the Mentee did not attend. This will see payment for
                the full meeting occurring.
              </li>
              <li className={css.ctli}>
                It is important that the Nvig8r does not leave this waiting room
                until the clock reaches zero. If they leave before this point, and
                the mentee does in fact arrive, then the system will see the Nvig8r
                as being the one who has not attended, and a full charge will occur
              </li>
              <li className={css.ctli}>
                Where applicable, Stripe handles all payments
              </li>
            </ul>
            <br/>
            If you’re the Navig8r, and you don’t give the Mentee the full allotted
            15 minutes AFTER the official start time. YOU will be seen as the person
            who didn’t attend. The mentee will be refunded the agreed rate for the meeting
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Discovery Meeting Rules of Cancellation</h2>

          <p className={css.contentText}>
            While there are no hard and fast rules for the Discovery Meeting - because
            they're free and no money is changing hands - it's still nice to be nice.<br/>
            And respectful.<br/>
            Please always give the person you’re meant to be meeting as much notice
            as possible if you realise you are going to need to cancel the meeting.<br/>
            As soon as you know you’re not going to be able to make a meeting, drop
            the other person a message through your Career Navig8r inbox.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Other Key Booking Information</h2>

          <p className={css.contentText}>
            Process of Booking a Meeting (Discovery and Full)
            <br/><br/>
            To book either a “Full Meeting” or “Discovery Meeting” involves:
            <br/><br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                The Mentee making a booking request
              </li>
              <li className={css.ctli}>
                This booking request is sent to the Navig8r who, on receipt, must
                accept or decline this meeting
              </li>
              <li className={css.ctli}>
                For both a “Discovery Meeting” and a “Full Meeting” a Mentor must
                accept the request within 6 calendar days of receiving it, or if
                the request is made within six calendar days of the meeting, then
                12 hours before the meeting is set to start.
              </li>
              <li className={css.ctli}>
                If the Mentor does not accept then the request will expire, and
                no monies (for a Full Meeting) will be deducted from the Mentee's account.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

SectionCancellationPolicyContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCancellationPolicyContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCancellationPolicyContent;
