import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldPhoneNumberEdit, NamedLink } from '../../components';

import css from './SignupForm.module.css';

import checkboxTickedImage from './checkbox_ticked.png';
import checkboxUntickedImage from './checkbox_unticked.png';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [ conditionsTicked, setConditionsTicked ] = useState( false );

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          userType,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenPrivacyPolicy,
        } = fieldRenderProps;

        // email
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // LinkedIn link
        const linkedinPlaceholder = intl.formatMessage({
          id: 'SignupForm.linkedinPlaceholder',
        });

        // Mobile phone number
        const mobileNumberPlaceholder = intl.formatMessage({
          id: 'SignupForm.mobileNumberPlaceholder',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !conditionsTicked;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsOfServiceLinkText" />
          </span>
        );

        const handlePrivacyKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenPrivacyPolicy();
          }
        };
        const privacyLink = (
          <span
            className={css.termsLink}
            onClick={onOpenPrivacyPolicy}
            role="button"
            tabIndex="1"
            onKeyUp={handlePrivacyKeyUp}
          >
          <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </span>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
              </div>
              <FieldTextInput
                className={css.email}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              <FieldTextInput
                className={css.linkedin}
                type="linkedinLink"
                id={formId ? `${formId}.linkedinLink` : 'linkedinLink'}
                name="linkedinLink"
                autoComplete="text"
                placeholder={linkedinPlaceholder}
              />
              <FieldPhoneNumberEdit
                className={css.mobileNumber}
                id={formId ? `${formId}.mobileNumber` : 'mobileNumber'}
                name="mobileNumber"
                placeholder={mobileNumberPlaceholder}
                defaultCountry="GB"
              />
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <img
                  className={css.checkboxImage}
                  src={ conditionsTicked ? checkboxTickedImage : checkboxUntickedImage }
                  onClick={() => setConditionsTicked( !conditionsTicked )}
                />
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsOfService: termsLink, privacyPolicy: privacyLink }}
                  />
                </span>
              </p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
              <div className={css.signinBlock}>
                <NamedLink
                  className={css.signinLink}
                  name="LoginPage"
                >
                  <FormattedMessage id="SignupForm.signinLinkText" />
                </NamedLink>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
