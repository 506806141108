import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionBecomeMenteeTop.module.css';

import inlineImage from './images/become-a-mentee-small.png';

const SectionBecomeMenteeTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Find a Mentor
                </h2>

                <p className={css.stepText}>
                  Feeling a little bit lost when it comes to your career?
                </p>
                <p className={css.stepText}>
                  Want to climb the next step on the ladder but need a leg up?
                </p>
                <p className={css.stepText}>
                  Or perhaps you’re not happy in your current job and want a complete
                  career change?
                </p>
                <p className={css.stepText}>
                  Sounds like you need to find a mentor.
                </p>
                <p className={css.stepText}>
                  And you couldn’t have come to a better place.
                </p>
                <p className={css.stepText}>
                  Our Career Navig8rs are ready and waiting to share their knowledge,
                  skills and experience with you to help you achieve your goals.
                  Become a mentee today and take your career to the next level.
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSearchPage1"
                    name="SearchPage"
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Find a mentor"
                  >
                    Find a mentor
                  </NamedLink>
                </div>

                <p className={css.stepSubSubTitle}>
                  What is a Mentee?
                </p>
                <p className={css.stepText}>
                  A mentee is someone who has a mentor - simple as that! One can’t exist
                  without the other, so take the time to find a mentor who’s right for you.
                </p>
                <p className={css.stepText}>
                  The
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/what-are-the-relationship-guidelines-between-a-mentor-and-mentee/"
                    className={css.link}
                    sameTab={true}
                  >
                    mentor-mentee relationship
                  </ExternalLink>
                  &nbsp;
                  is very special. Get the most out of it by prepping for every session,
                  communicating openly, sharing your goals and asking questions.
                </p>
                <p className={css.stepText}>
                  When you become a mentee, you’ll be putting your trust in your
                  mentor. Respect what they share with you - remember, they’ve already
                  been there, done it and no doubt got many promotional t-shirts along the way.
                </p>

                <p className={css.stepSubSubTitle}>
                  Benefits of Being a Mentee
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    As soon as you find a mentor who’s right for you, you’ll start
                    feeling the benefits of having that beacon lighting the way towards
                    your goals. Become a mentee to:
                    <ul className={css.diskList}>
                      <li>
                        Boost your career development
                      </li>
                      <li>
                        Expand your professional network
                      </li>
                      <li>
                        Get a fresh perspective on your career goals
                      </li>
                      <li>
                        Set a plan to reach those goals
                      </li>
                      <li>
                        Improve your confidence and leave imposter syndrome behind
                      </li>
                      <li>
                        Pick the brain of someone who’s done your job before - there’s
                        no better insight
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSearchPage2"
                    name="SearchPage"
                    className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
                    title="Find a mentor"
                  >
                    Find a mentor
                  </NamedLink>
                </div>

                <p className={css.stepSubSubTitle}>
                  How to Find a Mentor
                </p>
                <p className={css.stepText}>
                  There are lots of different ways to find a mentor, including via
                  LinkedIn, by approaching someone in your existing network, or by
                  signing up to our unique online platform.
                </p>
                <p className={css.stepText}>
                  To find a mentor who’s right for you, think about your long-term
                  goals and what kind of person will help you get there. You’ll
                  easily be able to see our Career Navig8rs’ skills and experience
                  on their mentor profiles to narrow your choice down.
                </p>

                <p className={css.stepSubSubTitle}>
                  Find a Mentor at Career Navig8r
                </p>
                <p className={css.stepText}>
                  Find a mentor that’s the perfect match for you at Career Navig8r
                  - it’s as easy as 1, 2, 3:
                  <ol>
                    <li>
                      Enter (and verify) your amail address
                    </li>
                    <li>
                      Browse by job type
                    </li>
                    <li>
                      Find a mentor
                    </li>
                  </ol>
                  <NamedLink
                    key="linkToSignupPage3"
                    name="SearchPage"
                    className={css.link}
                    title="Find a mentor"
                  >
                    Find a mentor
                  </NamedLink>
                  &nbsp;
                  who has excelled in your job role and knows the pitfalls, opportunities
                  and how to help you get to the next level in your career. Sign up
                  to become a mentee to boost your career, your confidence and your network.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMenteeTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMenteeTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMenteeTop;
