import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { addUserToMailList } from '../../util/api';

import subscriptionDesktop from './images/subscription-img-desktop.png';
import subscriptionMobile from './images/subscription-img-mobile.png';
import SectionSubscriptionForm from './SectionSubscriptionForm';

import css from './SectionSubscription.module.css';

const SectionSubscription = props => {
  const { rootClassName, className, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.contentItem}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionSubscription.title" />
          </h2>
          <SectionSubscriptionForm onSubmit={(values) => {

            addUserToMailList({
              userRole: currentUser?.attributes?.profile?.publicData?.userType || 'mentor',
              fullName: currentUser?.attributes?.profile?.firstName || 'Guest user',
              email: values?.email
            });
          }} />
        </div>

        <div className={css.contentItem}>
          <picture
            className={css.img}
            alt="Subscribe image"
          >
            <source media="(max-width: 767px)" srcSet={subscriptionMobile} />
            <source media="(min-width: 768px)" srcSet={subscriptionDesktop} />
            <img src={subscriptionDesktop} alt="Subscribe image" />
          </picture>
        </div>
      </div>
    </div>
  );
};

SectionSubscription.defaultProps = { rootClassName: null, className: null };

SectionSubscription.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionSubscription);
