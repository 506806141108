import React, { Component } from 'react';
import { compose } from 'redux';
import { string, func } from 'prop-types';
import truncate from 'lodash/truncate';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingBand.module.css';

import unfilledHeartImage from './images/unfilledFavorite.png';
import filledHeartImage from './images/filledFavorite.png';
import ratingStarImage from './images/ratingStar.png';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MAX_DESCRIPTION_LENGTH = 300;
const DESCRIPTION_OMISSION_SUFFIX = '...';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getCurrentPosition = workExperience => {
  let currentPosition = null;

  if( Array.isArray( workExperience )){
    workExperience.forEach( experienceRecord => {
      const isCurPos = experienceRecord.isCurrentPosition;

      if( Array.isArray( isCurPos ) && isCurPos.length === 1 && isCurPos[ 0 ] === "current"){
        currentPosition = {
          organisation: experienceRecord.organisation,
          category: experienceRecord.category
        };
      }
    });
  }

  return currentPosition;
};

export const ListingCardComponent = props => {
  const {
    className, rootClassName, intl, history, from, currentUser, listing, isFavorite,
    renderSizes, setActiveListing, addToFavorites, removeFromFavorites,
  } = props;

  const forwardToLogin = () => {
    const routes = routeConfiguration();

    // Redirect to LoginPage
    history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
  };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description, price, publicData = {}} = currentListing.attributes;
  const {
    type: listingType, numberOfReviews = 0, averageRating = 0, workExperience = []
  } = publicData;
  const currentPosition = getCurrentPosition( workExperience );
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const authorImage = author.profileImage ? author.profileImage : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters );
  const currentPositionCategory = currentPosition ?
    categoryOptions.find( categoryOption => currentPosition.category === categoryOption.key ) :
    null;
  const currentPositionCategoryLabel =
    currentPositionCategory ? currentPositionCategory.label : null;

  const numberOfVisibleSectors = typeof window === 'object' && window.innerWidth >= 768 ? 2 : 1;
  const sectors = workExperience.map( workRecord => {
    const option =
      categoryOptions.find(categoryOption => workRecord.category === categoryOption.key);

    return option ? option.label : null;
  });

  const sectorsList = (
    <>
      { sectors.map(( sector, index ) =>
        <div className={css.sectorName} key={`visibleSector-${index}-`}>{sector}</div>
      )}
    </>
  );

  const favoriteIcon = isFavorite ? (
    <img
      src={filledHeartImage}
      className={css.favoriteHeart}
      onClick={() => removeFromFavorites( listing.id.uuid )}
    />
  ) : (
    <img
      src={unfilledHeartImage}
      className={css.favoriteHeart}
      onClick={() => addToFavorites( listing.id.uuid )}
    />
  );

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={authorName}
            image={authorImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.ratingInfo}>
          <div className={css.authorName}>
            {authorName}
          </div>
          <div className={css.averageRating}>
            <img src={ratingStarImage} className={css.ratingStarImage} />
            <div className={css.ratingValue}>
              {Math.round( averageRating / 100 ) / 10}
            </div>
            <div className={css.numberOfReviews}>
              <FormattedMessage
                id="ListingCard.numberOfReviews"
                values={{ numberOfReviews }}
              />
            </div>
          </div>
        </div>
        { currentPosition ? (
          <div className={css.currentPosition}>
            {currentPositionCategoryLabel} @ {currentPosition.organisation}
          </div>
        ) : null }
        <div className={css.description}>
          {richText(
            truncate( description,
              {
                'length': MAX_DESCRIPTION_LENGTH,
                'omission': DESCRIPTION_OMISSION_SUFFIX,
              }
            ),
            {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            }
          )}
        </div>
        <div className={css.sectors}>
          <div className={css.sectorsLabel}>
            <FormattedMessage id="ListingCard.sectorsLabel"/>
          </div>
          {sectorsList}
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice} / <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
      </div>
      <div className={css.controlsArea}>
        { currentUser ? favoriteIcon : (
          <img
            src={unfilledHeartImage}
            className={css.favoriteHeart}
            onClick={forwardToLogin}
          />
        )}
        <NamedLink
          name={ from === "search" ? "FoundListingPage" : "ListingPage"}
          params={{ id, slug }}
          className={css.viewProfileButton}
        >
          <FormattedMessage id="ListingCard.viewProfileButton" />
        </NamedLink>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default compose( withViewport, injectIntl )(ListingCardComponent);
