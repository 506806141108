import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';

import HeroContentForMentor from './HeroContentForMentor';
import HeroContentForMentee from './HeroContentForMentee';

import css from './SectionHeroNew.module.css';


const SectionHeroNew = props => {
  const { rootClassName, className, isLandingPage, currentUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  const userRolesData = [
    { mainRole: 'Accounting Technician', wrongRole1: 'Chemist', wrongRole2: 'Developer' },
    { mainRole: 'Attorney', wrongRole1: 'CFO', wrongRole2: 'Engineer' },
    { mainRole: 'Chef', wrongRole1: 'Carpenter', wrongRole2: 'Technician' },
    { mainRole: 'Frontend Developer', wrongRole1: 'Marketer', wrongRole2: 'Life Coach' },
    { mainRole: 'Copywriter', wrongRole1: 'Chef', wrongRole2: 'Architect' },
    { mainRole: 'Engineer', wrongRole1: 'Technical Writer', wrongRole2: 'Attorney' },
    { mainRole: 'Provost', wrongRole1: 'ER Nurse', wrongRole2: 'Biologist' },
    { mainRole: 'Architect', wrongRole1: 'Pastor', wrongRole2: 'QA Tester' },
    { mainRole: 'Bank Teller', wrongRole1: 'CFO', wrongRole2: 'Legal Secretary' },
    { mainRole: 'ER Nurse', wrongRole1: 'Biochemist', wrongRole2: 'Editor' },
  ];

  return (
    <div className={classes}>
      {isLandingPage ?
        <HeroContentForMentor userRolesData={userRolesData} currentUser={currentUser } />
        : <HeroContentForMentee userRolesData={userRolesData} currentUser={currentUser } />
      }
    </div>
  );
};

SectionHeroNew.defaultProps = { rootClassName: null, className: null };

SectionHeroNew.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionHeroNew);
