import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionFindItMentorTop.module.css';

import inlineImage from './images/find-an-it-mentor-small.png';

const SectionBecomeMentor = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Find an IT Mentor
                </h2>

                <p className={css.stepText}>
                  Find a tech mentor with Career Navig8r and take your IT career
                  to the next level. Get IT mentoring at a time that suits you and
                  from the comfort of your own home - you can even wear your slippers.
                  Online career mentoring from tech geniuses who’ve already done
                  your job and have the knowhow will catapult you to where you want to be.
                </p>
                <p className={css.stepText}>
                  Whether you work in programming, software development, cloud computing,
                  data processing, cybersecurity, IT project management or another
                  field (the tech world changes so quickly that feline data technician
                  and AI masseuse could very well be jobs by the time you reach
                  the bottom of this page), a tech mentor will support you to make
                  your professional goals a reality.
                </p>

                <p className={css.stepSubSubTitle}>
                  What Are The Benefits of IT Mentoring?
                </p>
                <p className={css.stepText}>
                  IT mentoring has countless benefits. If you’re just starting out
                  in the industry, you’ve moved to the tech sector following a career
                  change or you’re looking to upskill to drive your career forward,
                  connecting with a tech career mentor will be hugely beneficial - fact.
                </p>
                <p className={css.stepText}>
                  With new software, applications and platforms launching all the time,
                  an IT mentor will steer you through the latest developments so
                  you can find your happy place.
                </p>
                <p className={css.stepText}>
                  AI is the current hot topic in the sector, creating new anxieties
                  and challenges for tech professionals - find a tech mentor to help
                  you navigate the latest changes. They’ll put you back in control
                  and you’ll stop waking up in a cold sweat after those vivid nightmares
                  where an evil AI overlord hands you a cardboard box containing
                  your sticky notes, wilting air plant and broken stapler, we promise.
                </p>
                <p className={css.stepText}>
                  An IT mentor will provide you with an impartial perspective and
                  invaluable advice on your role within this rapidly changing industry.
                  The IT sector is so broad that you can take your career in virtually
                  (play on words definitely intended) any direction. A tech mentor
                  will help you identify your transferable skills and most powerful
                  strengths to develop further.
                </p>
                <p className={css.stepText}>
                  From Gates to Jobs and from Cook to Berners-Lee and thousands
                  of other examples - every tech giant you can think of has had
                  someone to look up to, inspire them, and guide their way through
                  the unchartered waters of the IT landscape. Now it’s your turn.
                  Aye aye cap’n.
                </p>

                <p className={css.stepSubSubTitle}>
                  Proven IT Mentoring Benefits
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    The benefits of IT mentoring couldn’t be clearer to see in a study
                    carried out by Sun Microsystems a few years back. The IT company
                    looked at the impact of IT mentoring for both mentors and mentees
                    over a 13-year period, which produced some hard-to-argue-with results:
                    <ul className={css.diskList}>
                      <li>
                        25% of mentees received a salary increase within 2 years of
                        working with an IT mentor
                      </li>
                      <li>
                        28% of mentors also saw their salary increase within 2 years
                      </li>
                      <li>
                        Mentees were 5 times more likely to be promoted
                      </li>
                      <li>
                        Mentors were 6 times more likely to be promoted after trying
                        out IT mentoring
                      </li>
                      <li>
                        69% staff retention rate recorded for mentees involved with
                        an IT mentor
                      </li>
                      <li>
                        72% staff retention recorded for mentors who’d been acting
                        as a tech mentor
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  Find a Tech Mentor with Career Navig8r
                </p>
                <p className={css.stepText}>
                  Find an IT mentor in your area, whether you’re based in London,
                  Manchester or Edinburgh. Rotterdam, Liverpool and Rome coming soon…
                </p>
                <p className={css.stepText}>
                  Our online mentoring platform will connect you with a tech mentor
                  who’s the right fit for you, wherever in the country you’re based.
                  Connect with a perfectly matched IT mentor and take that life-changing
                  next step today.
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSearchPage1"
                    name="SearchPage"
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Find an IT Mentor"
                  >
                    Find an IT Mentor
                  </NamedLink>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
