import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    isAuthenticated,
    currentUser,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    onSendJobRoleSuggestionsToAdmin,
    sendJobRoleSuggestionInProgress,
    jobRoleSuggestionIsSent,
    sendJobRoleSuggestionError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, description, publicData = {}} = currentListing.attributes;
  const { workExperience = []} = publicData;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const publicityOptions = findOptionsForSelectFilter('publicity', config.custom.filters);
  const jobRoleOptions = config.custom.jobRoles;

  const getWorkExperienceFields = experienceRecords => {
    const recordFields = {};

    experienceRecords.forEach(( expRecord, index ) => {
      recordFields[`title${index + 1}`] = expRecord.title;
      recordFields[`category${index + 1}`] = expRecord.category;
      recordFields[`organisation${index + 1}`] = expRecord.organisation;
      recordFields[`publicity${index + 1}`] = expRecord.publicity;
      const startDate = new Date( expRecord.startDate );
      recordFields[`startDate${index + 1}`] = moment( startDate ).format('YYYY-MM-DD');
      const endDate = new Date( expRecord.endDate );
      recordFields[`endDate${index + 1}`] = moment( endDate ).format('YYYY-MM-DD');
      recordFields[`isCurrentPosition${index + 1}`] = expRecord.isCurrentPosition;
    });

    return recordFields;
  };

  const buildWorkExperienceArray = experienceFields => {
    const numberOfRecords =
      experienceFields.numberOfJobs ? Number.parseInt( experienceFields.numberOfJobs ) : 1;
    const experienceRecords = [];

    for( let fInd = 1; fInd <= numberOfRecords; fInd++ ){
      experienceRecords[ fInd - 1 ] = {
        title: experienceFields[`title${fInd}`],
        category: experienceFields[`category${fInd}`],
        organisation: experienceFields[`organisation${fInd}`],
        publicity: experienceFields[`publicity${fInd}`],
        startDate: ( new Date( experienceFields[`startDate${fInd}`])).getTime(),
        endDate: ( new Date( experienceFields[`endDate${fInd}`])).getTime(),
        isCurrentPosition: experienceFields[`isCurrentPosition${fInd}`]
      };
    }

    return experienceRecords;
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        numberOfJobs={ workExperience.length }
        initialValues={{
          numberOfJobs: workExperience.length,
          ...getWorkExperienceFields( workExperience )
        }}
        saveActionMsg={submitButtonText}
        onSendJobRoleSuggestionsToAdmin={onSendJobRoleSuggestionsToAdmin}
        onSubmit={values => {

          const category = values && buildWorkExperienceArray( values ).map(item => item.category);

          const updateValues = {
            title: title ? title : ' ',
            description: description ? description : ' ',
            publicData: { 
              workExperience: buildWorkExperienceArray( values ),
              category: category
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        onManageDisableScrolling={onManageDisableScrolling}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        publicity={publicityOptions}
        jobRoles={jobRoleOptions}
        sendJobRoleSuggestionInProgress={sendJobRoleSuggestionInProgress}
        jobRoleSuggestionIsSent={jobRoleSuggestionIsSent}
        sendJobRoleSuggestionError={sendJobRoleSuggestionError}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
