import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionCareerMentoringTop.module.css';

import inlineImage from './images/career-mentoring-small.png';

const SectionBecomeMentor = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Career Mentoring Services
                </h2>

                <p className={css.stepText}>
                  Career mentoring is the gateway to the next step in your professional
                  life. Whether you want to become a career mentor or are looking
                  for the right person to guide you forward, our career mentoring
                  services will help you.
                </p>
                <p className={css.stepText}>
                  Career Navig8r makes finding a mentor simple. We connect online
                  career mentors with mentees who need a guiding light. With remote
                  mentoring, your professional goals will become your reality, regardless
                  of where you’re based.
                </p>
                <p className={css.stepText}>
                  Learn from people who’ve already done your job and take control
                  of your career destiny - your goals are within your reach and your
                  career mentor will help you get there.
                </p>
                <p className={css.stepText}>
                  We’re not exaggerating when we say that finding the right career
                  mentor will change your life…it’s time to discover the power of
                  career mentoring.
                </p>

                <p className={css.stepSubSubTitle}>
                  What Is Career Mentoring?
                </p>
                <p className={css.stepText}>
                  A career mentor is an experienced professional who’ll help you
                  drive your career forward. They won’t be the one promoting you
                  or upping your salary, but they’ll help you unlock the power - well,
                  the skills and that all-important oomph - within you to drive
                  your career forward.
                </p>
                <p className={css.stepText}>
                  Your new job title, higher salary, dream role, or career you’re
                  properly proud of all await - you just need to find the right career mentor.
                </p>
                <p className={css.stepText}>
                  Your mentor can provide support and guidance with career development,
                  upskilling, building a professional network, interview prep, CV or
                  cover letter writing or navigating a career change. Our career
                  mentoring services include CV guidance, interview coaching, and
                  sector-specific IT coaching to help you level up your career.
                </p>
                <p className={css.stepText}>
                  Working with a career mentor means you’ll be getting 1-2-1 access
                  to objective, supportive feedback from someone who’s been there
                  already - they’ve had your dream job title, they’ve already smashed
                  the glass ceiling and now they’re passing on their knowledge and
                  experience to you.
                </p>
                <p className={css.stepText}>
                  A career mentor could be someone who works in the same organisation
                  as you, someone you meet at a networking event or someone you
                  connect with online via our career mentoring platform.
                </p>

                <p className={css.stepSubSubTitle}>
                  Career Mentoring Benefits For Mentees
                </p>
                <p className={css.stepText}>
                  Finding a career mentor will have huge benefits for your professional
                  future. You won’t realise the full benefits of becoming a mentee
                  until you’re fully immersed in career mentoring, but here are
                  a few of the benefits to look forward to:
                  <ul className={css.diskList}>
                    <li>
                      <b>Fresh perspective</b> - your career mentor will help you
                      to step back and see your path from a different angle. Access
                      to fresh eyes and a different opinion on your career will
                      open up new opportunities you’ve never considered before
                    </li>
                    <li>
                      <b>Niche expertise</b> - maybe you work in a niche sector
                      (from savoury ice cream flavour development to managing
                      the people who work on robotic toy design - everyone is welcome
                      at Career Navig8r) or want to take a very specific career
                      move in line with your skillset. Whatever your situation,
                      working with a career mentor who has similar experience will
                      provide you with priceless support
                    </li>
                    <li>
                      <b>Career development support</b> - work with your mentor
                      to create a clear, actionable, achievable career development plan
                    </li>
                    <li>
                      <b>Career change advice</b> - considering a new start? A career
                      mentor will look objectively at your transferable skills and
                      offer advice on taking the first step from people management
                      to candlestick making or from radiographer to yoga teacher
                    </li>
                    <li>
                      <b>Goal setting support</b> - turn your goals into actions with
                      advice on creating SMART goals
                    </li>
                    <li>
                      <b>Accountability</b> - regular mentor check-ins will keep you motivated,
                      focused and on track to your goals
                    </li>
                    <li>
                      <b>Direction</b> - big ambitions are incredibly exciting.
                      Knowing when to take the next turn can be tricky. That’s why
                      you need a Career Navig8r to be your compass
                    </li>
                  </ul>
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage1"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentee',
                    }}
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Become a mentee"
                  >
                    Become a mentee
                  </NamedLink>
                </div>

                <p className={css.stepSubSubTitle}>
                  Career Mentoring Benefits For Mentors
                </p>

                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    The benefits of career mentoring for mentors include:
                    <ul className={css.diskList}>
                      <li>
                        <b>Give something back</b> - becoming a career mentor means you’re
                        giving something back to your sector by passing on your expertise,
                        knowledge, and experience to someone who reminds you of you,
                        back in the day. Imagine how different your career would have
                        panned out with that guidance, and enjoy the warm fuzzy feeling
                        of doing something for the greater good
                      </li>
                      <li>
                        <b>Keep one foot in the door</b> - whether you’re considering a ‘returnship’
                        or you’re not quite ready to retire full-time, career mentoring
                        is an ideal option. Work a few hours here and there, keep your
                        brain cogs whirring and enjoy a slower pace as you ease into retirement
                      </li>
                      <li>
                        <b>Flexibility</b> - set up your mentoring sessions at a time
                        and place that suits you. Squeeze them in around childcare,
                        grandparent duties or even your current job
                      </li>
                      <li>
                        <b>Earn money</b> - start mentoring, get paid. Simple
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSignupPage2"
                    name="SignupPage"
                    to={{
                      search: 'userType=mentor',
                    }}
                    className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
                  >
                    Become a Career Mentor
                  </NamedLink>
                </div>

                <p className={css.stepSubSubTitle}>
                  Find a Career Mentor Near Me
                </p>
                <p className={css.stepText}>
                  Find a career mentor in your local area with Career Navig8r. Our
                  online mentoring platform will connect you with a career mentor
                  in your area. The beauty of remote mentoring is that you could
                  be connected with someone at the other end of the country who’s
                  able to offer exactly the kind of guidance you need.
                </p>
                <p className={css.stepText}>
                  Our career mentoring services extend from London to Manchester,
                  from Edinburgh to Nottingham, and everywhere in between.
                </p>

                <p className={css.stepSubSubTitle}>
                  Our Career Mentoring Services
                </p>
                <p className={css.stepText}>
                  Career Navig8r offers a range of career mentoring services to take
                  your career to the next level. Ready to work with a career mentor?
                  Register to become a mentee at Career Navig8r today.
                </p>

                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToSearchPage1"
                    name="SearchPage"
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Find a mentor"
                  >
                    Find a Career Mentor
                  </NamedLink>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
