import React, { useState, Component } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import { InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionHowItWorksContent.module.css';

import leftImage1 from './images/left-image-1.png';
import leftImage2 from './images/left-image-2.png';
import * as validators from '../../util/validators';

const BLOCK_FOR_MENTORS = 1;
const BLOCK_FOR_MENTEES = 2;

const TEXT_COLLAPSED_LENGTH = 90;

const truncated = s => {
  return truncate(s, {
    length: TEXT_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, text } = this.props;
    const truncatedText = truncated(text);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="SectionHowItWorksContent.readMore" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? text : truncatedText}
        {text !== truncatedText && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableText.defaultProps = { className: null };

ExpandableText.propTypes = {
  className: string,
  text: string.isRequired,
};

const SectionHowItWorksContent = props => {
  const [ selectedBlock, setSelectedBlock ] = useState( BLOCK_FOR_MENTORS );
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  const explanationStepBlocks = selectedBlock === BLOCK_FOR_MENTORS ? (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>1</div>
            <div className={css.stepItemPipe}>&nbsp;</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMentorsStep1Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={intl.formatMessage({ id: "SectionHowItWorksContent.forMentorsStep1Text"}, { br: <br/>})}
              />
            </div>
          </div>
        </div>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>2</div>
            <div className={css.stepItemPipe}>&nbsp;</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMentorsStep2Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={intl.formatMessage({ id: "SectionHowItWorksContent.forMentorsStep2Text"})}
              />
            </div>
          </div>
        </div>
        <div className={css.stepItemPipe}>&nbsp;</div>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>3</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMentorsStep3Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={
                  intl.formatMessage(
                    { id: "SectionHowItWorksContent.forMentorsStep3Text"},
                    { br: <br/> }
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>1</div>
            <div className={css.stepItemPipe}>&nbsp;</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMenteesStep1Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={
                  intl.formatMessage(
                    { id: "SectionHowItWorksContent.forMenteesStep1Text"},
                    { br: <br/> }
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>2</div>
            <div className={css.stepItemPipe}>&nbsp;</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMenteesStep2Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={
                  intl.formatMessage(
                    { id: "SectionHowItWorksContent.forMenteesStep2Text"},
                    { br: <br/> }
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={css.stepItemRow}>
          <div className={css.stepItemPipeRow}>
            <div className={css.stepItemActiveFigure}>3</div>
          </div>
          <div className={css.stepItemTextRow}>
            <div className={css.stepItemActiveTitle}>
              <FormattedMessage id="SectionHowItWorksContent.forMenteesStep3Title" />
            </div>
            <div className={css.stepItemActiveText}>
              <ExpandableText
                className={css.stepItemActiveText}
                text={
                  intl.formatMessage(
                    { id: "SectionHowItWorksContent.forMenteesStep3Text"},
                    { br: <br/> }
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <img
              className={css.explanationPicture}
              src={ selectedBlock === BLOCK_FOR_MENTORS ? leftImage1 : leftImage2 }
            />
          </div>
        </div>

        <div className={css.stepExplanationWrapper}>
          <h1 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorksContent.title" />
          </h1>
          <div className={css.explanationBlocks}>
            <div className={css.explanationBlock}>
              <p
                className={selectedBlock === BLOCK_FOR_MENTORS ? css.activeStepSubtitle : css.stepSubtitle}
                onClick={() => setSelectedBlock( BLOCK_FOR_MENTORS )}
              >
                <FormattedMessage id="SectionHowItWorksContent.forMentorsTabTitle" />
              </p>
              <p
                className={selectedBlock === BLOCK_FOR_MENTEES ? css.rightActiveStepSubtitle : css.rightStepSubtitle}
                onClick={() => setSelectedBlock( BLOCK_FOR_MENTEES )}
              >
                <FormattedMessage id="SectionHowItWorksContent.forMenteesTabTitle" />
              </p>
            </div>
          </div>

          {explanationStepBlocks}

        </div>
      </div>
    </div>
  );
};

SectionHowItWorksContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorksContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorksContent;
