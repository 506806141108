import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldTextInput, FieldCheckboxGroup, Form } from '../../components';

import css from './EditListingLanguagesForm.module.css';

const EditListingLanguagesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        languages,
        intl,
        values,
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress ||
        ( !Array.isArray( values[ name ]) || values[ name ].length === 0 ) &&
          !values.anotherLanguage;

      const anotherLanguagePlaceholderMessage = intl.formatMessage({
        id: 'EditListingLanguagesForm.anotherLanguagePlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLanguagesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLanguagesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id={name}
            name={name}
            options={languages}
            twoColumns={true}
          />

          <div className={css.languageSubtitle}>
            <FormattedMessage id="EditListingLanguagesForm.anotherLanguageSubtitle" />
          </div>

          <FieldTextInput
            id="anotherLanguage"
            name="anotherLanguage"
            className={css.anotherLanguage}
            type="text"
            placeholder={anotherLanguagePlaceholderMessage}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLanguagesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  languages: config.custom.languages,
};

EditListingLanguagesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingLanguagesForm = compose(injectIntl)(EditListingLanguagesFormComponent);

export default EditListingLanguagesForm;
