import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionHowToFindYourIdealMentorTop.module.css';

import inlineImage from './images/how-to-find-your-ideal-mentor-small.png';

const SectionHowToFindYourIdealMentorTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Career Navig8r Guide:<br/>How to Find Your Ideal Mentor
                </h2>

                <p className={css.stepText}>
                  Decided that you’d like a mentor? Welcome! 👋 Want to find someone
                  who’s the perfect fit for you? Read on to find out how to use
                  the Career Navig8r platform to connect with your ideal mentor…
                </p>

                <p className={css.stepSubSubTitle}>
                  1. Think About Why You Want a Mentor
                </p>
                <p className={css.stepText}>
                  Start by thinking about your reasons for needing a mentor, and keep
                  your ‘why’ front of mind throughout your search. Your ‘why’ might be:
                  <ul className={css.diskList}>
                    <li>
                      You want to go after a promotion
                    </li>
                    <li>
                      You’re considering a career change
                    </li>
                    <li>
                      You’d like a second opinion on your career path
                    </li>
                    <li>
                      You want to improve your skills in a particular area
                    </li>
                    <li>
                      You’re starting your own business and want some advice from
                      someone who’s been there before
                    </li>
                    <li>
                      You’re interested in supporting someone else with their career
                      path while they give you a steer at the same time via a
                      &nbsp;
                      <ExternalLink
                        href="https://careernavig8r.com/blog/what-is-peer-mentoring/"
                        className={css.link}
                        sameTab={true}
                      >
                        peer mentoring
                      </ExternalLink>
                      &nbsp;
                      partnership
                    </li>
                    Whatever your reason for searching for a mentor, it will influence
                    the type of mentor you’re looking for.
                  </ul>
                </p>


                <p className={css.stepSubSubTitle}>
                  2. Know What Your Ideal Mentor Looks Like
                </p>
                <p className={css.stepText}>
                  Next, think about what your perfect mentor looks like - we don’t
                  mean in terms of appearance, but rather the
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/what-are-the-qualities-of-a-great-mentor/"
                    className={css.link}
                    sameTab={true}
                  >
                    skills and qualities
                  </ExternalLink>
                  &nbsp;
                  you want them to have.
                  <ul className={css.diskList}>
                    <li>
                      What kind of skills do you want them to have? Think about
                      &nbsp;
                      <ExternalLink
                        href="https://careernavig8r.com/blog/why-soft-skills-mentoring-shouldnt-be-dismissed/"
                        className={css.link}
                        sameTab={true}
                      >
                        soft skills
                      </ExternalLink>
                      &nbsp;
                      , as well as ‘hard’ (technical or job-specific) skills
                    </li>
                    <li>
                      Would you prefer someone on the same level as you, who you
                      can bounce ideas off?
                    </li>
                    <li>
                      Do you want someone who works in your industry, or who has
                      the exact job title you’re aiming for yourself?
                    </li>
                  </ul>
                  Think about the qualities that are most important to you in a mentor
                  and bear these in mind as you start your search.
                </p>

                <p className={css.stepSubSubTitle}>
                  3. Use Our Search Function
                </p>
                <p className={css.stepText}>
                  Now you know what you’re looking for, it’s time for the exciting
                  bit as you start searching for your mentor. You could be about
                  to meet someone who’ll change your life and the entire course
                  of your career, helping you navigate the path towards your
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/how-to-set-mentoring-goals/"
                    className={css.link}
                    sameTab={true}
                  >
                    professional goals
                  </ExternalLink>
                  &nbsp;
                  and achieve a level of fulfilment you didn’t know was possible.
                </p>
                <p className={css.stepText}>
                  The Career Navig8r platform is incredibly easy to use:
                  <ul className={css.diskList}>
                    <li>
                      <b>Start</b>: go to our
                      &nbsp;
                      <NamedLink
                        key="linkToSearchPage1"
                        name="SearchPage"
                        className={css.link}
                        title="find a mentor"
                      >
                        find a mentor
                      </NamedLink>
                      &nbsp;
                      page. You’ll see all of our mentor profiles in one place,
                      but it’s best to use the search bar to narrow down your search
                      to find your ideal mentor
                    </li>
                    <li>
                      <b>Search</b>: use the search bar to type in a job role or select
                      one from the list - this could be your current job role or
                      the one you want to have one day, depending on what you want
                      to get out of the mentoring experience
                    </li>
                    <li>
                      <b>Results</b>: you’ll then see a list of mentors who match your
                      requirements. For example, if you’ve searched for ‘Account
                      Manager’, you’ll see a list (or grid - you can choose your
                      preferred view to make it work for you!) of people who are
                      Account Managers or who have experience in that area
                    </li>
                    <li>
                      <b>Find your Navig8r</b>: click through to the different mentor profiles
                      to find out more about each person. You’ll see a photo of
                      each mentor, alongside details of their experience, specific
                      projects they’ve worked on and their key skills
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  4. Refine Your Search
                </p>
                <p className={css.stepText}>
                  If you don’t find a mentor whose skills catch your eye right away,
                  there are several ways you can refine your search. The Career
                  Navig8r platform lets you search by sector, experience level,
                  language and price, so you can find a mentor who’s perfect for you.
                  <ul className={css.diskList}>
                    <li>
                      <b>Search by sector</b>: perhaps you’re looking for a mentor with
                      project management experience, but you work in the charity
                      sector where things are structured completely differently
                      to other industries. Our platform lets you get specific: search
                      for Project Managers, then select ‘Charity and Voluntary Work’
                      - the combinations are endless
                    </li>
                    <li>
                      <b>Browse by experience level</b>: whether you’re looking for a mentor
                      as you start your career or you’re at the top of your game
                      and want advice from someone who’s already walked in your
                      shoes, you can search for mentors with entry level, mid-level,
                      senior and C-level experience
                    </li>
                    <li>
                      <b>Select your preferred language</b>: we ask all of our Navig8rs
                      to list the languages they can speak on their profiles, so
                      you can find a mentor who speaks your language - both literally
                      and metaphorically
                    </li>
                    <li>
                      <b>Filter by budget</b>: Career Navig8rs can set their own
                      hourly rates, which means the cost of a mentoring session
                      will vary depending on the mentor you choose. Whatever your
                      budget is, you can narrow down your search so you’re only
                      seeing results that work with with your budget
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  5. How to Know You’ve Found the Right Mentor
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    So how do you know when you’ve found the right mentor? We recommend
                    that everyone books in a 20-minute free discovery call with their
                    chosen mentor before beginning a
                    <ExternalLink
                      href="https://careernavig8r.com/blog/what-are-the-relationship-guidelines-between-a-mentor-and-mentee/"
                      className={css.link}
                      sameTab={true}
                    >
                      mentoring relationship.
                    </ExternalLink>
                    &nbsp;
                    Just click the ‘Free discovery meeting’ button to schedule a
                    getting-to-know-you chemistry session in.
                    <br/><br/>
                    This is an opportunity to see if you feel comfortable chatting
                    openly to your potential mentor, to find out more about their
                    skills and experience, and to make sure their availability works
                    for you. Prepare a few questions and make yourself a cuppa ready
                    for a good chat. You’ll know when it feels right!
                    <br/><br/>
                    If you’re not sure if someone is the right mentor for you, don’t
                    worry - it doesn’t mean you’ve missed out! There’s no obligation
                    to continue a mentoring relationship after that first free session.
                    You can simply start your search again! The discovery call might
                    even make you realise you were looking for something a little different,
                    so reset your filters and keep going until you’ve found a mentor
                    who’s the right fit for you.
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  6. Reach Out to a Mentor
                </p>
                <p className={css.stepText}>
                  There are a few different ways you can reach out to a mentor:
                  <ul className={css.diskList}>
                    <li>
                      <b>Book a free discovery call</b>: spend 20 minutes getting to know
                      each other to find out if you’re the right fit for a mentoring
                      partnership. Not many things in life are free, but this is!
                    </li>
                    <li>
                      <b>Ask a question before booking anything</b>: there’s an option
                      to message mentors before you book a discovery session or
                      a proper mentoring session. Get in touch to ask about their
                      availability or to ask a specific question about the information
                      on their profile. This can be a great way to start your initial conversation
                    </li>
                    <li>
                      <b>Book a full meeting</b>: if you already know you want to work
                      with someone, go ahead and schedule your first mentoring session.
                      Remember, you’ll be charged the mentor’s hourly rate for this,
                      so make sure you’re certain before you book in
                    </li>
                  </ul>
                </p>
                <p className={css.stepText}>
                  The Career Navig8r platform is set up so that all your mentor-mentee
                  communications can take place in one place, using our virtual
                  meeting technology. This keeps everything secure and professional,
                  and protects your work-life balance too.
                </p>
                <p className={css.stepText}>
                  If you’re not sure how to craft your first message to a mentor,
                  try to include the following:
                  <ul className={css.diskList}>
                    <li>
                      A friendly greeting - use their name (and triple check you’ve
                      spelt it right!) to add a personal touch
                    </li>
                    <li>
                      Explain what you currently do for work - if you’re on a
                      &nbsp;
                      <ExternalLink
                        href="https://careernavig8r.com/blog/career-break-ideas-to-keep-your-bank-account-topped-up/"
                        className={css.link}
                        sameTab={true}
                      >
                        career break
                      </ExternalLink>
                      &nbsp;
                      , explain a bit about why and when you’re planning to return
                    </li>
                    <li>
                      Include a few details on why you’re looking for a mentor -
                      this will give them an idea of the kind of support and guidance
                      you require
                    </li>
                    <li>
                      Be clear about your availability - will you only be able to attend
                      mentoring sessions in the evenings? Would you prefer weekends?
                      Everyone has
                      &nbsp;
                      <ExternalLink
                        href="https://careernavig8r.com/blog/how-to-set-boundaries-in-mentoring/"
                        className={css.link}
                        sameTab={true}
                      >
                        boundaries
                      </ExternalLink>
                      &nbsp;
                      , so be clear about yours from the start
                    </li>
                    <li>
                      Let them know you’re looking forward to hearing from them -
                      it’s only polite!
                    </li>
                    <li>
                      Sign off - you don’t need to be super formal with a ‘yours sincerely’.
                      ‘Many thanks’ or ‘best wishes’ or even ‘kind regards’ will be fine 😊
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  What Happens Next?
                </p>
                <p className={css.stepText}>
                  Once you’ve made contact, wait for the mentor to get back to you,
                  schedule in your discovery call, then go ahead and start your mentoring
                  relationship. We can’t wait to hear about what you achieve together!
                </p>
                <p className={css.stepText}>
                  In the meantime, check out these tips on
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/how-to-be-a-great-mentee-and-impress-your-mentor/"
                    className={css.link}
                    sameTab={true}
                  >
                    how to be a great mentee
                  </ExternalLink>
                  &nbsp;.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionHowToFindYourIdealMentorTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowToFindYourIdealMentorTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowToFindYourIdealMentorTop;
