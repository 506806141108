import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './SectionCommunityThrivelinesContent.module.css';

const SectionCommunityThrivelinesContent = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <p className={css.contentText}>
            Careernavig8r.com is a place and means for people, with experience in a role, to mentor
            people new to these roles or people who hope to enter them.<br/>
            Equally, they can offer guidance to people already in the role and looking to get better.<br/>
            Both Navig8rs (Mentors) and Mentees use careernavig8r.com as a meeting place, or
            maybe more  accurately, as an online marketplace.<br/>
            Like any marketplace - in our case an exchange of skills, knowledge, experience
            Gguidance - for money - there needs to be a level of trust, honesty & openness.<br/>
            We’re looking to build an environment where people trust they will find the skills they
            are looking for to achieve the next step on their career path.<br/>
            An environment where people who have all of the knowledge know they can share it to
            help others on their career path, and be rewarded for it.<br/>
            We want a community.<br/>
            A community needs structure to thrive, so we’ve written down some ‘Community
            Guidelines’<br/>
            You could just as easily call them Community Thrivelines<br/>
            So, please see below for our community guidelines and try your hardest to stick
            to   them.  It  should  be  easy  as  most  of  them  are  fairly  logical  and
            straightforward, and all based on openness, honesty and integrity.<br/>
            NB - There will be one of those strict-sounding notices at the
            bottom. If you’re that way inclined, you can race down and
            read them now...<br/>
            If not sit back, relax and give the next few paragraphs a read.<br/>
            We’ve started with Mentors (or Navig8rs) for no particular reason other than...we wanted
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Mentors must:</h2>
          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Not take bookings or payment directly from their mentee(s)<br/>
                This isn’t just part of our community guidelines but goes against the terms &
                conditions of the site.<br/>
                This rule is for the benefit of everyone, not only does it ensure we get our 10%
                cut, but it also covers the Mentor too. Read on for why...<br/>
                <ul className={css.circleList}>
                  <li className={css.ctli}>
                    Success on Career Navig8r is gained by getting a steady stream of
                    Mentees, and revenue. Once you finish working with a mentee, they’ll
                    leave a review. The more reviews you have, the more likely future
                    mentees will pick you. Taking meetings off the site deprives you of that
                    opportunity
                  </li>
                  <li className={css.ctli}>
                    You’re also signaling to the Mentee, that you’re willing to be unethical.
                    (Remember, they have a set of guidelines too, and it’s also in their terms &
                    conditions) So, when payment is needed, would they be more or less likely
                    to trust you, if you’ve already shown you’re happy to circumnavigate the
                    rules?
                    Remember we talked about trust and honesty earlier :-)
                  </li>
                  <li className={css.ctli}>
                    You’ll also not be covered by our cancellation and arbitration policies.
                    The Career Navig8r payment system ensures all payments are
                    automatic, all financial information safe and you’re both protected by an
                    independent and highly secure payment system (e.g. Stripe). Having a
                    place to find a steady stream of mentees, and the systems in place to
                    support this makes continuing to work through us easy, profitable, safe
                    and ethical.
                  </li>
                </ul>
              </li>
              <li className={css.ctli}>
                Always comply with local and international laws and tax laws.<br/>
                Career Navig8r acts as a communication platform and is not liable for ensuring
                compliance by Mentors. It is your responsibility to ensure all local and
                international laws, and tax compliances are met. If you’re adult enough to offer
                mentoring services, then you’re adult enough to know that you are responsible for
                reporting your own financial situation to the relevant bodies.<br/>
                Career Navig8r is not your employer and bears no responsibility for your
                income/tax liabilities.
              </li>
              <li className={css.ctli}>
                Accurately represent themselves in terms of experience, education, pricing,
                and the services they offer.<br/>
                You should accurately present:<br/>
                Your education, educational awards/accreditation and working experience.<br/>
                Including the accrediting body, the time it took to gain your award and the level
                attained. Where you worked, the role(s) you had and what this involved.<br/>
                Give examples of how you approached the work and the length of time in each
                role. You’ve got previous mentoring experience? You should definitely mention
                that.!<br/>
                Your photograph must look like you, and ideally look professional.<br/>
                You must accurately present the services you offer and the price you charge for
                them. Career Navig8r will take action if it is discovered that you are
                misrepresenting yourself or the services that you offer.<br/>
              </li>
              <li className={css.ctli}>
                Behave professionally and courteously<br/>
                When communicating with anybody, for any reason, including during mentoring
                sessions, your behaviour should be professional, inclusive and non-
                discriminatory.<br/>
                We want you to relax and get on well with your mentees, that’s when mentoring
                works best. However please bear in mind your language & behaviours should be
                polite and respectful at all times.<br/>
                To make it easy we’ve compiled a little list of all the things we do not expect
                you to do while using Career Navig8r<br/>
                - No language or behaviours of a sexual nature.<br/>
                - No alcohol or illegal drugs should be consumed.<br/>
                - No  discrimination  of  any  nature  on  the  basis  of  -  Gender,  Age,  Marital
                Status,  Family  Status,  Sexual  Orientation,  Religion,  Race,  Disability,  or
                membership of the Travelling Community or, any other category of person
                as defined by local/national laws.<br/>
                - It is strictly forbidden to form any type of sexual/dating relationship with other
                members of the Career Navig8r community or users of careernavig8r.com<br/>
                Phew...it’s a long list, but I think you’ll agree it’s a fair one!
              </li>
              <li className={css.ctli}>
                Only list and offer Mentoring that you are allowed to offer.<br/>
                If any of your previous roles are covered by non-disclosure agreements or secrecy
                agreements (maybe you were a spy), please don’t offer those on Career Navig8r
                unless you have written confirmation from the relevant employer.<br/>
                If it is discovered that you are offering information that you do not have permission
                to offer, the listing will be removed, and your account will be subject to removal
                from the site.
              </li>
              <li className={css.ctli}>
                Never engage in illegal or prohibited activity to or with a Mentee
              </li>
              <li className={css.ctli}>
                Never meet a Mentee in person (except for online/virtual meetings)
              </li>
              <li className={css.ctli}>
                Confidentiality is a critical aspect of safety on Career Navig8r.
              </li>
              To round up - be nice - be honest - don’t discriminate and we’ll all get on fine
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Mentees must:</h2>
          <p className={css.contentText}>
            <ul className={css.diskList}>
              <li className={css.ctli}>
                Not ask your Mentor to take bookings or payments directly (not using the
                Career Navig8r website) nor accept this request from your Mentor.<br/>
                This isn’t just part of our community guidelines but goes against the terms &
                conditions of the site.<br/>
                This rule is for the benefit of everyone, not only does it ensure we get our 10%
                cut, but it also covers you, the Mentee too. Read on for why...<br/>
                <ul className={css.circleList}>
                  <li className={css.ctli}>
                    You’re also signalling to your Mentor, that you’re willing to be unethical.<br/>
                    (Remember, they have a set of guidelines too, and it’s also in their terms &
                    conditions) So, when payment is needed, would they be more or less likely
                    to trust you, if you’ve already shown you’re happy to circumnavigate the
                    rules?<br/>
                    Remember we talked about trust and honesty earlier :-)
                  </li>
                  <li className={css.ctli}>
                    You’ll also not be covered by our cancellation and arbitration policies.<br/>
                    The Career Navig8r payment system ensures all payments are
                    automatic, all financial information safe and you’re both protected by
                    an independent and highly secure payment system (e.g. Stripe). Having a
                    place to find a steady stream of mentors, and the systems in place to
                    support this makes continuing to work through us easy, profitable, safe
                    and ethical.
                  </li>
                  <li className={css.ctli}>
                    If you have a complaint, requesting a refund etc., Career Navig8r will not be
                    able to help and you will have to make this request to a Mentor who has
                    shown themselves willing to be unethical.
                  </li>
                  <li className={css.ctli}>
                    Adjudication can only occur through the Career Navig8r process. If you
                    have communicated through any other medium other than the Career
                    Navig8r communication system you have broken the Terms and Conditions
                    of the website and as such Career Navig8r is no longer in a position to
                    mediate on your behalf with the mentor. As such, any action to gain a
                    refund must be performed with the mentor.
                  </li>
                </ul>
              </li>
              <li className={css.ctli}>
                Understand the Role of “Career Navig8r” and what it offers.<br/>
                Career Navig8r is a communication platform and as such is not liable for
                ensuring compliance by Mentors. Your Mentoring purchase is direct with the
                Mentor (your Mentor is the service provider, not Career Navig8r).<br/>
                As such our ability to ensure Mentor’s compliance is limited to:<br/>
                <ul className={css.circleList}>
                  <li className={css.ctli}>
                    Being able to withhold payment to a Mentor for a limited time period while
                    an issue is investigated, and within this limited period, if the investigation
                    sides with the Mentee, to permanently withhold payment and refund the
                    Mentee (please see  terms and conditions).
                  </li>
                  <li className={css.ctli}>
                    The removal of the Mentor from the Career Navig8r platform.
                  </li>
                </ul>
              </li>
              <li className={css.ctli}>
                Accurately represent themselves to the Mentor<br/>
                You must accurately present your education, educational awards/accreditation
                and working experience
              </li>
              <li className={css.ctli}>
                Behave professionally and courteously<br/>
                When communicating with anybody, for any reason, including during mentoring
                sessions, your behaviour should be professional, inclusive and non-
                discriminatory..<br/>
                We want you to relax and get on well with your mentors, that’s when mentoring
                works best. However please bear in mind your language G behaviours should be
                polite and respectful at all times.<br/>
                To make it easy we’ve compiled a little list of all the things we do not expect
                you to do while using Career Navig8r<br/>
                - No language or behaviours of a sexual nature.<br/>
                - No alcohol or illegal drugs should be consumed.<br/>
                - No  discrimination  of  any  nature  on  the  basis  of  -  Gender,  Age,  Marital
                Status,  Family  Status,  Sexual  Orientation,  Religion,  Race,  Disability,  or
                membership of the Travelling Community or, any other category of person
                as defined by local/national laws.<br/>
                - It is strictly forbidden to form any type of sexual/dating relationship with other
                members of the Career Navig8r community or users of careernavig8r.com
              </li>
              <li className={css.ctli}>
                Only ask for Mentoring that you have the permission to receive.<br/>
                If any of your current or previous job roles are covered by any form of a non-
                disclosure agreement or secrecy agreement, you must ask for mentoring in
                these roles unless you have written permission from the relevant employer.<br/>
                If it is discovered that you are offering information or receiving Mentoring that you
                do not have permission to receive, your account will be subject to removal from the
                site.<br/>
              </li>
              <li className={css.ctli}>
                You must offer both Fair and Accurate Feedback.
              </li>
              <li className={css.ctli}>
                Never engage in illegal or prohibited activity with or to your Mentor.
              </li>
              <li className={css.ctli}>
                Never meet a Mentor in person (except for online/virtual meetings).
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Everyone must:</h2>
          <p className={css.contentText}>
            <ul className={css.diskList}>
              <li className={css.ctli}>
                Communicate honestly with others.<br/>
                Career Navig8r is a trusted marketplace and depends on the honesty, integrity, and
                best interest of everyone that uses the platform. Only message others with the
                best intent in mind, do not make false claims, or misrepresentations, do not engage
                in fraudulent activity and do not willingly be deceitful.<br/>
                Just be nice to each other folks.
              </li>
              <li className={css.ctli}>
                Use our platform for its intended use.<br/>
                Career Navig8r is a communication platform.<br/>
                It enables mentors and mentees to find each other. The messaging is limited to
                booking meetings, confirming or rejecting meetings and arranging meetings.<br/>
                It also enables Mentors to showcase the roles that can mentor in, and affords
                mentees the opportunity to discuss suitability with their mentor..<br/>
                Any messages that are not intended for the purposes above, or are deemed
                fraudulent or harmful to either user will be reviewed and where appropriate,
                may result in legal action being taken by Career Navig8r.<br/>
                All users and booking requests are also bound by the Terms of Service that are
                agreed upon by using the platform. Career Navig8r is neither a dating site nor a
                place for you to carry out (or even arrange) fraudulent activity. Play nicely,
                folks.
              </li>
              <li className={css.ctli}>
                Respect everybody's privacy and information.<br/>
                Many Mentors are world-renowned professionals, some may also have well-
                known Mentees. It is both professional and common courtesy to respect
                the privacy of the mentoring relationship.<br/>
                In some cases, a Mentee/Mentor may ask for a Non-Disclosure Agreement (NDA)
                prior to their booking. It’s entirely up to you if you want to sign one. But it’s
                equally up to the other person to not want to carry on with the relationship without
                one.<br/>
                <ul className={css.circleList}>
                  <li className={css.ctli}>
                    Maintaining all information received as Confidential<br/>
                    Mentoring and all the things discussed in a mentoring session should be treated as
                    confidential, and only to be shared between the two people in the session.<br/>
                    Don’t discuss the specifics of anything discussed inside your sessions with
                    anyone from outside your sessions.<br/>
                    Anyone found to be breaching the confidentiality of either their mentee or
                    mentor could have their profile and listing removed from the site.<br/>
                    Now, here comes another of those long, might be a bit boring, bit necessary
                    pieces... (please note: what follows is not a legally binding agreement or a legal
                    document at all, rather it is designed to give both Mentor and Mentee further
                    clarity in this area)
                  </li>
                  <li className={css.ctli}>
                    Confidential Information “means all business or technical information of
                    Discloser, whether it is received, accessed or viewed by Disclosee in writing,
                    visually, electronically or orally. Confidential Information includes, but is not
                    limited to, the following types of information, and other information of a
                    similar nature (whether or not reduced to writing or still in development):<br/>
                    designs, concepts, drawings, inventions, specifications, techniques,
                    discoveries, models, data, content material, source code, object code,
                    documentation, diagrams, flow charts, research, development, processes,
                    procedures, know-how, new product or new technology  information,
                    marketing techniques and materials, marketing plans, timetables, strategies
                    and development plans (including prospective trade names or  trademarks),
                    customer names and other information related to customers, pricing
                    policies, and financial information, as well as analyses, compilations, studies
                    or documents prepared by Discloser or its Representatives which contain or
                    reflect the Confidential information.
                  </li>
                  <li className={css.ctli}>
                    Either Party may disclose Confidential Information to the other Party in
                    confidence provided that the Discloser identifies such information as
                    proprietary and  confidential either by marking it, in the case of written
                    materials, or, in the case of information that is disclosed orally or written
                    materials that are not marked, by notifying the other Party of the proprietary
                    and confidential nature of the information, such notification to be done
                    orally, by e-mail or written correspondence, or via other means of
                    communication as might be appropriate within a reasonable time (not to
                    exceed thirty (30) days) after the disclosure, or provided the disclosed
                    information would, under the circumstances, appear to a reasonable person
                    to be confidential or proprietary.
                  </li>
                  <li className={css.ctli}>
                    ○Exceptions. Notwithstanding the above, this direction on
                    Confidentiality while operating on the Career Navig8r platform and
                    when in Mentoring Sessions / Meetings does not impose obligations
                    of secrecy if:<br/>
                    <ul className={css.squareList}>
                      <li className={css.ctli}>
                        Was known to the Disclosee, without restriction, at the time of
                        disclosure, as demonstrated by files in existence at the time of
                        disclosure;
                      </li>
                      <li className={css.ctli}>
                        The information in question is an object of public knowledge;
                      </li>
                      <li className={css.ctli}>
                        The information in question is approved for disclosure through prior
                        written authorization from the Discloser;
                      </li>
                      <li className={css.ctli}>
                        The information in question was independently developed by the
                        Disclosee without any use of the Confidential Information of the
                        Discloser and by employees of the Disclosee who have not had
                        access to the Confidential Information, as demonstrated by files
                        created at the time of such independent development;
                      </li>
                      <li className={css.ctli}>
                        The information in question shall be disclosed in order to fulfil
                        obligations under agreements between parties;
                      </li>
                      <li className={css.ctli}>
                        The information in question is disclosed to the Disclosee by a third
                        party who has the right to make such a disclosure, or
                      </li>
                      <li className={css.ctli}>
                        The information in question is disclosed in response to a valid order of
                        a court or other governmental agency or any political subdivision,
                        but only to the extent of and for the purpose of such an order;
                        provided, however, that the Disclosee shall first notify the Discloser
                        in writing to seek a protective order or take other appropriate and
                        necessary action.
                      </li>
                    </ul>
                  </li>
                  <li className={css.ctli}>
                    Obligations. The Disclosee should:<br/>
                    <ul className={css.squareList}>
                      <li className={css.ctli}>
                        Hold the Discloser’s Confidential Information in strict confidence;
                      </li>
                      <li className={css.ctli}>
                        Not disclose such Confidential Information to any third party except
                        as specifically authorized herein or as specifically authorized by the
                        Discloser in writing;
                      </li>
                      <li className={css.ctli}>
                        further agrees that in protecting Confidential Information against
                        unauthorised use, dissemination or publication, it shall use the same
                        degree of care as it uses in protecting its own information of similar
                        nature, but no less than reasonable care; and
                      </li>
                      <li className={css.ctli}>
                        not to use any Confidential Information for any purpose other than
                        for the purpose of giving or receiving mentoring
                      </li>
                      <li className={css.ctli}>
                        inform the other party immediately upon becoming aware or
                        suspecting that an unauthorised person has become aware of
                        Confidential Information;
                      </li>
                    </ul>
                  </li>
                  <li className={css.ctli}>
                    The Disclosee shall:<br/>
                    <ul className={css.squareList}>
                      <li className={css.ctli}>
                        inform anyone to whom it discloses Confidential Information
                        that the information is confidential; and
                      </li>
                      <li className={css.ctli}>
                        procure that anyone to whom it discloses the Confidential Information
                        complies with this agreement as if they were a party and shall, in any
                        event, remain liable to the Discloser or for any breach of this
                        Agreement committed
                        by any party to whom the Disclosee has disclosed the Confidential
                        Information.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <br/>
            Congratulations if you read through all of the last bit.<br/>
            Now, just to not lighten the mood even further, what follows next is the strict-sounding
            piece we warned you about..<br/>
            We mentioned our Community Guidelines (or Thrivelines) earlier and while we try
            to keep it light-hearted and relaxed, they are there for a reason.<br/>
            The reasons, I’m sure you remember are safety, honesty, trust, openness and general
            all-round success of everyone involved with the platform.<br/>
            As such:<br/>
            Career Navig8r Community Guidelines are actively enforced to enable effective trust,
            and its critical components of safety and effective behaviour, to be adhered to. Career
            Navig8r takes violations of these Community Guidelines seriously and may suspend
            or terminate an Account for ongoing or serious violations. Career Navig8r reserves the
            right to edit or remove content on the site that violates these Community Guidelines or
            if we believe it adversely affects the integrity of the Career Navig8r platform or its
            users.<br/>
            By using Career Navig8r / careernavig8r.com, you agree to strictly observe and follow
            these Community Guidelines. If you have any questions about these Community
            Guidelines, or if you believe that a user has violated these Community Guidelines, please
            email us immediately at admin@careernavig8r.com<br/>
            We’re here for you so if you have ANY fears or concerns or if you have any worries at
            all about your privacy or safety on Career Navig8r please send us an email
          </p>
        </div>
      </div>
    </div>
  );
};

SectionCommunityThrivelinesContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCommunityThrivelinesContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCommunityThrivelinesContent;
