import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionHero.module.css';

import arrowImage from './images/arrow-down.png';

const NUMBER_OF_TITLES = 7;

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState( 1 );
  const { rootClassName, className, viewport } = props;

  useEffect(() => {
    setMounted(true);
    const intervalID = setInterval(() => {
        setSelectedTitle( preIndex => preIndex < NUMBER_OF_TITLES ? preIndex + 1 : 1 );
      },
      3000 );

    return () => clearInterval( intervalID );
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage id={`SectionHero.title${selectedTitle}`}/>
        </h1>
        <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" values={{ br: <br/>}} />
        </h2>
        <div className={css.buttonsWrapper}>
          <NamedLink
            key="linkToLandingPage1"
            name="SearchPage"
            className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
            title="Find a mentor"
          >
            <FormattedMessage id="SectionHero.browseButton1" />
          </NamedLink>
          <NamedLink
            name="SignupPage"
            to={{
              search: 'userType=mentor',
            }}
            className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
          >
            <FormattedMessage id="SectionHero.browseButton2" />
          </NamedLink>
        </div>
        <NamedLink
          className={css.arrowImageLink}
          name="LandingPage"
          to={{ hash: '#sectionBecomeMentor' }}
        >
          <img className={css.arrowImage} src={arrowImage} />
        </NamedLink>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionHero);
