import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import BenefitsContentForMentor from './BenefitsContentForMentor';
import BenefitsContentForMentee from './BenefitsContentForMentee';

import css from './SectionBenefits.module.css';

const SectionBenefitsComponent = props => {
  const { rootClassName, className, isLandingPage, currentUser, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {isLandingPage ? <BenefitsContentForMentor currentUser={currentUser} intl={intl} />
        : <BenefitsContentForMentee currentUser={currentUser} intl={intl} />
      }
    </div>
  );
};

SectionBenefitsComponent.defaultProps = { rootClassName: null, className: null };

SectionBenefitsComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionBenefits = injectIntl(SectionBenefitsComponent);

export default SectionBenefits;
