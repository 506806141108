import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SignupSectionPending.module.css';

const SignupSectionPendingComponent = props => {
  const { rootClassName, className, intl, email } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div>
        <div className={css.formTitle}>
          <FormattedMessage id="SignupSectionPending.title" values={{ br: <br/> }}/>
        </div>
        <div className={css.formSubtitle}>
          <FormattedMessage
            id="SignupSectionPending.text"
            values={{ br: <br/>, email: <div className={css.emailText}>{email}</div> }}
          />
        </div>
      </div>
    </>
  );
};

SignupSectionPendingComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupSectionPendingComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupSectionPending = compose(injectIntl)(SignupSectionPendingComponent);
SignupSectionPending.displayName = 'SignupSectionPending';

export default SignupSectionPending;
