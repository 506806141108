import React, { useState, useEffect } from 'react';
import SlotCounter from 'react-slot-counter';

const SlotTest = (props) => {

  const { text, id } = props;
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const slotText = (
    <SlotCounter
      key={id}
      value={text}
      dummyCharacters={'---'}
      duration={0.25}
      charClassName='slot-counter-char'
      separatorClassName='slot-counter-separator'
      containerClassName='slot-container'
    />
  );

  const hiddenSlotText = (
    <SlotCounter
      key={`${id}-hidden`}
      value={text}
      dummyCharacters={'---'}
      duration={0.25}
      charClassName='slot-counter-char'
      separatorClassName='slot-counter-separator'
      containerClassName='slot-container-hidden'
    />
  );

  return (
    <>
      {showText ? slotText : hiddenSlotText}
    </>
  );
};

export default SlotTest;
