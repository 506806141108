import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionHowToLeaveFeedbackForYourMentorTop.module.css';

import inlineImage from './images/how-to-leave-feedback-for-your-mentor-small.png';

const SectionHowToLeaveFeedbackForYourMentorTop = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  Career Navig8r Guide:<br/>How to Leave Feedback for Your Mentor
                </h2>

                <p className={css.stepText}>
                  Did you know the Career Navig8r platform lets you brighten your
                  mentor or mentee’s day in a matter of moments?
                </p>
                <p className={css.stepText}>
                  Our easy-to-use feedback function lets you leave a comment for
                  your mentor, so they can see how useful your sessions have been.
                  This also lets other mentees see an honest review that might help
                  make up their mind on which mentor to reach out to. Whether you’re
                  buying a new shirt, looking for the best pizza restaurant or choosing
                  a mentor, it’s always good to get someone else’s opinion first!
                </p>
                <p className={css.stepText}>
                  Mentors can leave feedback for their mentees too. A lovely comment
                  on how far you’ve come so far might just be the motivation you
                  need to take the next steps towards your
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/how-to-set-mentoring-goals/"
                    className={css.link}
                    sameTab={true}
                  >
                    mentoring goals
                  </ExternalLink>
                  &nbsp;
                  .
                </p>
                <p className={css.stepText}>
                  It’s easy to leave feedback via Career Navig8r and it can be really
                  beneficial for all parties, so go ahead and make someone’s day.
                  It’s free and takes just minutes, but can make someone feel a million dollars.
                </p>

                <p className={css.stepSubSubTitle}>
                  The Power of Feedback
                </p>
                <p className={css.stepText}>
                  It’s nice to be nice. But it’s also good to provide
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/how-to-give-a-mentee-constructive-criticism/"
                    className={css.link}
                    sameTab={true}
                  >
                    constructive feedback
                  </ExternalLink>
                  &nbsp;
                  or to be open about where you feel there’s room for improvement.
                  Honest feedback can help mentors hone their techniques and learn
                  more about what you might find useful.
                </p>
                <p className={css.stepText}>
                  Meanwhile, receiving feedback as a mentee can be a great confidence
                  boost and help reassure you that you’re on the right track to
                  your goals. If you really look up to your mentor, hearing their
                  feedback can mean a lot.
                </p>
                <p className={css.stepText}>
                  Our
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/community-thrivelines"
                    className={css.link}
                  >
                    Community Thrivelines
                  </ExternalLink>
                  &nbsp;
                  ask that all feedback you leave is fair
                  and accurate - honesty and trust are integral to every successful
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/what-are-the-relationship-guidelines-between-a-mentor-and-mentee/"
                    className={css.link}
                    sameTab={true}
                  >
                    mentor-mentee relationship
                  </ExternalLink>
                  &nbsp;
                  . Fairness and accuracy are the bare
                  minimum in our eyes, but you can go to town with your glowing
                  praise, endorsements and recommendations. Spread a little positivity.
                  There’s always room for more of that 😊
                </p>

                <p className={css.stepSubSubTitle}>
                  When to Leave Feedback for Your Mentor
                </p>
                <p className={css.stepText}>
                  So, when should you leave feedback for your mentor? You can leave
                  it after every session, if you like, but you might feel especially
                  compelled to leave them a note on these occasions:
                  <ul className={css.diskList}>
                    <li>
                      When they help you overcome a challenge
                    </li>
                    <li>
                      When you have a session that leaves you feeling really inspired
                    </li>
                    <li>
                      When they pass on a piece of wisdom or advice that changes
                      the way you think about the path ahead
                    </li>
                    <li>
                      When you achieve something that they’ve been helping you with -
                      whether it’s getting that promotion or a new job offer or feeling
                      a light bulb turn on in your brain, illuminating the path ahead for you
                    </li>
                    <li>
                      When they go above and beyond for you. They might connect you
                      with one of their key contacts or spend hours preparing resources
                      for your next session. Going the extra mile deserves recognition
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  How to Leave Feedback for Your Mentor
                </p>
                <p className={css.stepText}>
                  After your mentoring session, you’ll be prompted to leave feedback
                  for your mentor. There’s no obligation to, but it can be a really
                  lovely, helpful thing to do, so try to do it from time to time.
                </p>
                <p className={css.stepText}>
                  If you choose to give feedback, you’ll be presented with a comment
                  box where you can leave a few lines to let your mentor - and other
                  prospective mentees who might be reading it - know how you feel
                  your sessions are going.
                </p>
                <p className={css.stepText}>
                  Once you’ve left feedback, the mentor will have the opportunity
                  to respond, and then the comment will be closed.
                </p>

                <p className={css.stepSubSubTitle}>
                  Mentor Feedback: What To Include
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    It can be tricky to know where to start with leaving feedback for
                    your mentor, especially if they’ve helped you in lots of ways.
                    Here are some ideas for what to include:
                    <ul className={css.diskList}>
                      <li>
                        A little summary of how you feel the individual mentoring
                        session or your entire mentoring journey has gone
                      </li>
                      <li>
                        Were your expectations met, exceeded or perhaps even completely
                        blown away? Let them know!
                      </li>
                      <li>
                        Would you like to work with the mentor again? Maybe you can’t
                        wait for your next session or you’ve come to the end of your
                        mentoring journey and want other people to have a similar
                        experience to the one you’ve had
                      </li>
                      <li>
                        Make it personal - take the time to mention a few specific
                        details of the kind of goals or skills the mentor has helped
                        you with. Reference a particular conversation that inspired
                        you or a time when your mentor went out of their way to help
                        you. Adding a personal touch can make it even more meaningful
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  How to Leave Feedback for a Mentee
                </p>
                <p className={css.stepText}>
                  Our Career Navig8rs can also leave feedback for their mentees.
                  As a mentor, you might want to let your mentee know how you feel
                  they’re doing with making progress towards their goals. Or you
                  might just want to let them know that you think they’re great
                  and that they’ve inspired you!
                </p>
                <p className={css.stepText}>
                  After each session, you’ll be asked if you’d like to leave feedback
                  for your mentee. Although you don’t have to, it only takes a few
                  minutes. Deliver the warm fuzzies and make someone’s day.
                </p>
                <p className={css.stepText}>
                  You may have had a session where they were struggling with a problem
                  or with their self-belief and you want to give them a boost. You
                  might have just come away from a session where they shared an
                  achievement you’ve been helping them work towards for a long time
                  and you’re bursting with pride. In both hard times and in times
                  of celebration, receiving feedback can be a real boost to your
                  mentee’s confidence, helping them recognise everything they’ve achieved.
                </p>

                <p className={css.stepSubSubTitle}>
                  Mentee Feedback: What To Include
                </p>
                <p className={css.stepText}>
                  You probably let your mentee know how they’re doing and tell them
                  ‘well done’ as part of your mentoring sessions routinely, but
                  it’s nice to take that further and leave them some written feedback
                  too. For some people, that praise will sink in more if they see
                  it written down, so it really can make their week. Here are some
                  ideas of what to include:
                  <ul className={css.diskList}>
                    <li>
                      Summarise their achievements and congratulate them on their
                      progress. Link this back to the goals you’ve set together
                      to really bring it home
                    </li>
                    <li>
                      Highlight specific traits you’ve been impressed by. Did they
                      arrive at every session fully prepared? Were they working
                      towards their goals in between your catch-ups? Let them know!
                    </li>
                    <li>
                      Remind them of how far they’ve come on their journey so far.
                      If your mentee experiences self-doubt, making them reflect
                      on their progress can hammer home everything they’ve managed
                      to achieve for themselves
                    </li>
                    <li>
                      Praise their
                      &nbsp;
                      <ExternalLink
                        href="https://careernavig8r.com/blog/why-soft-skills-mentoring-shouldnt-be-dismissed/"
                        className={css.link}
                        sameTab={true}
                      >
                        soft skills
                      </ExternalLink>
                      &nbsp;
                      too. Perhaps you were impressed by
                      their determination to succeed or felt they always communicated
                      clearly. Don’t just wait until they’ve achieved their end goal,
                      receiving feedback throughout the whole mentoring journey
                      can be a huge confidence and motivation boost
                    </li>
                  </ul>
                </p>

                <p className={css.stepSubSubTitle}>
                  How to Respond To Feedback
                </p>
                <p className={css.stepText}>
                  For many of us, there can be nothing more awkward than receiving
                  a compliment. How are you meant to take it?! As a society, we
                  all need to get better at accepting praise and recognising - and
                  celebrating 🥳 - our achievements.
                </p>
                <p className={css.stepText}>
                  If you receive great feedback from your mentor or mentee, make
                  sure you acknowledge it (either verbally or in writing, depending
                  on how the feedback was received) and thank them. Take the time
                  to really think about their comments and let yourself feel that
                  warm glow of praise. You deserve it!
                </p>
                <p className={css.stepText}>
                  Think about returning the compliment, or pay it forward. Keep
                  the positivity train going and make someone else’s day by paying
                  them a compliment or giving them good feedback too.
                </p>
                <p className={css.stepText}>
                  If you receive feedback that feels negative or critical - or maybe
                  you don’t know how to feel about it - try to take a step back and
                  think about whether the comments feel fair before letting your
                  emotions get in the way. Ask for extra details if you need to and
                  try to use whatever’s been said as a learning opportunity. Read
                  more about
                  &nbsp;
                  <ExternalLink
                    href="https://careernavig8r.com/blog/how-to-take-feedback-when-its-good-or-bad/"
                    className={css.link}
                    sameTab={true}
                  >
                    how to take feedback
                  </ExternalLink>
                  &nbsp;
                  , whether it’s good or bad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionHowToLeaveFeedbackForYourMentorTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowToLeaveFeedbackForYourMentorTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowToLeaveFeedbackForYourMentorTop;
