import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionWithVideoTop.module.css';

const VideoTopContentForMentor = ({ currentUser }) => {

  return (
    <div className={css.content}>

      <div className={css.contentItem}>
        <h2 className={css.title}>
          <FormattedMessage
            id="SectionWithVideoTop.titleMentor"
            values={{
              lineBreak: <br />
            }}
          />
        </h2>

        <ul className={css.list}>
          <li className={css.listItem}>
            <FormattedMessage id="SectionWithVideoTop.listItemMentor1" />
          </li>
          <li className={css.listItem}>
            <FormattedMessage id="SectionWithVideoTop.listItemMentor2" />
          </li>
          <li className={css.listItem}>
            <FormattedMessage
              id="SectionWithVideoTop.listItemMentor3"
              values={{
                orangeText: <span className={css.orangeText}>5 minutes.</span>
              }}
            />
          </li>
        </ul>

        {!currentUser && (
          <NamedLink
            name="SignupPage"
            to={{
              search: 'userType=mentor',
            }}
            className={classNames(css.button, css.buttonDesktop)}
          >
            <FormattedMessage id="SectionWithVideoTop.becomeAMentor" />
          </NamedLink>
        )}

      </div>

      <div className={css.contentItem}>
        <div className={css.videoWrapper}>
          <iframe
            key="s2dttMIqjgw"
            width="526"
            height="296"
            src="https://www.youtube.com/embed/s2dttMIqjgw?si=F4Yfc7EsEJW3PwdG"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            title="Embedded youtube"
            referrerPolicy="strict-origin-when-cross-origin"
          />
        </div>
      </div>

      {!currentUser && (
        <NamedLink
          name="SignupPage"
          to={{
            search: 'userType=mentor',
          }}
          className={classNames(css.button, css.buttonMobile)}
        >
          <FormattedMessage id="SectionWithVideoTop.becomeAMentor" />
        </NamedLink>
      )}

    </div>
  );
};


export default VideoTopContentForMentor;
