import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionWithVideoTop.module.css';

const VideoTopContentForMentee = ({ currentUser }) => {

  return (
    <div className={css.content}>

      <div className={css.contentItem}>
        <h2 className={css.title}>
          <FormattedMessage
            id="SectionWithVideoTop.titleMentee"
            values={{
              lineBreak: <br />
            }}
          />
        </h2>
        <p className={css.subtitle}>
          <FormattedMessage
            id="SectionWithVideoTop.subTitleMentee"
            values={{
              orangeText: <><br /><span className={css.orangeText}>5 times more likely</span></>
            }}
          />
        </p>

        <ul className={classNames(css.list, css.listMentee)}>
          <li className={css.listItem}>
            <FormattedMessage id="SectionWithVideoTop.listItemMentee1" />
          </li>
          <li className={css.listItem}>
            <FormattedMessage id="SectionWithVideoTop.listItemMentee2" />
          </li>
          <li className={css.listItem}>
            <FormattedMessage id="SectionWithVideoTop.listItemMentee3" />
          </li>
        </ul>

        {!currentUser && (
          <NamedLink
            name="SignupPage"
            to={{
              search: 'userType=mentor',
            }}
            className={classNames(css.button, css.buttonDesktop)}
          >
            <FormattedMessage id="SectionWithVideoTop.becomeAMentee" />
          </NamedLink>
        )}

      </div>

      <div className={css.contentItem}>
        <div className={css.videoWrapper}>
          <iframe
            key="s2dttMIqjgw"
            width="526"
            height="296"
            src="https://www.youtube.com/embed/buEl4SQZ_8s?si=5CqqU4vVvg1DRuHK"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            title="Embedded youtube"
            referrerPolicy="strict-origin-when-cross-origin"
          />
        </div>
      </div>

      {!currentUser && (
        <NamedLink
          name="SignupPage"
          to={{
            search: 'userType=mentor',
          }}
          className={classNames(css.button, css.buttonMobile)}
        >
          <FormattedMessage id="SectionWithVideoTop.becomeAMentee" />
        </NamedLink>
      )}

    </div>
  );
};


export default VideoTopContentForMentee;
