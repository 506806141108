import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionCvCoachingTop.module.css';

import inlineImage from './images/cv-coaching-small.png';

const SectionBecomeMentor = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                &nbsp;
              </div>
            </div>
            <div className={css.titleSection}>
              <h2 className={css.stepTitle}>
                &nbsp;
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <h2 className={css.stepTitle}>
                  CV Coaching
                </h2>

                <p className={css.stepText}>
                  A CV coach can help you land your dream job. Knowing how to write
                  a CV that grabs attention, shows hirers who you truly are and feels
                  unique is a skill that shouldn’t be underestimated.
                </p>
                <p className={css.stepText}>
                  CV coaches can do exactly that. They might be people who specialise
                  in recruitment or they may have had a lot of success with their
                  own CV. A CV coach will help you navigate this important step, so
                  you can bag the career opportunities you deserve.
                </p>

                <p className={css.stepSubSubTitle}>
                  What Is A CV Coach?
                </p>
                <p className={css.stepText}>
                  A CV coach is someone who provides help writing a CV to make you
                  stand out from the crowd. At Career Navig8r, many of our mentors
                  offer CV coaching to support you on your journey to reach (and smash)
                  your career goals.
                </p>
                <p className={css.stepText}>
                  Your CV coach isn’t there to write your CV for you. Instead, they’ll
                  look over your current CV and give you some hints and tips to make
                  it more impactful. It needs to show hirers what makes you unique
                  as a candidate and demonstrate why they need you on their team.
                </p>
                <p className={css.stepText}>
                  If your CV is a cliché-heavy list full of buzzwords and says you
                  like ‘reading in your spare time’ even though you haven’t picked up
                  a book since school, it’s probably a sign that you’re in desperate
                  need of a CV coach.
                </p>

                <p className={css.stepSubSubTitle}>
                  The Benefits Of Getting Proper CV Support
                </p>
                <p className={css.stepColumnText}>
                  <p className={css.stepColumnTextBlock1}>
                    Knowing how to write a CV is a CV-worthy skill itself (one you’ll
                    actually find on many of our Career Navig8rs’ profiles 👀). When
                    you invest in proper CV support from an experienced CV coach, you’re
                    making an investment in your future by accessing one of the keytools
                    you need to make your dream job a reality. After years of studying,
                    training and hard graft, you deserve it.
                    <br/><br/>
                    Benefits of CV coaching include:
                    <ul className={css.diskList}>
                      <li>
                        A chance to get an honest answer to all your CV questions
                      </li>
                      <li>
                        Getting advice on the skills, qualifications and experience
                        you *should* include
                      </li>
                      <li>
                        An objective pair of eyes on your CV for fresh perspective
                        on what’s working and what’s not
                      </li>
                      <li>
                        Gaining insight that you’ll remember forever for all your
                        future CVs/cover letters/applications
                      </li>
                    </ul>
                  </p>
                  <p className={css.stepColumnTextBlock2}>
                    <img className={css.inlineImage} src={inlineImage}/>
                  </p>
                </p>

                <p className={css.stepSubSubTitle}>
                  CV Advice To Give You An Edge
                </p>
                <p className={css.stepText}>
                  Employers and hirers have usually seen it all. They’re rolling
                  their eyes at ‘works well independently’; they’re despairing at
                  ‘great team player’; they’re screaming inwardly (well, we hope
                  it’s just inwardly) at ‘hard-working individual’. A CV coach will
                  help you put together what hirers *do* want to see, so you can
                  craft a CV that stands out from the pack.
                </p>
                <p className={css.stepText}>
                  Seek CV writing advice from someone who’s already bagged their
                  dream job, rather than the faceless recruitment site that everyone
                  else is reading. You need to give yourself an edge to be noticed,
                  and CV coaching will help you with exactly that.
                </p>
                <p className={css.stepText}>
                  Connect with one of our Career Navig8rs and get access to CV advice
                  from someone who knows what works and what doesn’t - they’ve been
                  there themselves. Investing in CV mentoring could be the difference
                  between stagnation and achieving that promotion/new career/better
                  work-life balance.
                </p>

                <p className={css.stepSubSubTitle}>
                  Find A CV Coach
                </p>
                <p className={css.stepText}>
                  Take your CV to the next level with the help of a CV coach. Our
                  Career Navig8rs have all applied for a job or two (or several dozen)
                  in their time, so they’ve likely learnt how to write a CV that
                  packs a punch.
                </p>
                <p className={css.stepText}>
                  When you’re ready to find a mentor on our platform, you can see
                  our Navig8rs’ unique skills and experience, helping you narrow
                  down your search. Look for someone who’s offering CV coaching
                  specifically or someone with the kind of experience that makes
                  for a kick-ass CV.
                </p>
                <p className={css.stepText}>
                  Once you’ve found a potential CV coach, check their hourly rate,
                  send them a message and start your mentee journey from there.&nbsp;
                  <NamedLink
                    key="linkToSearchPage"
                    name="SearchPage"
                    title="Start your mentor search"
                  >
                    Start your mentor search.
                  </NamedLink>
                </p>
                <p className={css.stepText}>
                  <i>And if you’re the owner of one of those CVs that makes hirers
                    weak at the knees, we’d love to have you on board as a CV coach.
                    Sign up to become a Career Navig8r.</i>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
