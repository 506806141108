import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled, type } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className, {[css.customIcon]: type === "custom"});

  if (type === "custom") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        fill="none"
        viewBox="0 0 16 17"
      >
        <path
          d="M7.297.998a.745.745 0 011.406 0l1.668 4.164c.112.28.36.472.643.495l4.279.356c.686.057.959.985.428 1.458l-3.217 2.86a.862.862 0 00-.256.835l.99 4.31c.163.708-.547 1.278-1.13.908l-3.72-2.359a.718.718 0 00-.776 0l-3.719 2.359c-.584.37-1.294-.2-1.131-.909l.99-4.31a.862.862 0 00-.256-.833L.28 7.47c-.53-.473-.258-1.4.428-1.458l4.279-.356a.771.771 0 00.643-.495L7.297.998z"
        ></path>
      </svg>
    );
  } else {

    return (
      <svg
        className={classes}
        width="23"
        height="23"
        viewBox="0 0 23 23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.938 8.008c-.15-.412-.544-.69-.985-.69H14.38L12.507.758C12.377.31 11.967 0 11.5 0c-.467 0-.88.31-1.006.76L8.618 7.317H1.046c-.442 0-.833.278-.983.69-.15.414-.025.876.314 1.16l5.7 4.75L3.2 21.59c-.16.43-.02.916.346 1.196.362.28.87.29 1.242.02l6.71-4.79 6.713 4.79c.375.27.88.26 1.245-.02.366-.28.504-.765.343-1.196l-2.875-7.67 5.7-4.75c.34-.284.463-.746.315-1.16"
          fillRule="evenodd"
        />
      </svg>
    );
  }

};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
