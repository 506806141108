import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionRoadmap.module.css';

import rightImage from './images/right-image.png';
import rightImage2 from './images/right-image2.png';

const BLOCK_FIND_MENTOR = 1;
const BLOCK_BE_MENTOR = 2;

const NUMBER_OF_POINTS = 3;

const SectionRoadmap = props => {
  const [ selectedBlock, setSelectedBlock ] = useState( BLOCK_FIND_MENTOR );
  const [ selectedPoint, setSelectedPoint ] = useState( 1 );
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    const intervalID = setInterval(() => {
        setSelectedPoint( preIndex => preIndex < NUMBER_OF_POINTS ? preIndex + 1 : 1 );
      },
      1500 );

    return () => clearInterval( intervalID );
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  const explanationStepBlocks = selectedBlock === BLOCK_FIND_MENTOR ? (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        <div className={css.stepItemRow}>
          <div className={css.stepItemActiveFigure}>
            1
          </div>
          <div className={css.stepItemActiveText}>
            <FormattedMessage id="SectionRoadmap.findMentorStep1Text" />
          </div>
        </div>
        <div className={css.stepItemPipe}>&nbsp;</div>
        <div className={css.stepItemRow}>
          <div className={ selectedPoint >= 2 ? css.stepItemActiveFigure : css.stepItemFigure }>
            2
          </div>
          <div className={ selectedPoint >= 2 ? css.stepItemActiveText : css.stepItemText }>
            <FormattedMessage id="SectionRoadmap.findMentorStep2Text" />
          </div>
        </div>
        <div className={css.stepItemPipe}>&nbsp;</div>
        <div className={css.stepItemRow}>
          <div className={ selectedPoint >= 3 ? css.stepItemActiveFigure : css.stepItemFigure }>
            3
          </div>
          <div className={ selectedPoint >= 3 ? css.stepItemActiveText : css.stepItemText}>
            <FormattedMessage id="SectionRoadmap.findMentorStep3Text" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={css.explanationStepBlocks}>
      <div className={css.explanationItemBlock}>
        <div className={css.stepItemRow}>
          <div className={css.stepItemActiveFigure}>
            1
          </div>
          <div className={css.stepItemActiveText}>
            <FormattedMessage id="SectionRoadmap.beMentorStep1Text" />
          </div>
        </div>
        <div className={css.stepItemPipe}>&nbsp;</div>
        <div className={css.stepItemRow}>
          <div className={ selectedPoint >= 2 ? css.stepItemActiveFigure : css.stepItemFigure}>
            2
          </div>
          <div className={ selectedPoint >= 2 ? css.stepItemActiveText : css.stepItemText }>
            <FormattedMessage id="SectionRoadmap.beMentorStep2Text" />
          </div>
        </div>
        <div className={css.stepItemPipe}>&nbsp;</div>
        <div className={css.stepItemRow}>
          <div className={ selectedPoint >= 3 ? css.stepItemActiveFigure : css.stepItemFigure }>
            3
          </div>
          <div className={ selectedPoint >= 3 ? css.stepItemActiveText : css.stepItemText }>
            <FormattedMessage id="SectionRoadmap.beMentorStep3Text" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionRoadmap.title" />
          </h2>
          <div className={css.explanationBlocks}>
            <div className={css.explanationBlock}>
              <p
                className={selectedBlock === BLOCK_FIND_MENTOR ? css.activeStepSubtitle : css.stepSubtitle}
                onClick={() => setSelectedBlock( BLOCK_FIND_MENTOR )}
              >
                <FormattedMessage id="SectionRoadmap.findMentorSubtitle" />
              </p>
              <p
                className={selectedBlock === BLOCK_BE_MENTOR ? css.rightActiveStepSubtitle : css.rightStepSubtitle}
                onClick={() => setSelectedBlock( BLOCK_BE_MENTOR )}
              >
                <FormattedMessage id="SectionRoadmap.beMentorSubtitle" />
              </p>
            </div>
          </div>

          {explanationStepBlocks}

          <NamedLink
            name="SignupPage"
            to={{
              search:
                'address=Finland&bounds=70.0922932%2C31.5870999%2C59.693623%2C20.456500199999937',
            }}
            className={css.signupButton}
          >
            <FormattedMessage id="SectionBecomeMentor.signupButton" />
          </NamedLink>
        </div>

        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <img
              className={css.explanationPicture}
              src={ selectedBlock === BLOCK_FIND_MENTOR ? rightImage2 : rightImage }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionRoadmap.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionRoadmap.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionRoadmap;
