import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './SectionGdprStatementContent.module.css';

const SectionCancellationPolicyContent = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepExplanationWrapper}>
          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Data Controller, The Beacon HRM Group
              </li>
              <li className={css.ctli}>
                Owner contact email: admin@CareerNavig8r.com
              </li>
            </ul>
            At Career Navig8r we take your privacy and the protection of your information
            seriously. We have created this policy in line with the Data Protection
            Commissioner to demonstrate this commitment and let you know how we
            handle and use your data.<br/>
            After reading this policy, if you have any questions please contact
            us using the details above but please know:
            <br/><br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Please review this statement periodically as we will amend and update
                it from time to time. This policy relates to transactions and activities
                in which you voluntarily engage, and it relates to data gathered on our website.
              </li>
              <li className={css.ctli}>
                It does not extend to any offline activities between you and any of
                our third-party advertisers or news items.
              </li>
              <li className={css.ctli}>
                Please note that the site may contain links to other websites. For
                example, if you click on a banner or advertisement on the site and
                link to another website, then this privacy policy will not apply to
                any information collected on that website. We recommend that you
                read the privacy policies of each website that you visit.
              </li>
              <li className={css.ctli}>
                We may transfer, sell, or assign data to another company because
                of a merger, sale, or company reorganisation. We will notify you
                before this happens IF it should ever happen.
              </li>
              <li className={css.ctli}>
                We endeavour to comply with the guidelines laid out by Ireland's
                Data Commissioner in the collection, protection, and distribution
                of data we may collect about you on our website. You should be aware
                of your rights and your right to contact us;
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Persons under the age of 18</h2>

          <p className={css.contentText}>
            Career Navig8r is not aimed at users under the age of 18. We do not
            to the best of our knowledge collect information from or relating to
            persons under the age of 18. If we discover that we have collected personal
            information from a person below the age of 18 we will delete that information
            as quickly as possible. If you believe we might have any information from
            or about a person under the age of 18, please contact us at admin@CareerNavig8r.com
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>What information do we collect?</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                We only request information which is necessary for us to fulfil our
                service obligations on the website.
              </li>
              <li className={css.ctli}>
                We do require that you register to receive our services but; to
                view our content on desktop or mobile it’s not a requirement.
              </li>
              <li className={css.ctli}>
                You may opt to register using a form on the website.
              </li>
              <li className={css.ctli}>
                If you sign up for a news alert or newsletter you will also be
                registering a profile with us.
              </li>
              <li className={css.ctli}>
                We will keep a record of any contact you have with us.
              </li>
              <li className={css.ctli}>
                With your consent, we may use your mobile number or email address
                to send you
              </li>
              <li className={css.ctli}>
                promotions, notifications, or other services. You have the option
                to unsubscribe or opt out of all communications on every text or
                email we send you.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>By registering with us you promise to:</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Inform us if you think someone has accessed your account without your permission
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>What do we use this information for?</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Any of the information we collect from you may be used in one of the following ways:
              </li>
              <li className={css.ctli}>
                To notify you of any changes to the website or service
              </li>
              <li className={css.ctli}>
                To notify you of offers/news within any newsletter or communication
                we may produce that
              </li>
              <li className={css.ctli}>
                you’ve voluntarily registered for
              </li>
              <li className={css.ctli}>
                To analyse our audience demographically for internal use and behaviour tracking, we
              </li>
              <li className={css.ctli}>
                never disclose personal information to any 3rd party
              </li>
              <li className={css.ctli}>
                To improve our customer service levels. Having your information and
                knowing how you use the website helps us to respond efficiently and
                effectively to your support requests
              </li>
              <li className={css.ctli}>
                We can tell using registration information and cookies we have collected
                a bit more about you and your interests – this helps us to develop
                additional content we know you will like
              </li>
              <li className={css.ctli}>
                and want to read. Remembering who you are makes it easier for you
                to use the site and its
              </li>
              <li className={css.ctli}>
                services and/or comment or share articles on social media.
              </li>
              <li className={css.ctli}>
                Your information, whether public or private, will not be sold to
                any other company for any
              </li>
              <li className={css.ctli}>
                reason whatsoever without your express consent.
              </li>
              <li className={css.ctli}>
                You are always in control of your data – we are relying on you to help
                us deliver a service you & our other users want to use. We need to know
                more about you to improve our service to you.
              </li>
              <li className={css.ctli}>
                Please do not submit your personal information if you do not want
                us to collect it.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Information you post</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Any personal information which you volunteer in your public profile
                either through Social Media or on our website you post as a comment
                that will be available worldwide to anyone with access to the website.
              </li>
              <li className={css.ctli}>
                Please do not post any information you are not happy to leave up
                indefinitely or turn up in Google search results. Please do not post
                any offensive or discriminatory information (breaking house rules.)
                We recommend you do not post your name, address, telephone number,
                email address or anything else that may lead someone to identify
                you if this is something you would not wish to have publicly accessible.
              </li>
              <li className={css.ctli}>
                Please note that certain information (such as profile photographs)
                which you may choose to provide might reveal your gender, ethnic
                origin, nationality, religion, and/or sexual orientation.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Site Security</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                At Career Navig8r we make every effort to protect the data which
                you provide to us. We consistently monitor our servers for unauthorised
                access, and we have appropriate security management systems in place
                to safeguard this.
              </li>
              <li className={css.ctli}>
                Anti-virus software and Firewalls are installed on all areas of the site.
                If we ask anyone else to do some work for us on our websites or servers,
                we ensure they have the same prominent levels of security in place.
              </li>
              <li className={css.ctli}>
                We do recognise however that, unfortunately, no data transmission
                over the internet can be guaranteed to be 100% secure. As a result,
                we cannot ensure or warrant the security of any information you
                provide to us. You do so at your own risk.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>IP Addresses and Cookies</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Like most other websites we will collect information about your
                computer including, if available, your IP Address, Operating System,
                Browser Type and Location for statistical and behavioural tracking.
                This information helps us to enhance our service to you. This does
                not identify any individual; it is used as a tool to gather broad
                levels of data for analysis and to measure audience size and patterns
                of usage such as visitor numbers and how unique they are.
              </li>
              <li className={css.ctli}>
                We may also collect a cookie file for the same reason. These are
                small files that a site or service provider transfers to your hard
                drive through your browser which essentially enables us to remember
                certain non-identifiable information. You can disable cookies through
                Internet Options on your browser menu at any time.
              </li>
              <li className={css.ctli}>
                We also use outside providers for website statistics. The primary
                provider we use is Google Analytics. You can find further information
                on the information which it collects at http://www.google.com/analytics/.
              </li>
              <li className={css.ctli}>
                If you access us via a mobile browser, we may identify the location
                of your device, the browser type you accessed with and the operating system.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Types of Data collected</h2>

          <p className={css.contentText}>
            In working with our clients and when they provide us with their details
            in a commercial transaction, we only use the information provided for
            the purposes of serving the client. No confidential information is shared
            publicly. The information provided to us by a client that is shared
            publicly by us is done solely for the promotion of our clients across
            our publications and platforms and with their agreement and is never
            used by us or allowed by us to be used by third parties for mailing
            lists or direct marketing purposes<br/>
            Among the types of Personal Data that this Application collects, by
            itself or through third parties, are: -<br/>
            Cookies, Usage Data, names, phone numbers and email addresses.<br/>
            Complete details on each type of Personal Data collected are provided
            in the dedicated sections of this privacy policy or by specific explanation
            texts displayed prior to the Data collection.<br/>
            The Personal Data may be freely provided by the User, or, in the case
            of Usage Data, collected automatically when using this Application.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Mode and place of processing the Data</h2>

          <p className={css.contentText}>
            Methods of processing<br/>
            The Data Controller processes the Data of Users in a proper manner and
            shall take appropriate security measures to prevent unauthorised access,
            disclosure, modification, or unauthorised destruction of the Data.<br/>
            The Data processing is carried out using computers and/or IT-enabled tools,
            following organisational procedures and modes strictly related to the purposes
            indicated. In addition to the Data Controller, in some cases, the Data
            may be accessible to certain types of persons in charge, involved with
            the operation of the site (administration, sales, marketing, legal, system
            administration) or external parties (such as third-party technical service
            providers, mail carriers, hosting providers, IT companies, communications
            agencies) appointed, if necessary, as Data Processors by the Owner. The updated
            list of these parties may be requested from the Data Controller at any time.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Place</h2>

          <p className={css.contentText}>
            The Data is processed at the Data Controller’s operating offices and
            in any other places where the parties involved in the processing are
            located. For further information, please contact the Data Controller.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Retention time</h2>

          <p className={css.contentText}>
            The Data is kept for the time necessary to provide the service requested
            by the User, or stated by the purposes outlined in this document, and
            the User can always request that the Data Controller suspend or remove the data.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>The use of the collected Data</h2>

          <p className={css.contentText}>
            The Data concerning the User is collected to allow the Owner to provide
            its services so that Mentees are capable of choosing Mentors effectively,
            as well as for the following purposes: Analytics, Contacting the User
            and Displaying content from external platforms. Managing contacts and
            sending messages, Platform services and hosting.<br/>
            The Personal Data used for each purpose is outlined in the specific
            sections of this document.
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Detailed information on the processing of Personal Data</h2>

          <p className={css.contentText}>
            Personal Data is collected for the following purposes and using the following services:<br/>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Analytics<br/>
                The services contained in this section enable the Owner to monitor
                and analyse web traffic and can be used to keep track of User behaviour.
              </li>
              <li className={css.ctli}>
                Google Analytics (Google Inc.)<br/>
                Google Analytics is a web analysis service provided by Google Inc.
                ("Google"). Google utilises the Data collected to track and examine
                the use of this Application, prepare reports on its activities and
                share them with other Google services.
              </li>
              <li className={css.ctli}>
                Google may use the Data collected to contextualise and personalise
                the ads of its own advertising network.
              </li>
              <li className={css.ctli}>
                Personal Data collected: Cookies and Usage Data.
              </li>
              <li className={css.ctli}>
                Place of processing: United States, Ireland, and Finland – Privacy Policy.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

          <h2 className={css.sectionTitle}>Contacting the User</h2>

          <p className={css.contentText}>
            <ul  className={css.diskList}>
              <li className={css.ctli}>
                Contact form (this Application)
              </li>
              <li className={css.ctli}>
                By filling in the contact form with their Data, the User authorises
                this Application to use these details to reply to requests for information,
                quotes, or any other kind of request as indicated by the form’s header.
              </li>
              <li className={css.ctli}>
                Personal Data collected: email address, name, and phone number.
              </li>
            </ul>
          </p>

          <div className={css.sectionDivider}></div>

        </div>
      </div>
    </div>
  );
};

SectionCancellationPolicyContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCancellationPolicyContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCancellationPolicyContent;
