import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

import unrollIconImage from './images/unwrap-section-icon.png';
import rollIconImage from './images/wrap-section-icon.png';

const SectionProjects = props => {
  const [ isUnrolled, setUnrolled ] = useState( false );

  const { keyProjects } = props;

  return keyProjects ? (
    <div className={css.sectionDescription}>
      <img
        src={isUnrolled ? rollIconImage : unrollIconImage}
        className={css.rollUnrollIcon}
        onClick={() => setUnrolled( !isUnrolled )}
      />
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.projectTitle" />
      </h2>
      <div className={isUnrolled ? css.unrolledProjectRecords : css.projectRecords}>
        {keyProjects.map( projectRecord => (
          <>
            <div className={css.projectRecord}>
              <div className={css.projectLabel}>
                {projectRecord.name}
              </div>
            </div>
            <div className={css.projectDescription}>
              {projectRecord.details}
            </div>
          </>
        ))}
      </div>
    </div>
  ) : null;
};

export default SectionProjects;
