import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import peopleDesktop from './images/people-desktop.jpg';
import peopleMobile from './images/people-mobile.jpg';

import css from './SectionPeople.module.css';

const SectionPeople = props => {
  const { rootClassName, className, isLandingPage } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      {isLandingPage ? (
        <div className={css.content}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionPeople.titleMentor" />
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage id="SectionPeople.subTitleMentor" />
          </p>
        </div>
      ) : (
        <div className={css.content}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionPeople.titleMentee" />
          </h2>
          <p className={css.subTitle}>
            <FormattedMessage id="SectionPeople.subTitleMentee" />
          </p>
        </div>
      )}

      <picture
        className={css.img}
        alt='Never Thought of Being a Mentor? Think Again.'
      >
        <source media="(max-width: 767px)" srcSet={peopleMobile} />
        <source media="(min-width: 768px)" srcSet={peopleDesktop} />
        <img src={peopleDesktop} alt="Never Thought of Being a Mentor? Think Again." />
      </picture>
    </div>
  );
};

SectionPeople.defaultProps = { rootClassName: null, className: null };

SectionPeople.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(withViewport)(SectionPeople);
