import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, SecondaryButton, FieldTextInput } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './JobRoleSuggestionForm.module.css';

const JobRoleSuggestionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        onCancel,
        inProgress,
        intl,
        categories,
        sendEnquiryError,
      } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage(
        {
          id: 'JobRoleSuggestionForm.emailPlaceholder',
        },
      );
      const emailRequiredMessage = intl.formatMessage({
        id: 'JobRoleSuggestionForm.emailRequired',
      });
      const emailRequired = validators.requiredAndNonEmptyString(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'JobRoleSuggestionForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const messagePlaceholder = intl.formatMessage(
        {
          id: 'JobRoleSuggestionForm.messagePlaceholder',
        },
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'JobRoleSuggestionForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <h2 className={css.heading}>
            <FormattedMessage id="JobRoleSuggestionForm.heading" />
          </h2>
          <FieldTextInput
            className={css.field}
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            placeholder={emailPlaceholder}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          <FieldTextInput
            className={css.field}
            type="text"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="JobRoleSuggestionForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="JobRoleSuggestionForm.submitButtonText" />
            </PrimaryButton>
            <SecondaryButton
              className={css.cancelButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              onClick={onCancel}
            >
              <FormattedMessage id="JobRoleSuggestionForm.cancelButtonText" />
            </SecondaryButton>
          </div>
        </Form>
      );
    }}
  />
);

JobRoleSuggestionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

JobRoleSuggestionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const JobRoleSuggestionForm = compose(injectIntl)(JobRoleSuggestionFormComponent);

JobRoleSuggestionForm.displayName = 'JobRoleSuggestionForm';

export default JobRoleSuggestionForm;
