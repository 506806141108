import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionAboutTop.module.css';

import arrowImage from './images/arrow-down.png';
import leftImage from './images/left-top-image.png';

const SectionBecomeMentor = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.rootTopSection}>
        <div className={css.rootWrapper}>
          <div className={css.steps}>
            <div className={css.stepTopLeftPictureWrapper}>
              <div className={css.stepPicture}>
                <img
                  className={css.explanationPicture}
                  src={leftImage}
                />
              </div>
            </div>
            <div className={css.titleSection}>
              <h1 className={css.stepTitle}>
                <FormattedMessage id="SectionAboutTop.part1Title" />
              </h1>
              <h1 className={css.stepTitle2}>
                <FormattedMessage id="SectionAboutTop.part2Title" />
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className={classes}>
        <div className={css.steps}>
          <div className={css.stepExplanationWrapper}>
            <div className={css.stepQuestionsWrapper}>
              <div className={css.stepQuestionsColumnLeft}>
                <p className={css.stepSubSubTitle}>
                  If you’re looking for a mentor
                </p>
                <p className={css.stepFirstText}>
                  Looking for expert advice for the next stage of your career?
                </p>
                <p className={css.stepText}>
                  Looking to benefit from the experience of someone who’s already succeeded in your job?
                </p>
                <p className={css.stepText}>
                  Uncertain about that upcoming interview and would like some expert help.
                </p>
                <p className={css.stepText}>
                  Think you know what to do. But would just like to make sure?
                </p>
                <p className={css.stepText}>
                  Fill out your mentee details and get the help you want.
                </p>
                <div className={css.buttonLine}>
                  <NamedLink
                    key="linkToLandingPage1"
                    name="SearchPage"
                    className={classNames(css.heroButton1, { [css.heroButtonFEDelay]: mounted })}
                    title="Find a mentor"
                  >
                    <FormattedMessage id="SectionHero.browseButton1" />
                  </NamedLink>
                </div>
              </div>
              <div className={css.stepQuestionsColumnRight}>
                <p className={css.stepSubSubTitle}>
                  If you want to be a mentor
                </p>
                <p className={css.stepFirstText}>
                  Are you retired, twiddling your thumbs and want a ‘career encore’?
                </p>
                <p className={css.stepText}>
                  Know you’ve got expert advice to give, but have nowhere to give it or no one to give it to?
                </p>
                <p className={css.stepText}>
                  Looking to help the next generation of you?
                </p>
                <p className={css.stepText}>
                  Maybe you just want to benefit financially from your years of knowledge
                </p>
                <p className={css.stepText}>
                  Fill out your Navig8r profile and get started.
                </p>
                <div className={css.buttonLine}>
                  <NamedLink
                    name="SignupPage"
                    to={{
                      search: 'userType=mentor',
                    }}
                    className={classNames(css.heroButton2, { [css.heroButtonFEDelay]: mounted })}
                  >
                    <FormattedMessage id="SectionHero.browseButton2" />
                  </NamedLink>
                </div>
              </div>
            </div>
            <div className={css.arrowImageWrapper}>
              <img className={css.arrowImage} src={arrowImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
