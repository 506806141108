import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionBecomeMentor.module.css';

import leftImage from './images/left-image.png';

const SectionBecomeMentor = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <img
              className={css.explanationPicture}
              src={leftImage}
            />
          </div>
        </div>

        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionBecomeMentor.part1Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage
              id="SectionBecomeMentor.part1Text"
              values={{ always: <b>always</b>, that: <b>that</b>, br: <br/> }}
            />
          </p>
          <div className={css.stepPictureMobileWrapper}>
            <div className={css.stepPicture}>
              <img
                className={css.explanationPicture}
                src={leftImage}
              />
            </div>
          </div>
          <NamedLink
            name="SignupPage"
            to={{
              search:
                'address=Finland&bounds=70.0922932%2C31.5870999%2C59.693623%2C20.456500199999937',
            }}
            className={css.signupButton}
          >
            <FormattedMessage id="SectionBecomeMentor.signupButton" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionBecomeMentor.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionBecomeMentor.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionBecomeMentor;
