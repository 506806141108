import reviewMentorImage1 from './review-mentor-img-1.png';
import reviewMentorImage2 from './review-mentor-img-2.png';
import reviewMentorImage3 from './review-mentor-img-3.png';
import reviewMentorImage4 from './review-mentor-img-4.png';

import reviewMenteeImage1 from './review-mentee-img-1.png';
import reviewMenteeImage2 from './review-mentee-img-2.png';
import reviewMenteeImage3 from './review-mentee-img-3.png';

export const mentorImages = {
  reviewMentorImage1,
  reviewMentorImage2,
  reviewMentorImage3,
  reviewMentorImage4,
};

export const menteeImages = {
  reviewMenteeImage1,
  reviewMenteeImage2,
  reviewMenteeImage3,
};
