import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  FooterLogo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

import fbImage from './images/facebook-icon.png';
import instaImage from './images/instagram-icon.png';
import liImage from './images/linked-in-icon.png';
import twitterImage from './images/twitter-icon.png';


const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToLi = intl.formatMessage({ id: 'Footer.goToLinkedIn' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <img src={fbImage} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <img src={twitterImage} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <img src={instaImage} />
    </ExternalLink>
  ) : null;

  const liLink = siteLinkedInPage ? (
    <ExternalLink
      key="linkToLinkedIn"
      href={siteLinkedInPage}
      className={css.icon}
      title={goToLi}
    >
      <img src={liImage} />
    </ExternalLink>
  ) : null;

  return [liLink, instragramLink, fbLink, twitterLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, viewport } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  // Mobile view
  if (viewport.width < 768) {
    return (
      <div className={classes}>
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.links}>
              <div className={css.logoMobileLine}>
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <FooterLogo format="desktop" className={css.mobileLogo} />
                </NamedLink>
              </div>

              <div className={css.infoLinksMobile}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="AboutPage" className={css.link}>
                      <FormattedMessage id="Footer.toAboutPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="HowItWorksPage" className={css.link}>
                      <FormattedMessage id="Footer.toHowItWorksPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="BecomeMentorPage" className={css.link}>
                      <FormattedMessage id="Footer.toBecomeAmentorPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CMSPage" params={{ pageId: "side-hustle" }} className={css.link}>
                      <FormattedMessage id="Footer.toSideHustlePage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CMSPage" params={{ pageId: "second-job" }} className={css.link}>
                      <FormattedMessage id="Footer.toSecondJobPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesMobile}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <ExternalLink href="https://careernavig8r.com/blog/" className={css.link} sameTab={true}>
                      <FormattedMessage id="Footer.toBlogPage" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="FaqPage" className={css.link}>
                      <FormattedMessage id="Footer.toFaqPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtraMobile}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <ExternalLink href="https://careernavig8r.com/blog/" className={css.link} sameTab={true}>
                      <FormattedMessage id="Footer.toBlogPage2" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="CommunityThrivelinesPage" className={css.link}>
                      <FormattedMessage id="Footer.toCommunityThrivelinesPage" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.someLiksMobile}>{socialMediaLinks}</div>
              <div className={css.extraLinksMobile}>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li className={css.ctli}>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li className={css.ctli}>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={css.organizationMobile} id="organization">
                <div className={css.organizationInfo}>
                  <p className={css.organizationCopyright}>
                    <span className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <FooterLogo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <br />
                <p className={css.organizationCopyright}>
                  <span className={css.copyrightLink}>+
                    <FormattedMessage id="Footer.copyright" />
                  </span>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" className={css.link}>
                    <FormattedMessage id="Footer.toHowItWorksPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="BecomeMentorPage" className={css.link}>
                    <FormattedMessage id="Footer.toBecomeAmentorPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: "side-hustle" }} className={css.link}>
                    <FormattedMessage id="Footer.toSideHustlePage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: "second-job" }} className={css.link}>
                    <FormattedMessage id="Footer.toSecondJobPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink href="https://careernavig8r.com/blog/" className={css.link} sameTab={true}>
                    <FormattedMessage id="Footer.toBlogPage" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FaqPage" className={css.link}>
                    <FormattedMessage id="Footer.toFaqPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink href="mailto:admin@careernavig8r.com" className={css.link}>
                    <FormattedMessage id="Footer.toReportIssue" />
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CommunityThrivelinesPage" className={css.link}>
                    <FormattedMessage id="Footer.toCommunityThrivelinesPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.socialLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li className={css.ctli}>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li className={css.ctli}>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(Footer);
