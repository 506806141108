import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './SearchRequestForm.module.css';

const SearchRequestFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        sendEnquirySuccess,
        sendEnquiryError,
      } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage({
        id: 'SearchRequestForm.emailPlaceholder'
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SearchRequestForm.emailRequired',
      });
      const emailRequired = validators.requiredAndNonEmptyString(emailRequiredMessage);

      const mentoringTypePlaceholder = intl.formatMessage({
        id: 'SearchRequestForm.mentoringTypePlaceholder'
      });
      const mentoringTypeRequiredMessage = intl.formatMessage({
        id: 'SearchRequestForm.mentoringTypeRequired',
      });
      const mentoringTypeRequired = validators.requiredAndNonEmptyString(mentoringTypeRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            inputRootClass={css.fieldInput}
            type="input"
            name="email"
            id={formId ? `${formId}.email` : 'email'}
            placeholder={emailPlaceholder}
            validate={emailRequired}
          />
          <FieldTextInput
            className={css.field}
            inputRootClass={css.fieldInput}
            type="input"
            name="mentoringType"
            id={formId ? `${formId}.mentoringType` : 'mentoringType'}
            placeholder={mentoringTypePlaceholder}
            validate={mentoringTypeRequired}
          />

          <div className={submitButtonWrapperClassName}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="SearchRequestForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={ sendEnquirySuccess ? css.submitButtonSuccess : css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage
                id={ sendEnquirySuccess ?
                  "SearchRequestForm.submitButtonSuccessText" : "SearchRequestForm.submitButtonText"
                }
              />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SearchRequestFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

SearchRequestFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchRequestForm = compose(injectIntl)(SearchRequestFormComponent);

SearchRequestForm.displayName = 'SearchRequestForm';

export default SearchRequestForm;
