import React, { Component, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { string, func } from 'prop-types';
import truncate from 'lodash/truncate';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { findOptionsForSelectFilter } from '../../util/search';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { NamedLink, ExternalLink, ResponsiveImage, YoutubeVideoModal } from '../../components';


import css from './ProfileCard.module.css';

import unfilledHeartImage from './images/unfilledFavorite.png';
import filledHeartImage from './images/filledFavorite.png';
import ratingStarImage from './images/ratingStar.png';
import linkedinIconImage from './images/ion_logo-linkedin.png';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { fetchTimeSlots, fetchTransactionLineItems, sendEnquiry } from '../../containers/ListingPage/ListingPage.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MAX_DESCRIPTION_LENGTH = 90;
const DESCRIPTION_OMISSION_SUFFIX = '...';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getCurrentPosition = workExperience => {
  let currentPosition = null;

  if( Array.isArray( workExperience )){
    workExperience.forEach( experienceRecord => {
      const isCurPos = experienceRecord.isCurrentPosition;

      if( Array.isArray( isCurPos ) && isCurPos.length === 1 && isCurPos[ 0 ] === "current"){
        currentPosition = {
          organisation: experienceRecord.organisation,
          category: experienceRecord.category
        };
      }
    });
  }

  return currentPosition;
};

export const ProfileCardComponent = props => {
  const [ youtubeModalOpen, setYoutubeModalOpen ] = useState( false );
  const {
    className, rootClassName, intl, viewport, listing, renderSizes,
    scrollingDisabled, onManageDisableScrolling,
    currentUser, isOwnListing, currentAuthor, showYoutubeUrl,
    setActiveListing, openEnquiryDialog, openBookingDialog, openDiscoveryDialog,
    openYoutubeVideoDialog,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description, price, publicData = {}} = currentListing.attributes;
  const {
    type: listingType, numberOfReviews = 0, averageRating = 0, workExperience = []
  } = publicData;
  const currentPosition = getCurrentPosition( workExperience );
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const authorPublicData = author.attributes.profile.publicData || {};
  const authorImage = author.profileImage ? author.profileImage : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ProfileCard.perNight'
    : isDaily
    ? 'ProfileCard.perDay'
    : 'ProfileCard.perUnit';

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters );
  const currentPositionCategory = currentPosition ?
    categoryOptions.find( categoryOption => currentPosition.category === categoryOption.key ) :
    null;
  const currentPositionCategoryLabel =
    currentPositionCategory ? currentPositionCategory?.label : null;

  const youtubeVideoId =
    authorPublicData?.youtubeVideoUrl?.match(/^https:\/\/www.youtube.com\/watch\?v=.+/) ?
      authorPublicData?.youtubeVideoUrl?.replace('https://www.youtube.com/watch?v=', '') : null;

  const linkedinLink = authorPublicData.linkedinLink ? (
    <ExternalLink href={authorPublicData.linkedinLink}>
      <img src={linkedinIconImage}/>
    </ExternalLink>
  ) : null;

  const youtubeLink = showYoutubeUrl && authorPublicData?.youtubeVideoUrl && youtubeVideoId ? (
    <div
      className={css.youtubeButton}
      onClick={() => setYoutubeModalOpen( true )}
    >
      <FormattedMessage id="ProfileCard.youtubeButton" />
    </div>
  ) : null;

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <img src={unfilledHeartImage} className={css.favoriteHeart}/>
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={authorName}
            image={authorImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.ratingInfo}>
          <div className={css.averageRating}>
            <img src={ratingStarImage} className={css.ratingStarImage} />
            <div className={css.ratingValue}>
              {Math.round( averageRating / 100 ) / 10}
            </div>
            <div className={css.numberOfReviews}>
              <FormattedMessage
                id="ProfileCard.numberOfReviews"
                values={{ numberOfReviews }}
              />
            </div>
          </div>
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice} / <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
      </div>
      <div className={css.mainInfo}>
        <div className={css.authorName}>
          {authorName}
          {linkedinLink}
        </div>
        { currentPosition ? (
          <div className={css.currentPosition}>
            {currentPositionCategoryLabel} @ {currentPosition?.organisation}
          </div>
        ) : null }
        <div className={css.youtubeVideoInfo}>
          {youtubeLink}
        </div>
      </div>
      { isOwnListing ? (
        <div className={css.ownListingMessage}>
          <FormattedMessage id="ProfileCard.ownListingMessage" />
        </div>
      ) : (
        <>
          <div
            className={css.askMeButton}
            onClick={openEnquiryDialog}
          >
            <FormattedMessage id="ProfileCard.askMeButton" />
          </div>
          <div
            className={css.bookFullMeetingButton}
            onClick={openBookingDialog}
          >
            <FormattedMessage id="ProfileCard.bookFullMeetingButton" />
          </div>
          <div
            className={css.bookDiscoveryMeetingButton}
            onClick={openDiscoveryDialog}
          >
            <FormattedMessage id="ProfileCard.bookDiscoveryMeetingButton" />
          </div>
        </>
      )}
      <YoutubeVideoModal
        id="ProfileCard.youtubeModal"
        contentClassName={css.youtubeModalContent}
        isOpen={youtubeModalOpen}
        onClose={() => setYoutubeModalOpen( false )}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.youtubeBlock}>
          <iframe
            width="100%" height="100%" src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            title="Mentor Profile Video" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen>
          </iframe>
        </div>
      </YoutubeVideoModal>
    </div>
  );
};

Component.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ProfileCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

export default compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileCardComponent);
