import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { SearchRequestForm } from '../../forms';

import css from './SearchRequestClause.module.css';

const SearchRequestClauseComponent = props => {
  const {
    rootClassName,
    className,
    listingsAreLoaded,
    resultsCount,
    sendEnquiryInProgress,
    sendEnquirySuccess,
    sendEnquiryError,
    onSendEnquiry,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const onSubmitEnquiry = values => {
    const { email, mentoringType } = values;

    onSendEnquiry( email, mentoringType )
      .then(txId => {
        // Ignore, the handling is on form
      })
      .catch(() => {
        // Ignore, error handling is on form
      });
  };

  return hasNoResult ? (
    <div className={classes}>
      <div className={css.requestClause}>
        <div className={css.requestMain}>
          <div className={css.requestHeader}>
            <div className={css.headerTitle}>
              <FormattedMessage
                id="SearchRequestClause.headerTitle"
                values={{ br: <br/>}}
              />
            </div>
          </div>
          <div className={css.fieldsWrapper}>
            <SearchRequestForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              sendEnquirySuccess={sendEnquirySuccess}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitEnquiry}
              inProgress={sendEnquiryInProgress}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

SearchRequestClauseComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchRequestClauseComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchRequestClause = SearchRequestClauseComponent;

export default SearchRequestClause;
