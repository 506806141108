import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1H21" stroke="#061F29" strokeWidth="2" strokeLinecap="round"/>
      <path d="M7 7H21" stroke="#061F29" strokeWidth="2" strokeLinecap="round"/>
      <path d="M1 13H21" stroke="#061F29" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
