import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ListingPage.module.css';

import unrollIconImage from './images/unwrap-section-icon.png';
import rollIconImage from './images/wrap-section-icon.png';

const SectionSectors = props => {
  const [ isUnrolled, setUnrolled ] = useState( false );

  const { workExperience } = props;

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters );

  const publicSectors = workExperience
    .filter( workRecord => {
      return Array.isArray(workRecord.publicity) && workRecord.publicity.includes('public');
    }).map( workRecord => {
      const option =
        categoryOptions.find(categoryOption => workRecord.category === categoryOption.key);

      return option ? option.label : null;
    });

  const privateSectors = workExperience
    .filter( workRecord => {
      return Array.isArray(workRecord.publicity) && workRecord.publicity.includes('private');
    }).map( workRecord => {
      const option =
        categoryOptions.find(categoryOption => workRecord.category === categoryOption.key);

      return option ? option.label : null;
    });

  const publicSectorsList = (
    <>
      { publicSectors.map(( sector, index ) =>
        <div className={css.sectorName} key={`publicSector-${index}-`}>{sector}</div>
      )}
    </>
  );

  const privateSectorsList = (
    <>
      { privateSectors.map(( sector, index ) =>
        <div className={css.sectorName} key={`privateSector-${index}-`}>{sector}</div>
      )}
    </>
  );

  return workExperience ? (
    <div className={css.sectionDescription}>
      <img
        src={isUnrolled ? rollIconImage : unrollIconImage}
        className={css.rollUnrollIcon}
        onClick={() => setUnrolled( !isUnrolled )}
      />
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.sectorsTitle" />
      </h2>
      <div className={isUnrolled ? css.unrolledSectors : css.sectors}>
        { publicSectors.length > 0 ? (
          <div className={css.publicSectors}>
            <div className={css.sectorsLabel}>
              <FormattedMessage id="SectionSectors.publicLabel"/>
            </div>
            {publicSectorsList}
          </div>
        ) : null }
        { privateSectors.length > 0 ? (
          <div className={css.privateSectors}>
            <div className={css.sectorsLabel}>
              <FormattedMessage id="SectionSectors.privateLabel"/>
            </div>
            {privateSectorsList}
          </div>
        ) : null }
      </div>
    </div>
  ) : null;
};

export default SectionSectors;
