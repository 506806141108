import React from 'react';
import { string } from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { mentorImages, menteeImages } from './images/reviewImages';
import { IconArrowCarousel, IconReviewStar } from '../../components';

import css from './SectionReviews.module.css';

const STARS = [1, 2, 3, 4, 5];
const MOBILE_BREAKPOINT = 768;

const SectionReviewsComponent = props => {
  const { rootClassName, className, isLandingPage, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const mentorsReviews = [
    {
      image: mentorImages.reviewMentorImage4,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentor4Name' }),
      rating: 5,
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentor4Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentor4Text' })
    },
    {
      image: mentorImages.reviewMentorImage1,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentor1Name' }),
      rating: 5,
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentor1Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentor1Text' })
    },
    {
      image: mentorImages.reviewMentorImage2,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentor2Name' }),
      rating: 5,
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentor2Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentor2Text' })
    },
    {
      image: mentorImages.reviewMentorImage3,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentor3Name' }),
      rating: 5,
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentor3Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentor3Text' })
    },
  ];

  const menteesReviews = [
    {
      image: menteeImages.reviewMenteeImage1,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentee1Name' }),
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentee1Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentee1Text' })
    },
    {
      image: menteeImages.reviewMenteeImage2,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentee2Name' }),
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentee2Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentee2Text' })
    },
    {
      image: menteeImages.reviewMenteeImage3,
      name: intl.formatMessage({ id: 'SectionReviews.reviewMentee3Name' }),
      position: intl.formatMessage({ id: 'SectionReviews.reviewMentee3Position' }),
      review: intl.formatMessage({ id: 'SectionReviews.reviewMentee3Text' })
    },
  ];

  const reviews = isLandingPage ? mentorsReviews : menteesReviews;

  const reviewsItems = reviews.map(item => {
    return (
      <div className={css.reviewsItem}>
        <div className={css.reviewsImgHolder}>
          <img className={css.reviewsImg} src={item.image} alt={item.name} />
        </div>
        <div className={css.reviewsTitleHolder}>
          <h3 className={css.reviewsTitle}>
            {item.name}
          </h3>
          {item?.rating && (
            <div className={css.reviewsRating}>
              {STARS.map(star => (
                <IconReviewStar
                  key={`star-${star}`}
                  isFilled={star <= item.rating}
                  type='custom'
                />
              ))}
              <span>
                {item.rating.toFixed(1)}
              </span>
            </div>
          )}
        </div>
        <p className={css.reviewsPosition}>
          {item.position}
        </p>
        <p className={css.reviewsDescription}>
          {item.review}
        </p>
      </div>
    )
  });

  const prevButton = () => {
    return <IconArrowCarousel direction="left" />
  };

  const nextButton = () => {
    return <IconArrowCarousel direction="right" />
  };

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MOBILE_BREAKPOINT;

  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          {isLandingPage ?
            <FormattedMessage id="SectionReviews.title" values={{ lineBreak: <br /> }} /> :
            <FormattedMessage id="SectionReviews.titleMentee" values={{ lineBreak: <br /> }} />
          }
        </h2>

        <div className={css.reviewsWrapper}>
          <AliceCarousel
            disableDotsControls={true}
            disableButtonsControls={isMobileLayout}
            infinite={true}
            disableSlideInfo={false}
            mouseTracking
            items={reviewsItems}
            renderPrevButton={() => prevButton()}
            renderNextButton={() => nextButton()}
            paddingRight={isMobileLayout && 40}
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 2,
                itemsFit: 'contain',
              },
              1280: {
                items: 3,
                itemsFit: 'contain',
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

SectionReviewsComponent.defaultProps = { rootClassName: null, className: null };

SectionReviewsComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionReviews = injectIntl(SectionReviewsComponent);

export default SectionReviews;
