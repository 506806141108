import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, ListingBand, PaginationLinks } from '../../components';
import css from './FavoritesPanel.module.css';

const FavoritesPanel = props => {
  const {
    className, rootClassName, currentUser, listings, history, removeFromFavorites
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `${panelLargeWidth / 2}vw`,
  ].join(', ');

  const cards = (
    <div className={css.listingCards}>
      {listings.map(l => (
        <ListingCard
          className={css.listingCard}
          key={l.id.uuid}
          listing={l}
          renderSizes={cardRenderSizes}
          from="search"
          history={history}
          currentUser={currentUser}
          isFavorite={ true }
          removeFromFavorites={removeFromFavorites}
        />
      ))}
      {props.children}
    </div>
  );

  return (
    <div className={classes}>
      {cards}
    </div>
  );
};

FavoritesPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

FavoritesPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default FavoritesPanel;
