import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, ExternalLink } from '../../components';

import css from './SectionLearnFromPeople.module.css';

import leftImage from './images/left-image.png';

const SectionLearnFromPeople = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.stepPictureWrapper}>
          <div className={css.stepPicture}>
            <img
              className={css.explanationPicture}
              src={leftImage}
            />
          </div>
        </div>

        <div className={css.stepExplanationWrapper}>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionLearnFromPeople.part1Title" />
          </h2>
          <div className={css.actionButtons}>
            <NamedLink
              key="linkToLandingPage1"
              name="SearchPage"
              className={css.searchButton}
              title="Find a mentor"
            >
              <FormattedMessage id="SectionLearnFromPeople.chooseMentorButton" />
            </NamedLink>
            <NamedLink
              name="SignupPage"
              to={{
                search: 'userType=mentor',
              }}
              className={css.signupButton}
            >
              <FormattedMessage id="SectionLearnFromPeople.becomeMentorButton" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionLearnFromPeople.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionLearnFromPeople.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionLearnFromPeople;
