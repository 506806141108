import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { menteeIcons } from './images/menteeIcons';

import css from './SectionBenefits.module.css';

const BenefitsContentForMentee = ({ currentUser, intl }) => {

  const cardsData = [
    {
      image: menteeIcons?.excellentMentors,
      title: intl.formatMessage({id: 'SectionBenefits.menteeTitle1'}),
      text: intl.formatMessage({id: 'SectionBenefits.menteeText1'}),
    },
    {
      image: menteeIcons?.interviewPrep,
      title: intl.formatMessage({id: 'SectionBenefits.menteeTitle2'}),
      text: intl.formatMessage({id: 'SectionBenefits.menteeText2'}),
    },
    {
      image: menteeIcons?.careerClarity,
      title: intl.formatMessage({id: 'SectionBenefits.menteeTitle3'}),
      text: intl.formatMessage({id: 'SectionBenefits.menteeText3'}),
    },
    {
      image: menteeIcons?.skillBuilding,
      title: intl.formatMessage({id: 'SectionBenefits.menteeTitle4'}),
      text: intl.formatMessage({id: 'SectionBenefits.menteeText4'}),
    },
    {
      image: menteeIcons?.networking,
      title: intl.formatMessage({id: 'SectionBenefits.menteeTitle5'}),
      text: intl.formatMessage({id: 'SectionBenefits.menteeText5'}),
    },
  ];

  return (
    <div className={css.content}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionBenefits.titleMentee" />
      </h2>

      <ul className={css.cards}>
        {cardsData.map(item => {
          return (
            <li className={css.cardsItem} key={item.title}>
              {item.image}
              <h3 className={css.cardsItemTitle}>
                {item.title}
              </h3>
              <p className={css.cardsItemText}>
                {item.text}
              </p>
            </li>
          )
        })}
      </ul>

      <p className={classNames(css.subTitle, css.subTitleForMentee)}>
        <FormattedMessage
          id="SectionBenefits.subTitleMentee"
          values={{
            orangeUnderline: <span className={css.orangeTextUnderline}>Get a leg up in your career</span>,
            orangeText1: <span className={css.orangeText}>YOUR specific job</span>,
            orangeText2: <span className={css.orangeText}>your dream job</span>,
          }}
        />
      </p>

      {!currentUser && (
        <NamedLink
          name="SignupPage"
          to={{
            search: 'userType=mentor',
          }}
          className={classNames(css.button, css.buttonMint)}
        >
          <FormattedMessage id="SectionBenefits.startMenteeingToday" />
        </NamedLink>
      )}

    </div>
  );
};

export default BenefitsContentForMentee;